export const GET_DASHBOARDS = "GET_DASHBOARDS";
export const CHANGE_DASHBOARD = "CHANGE_DASHBOARD";
export const ADD_TO_DASHBOARD = "ADD_TO_DASHBOARD";
export const UPDATE_DASHBOARD = "UPDATE_DASHBOARD";
export const RELOAD_DASHBOARD = "RELOAD_DASHBOARD";

// Social Media actions

export const GET_TWEETS = "GET_TWEETS";
export const GET_TOPICS = "GET_TOPICS";
export const GET_TOPIC_TWEETS = "GET_TOPIC_TWEETS";
export const GET_COMMUNITIES = "GET_COMMUNITIES";
export const GET_COMMUNITY_USER_TWEETS = "GET_COMMUNITY_USER_TWEETS";
export const SELECT_COMMUNITY = "SELECT_COMMUNITY";

// Geolocalised Linked Data actions

export const GET_GEOLOCALISED_LINKED_DATA = "GET_GEOLOCALISED_LINKED_DATA";
