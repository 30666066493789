<template>
  <empty
    class="mt-5"
    :text="`Redirecting to ${authProviderName} ...`"
    loading
    show-loading-content
  ></empty>
</template>

<script>
import Vue from "vue";
import { TOKEN_LOGIN } from "@/store/action-types";
import { TOKEN_COOKIE_NAME } from "@/api/utils";
import userService from "@/api/asb/services/usermgr";
import { getLoginUrl } from "@/api/utils";
import Empty from "@/components/share/Empty";

export default {
  name: "LoginRedirect",
  components: { Empty },
  props: {
    oidcRedirect: {
      type: Boolean,
      default: false
    }
  },
  beforeRouteEnter(to, from, next) {
    Vue.$cookies.remove(TOKEN_COOKIE_NAME);
    next(vm => {
      // dispatch TOKEN_LOGIN to check if the user is already authenticated from /usermgr/ path.
      // If it is the case, the sessionid cookie will be used as authentication mechanism.
      // Note that this works because the webapp and the User Manager run under the same host.
      vm.$store
        .dispatch(TOKEN_LOGIN, {})
        .then(() => {
          vm.$router.push({ name: "home" });
        })
        .catch(error => {
          if (!error.response || (error.response && error.response.status > 499)) {
            userService.handleError(error);
          } else {
            // here the User Manager returns a 400-404 error,
            // which means that the user needs to login to retrieve his details
            let nextHref;
            if (vm.$route.query["next"] === undefined) {
              nextHref = "/asb/";
            } else {
              nextHref = vm.$route.query["next"];
            }
            const loginUrl = getLoginUrl(vm.oidcRedirect);
            window.location.href = `${loginUrl}/?next=${nextHref}`;
          }
        });
    });
  },
  computed: {
    authProviderName() {
      let value;
      if (this.oidcRedirect) {
        value = this.$store.state.instance.oidcProviderName;
      } else {
        value = "CAS";
      }
      return value !== undefined ? value : "authentication service";
    }
  }
};
</script>

<style scoped></style>
