<template>
  <b-container class="my-4">
    <b-modal
      ref="download-cwl"
      hide-footer
      id="download-cwl"
      :title="`Download CWL file for application ${selectedApp.title}`"
    >
      <b-row align-h="end" no-gutters>
        <b-col cols="12 text-center">
          <fa-icon class="mr-2" icon="download" size="2x"></fa-icon>
        </b-col>
        <b-col cols="12" class="text-center">
          <a :href="selectedApp.downloadLink" download>
            <b-button variant="success" class="mt-2" :disabled="cwlLoading">
              <span v-if="!cwlLoading">Download CWL file</span>
              <span v-else><loading :loading="cwlLoading"></loading></span>
            </b-button>
          </a>
        </b-col>
      </b-row>
      <b-row align-h="end" no-gutters>
        <b-col cols="3"> </b-col>
      </b-row>
    </b-modal>
    <b-modal
      ref="delete-app"
      hide-footer
      id="delete-app"
      :title="`Deleting application ${selectedApp.title}`"
    >
      <b-row align-h="center" no-gutters>
        <b-col class="my-3" cols="12">
          <span class="lead">
            <span
              >You are about to delete <b>{{ selectedApp.title }}</b> &nbsp;</span
            >
          </span>
          <b-button
            variant="failed"
            @click="deleteApplication"
            :disabled="deleting"
            block
            class="mt-4"
          >
            <span v-if="!deleting">
              <fa-icon class="mr-3" icon="trash" />
              <span>Delete it</span>
            </span>
            <b-spinner v-else small></b-spinner>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal ref="show-info" hide-footer id="show-info" :title="`Info about ${selectedApp.title}`">
      <b-row align-h="end" no-gutters>
        <b-col cols="12" class="text-center" v-if="appInfoLoading">
          <loading :loading="appInfoLoading" class="mx-auto"></loading>
        </b-col>
        <b-col cols="12" v-else>
          <div>
            <h5>{{ selectedAppInfo.doc }}</h5>
            <p class="mb-1"><b>Version</b>: {{ selectedAppInfo.softwareVersion }}</p>
            <p class="mb-0"><b>Inputs</b>:</p>
            <ul class="mb-1">
              <li v-for="input in selectedAppInfo.inputs" :key="input.label">
                {{ input.label }}
                <span class="text-muted">({{ input.type }})</span>
              </li>
            </ul>
            <div v-if="selectedAppInfo.author">
              <p class="mb-0"><b>Contact:</b></p>
              <ul>
                <li v-for="item in contactInformation" :key="item.key">
                  <b>{{ item.key }}</b
                  >: {{ item.value }}
                </li>
              </ul>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row align-h="end" no-gutters>
        <b-col cols="3"> </b-col>
      </b-row>
    </b-modal>
    <b-row align="center">
      <b-col>
        <loading :loading="loading"></loading>
      </b-col>
    </b-row>
    <page-title
      :title="`${collection.ownerId} / ${collection.verboseName}`"
      :subtitle="collection.abstract"
    >
      <template v-slot:toolbar>
        <b-btn-toolbar class="float-right" justify>
          <slot name="title-toolbar" v-bind:loading="loading"></slot>
          <b-btn-group class="ml-2" v-if="canUploadNewApplication">
            <UploadCWL
              :collectionId="id"
              :collectionName="collection.verboseName"
              v-on:registered-application="loadApplications"
            ></UploadCWL>
          </b-btn-group>
        </b-btn-toolbar>
      </template>
    </page-title>
    <b-row v-if="!$_.isEmpty(workspaces)" class="divider" no-gutters>
      <b-col class="mb-2" cols="12">
        <span class="text-secondary">Workspaces</span>
      </b-col>
      <b-col cols="12">
        <workspace-badges :workspaces="collection.workspaces"></workspace-badges>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col>
        <h4>Applications in this collection</h4>
      </b-col>
    </b-row>
    <b-row align="center">
      <b-col>
        <b-overlay class="mt-3" :show="applicationsLoading">
          <b-table
            ref="table"
            :items="applications"
            thead-class="d-none"
            :fields="fields"
            show-empty
          >
            <template v-slot:cell(detail)="data">
              <b>{{ data.item.title }} </b
              ><b-badge variant="primary">{{ version(data.item) }}</b-badge>
              <br />
              {{ data.item.abstract }}
            </template>
            <template v-slot:cell(toolbar)="data">
              <div class="float-right">
                <b-button variant="primary" size="sm" class="mx-1" @click="displayInfo(data.item)"
                  >Info</b-button
                >
                <b-button
                  variant="primary"
                  size="sm"
                  class="mx-1"
                  :to="`${id}/detail/${data.item.identifier}`"
                  >Details</b-button
                >
                <b-button
                  variant="danger"
                  size="sm"
                  class="mx-1"
                  :to="`/collections/${id}/execute/${data.item.identifier}`"
                  >Execute</b-button
                >
                <b-dropdown
                  class="no-caret"
                  variant="transparent"
                  size="sm"
                  right
                  v-if="ownerOrSuperuser"
                >
                  <template slot="button-content">
                    <fa-icon icon="bars"></fa-icon>
                  </template>
                  <b-dropdown-item @click="downloadCWL(data.item)">
                    <fa-icon class="mr-2" icon="download"></fa-icon>
                    <span>Download CWL</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="confirmDelete(data.item)">
                    <fa-icon class="mr-2" icon="trash"></fa-icon>
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
            <template #empty="">
              <h5>
                There are no applications registered in this collection yet
              </h5>
            </template>
          </b-table>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import PageTitle from "@/components/share/PageTitle";
import { removeJsonApiMeta } from "@/utils";
import backendService from "@/api/asb/services/backend";
import WorkspaceBadges from "@/components/share/WorkspaceBadges";
import Loading from "@/components/share/Loading";
import UploadCWL from "@/components/collections/UploadCWL";
import { capitalize } from "@/utils";
import { mapState } from "vuex";

export default {
  name: "CollectionDetail",
  components: {
    PageTitle,
    WorkspaceBadges,
    Loading,
    UploadCWL
  },
  data() {
    return {
      id: this.$route.params["id"],
      loading: false,
      workspaces: [],
      workspaceOptions: [],
      applicationsLoading: false,
      applications: [],
      fields: [
        {
          key: "detail",
          label: "",
          sortable: true
        },
        {
          key: "toolbar",
          label: "",
          sortable: false,
          tdClass: "toolbarcell"
        }
      ],
      cwlLoading: false,
      selectedApp: {
        title: "application",
        downloadLink: ""
      },
      selectedAppInfo: {},
      appInfoLoading: false,
      deleting: false,

      ESClient: undefined,
      ESClientConnected: false
    };
  },
  created() {
    this.loadApplications();
  },
  methods: {
    downloadCWL(application) {
      this.selectedApp = application;
      this.cwlLoading = true;
      backendService
        .createCWLDownloadLink(this.id, application.identifier)
        .then(link => {
          this.selectedApp.downloadLink = link;
          this.cwlLoading = false;
        })
        .catch(error => {
          backendService.handleError(error);
          this.cwlLoading = false;
        });
      this.$bvModal.show("download-cwl");
    },
    confirmDelete(application) {
      this.selectedApp = application;
      this.$bvModal.show("delete-app");
    },
    deleteApplication() {
      this.deleting = true;
      backendService
        .deleteApplication(this.id, this.selectedApp.identifier)
        .then(() => {
          this.$notify({
            type: "success",
            title: "Successfully deleted application",
            text: this.selectedApp.title
          });
          this.$bvModal.hide("delete-app");
          this.loadApplications();
          this.deleting = false;
        })
        .catch(error => {
          backendService.handleError(error);
          this.deleting = false;
        });
    },
    loadApplications() {
      this.loading = true;
      this.$store.dispatch("backend/get", "workspaces").then(data => {
        const workspaces = removeJsonApiMeta(data);
        this.workspaceOptions = Object.values(workspaces);
        this.$store
          .dispatch("backend/get", `catalogue-collections/${this.id}`)
          .then(() => {
            this.workspaces = [];
            if (this.collection.workspaces) {
              for (let workspaceId in workspaces) {
                if (workspaceId in this.collection.workspaces) {
                  this.workspaces.push(workspaces[workspaceId]);
                }
              }
            }
          })
          .catch(error => backendService.handleError(error))
          .finally(() => (this.loading = false));
        this.applicationsLoading = true;
        backendService
          .getCollectionApplications(this.id)
          .then(applications => (this.applications = applications))
          .catch(error => backendService.handleError(error))
          .finally(() => (this.applicationsLoading = false));
      });
    },
    displayInfo(application) {
      this.selectedApp = application;
      this.appInfoLoading = true;
      backendService
        .describeApplication(this.id, application.identifier)
        .then(data => (this.selectedAppInfo = data.data))
        .catch(error => backendService.handleError(error))
        .finally(() => (this.appInfoLoading = false));
      this.$bvModal.show("show-info");
    },
    version(application) {
      let version = "";
      for (let keyword of application.keywords) {
        if (/softwareVersion:(.*)/.test(keyword)) {
          version = keyword.split(":")[1];
          break;
        }
      }
      return version;
    }
  },
  computed: {
    ...mapState({
      user: state => state.user
    }),
    collection() {
      return this.$store.getters["backend/get"](`catalogue-collections/${this.id}`);
    },
    contactInformation() {
      let info = [];

      if (this.selectedAppInfo.author) {
        for (let key in this.selectedAppInfo.author[0]) {
          if (key == "class") {
            continue;
          }
          let value = this.selectedAppInfo.author[0][key];
          let newKey;
          switch (key) {
            case "s:affiliation":
              newKey = "Affiliation";
              break;
            case "s:name":
              newKey = "Name";
              break;
            case "s:email":
              newKey = "Email";
              break;
            default:
              newKey = key;
          }
          newKey = capitalize(newKey);
          info.push({
            key: newKey,
            value: value
          });
        }
      }
      return info;
    },
    ownerOrSuperuser() {
      return Object.keys(this.user).length != 0
        ? this.user.is_superuser
          ? true
          : this.user.username === this.collection.ownerId
        : false;
    },
    canUploadNewApplication() {
      if (this.user.is_superuser) {
        return true;
      }
      return (
        this.user.username === this.collection.ownerId &&
        !this.collection.identifier.endsWith(":published")
      );
    }
  }
};
</script>

<style>
.toolbarcell {
  width: 25%;
}
</style>
