<template>
  <b-container class="mt-4">
    <b-modal
      ref="upload"
      hide-footer
      id="upload"
      :title="`Upload a new application CWL to ${collectionName}`"
    >
      <b-row align-h="end" no-gutters>
        <b-col cols="12">
          <vue-dropzone
            ref="dropzone"
            id="dropzone"
            v-on:vdropzone-success="uploadSuccess"
            v-on:vdropzone-error="uploadError"
            :options="dropzoneOptions"
            useCustomSlot
          ></vue-dropzone>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="my-2 text-center">
          <b-progress
            :value="uploadProgress"
            max="100"
            show-progress
            animated
            :variant="uploadVariant"
          ></b-progress>
          <span>
            {{ uploadStepText }}
          </span>
        </b-col>
      </b-row>
      <b-row align-h="end" no-gutters>
        <b-col cols="3">
          <b-button type="submit" @click="uploadFiles" variant="success" class="mt-2" block>
            Upload
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-row>
      <b-col>
        <b-btn v-b-modal.upload float-right variant="success">
          Register new application
        </b-btn>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import SSEClient from "@/api/sse.js";
import { mapState } from "vuex";

export default {
  name: "UploadCWL",
  components: {
    vueDropzone: vue2Dropzone
  },
  props: {
    collectionId: {
      type: String,
      required: true,
      default: "0"
    },
    collectionName: {
      type: String,
      required: true,
      default: ""
    }
  },
  data() {
    return {
      dropzoneOptions: {
        url: `/backend/v1/catalogue-collections/${this.collectionId}/upload_cwl`,
        thumbnailWidth: 100,
        maxFilesize: 20,
        autoProcessQueue: false,
        headers: {
          Authorization: `Bearer ${this.$cookies.get("asbglobaltoken")}`,
          Accept: "application/vnd.api+json"
        }
        // addRemoveLinks: true
      },
      SSEClient: undefined,
      SSEClientConnected: false,
      uploadProgress: 0,
      uploadStepText: "",
      uploadFailed: false
    };
  },
  created() {
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      if (modalId == "upload") {
        this.uploadProgress = 0;
        this.uploadFailed = false;
        (this.uploadStepText = ""), this.ESClient.disconnect();
      }
    });
    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      if (modalId == "upload") {
        this.connectToSSE();
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    this.ESClient.disconnect();
    next();
  },
  methods: {
    uploadSuccess() {
      console.log("upload success");
      this.$bvModal.hide("upload");
      this.$emit("registered-application");
      this.uploadProgress = 0;
      this.uploadFailed = false;
      this.uploadStepText = "";
    },
    uploadError() {
      console.log("upload error");
      this.uploadProgress = 100;
      this.uploadFailed = true;
    },
    uploadFiles() {
      console.log("submitting!");
      this.uploadProgress = 0;
      this.uploadFailed = false;
      this.uploadStepText = "";
      this.$refs.dropzone.processQueue();
    },
    connectToSSE() {
      this.ESClient = new SSEClient(`/backend/live/${this.user.username}/`);
      this.ESClientConnected = true;
      this.ESClient.addMessageHandler(this.handleSSEMessage);
    },
    handleSSEMessage(message) {
      if (message.type != "uploadUpdate") {
        return;
      }
      this.uploadProgress = message.progress;
      this.uploadStepText = message.step;
    }
  },
  computed: {
    ...mapState({
      user: state => state.user
    }),
    uploadVariant() {
      return this.uploadFailed ? "danger" : "primary";
    }
  }
};
</script>

<style></style>
