<template>
  <!-- prettier-ignore -->
  <highlight-code lang="python">
      <pre>
#!/usr/bin/python

import logging

# --------------------------------------------------------------------------------------
# Save this code in file "process_wrapper.py" and adapt as indicated in inline comments.
#
# Notes:
#  - This is a Python 3 script.
#  - The inputs will be given values by name, thus their order has no importance ...
#  - ... except that the inputs with a default value must be listed last.
#  - Parameter names are automatically converted into valid Python variable names.
#  - Any empty line or line starting with a '#' character will be ignored.
# --------------------------------------------------------------------------------------


logger = logging.getLogger(__name__)

def execute({{ inputParams }}):
    """
    Inputs:
{{ inputList }}

    Outputs:
{{ outputList }}

    Main Dependency:
{{ mainDependency }}

    Software Dependencies:
{{ softwareDependencyList }}

    Processing Resources:
    ram -- {{ resources.ram }}
    disk -- {{ resources.disk }}
    cpu -- {{ resources.cpu }}
    gpu -- {{ resources.gpu }}
    """

{{ outputsInit }}

    # ----------------------------------------------------------------------------------
    # Insert your own code below.
    # The files generated by your code must be stored in the "out_dir" folder.
    # Only the content of that folder is persisted in the datastore.
    # Give appropriate values to the output parameters. These will be passed to the next
    # process(es) following the workflow connections.
    # ----------------------------------------------------------------------------------

    logger.info("Starting...")

    # ...


    # ----------------------------------------------------------------------------------
    # The wrapper must return a dictionary that contains the output parameter values.
    # ----------------------------------------------------------------------------------
    return {
{{ outputsReturn }}
    }
    </pre>
  </highlight-code>
</template>

<script>
import { slugify } from "@/utils.js";

export default {
  name: "ProcessTemplate",
  props: {
    tag: {
      type: String
    },
    inputs: {
      type: Array
    },
    outputs: {
      type: Array
    },
    resources: {
      type: Object
    },
    mainDep: {
      type: Object
    },
    softwareDeps: {
      type: Array
    }
  },
  computed: {
    inputParams() {
      let l = ["out_dir"];
      for (let input of this.inputs) {
        if (input.defaultValue) {
          l.push(`${slugify(input.name, "_")}='${input.defaultValue}'`);
        } else {
          l.push(`${slugify(input.name, "_")}`);
        }
      }
      return l.join(", ");
    },
    inputList() {
      let l = [];
      for (let input of this.inputs) {
        const dataTypeFullId = input.dataType._jv.id + "/" + input.dataType.label;
        let value = `    ${slugify(input.name, "_")} -- ${input.name} -- ${dataTypeFullId}`;
        if (input.defaultValue) {
          value += ` -- ${input.defaultValue}`;
        }
        l.push(value);
      }
      return l.join("\n");
    },
    outputList() {
      let l = [];
      for (let output of this.outputs) {
        const dataTypeFullId = output.dataType._jv.id + "/" + output.dataType.label;
        let value = `    ${slugify(output.name, "_")} -- ${output.name} -- ${dataTypeFullId}`;
        l.push(value);
      }
      return l.join("\n");
    },
    mainDependency() {
      return `    ${this.mainDep ? this.mainDep.identifier : ""}`;
    },
    softwareDependencyList() {
      let l = [];
      for (let dep of this.softwareDeps) {
        let value = `    ${dep.identifier}`;
        l.push(value);
      }
      return l.join("\n");
    },
    outputsInit() {
      let l = [];
      for (let output of this.outputs) {
        l.push(`    ${slugify(output.name, "_")} = None`);
      }
      return l.join("\n");
    },
    outputsReturn() {
      let l = [];
      for (let output of this.outputs) {
        l.push(`        "${slugify(output.name, "_")}": ${slugify(output.name, "_")}`);
      }
      return l.join(",\n");
    }
  }
};
</script>

<style scoped></style>
