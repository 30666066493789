<template>
  <b-container class="my-4 px-5" fluid>
    <b-row align-h="center">
      <b-col cols="11">
        <page-title class="mb-2" title="Executions" :fetching="fetching" :no-fetching="false">
          <template v-slot:toolbar>
            <b-btn-toolbar class="float-right"> </b-btn-toolbar>
          </template>
        </page-title>
        <b-row>
          <b-col>
            <b-table
              v-show="!$_.isEmpty(executions)"
              :fields="fields"
              :items="Object.values(executions)"
              sort-by="createdAt"
              sort-desc
              small
              hover
              foot-clone
              fixed
            >
              <template v-slot:row-details="data">
                <b-row class="my-2" align-h="end">
                  <b-col cols="9">
                    <h5 class="no-bold">Inputs</h5>
                    <b-row>
                      <b-col class="mt-1" cols="12">
                        <b-row no-gutters>
                          <b-col>
                            <table class="my-0 table table-bordered" v-if="!isAModel">
                              <tbody>
                                <tr v-for="(value, key) in data.item.inputs" :key="key">
                                  <th scope="row" width="20%">{{ key }}</th>
                                  <td>{{ value }}</td>
                                </tr>
                              </tbody>
                            </table>
                            <table class="my-0 table table-bordered" v-if="isAModel">
                              <tbody>
                                <tr v-for="(value, key) in data.item.inputs.inputs" :key="key">
                                  <th scope="row" width="20%">{{ value.name }}</th>
                                  <td>{{ value.data }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </template>
              <template v-slot:cell(createdAt)="data">
                <span>{{ data.value | moment("YYYY-MM-DD HH:mm") }}</span>
                <b-btn
                  v-if="!$_.isEmpty(data.item.schedule)"
                  variant="link"
                  size="sm"
                  :to="{
                    name: 'schedule',
                    params: { id: data.item.schedule._jv.id }
                  }"
                >
                  <fa-icon icon="clock" class="mr-2"></fa-icon>
                  <span>{{ data.item.schedule.identifier }}</span>
                </b-btn>
              </template>
              <template v-slot:cell(targetPlatform)="data">
                <span>{{ data.item.workerSelectors[0].label }}</span>
              </template>
              <template v-slot:cell(toolbar)="data">
                <!-- we use @click.stop here to prevent emitting of a 'row-clicked' event  -->
                <b-row>
                  <b-col cols="12" align="right">
                    <b-btn-group class="mr-1 mb-1">
                      <status-badge
                        class="status-badge-group"
                        :progress="data.item.progress"
                        :status="data.item.status"
                      ></status-badge>
                    </b-btn-group>
                    <b-btn-group class="mr-1 mb-1">
                      <b-button
                        variant="outline-dark"
                        size="sm"
                        :to="getExecutionReportRoute(data.item._jv.id)"
                      >
                        <fa-icon far icon="file-alt"></fa-icon>
                        <span class="ml-2">Execution Report</span>
                      </b-button>
                    </b-btn-group>
                    <b-btn-group class="mr-1 mb-1">
                      <b-button variant="transparent" size="sm" @click.stop="data.toggleDetails">
                        <fa-icon
                          :icon="data.detailsShowing ? 'chevron-down' : 'chevron-left'"
                        ></fa-icon>
                      </b-button>
                    </b-btn-group>
                  </b-col>
                </b-row>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <empty
          v-if="$_.isEmpty(executions)"
          :text="error ? 'Something went wrong while fetching executions' : 'No executions found'"
          :icon="error ? 'exclamation' : 'folder-open'"
          class="mt-2"
        ></empty>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <b-pagination
          v-show="!loading && !$_.isEmpty(executions)"
          align="center"
          :total-rows="pagination.count"
          :per-page="pageSize"
          v-model="pagination.page"
          @input="changePage"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import taskmgrService from "@/api/asb/services/taskmgr";
import StatusBadge from "@/components/share/StatusBadge";
import PageTitle from "@/components/share/PageTitle";
import SSEClient from "@/api/sse.js";
import { mapState } from "vuex";
import { utils } from "jsonapi-vuex";
import Empty from "@/components/share/Empty";

export default {
  name: "ApplicationExecutionList",
  components: { StatusBadge, PageTitle, Empty },
  props: {
    isAModel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fields: [
        {
          key: "workflowIdentifier",
          label: "Application ID",
          sortable: true
        },
        {
          key: "processVersion",
          label: "Version",
          sortable: true
        },
        {
          key: "ownerId",
          label: "Executed by",
          sortable: true
        },
        {
          key: "createdAt",
          label: "Started at",
          sortable: true
        },
        {
          key: "targetPlatform",
          label: "Target Platform",
          sortable: true
        },
        {
          key: "toolbar",
          label: "",
          sortable: false,
          thStyle: { width: "30%" }
        }
      ],
      pagination: {
        page: 1,
        pages: 1,
        count: 1
      },
      error: false,
      pageSize: 20,
      loading: false,
      fetching: false,
      ESClient: undefined,
      ESClientConnected: false
    };
  },
  created() {
    this.fetchExecutions();
    this.connectToSSE();
  },
  beforeRouteLeave(to, from, next) {
    this.ESClient.disconnect();
    next();
  },
  methods: {
    fetchExecutions(page = 1) {
      this.fetching = true;
      const params = {
        sort: "-createdAt",
        "page[number]": page,
        "page[size]": this.pageSize
      };
      let taskType = "ades-tasks";
      if (this.isAModel) {
        taskType = "seldon-tasks";
      }
      this.$store.commit("taskmgr/clearRecords", {
        _jv: { type: "tasks" }
      });
      this.$store
        .dispatch("taskmgr/get", [taskType, { params: params }])
        .then(response => {
          response = response._jv.json;
          this.pagination = response.meta.pagination;
          this.fetching = false;
        })
        .catch(error => {
          taskmgrService.handleError(error);
          this.fetching = false;
          this.error = true;
        });
    },
    changePage(page) {
      console.debug("Changing page to", page);
      this.fetchExecutions(page, this.filter);
    },
    connectToSSE() {
      let url;
      if (this.user.is_superuser) {
        url = "/backend/live/_all_executions/";
      } else {
        url = `/backend/live/${this.user.username}/`;
      }
      this.ESClient = new SSEClient(url);
      this.ESClientConnected = true;
      this.ESClient.addMessageHandler(this.handleSSEMessage);
      this.ESClient.addErrorHandler(this.handleSSEError);
    },
    handleSSEMessage(message) {
      message = message.data;
      // Only handle executionUpdate messages
      if (message.type != "executionUpdate") {
        return;
      }
      // Check if the execution already exists in the store, otherwise fetch it
      let executionToUpdate = this.$store.getters["taskmgr/get"](`tasks/${message.task_id}`);
      if (Object.keys(executionToUpdate).length === 0) {
        this.$store.dispatch("taskmgr/get", `tasks/${message.task_id}`);
      }

      // Update the actual execution in the store
      for (let execution in this.executions) {
        if (message.task_id == this.executions[execution]._jv.id) {
          let updatedExecution = utils.deepCopy(this.executions[execution]);
          if (message.progress == 100) {
            updatedExecution.status = message.status;
          }
          updatedExecution.progress = message.progress / 100;
          updatedExecution.workflowIdentifier = message.application_id;
          updatedExecution.processVersion = message.process_version;
          this.$store.commit("taskmgr/addRecords", updatedExecution);
        }
      }
    },
    handleSSEError(error) {
      this.$notify({
        type: "error",
        title: "Failed to connect to live updates",
        text: error
      });
    },
    getExecutionReportRoute(id) {
      if (this.isAModel) {
        return `/monitoring/models/${id}/report`;
      } else {
        return `/monitoring/applications/${id}/report`;
      }
    }
  },
  computed: {
    executions() {
      return this.$store.getters["taskmgr/get"]("tasks");
    },
    ...mapState({
      user: state => state.user,
      theme_features: state => state.instance.theme_features
    })
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/default/asb.scss";

.status-badge-group {
  width: $font-size-base * 12;
  height: $font-size-base * 2;
}
</style>
