<template>
  <b-container class="mt-4 mb-2">
    <b-row>
      <b-col>
        <page-title class="mb-3" :title="'New Collection'"> </page-title>
        <b-row class="mt-5" align-h="center">
          <b-col lg="9" cols="12">
            <page-title subtitle="Collection information" no-divider></page-title>
            <b-form-row class="mb-2">
              <b-col class="col-form-label" cols="2">
                <label for="lang"><b>Identifier:</b></label>
              </b-col>
              <b-col>
                <b-form-input
                  id="lang"
                  type="text"
                  v-model="newCollection.identifier"
                  @blur="stringToSlug"
                />
                <b-form-text>
                  Prefix "urn:eop:MEPWPS:" will be automatically prepended.
                </b-form-text>
              </b-col>
            </b-form-row>
            <b-form-row class="mb-2">
              <b-col class="col-form-label" cols="2">
                <label for="title"><b>Title:</b></label>
              </b-col>
              <b-col>
                <b-form-input id="title" type="text" v-model="newCollection.title" />
              </b-col>
            </b-form-row>
            <b-form-row class="mb-2">
              <b-col class="col-form-label" cols="2">
                <label for="geometry">Geometry:</label>
              </b-col>
              <b-col>
                <b-form-input
                  id="geometry"
                  type="text"
                  v-model="newCollection.geometry"
                  :state="isValidJSON('geometry')"
                />
                <b-form-text>
                  Please insert a valid geometry in double square brackets JSON format.
                </b-form-text>
              </b-col>
            </b-form-row>
            <b-form-row class="mb-2">
              <b-col class="col-form-label" cols="2">
                <label for="lang">Language:</label>
              </b-col>
              <b-col>
                <b-form-input id="lang" type="text" v-model="newCollection.lang" />
              </b-col>
            </b-form-row>
            <b-form-row class="mb-2">
              <b-col class="col-form-label" cols="2">
                <label for="publisher">Publisher:</label>
              </b-col>
              <b-col>
                <b-form-input id="publisher" type="text" v-model="newCollection.publisher" />
              </b-col>
            </b-form-row>
            <b-form-row class="mb-2">
              <b-col class="col-form-label" cols="2">
                <label for="keywords">Keywords:</label>
              </b-col>
              <b-col>
                <b-form-input id="keywords" type="text" v-model="newCollection.keywords" />
                <b-form-text>
                  Keywords separated by spaces. "MEP-WPS" will be always added to the list.
                </b-form-text>
              </b-col>
            </b-form-row>
            <b-form-row class="mb-2">
              <b-col class="col-form-label" cols="2">
                <label for="rights">Rights:</label>
              </b-col>
              <b-col>
                <b-form-input id="rights" type="text" v-model="newCollection.rights" />
              </b-col>
            </b-form-row>
            <b-form-row class="mb-2">
              <b-col class="col-form-label" cols="2">
                <label for="contactPoint">Contact Point:</label>
              </b-col>
              <b-col>
                <b-form-input
                  id="contactPoint"
                  type="text"
                  v-model="newCollection.contactPoint"
                  :state="isValidJSON('contactPoint')"
                />
                <b-form-text>
                  Please enter a valid JSON string.
                </b-form-text>
              </b-col>
            </b-form-row>
            <b-form-row class="mb-2">
              <b-col class="col-form-label" cols="2">
                <label for="abstract">Abstract:</label>
              </b-col>
              <b-col>
                <b-form-input id="abstract" type="text" v-model="newCollection.abstract" />
              </b-col>
            </b-form-row>
            <b-form-row class="mb-2">
              <b-col class="col-form-label" cols="2">
                <label for="acquisitionInformation">Acquisition Information:</label>
              </b-col>
              <b-col>
                <b-form-input
                  id="acquisitionInformation"
                  type="text"
                  v-model="newCollection.acquisitionInformation"
                  :state="isValidJSON('acquisitionInformation')"
                />
                <b-form-text>
                  Please enter a valid JSON string.
                </b-form-text>
              </b-col>
            </b-form-row>
            <hr />
            <b-form-row class="mb-2">
              <b-col class="col-form-label" cols="2">
                <label for="authorType">Authors</label>
              </b-col>
            </b-form-row>
            <b-form-row class="mb-2" v-for="(author, index) in newCollection.authors" :key="index">
              <b-col class="col-form-label" cols="2">
                <label for="authorType">Author Type:</label>
              </b-col>
              <b-col>
                <b-form-input id="authorType" type="text" v-model="author.type" />
              </b-col>
              <b-col class="col-form-label" cols="2">
                <label for="authorEmail">Author Email:</label>
              </b-col>
              <b-col>
                <b-form-input id="authorEmail" type="email" v-model="author.email" />
              </b-col>
              <b-col class="col-form-label" cols="2">
                <label for="authorName">Author Name:</label>
              </b-col>
              <b-col>
                <b-form-input id="authorName" type="text" v-model="author.name" />
              </b-col>
            </b-form-row>
            <b-form-row class="mb-2">
              <b-col>
                <b-button variant="primary" size="md" @click="addNewAuthor">
                  <span>Add Author</span>
                </b-button>
              </b-col>
            </b-form-row>
            <hr />
            <b-form-row class="mb-2">
              <b-col class="col-form-label" cols="2">
                <label for="authorType">Additional Attributes</label>
              </b-col>
            </b-form-row>
            <b-form-row
              class="mb-2"
              v-for="(addAttr, index) in newCollection.additionalAttributes"
              :key="index"
            >
              <b-col class="col-form-label" cols="2">
                <label for="additionalAttributes">Key:</label>
              </b-col>
              <b-col>
                <b-form-input id="additionalAttributes" type="text" v-model="addAttr.key" />
              </b-col>
              <b-col class="col-form-label" cols="2">
                <label for="additionalAttributes">Value:</label>
              </b-col>
              <b-col>
                <b-form-input id="additionalAttributes" type="text" v-model="addAttr.value" />
              </b-col>
            </b-form-row>
            <b-form-row class="mb-2">
              <b-col>
                <b-button variant="primary" size="md" @click="addAdditionalAttributes">
                  <span>Add Additional Attributes</span>
                </b-button>
              </b-col>
            </b-form-row>
            <hr />
            <b-form-row class="mb-2">
              <b-col class="col-form-label" cols="2">
                <label for="distribution">Distribution:</label>
              </b-col>
              <b-col>
                <b-form-input
                  id="distribution"
                  type="text"
                  v-model="newCollection.distribution"
                  :state="isValidJSON('distribution')"
                />
                <b-form-text>
                  Please enter a valid JSON string.
                </b-form-text>
              </b-col>
            </b-form-row>
            <b-form-row class="mb-2">
              <b-col class="col-form-label" cols="2">
                <label for="categories">Categories:</label>
              </b-col>
              <b-col>
                <b-form-input
                  id="categories"
                  type="text"
                  v-model="newCollection.categories"
                  :state="isValidJSON('categories')"
                />
                <b-form-text>
                  Please enter a valid JSON string.
                </b-form-text>
              </b-col>
            </b-form-row>
            <b-form-row class="mb-2">
              <b-col class="col-form-label" cols="2">
                <label for="categories">Product Information:</label>
              </b-col>
              <b-col>
                <b-form-input
                  id="productInformation"
                  type="text"
                  v-model="newCollection.productInformation"
                  :state="isValidJSON('productInformation')"
                />
                <b-form-text>
                  Please enter a valid JSON string.
                </b-form-text>
              </b-col>
            </b-form-row>
            <b-form-row class="mb-2">
              <b-col class="col-form-label" cols="2">
                <label for="categories">Authorizations:</label>
              </b-col>
              <b-col>
                <b-form-input
                  id="authorizations"
                  type="text"
                  v-model="newCollection.authorizations"
                  :state="isValidJSON('authorizations')"
                />
                <b-form-text>
                  Please enter a valid JSON string.
                </b-form-text>
              </b-col>
            </b-form-row>
            <b-form-row class="mb-2">
              <b-col class="col-form-label" cols="2">
                <label for="categories">Source Data Access:</label>
              </b-col>
              <b-col>
                <b-form-input
                  id="srcDataAccess"
                  type="text"
                  v-model="newCollection.srcDataAccess"
                />
              </b-col>
            </b-form-row>
            <b-form-row class="mb-2">
              <b-col class="col-form-label" cols="2">
                <label for="categories">Destination Data Access:</label>
              </b-col>
              <b-col>
                <b-form-input
                  id="dstDataAccess"
                  type="text"
                  v-model="newCollection.dstDataAccess"
                />
              </b-col>
            </b-form-row>
            <b-form-row class="mb-2">
              <b-col class="col-form-label" cols="2">
                <label for="categories">ES Index:</label>
              </b-col>
              <b-col>
                <b-form-input id="esIndex" type="text" v-model="newCollection.esIndex" />
              </b-col>
            </b-form-row>

            <!--b-col cols="4">
              <b-button
                :variant="error ? 'failed' : 'success'"
                @click="createCollection"
                :disabled="creating"
                block
              >
              <b-button
                @click="createCollection"
                block
              >
                <span v-if="!creating">Create Collection</span>
                <b-spinner v-else small></b-spinner>
              </b-button>
            </b-col-->
          </b-col>
        </b-row>

        <b-row class="mt-3" align-h="center">
          <b-col lg="9" cols="12" align="right">
            <b-button variant="primary" size="md" @click="saveCollection()">
              <span>Request collection</span>
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import PageTitle from "@/components/share/PageTitle";
import knowledgeService from "@/api/asb/services/knowledge";
import Vue from "vue";

export default {
  name: "RequestCollection",
  components: { PageTitle },
  props: {
    additionalAttributes: {
      type: Array,
      required: false
    },
    authors: {
      type: Array,
      required: false
    },
    properties: {
      type: String,
      required: false
    },
    updated: {
      type: String,
      required: false
    },
    date: {
      type: Date,
      required: false
    },
    bbox: {
      type: String,
      required: false
    },
    hiddenInformation: {
      type: String,
      required: false
    },
    productInformation: {
      type: String,
      required: false
    },
    authorizations: {
      type: String,
      required: false
    },
    srcDataAccess: {
      type: String,
      required: false
    },
    dstDataAccess: {
      type: String,
      required: false
    },
    esIndex: {
      type: String,
      required: false
    }
  },
  methods: {
    saveCollection() {
      this.sending = true;
      let createdCollection = this.newCollection;
      console.debug(createdCollection);
      const newColl = {
        ...createdCollection,
        _jv: {
          type: "collections"
        }
      };
      this.$store
        .dispatch("knowledge/post", [newColl])
        .then(() => {
          this.$notify({
            group: "global",
            type: "success",
            title: `Your collection request has been sent`,
            duration: 3000,
            text: ""
          });
          this.$router.push({ name: "collections" });
        })
        .catch(e => {
          const response = e.response;
          let notified = false;
          if (response) {
            if (response.status === 400) {
              notified = true;
              Vue.notify({
                group: "global",
                type: "warn",
                title: "Bad request",
                duration: 20000, // in ms, -1 = no duration
                text: "Identifier and title are mandatory fields"
              });
            }
          }
          if (!notified) {
            knowledgeService.handleError(e);
          }
        });
      this.sending = false;
    },
    isValidJSON(attribute) {
      if (this.newCollection[attribute] === undefined) {
        return null;
      }
      try {
        JSON.parse(this.newCollection[attribute]);
      } catch (e) {
        return false;
      }
      return null;
    },
    isValidJSONGeometry(attribute) {
      if (!this.isValidJSON(attribute)) {
        return false;
      } else {
        return Boolean(this.newCollection[attribute].match(/\[\[.*\]\]/));
      }
    },
    addNewAuthor() {
      if (!this.sending) {
        this.newCollection.authors.push({
          type: "",
          email: "",
          name: ""
        });
      }
    },
    addAdditionalAttributes() {
      if (!this.sending) {
        this.newCollection.additionalAttributes.push({
          key: "",
          value: ""
        });
      }
    },
    stringToSlug() {
      console.log(this.newCollection.identifier);
      console.log("Uauuaeu");
      var str = this.newCollection.identifier.replace(/^urn:eop:MEPWPS:/, "");
      console.log(str);
      str = str.replace(/^\s+|\s+$/g, ""); // trim
      str = str.toLowerCase();

      // remove accents, swap ñ for n, etc
      var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,;";
      var to = "aaaaeeeeiiiioooouuuunc-----";
      for (var i = 0; i < from.length; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }

      str = str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-"); // collapse dashes

      this.newCollection.identifier = "urn:eop:MEPWPS:" + str;
    }
  },
  data: function() {
    return {
      newCollection: {
        authors: [
          {
            type: "",
            email: "",
            name: ""
          }
        ],
        additionalAttributes: [
          {
            key: "",
            value: ""
          }
        ]
      },
      sending: false
    };
  }
};
</script>

<style lang="scss"></style>
