<template>
  <base-admin-page>
    <template v-slot:content>
      <user-management></user-management>
    </template>
  </base-admin-page>
</template>

<script>
import UserManagement from "@/components/admin/UserManagement";
import BaseAdminPage from "@/pages/admin/BaseAdminPage";

export default {
  name: "AdminUsers",
  components: { BaseAdminPage, UserManagement }
};
</script>

<style scoped></style>
