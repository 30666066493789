<template>
  <side-bar title="Administration" short-title="Admin" :items="sidebarItems">
    <template v-slot:content>
      <slot name="content"> </slot>
    </template>
  </side-bar>
</template>

<script>
import SideBar from "@/components/share/SideBar";

export default {
  name: "BaseAdminPage",
  components: { SideBar },
  data() {
    return {
      sidebarItems: [
        {
          label: "Users",
          icon: "user",
          to: { name: "admin:users" }
        },
        {
          label: "Workspaces",
          icon: "network-wired",
          to: { name: "admin:workspaces" }
        },
        {
          label: "ADES Instances",
          icon: "microchip",
          to: { name: "admin:ades" }
        },
        {
          label: "Database & Airflow",
          icon: "database",
          to: { name: "admin:database" }
        }
      ]
    };
  }
};
</script>

<style scoped></style>
