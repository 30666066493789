<template>
  <b-table-simple small bordered striped>
    <b-thead>
      <b-tr>
        <b-th colspan="2"><b>Details of selected feature</b></b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <b-tr>
        <b-td>
          <b>Title</b>
        </b-td>
        <b-td>
          {{ feature.properties.title }}
        </b-td>
      </b-tr>
      <b-tr>
        <b-td>
          <b>Description</b>
        </b-td>
        <b-td>
          {{ feature.properties.description }}
        </b-td>
      </b-tr>
      <b-tr>
        <b-td>
          <b>Id</b>
        </b-td>
        <b-td>
          {{ feature.id }}
        </b-td>
      </b-tr>
      <b-tr>
        <b-td>
          <b>Type</b>
        </b-td>
        <b-td>
          {{ feature.type }}
        </b-td>
      </b-tr>
      <b-tr>
        <b-td>
          <b>Geometry</b>
        </b-td>
        <b-td>
          {{ feature.geometry.type }}
        </b-td>
      </b-tr>
      <b-tr>
        <b-td>
          <b>Bounds</b>
        </b-td>
        <b-td>
          {{ feature.geometry.coordinates[0] }}
        </b-td>
      </b-tr>
      <b-tr>
        <b-td>
          <b>Created</b>
        </b-td>
        <b-td>
          {{ feature.properties.created }}
        </b-td>
      </b-tr>
      <b-tr>
        <b-td>
          <b>Updated</b>
        </b-td>
        <b-td>
          {{ feature.properties.recordUpdated }}
        </b-td>
      </b-tr>
      <b-tr>
        <b-td>
          <b>Start Datetime</b>
        </b-td>
        <b-td>
          {{ feature.properties.start_datetime }}
        </b-td>
      </b-tr>
      <b-tr>
        <b-td>
          <b>End Datetime</b>
        </b-td>
        <b-td>
          {{ feature.properties.end_datetime }}
        </b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>
export default {
  name: "FeatureDetail",
  props: {
    feature: {
      type: Object,
      required: true
    }
  }
};
</script>

<style></style>
