<template>
  <b-container class="my-4">
    <b-modal ref="stopMaaS" hide-footer id="stopMaaS" :title="`Stop ${model_name} Service?`">
      <b-row no-gutters>
        <p>Please confirm (or click outside this dialog to cancel) ...</p>
      </b-row>
      <b-row align-h="end" no-gutters>
        <b-col cols="4">
          <b-button class="btn-block" variant="danger" @click="stopModelAsAService()">
            <fa-icon icon="times-circle" class="mt-1 ml-2 float-right" />
            <span>Stop the Service</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-row align="center" v-if="loading">
      <b-col>
        <loading :loading="loading"></loading>
      </b-col>
    </b-row>
    <div v-else>
      <page-title :title="model_name" :subtitle="model_description">
        <template v-slot:toolbar>
          <b-btn-toolbar class="float-right" justify>
            <slot name="title-toolbar" v-bind:loading="loading"></slot>
            <b-btn-group v-if="is_authenticated">
              <b-button
                v-if="maasInfo.status == 'undeployed'"
                variant="outline-secondary"
                class="ml-2 rounded"
                type="submit"
                @click="maasTabIsActive = true"
                >Service not started
              </b-button>
              <b-button
                v-if="maasInfo.status == 'deploying' || maasInfo.status == 'creating'"
                variant="outline-warning"
                class="ml-2 rounded"
                type="submit"
                @click="maasTabIsActive = true"
                >Model starting as a Service
              </b-button>
              <b-button
                v-if="maasInfo.status == 'available'"
                variant="outline-success"
                class="ml-2 rounded"
                type="submit"
                @click="maasTabIsActive = true"
                >Model is running as a Service
              </b-button>
              <b-button
                v-if="maasInfo.status == 'error'"
                variant="outline-danger"
                class="ml-2 rounded"
                type="submit"
                @click="maasTabIsActive = true"
                >Service error
              </b-button>
              <b-button
                variant="primary"
                class="ml-2 rounded"
                :to="`/collections/${collection_id}/model_execute/${model_id}`"
                v-b-popover.hover.top="'Open the model execution page'"
                >Execute
                <fa-icon icon="arrow-right" class="mt-1 ml-2 float-right" />
              </b-button>
            </b-btn-group>
          </b-btn-toolbar>
        </template>
      </page-title>
      <b-row>
        <b-col cols="10">
          <b-tabs>
            <b-tab title="Properties">
              <b-overlay :show="updating">
                <b-row class="my-3" align-h="center">
                  <b-col>
                    <b-form-row>
                      <b-col class="col-form-label" cols="2">
                        <label for="title" class="my-0">Title:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input id="title" type="text" plaintext v-model="model_name" />
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col class="col-form-label" cols="2">
                        <label for="version" class="my-0">Description:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="version"
                          type="text"
                          plaintext
                          v-model="model_description"
                        />
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col class="col-form-label" cols="2">
                        <label for="version" class="my-0">Version:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input id="version" type="text" plaintext v-model="model_version" />
                      </b-col>
                    </b-form-row>
                    <!-- <b-form-row v-if="modelInfo.properties.type">
                      <b-col class="col-form-label" cols="2">
                        <label for="version" class="my-0">Type:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="version"
                          type="text"
                          plaintext
                          v-model="modelInfo.properties.type"
                        />
                      </b-col>
                    </b-form-row> -->
                    <!-- <b-form-row v-if="modelInfo.stac_version">
                      <b-col class="col-form-label" cols="2">
                        <label for="version" class="my-0">STAC version:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="version"
                          type="text"
                          plaintext
                          v-model="modelInfo.stac_version"
                        />
                      </b-col>
                    </b-form-row> -->
                    <b-form-row v-if="modelInfo.collection && is_developer">
                      <b-col class="col-form-label" cols="2">
                        <label for="version" class="my-0">Collection:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="version"
                          type="text"
                          plaintext
                          v-model="modelInfo.collection.split(':')[0]"
                        />
                      </b-col>
                    </b-form-row>
                    <b-form-row v-if="modelInfo.properties.datetime">
                      <b-col class="col-form-label" cols="2">
                        <label for="version" class="my-0">Date and time:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="version"
                          type="text"
                          plaintext
                          v-model="modelInfo.properties.datetime"
                        />
                      </b-col>
                    </b-form-row>
                    <b-form-row v-if="modelInfo.properties.start_datetime">
                      <b-col class="col-form-label" cols="2">
                        <label for="version" class="my-0">Start:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="version"
                          type="text"
                          plaintext
                          v-model="modelInfo.properties.start_datetime"
                        />
                      </b-col>
                    </b-form-row>
                    <b-form-row v-if="modelInfo.properties.end_datetime">
                      <b-col class="col-form-label" cols="2">
                        <label for="version" class="my-0">End:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="version"
                          type="text"
                          plaintext
                          v-model="modelInfo.properties.end_datetime"
                        />
                      </b-col>
                    </b-form-row>
                    <b-form-row v-if="tc_url">
                      <b-col class="col-form-label" cols="2">
                        <label for="version" class="my-0">License:</label>
                      </b-col>
                      <b-col class="col-form-label" cols="10">
                        <b-form-row>
                          <b-link :href="tc_url" target="_blank">
                            {{ tc_name }}
                            <fa-icon
                              class="ml-1 fa-light"
                              v-b-tooltip.hover
                              title="Opens in new tab/page"
                              icon="external-link-alt"
                              size="xs"
                            >
                            </fa-icon>
                          </b-link>
                        </b-form-row>
                      </b-col>
                    </b-form-row>
                    <b-form-row
                      v-for="(provider, index) in modelInfo.properties.providers"
                      :key="'provider' + index"
                    >
                      <b-col class="col-form-label" cols="2">
                        <label for="version" class="my-0">Provider:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-row v-for="(detail, name) in provider" :key="'detail' + name">
                          <b-col class="col-form-label" cols="2">{{ name }}:</b-col>
                          <b-col class="col-form-label" cols="8">{{ detail }}</b-col>
                        </b-form-row>
                      </b-col>
                    </b-form-row>
                    <b-form-row v-if="is_developer && experiment_id">
                      <b-col class="col-form-label" cols="2">
                        <label for="version" class="my-0">Experiment:</label>
                      </b-col>
                      <b-col class="col-form-label" cols="10">
                        <b-form-row>
                          <label for="version">Id: {{ experiment_id }}</label>
                        </b-form-row>
                        <b-form-row>
                          <b-link :href="tracking_server_experiment_url" target="_blank"
                            >Tracking Server
                            <fa-icon
                              class="ml-1 fa-light"
                              v-b-tooltip.hover
                              title="Opens in new tab/page"
                              icon="external-link-alt"
                              size="xs"
                            >
                            </fa-icon>
                          </b-link>
                        </b-form-row>
                      </b-col>
                    </b-form-row>
                    <b-form-row v-if="is_developer && run_id">
                      <b-col class="col-form-label" cols="2">
                        <label for="version" class="my-0">Model Run:</label>
                      </b-col>
                      <b-col class="col-form-label" cols="10">
                        <b-form-row>
                          <label for="version">Id: {{ run_id }}</label>
                        </b-form-row>
                        <b-form-row>
                          <b-link :href="tracking_server_run_url" target="_blank"
                            >Tracking Server
                            <fa-icon
                              class="ml-1 fa-light"
                              v-b-tooltip.hover
                              title="Opens in new tab/page"
                              icon="external-link-alt"
                              size="xs"
                            >
                            </fa-icon>
                          </b-link>
                        </b-form-row>
                        <b-form-row>
                          <b-link :href="model_run_details_url" target="_blank"
                            >Run Details
                            <fa-icon
                              class="ml-1 fa-light"
                              v-b-tooltip.hover
                              title="Opens in new tab/page"
                              icon="external-link-alt"
                              size="xs"
                            >
                            </fa-icon>
                          </b-link>
                        </b-form-row>
                      </b-col>
                    </b-form-row>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
            <b-tab title="Signature">
              <b-overlay :show="updating">
                <b-row>
                  <b-col cols="12" class="mt-3">
                    <page-title subtitle="Model Inputs" no-divider></page-title>
                    <ul class="mb-1">
                      <li
                        v-for="(input, key) in signature.inputs"
                        :key="'input-' + key"
                        class="mb-2"
                      >
                        <span v-if="input.label">{{ input.label }}</span>
                        <span v-else>{{ input.name }}</span>
                        <b-badge variant="primary">{{ input.type }}</b-badge>
                        <ul>
                          <li v-if="input.doc">Description: {{ input.doc }}</li>
                          <li v-if="input.default">Default: {{ input.default }}</li>
                          <li v-if="input['tensor-spec'] && input['tensor-spec'].dtype">
                            Datatype: {{ input["tensor-spec"].dtype }}
                          </li>
                          <li v-if="input['tensor-spec'] && input['tensor-spec'].shape">
                            Shape: {{ input["tensor-spec"].shape }}
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" class="mt-3">
                    <page-title subtitle="Model Outputs" no-divider></page-title>
                    <ul class="mb-1" v-if="signature.outputs">
                      <li
                        v-for="output in signature.outputs"
                        :key="'output-' + output.label"
                        class="mb-2"
                      >
                        <span>{{ output.label }}</span>
                        <span>{{ output.id }}</span>
                        <b-badge variant="primary">{{ output.type }}</b-badge>
                        <ul>
                          <li v-if="output.doc">Description: {{ output.doc }}</li>
                          <li v-if="output['tensor-spec'] && output['tensor-spec'].dtype">
                            Datatype: {{ output["tensor-spec"].dtype }}
                          </li>
                          <li v-if="output['tensor-spec'] && output['tensor-spec'].shape">
                            Shape: {{ output["tensor-spec"].shape }}
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
            <b-tab title="Training Details" v-if="is_authenticated">
              <b-overlay :show="updating">
                <b-row>
                  <b-col cols="12" class="mt-3">
                    <page-title subtitle="Training Parameters" no-divider></page-title>
                    <b-table
                      v-if="signature.params"
                      :items="signature.params"
                      :fields="params_fields"
                      small="small"
                      outlined
                      table-class="w-auto"
                      sort-by="key"
                      head-variant="light"
                    >
                      <template #cell(key)="data">
                        <span class="mr-5">{{ data.value }}</span>
                      </template>
                      <template #cell(value)="data">
                        <span class="mr-5">{{ data.value }}</span>
                      </template>
                    </b-table>
                    <label v-else class="my-0">Not available</label>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" class="mt-3">
                    <page-title subtitle="Training Metrics" no-divider></page-title>
                    <b-table
                      v-if="metrics && metrics.length > 0"
                      :items="metrics"
                      :fields="metrics_fields"
                      small="small"
                      outlined
                      table-class="w-auto"
                      sort-by="key"
                      head-variant="light"
                    >
                      <template #cell(key)="data">
                        <span class="mr-5">{{ data.value }}</span>
                      </template>
                      <template #cell(value)="data">
                        <span class="mr-5">{{ data.value }}</span>
                      </template>
                    </b-table>
                    <label v-else class="my-0">Not available</label>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
            <b-tab title="Geometry" v-if="false">
              <b-overlay :show="updating">
                <b-row class="my-3" align-h="center">
                  <b-col>
                    <b-form-row>
                      <b-col class="col-form-label" cols="2">
                        <label for="description" class="my-0">Type:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="description"
                          type="text"
                          plaintext
                          v-model="modelInfo.geometry.type"
                        />
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col class="col-form-label" cols="2">
                        <label for="description" class="my-0">Coordinates:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="description"
                          type="text"
                          plaintext
                          v-model="coordinates"
                        />
                      </b-col>
                    </b-form-row>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
            <b-tab title="Themes" v-if="modelInfo.properties.themes">
              <b-overlay :show="updating">
                <b-row
                  class="my-3"
                  align-h="center"
                  v-for="(theme, index) in modelInfo.properties.themes"
                  :key="'theme' + index"
                >
                  <b-col>
                    <theme-concepts-card :theme="theme"> </theme-concepts-card>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
            <b-tab title="Contacts" v-if="modelInfo.properties.contacts">
              <b-overlay :show="updating">
                <b-row
                  class="my-3"
                  align-h="center"
                  v-for="(contact, index) in modelInfo.properties.contacts"
                  :key="'contact' + index"
                >
                  <b-col>
                    <contact-card :contact="contact"> </contact-card>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
            <b-tab title="Links" v-if="is_developer">
              <b-overlay :show="updating">
                <b-row class="my-3" align-h="center">
                  <b-col>
                    <b-card v-for="(link, index) in modelInfo.links" :key="'link' + index">
                      <b-form-row v-if="link.title">
                        <b-col class="col-form-label" cols="2">
                          <label for="description" class="my-0">Title:</label>
                        </b-col>
                        <b-col cols="10">
                          <b-form-input
                            id="description"
                            type="text"
                            plaintext
                            v-model="link.title"
                          />
                        </b-col>
                      </b-form-row>
                      <b-form-row v-if="link.type">
                        <b-col class="col-form-label" cols="2">
                          <label for="description" class="my-0">Type:</label>
                        </b-col>
                        <b-col cols="10">
                          <b-form-input
                            id="description"
                            type="text"
                            plaintext
                            v-model="link.type"
                          />
                        </b-col>
                      </b-form-row>
                      <b-form-row v-if="link.href">
                        <b-col class="col-form-label" cols="2">
                          <label for="description" class="my-0">Href:</label>
                        </b-col>
                        <b-col cols="10">
                          <b-form-input
                            id="description"
                            type="text"
                            plaintext
                            v-model="link.href"
                          />
                        </b-col>
                      </b-form-row>
                      <b-form-row v-if="link.rel">
                        <b-col class="col-form-label" cols="2">
                          <label for="description" class="my-0">Rel:</label>
                        </b-col>
                        <b-col cols="10">
                          <b-form-input id="description" type="text" plaintext v-model="link.rel" />
                        </b-col>
                      </b-form-row>
                    </b-card>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
            <b-tab title="Assets" v-if="is_developer">
              <b-overlay :show="updating">
                <b-row class="my-3" align-h="center">
                  <b-col>
                    <div
                      v-for="(assetData, assetName) in modelInfo.assets"
                      :key="'asset' + assetName"
                    >
                      <b-card :title="assetName">
                        <b-form-row v-if="assetData.title">
                          <b-col class="col-form-label" cols="2">
                            <label for="description" class="my-0">Title:</label>
                          </b-col>
                          <b-col cols="10">
                            <b-form-input
                              id="description"
                              type="text"
                              plaintext
                              v-model="assetData.title"
                            />
                          </b-col>
                        </b-form-row>
                        <b-form-row v-if="assetData.type">
                          <b-col class="col-form-label" cols="2">
                            <label for="description" class="my-0">Type:</label>
                          </b-col>
                          <b-col cols="10">
                            <b-form-input
                              id="description"
                              type="text"
                              plaintext
                              v-model="assetData.type"
                            />
                          </b-col>
                        </b-form-row>
                        <b-form-row v-if="assetData.href">
                          <b-col class="col-form-label" cols="2">
                            <label for="description" class="my-0">Href:</label>
                          </b-col>
                          <b-col cols="10">
                            <b-form-input
                              id="description"
                              type="text"
                              plaintext
                              v-model="assetData.href"
                            />
                          </b-col>
                        </b-form-row>
                        <b-form-row v-if="assetData.roles">
                          <b-col class="col-form-label" cols="2">
                            <label for="description" class="my-0">Roles:</label>
                          </b-col>
                          <b-col class="col-form-label" cols="10">
                            {{ assetData.roles }}
                          </b-col>
                        </b-form-row>
                      </b-card>
                    </div>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
            <b-tab title="Extensions" v-if="is_developer">
              <b-overlay :show="updating">
                <b-row class="my-3" align-h="center">
                  <b-col>
                    <div
                      v-for="(extensionData, extensionName) in extensions"
                      :key="'ext-' + extensionName"
                    >
                      <b-card :title="extensionName">
                        <b-form-row
                          v-for="(extensionDataValue, extensionDataName) in extensionData"
                          :key="'ext-data-' + extensionDataName"
                        >
                          <b-col class="col-form-label" cols="2">
                            <label for="description" class="my-0"
                              >{{ extensionDataName | makeTitle }}:</label
                            >
                          </b-col>
                          <b-col class="col-form-label" cols="10">
                            <label for="description" class="my-0">{{ extensionDataValue }}</label>
                          </b-col>
                        </b-form-row>
                      </b-card>
                    </div>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
            <b-tab
              title="Model·as·a·Service"
              v-if="is_authenticated"
              v-bind:active.sync="maasTabIsActive"
            >
              <b-overlay :show="updating">
                <b-row v-if="maasInfo">
                  <b-col cols="12" class="mt-3" v-if="maasInfo.status == 'undeployed'">
                    <page-title subtitle="Model as a Service" no-divider></page-title>
                    <p>
                      The model may be deployed as a service and made accessible to remote
                      applications via an
                      <a href="https://ogcapi.ogc.org/processes/" target="_blank"
                        >OGC API - Processes <fa-icon icon="external-link-alt" class="mt-1 mr-1"
                      /></a>
                      interface.
                    </p>
                    <p>Instructions are provided here when the service is running.</p>
                    <p>
                      Please note that a model running as a service consumes cloud resources until
                      it is manually stopped.
                    </p>
                    <p>Come back to this page to stop the service when it is not needed anymore.</p>
                    <b-button
                      variant="danger"
                      class="rounded"
                      v-b-popover.hover.top="'Start the service (without confirmation)'"
                      @click="startModelAsAService()"
                      :disabled="maasIsInTransition"
                    >
                      <fa-icon icon="play-circle" class="mt-1 ml-2 float-right" />
                      Start Model as a Service</b-button
                    >
                  </b-col>
                  <b-col
                    cols="12"
                    class="mt-3"
                    v-if="maasInfo.status == 'deploying' || maasInfo.status == 'creating'"
                  >
                    <page-title subtitle="Model as a Service" no-divider></page-title>
                    <p>The model is being deployed. Please wait ...</p>
                  </b-col>
                  <b-col cols="12" class="mt-3" v-if="maasInfo.status == 'available'">
                    <page-title subtitle="Model as a Service" no-divider></page-title>
                    <p>The model is running as a service.</p>
                    <p>
                      You may execute the model remotely using the
                      <b-link href="https://ogcapi.ogc.org/processes/" target="_blank">
                        OGC API - Processes
                        <fa-icon
                          class="ml-1 fa-light"
                          v-b-tooltip.hover
                          title="Opens in new tab/page"
                          icon="external-link-alt"
                          size="xs"
                        >
                        </fa-icon>
                      </b-link>
                      protocol.
                    </p>
                    <p>Use the following URL to obtain information about the process:</p>
                    <p>
                      <fa-icon icon="long-arrow-alt-right" class="mt-1 mr-2 float-left" />
                      <b-link :href="maasInfo.model_proc_url" target="_blank"
                        >{{ maasInfo.model_proc_url }}
                        <fa-icon
                          class="ml-1 fa-light"
                          v-b-tooltip.hover
                          title="Opens in new tab/page"
                          icon="external-link-alt"
                          size="xs"
                        >
                        </fa-icon>
                      </b-link>
                    </p>
                    <p v-if="is_developer">
                      As developer, you may also obtain predictions using the following local URL in
                      your IDE:
                    </p>
                    <p v-if="is_developer">
                      <fa-icon icon="long-arrow-alt-right" class="mt-1 mr-2 float-left" />{{
                        maasInfo.model_infer_url
                      }}
                      <b-link class="ml-2" @click="writeToClipboard(maasInfo.model_infer_url)">
                        <fa-icon
                          class="ml-1 fa-light"
                          v-b-tooltip.hover
                          title="Copy link to clipboard"
                          icon="clipboard"
                          size="sm"
                        >
                        </fa-icon>
                      </b-link>
                    </p>
                    <p>
                      Please note that a model running as a service consumes cloud resources until
                      it is manually stopped using the button below.
                    </p>
                    <p>
                      You may inspect the list of models currently running for you in your<b-button
                        variant="outline-success"
                        class="ml-2 mr-2 mb-0"
                        to="/resources"
                        size="sm"
                        v-b-popover.hover.top="'Open the resources usage page'"
                        >Resources&nbsp;Usage </b-button
                      >page.
                    </p>
                    <p>
                      <b-button
                        variant="danger"
                        class="rounded"
                        v-b-popover.hover.top="'Stop the service (requires confirmation)'"
                        @click="confirmStopModelAsAService()"
                      >
                        <fa-icon icon="times-circle" class="mt-1 ml-2 float-right" />
                        Stop Model as a Service</b-button
                      >
                    </p>
                  </b-col>
                </b-row>
                <b-row v-else>
                  <page-title subtitle="Model as a Service" no-divider></page-title>
                  <p>This model cannot be deployed as a service.</p>
                </b-row>
              </b-overlay>
            </b-tab>
            <b-tab title="Raw JSON" v-if="is_developer">
              <div>
                <pre>{{ modelInfo | pretty }}</pre>
              </div>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import backendService from "@/api/asb/services/backend";
import PageTitle from "@/components/share/PageTitle";
import Loading from "@/components/share/Loading";
import ContactCard from "@/components/share/STAC/ContactCard";
import ThemeConceptsCard from "@/components/share/STAC/ThemeConceptsCard";
import { mapState } from "vuex";
import { canWriteToClipboard, writeToClipboard } from "@/utils";

export default {
  name: "ModelDetail",
  components: {
    PageTitle,
    Loading,
    ContactCard,
    ThemeConceptsCard
  },
  data() {
    return {
      model_id: "",
      collection_id: "",
      loading: true,
      modelInfo: {},
      signature: {},
      params_fields: [{ key: "key", label: "Name" }, "value"],
      metrics: [],
      metrics_fields: [{ key: "key", label: "Name" }, "value"],
      updating: false,
      collection: "",
      extensions: [],
      extensions_schemas: {},
      coordinates: "",
      maasInfo: {},
      maasTabIsActive: false,
      maasIsInTransition: false,
      jsonMsg: ""
    };
  },
  created() {
    this.model_id = this.$route.params.model_id;
    this.collection_id = this.$route.params.id;
    this.getModelInfo();
  },
  methods: {
    formatAlertMessage(alertMessage) {
      return alertMessage.replace("\n", "<br />");
    },
    getModelInfo() {
      backendService
        .describeItem(this.collection_id, this.model_id)
        .then(data => this.initInfo(data))
        .catch(error => backendService.handleError(error))
        .finally(() => (this.loading = false));
    },
    initInfo(modelInfo) {
      this.modelInfo = modelInfo;
      this.extensions = this.getExtensions(this.modelInfo);
      this.coordinates = this.getCoordinates(this.modelInfo);
      this.signature = this.getSignature(this.modelInfo);
      console.log(this.modelInfo);
      this.fetchRunDetails();
      this.initMaasInfo();
    },
    getSTACExtensionTitle(schema_url) {
      if (this.extensions_schemas[schema_url] === undefined) {
        this.fetchSTACExtensionSchema(schema_url);
      }
      return this.extenions_schema[schema_url].title;
    },
    fetchSTACExtensionSchema(schema_url) {
      fetch(schema_url)
        .then(response => {
          response.json().then(data => {
            this.extensions_schemas[schema_url] = data;
          });
        })
        .catch(err => {
          console.error(err);
        });
    },
    getExtensions(modelInfo) {
      let extensions = {};
      let mlModel = {};
      let dlModel = {};
      let mlAoi = {};
      let mlm = {};
      let sci = {};
      let properties = modelInfo.properties;
      for (const [key, value] of Object.entries(properties)) {
        if (key.startsWith("mlm:")) {
          let extensionKey = key.replace("mlm:", "");
          mlm[extensionKey] = value;
        } else if (key.startsWith("ml-model:")) {
          let extensionKey = key.replace("ml-model:", "");
          mlModel[extensionKey] = value;
        } else if (key.startsWith("dlm:")) {
          let extensionKey = key.replace("dlm:", "");
          dlModel[extensionKey] = value;
        } else if (key.startsWith("ml-aoi:")) {
          let extensionKey = key.replace("ml-aoi:", "");
          mlAoi[extensionKey] = value;
        } else if (key.startsWith("sci:")) {
          let extensionKey = key.replace("sci:", "");
          sci[extensionKey] = value;
        }
      }
      if (Object.keys(mlm) != 0) {
        extensions["mlm"] = mlm;
      }
      if (Object.keys(mlModel) != 0) {
        extensions["ml-model"] = mlModel;
      }
      if (Object.keys(dlModel) != 0) {
        extensions["dlm"] = dlModel;
      }
      if (Object.keys(mlAoi) != 0) {
        extensions["ml-aoi"] = mlAoi;
      }
      if (Object.keys(sci) != 0) {
        extensions["sci"] = sci;
      }
      console.log(extensions);
      return extensions;
    },
    getCoordinates(modelInfo) {
      let coordinates = "";
      let rawCoordinates = modelInfo.geometry.coordinates[0];
      for (let i = 0; i < rawCoordinates.length; i++) {
        coordinates = coordinates + "[" + modelInfo.geometry.coordinates[0][i] + "] ";
      }
      return coordinates;
    },
    getSignature(modelInfo) {
      console.log("getSignature", modelInfo);
      let signature = {};
      if ("dlm:inputs" in modelInfo.properties) {
        signature.inputs = modelInfo.properties["dlm:inputs"];
      }
      if ("dlm:outputs" in modelInfo.properties) {
        signature.outputs = modelInfo.properties["dlm:outputs"];
      }
      console.log("Signature", signature);
      return signature;
    },
    initMaasInfo() {
      // Anonymous users may not use MaaS
      if (this.user.username) {
        this.pollModelAsAServiceStatus();
      }
    },
    startModelAsAService() {
      console.log("Deploying on WPS");
      this.maasIsInTransition = true;
      backendService
        .startModelAsAService(this.collection_id, this.model_id)
        .then(data => {
          this.maasInfo = data;
          this.pollModelAsAServiceStatus();
        })
        .catch(error => backendService.handleError(error))
        .finally(() => (this.loading = false));
    },
    confirmStopModelAsAService() {
      this.$refs.stopMaaS.show();
    },
    stopModelAsAService() {
      console.log("Undeploying from WPS");
      this.maasIsInTransition = true;
      this.$refs.stopMaaS.hide();
      backendService
        .stopModelAsAService(this.collection_id, this.model_id)
        .then(data => (this.maasInfo = data))
        .catch(error => backendService.handleError(error))
        .finally(() => {
          this.loading = false;
          this.maasIsInTransition = false;
        });
    },
    fetchRunDetails() {
      console.log("Fetching run details ...");
      if (
        this.workspaces[0] === undefined ||
        this.experiment_id === undefined ||
        this.run_id === undefined
      ) {
        return;
      }
      backendService
        .describeModelRun(this.workspaces[0], this.experiment_id, this.run_id)
        .then(data => {
          console.debug("Model run details:", data);
          if (data && data.run) {
            this.runDetails = data;
            this.metrics = data.run.data.metrics;
            this.signature.params = data.run.data.params;
          }
        })
        .catch(error => backendService.handleError(error));
    },
    pollModelAsAServiceStatus() {
      console.log("Fetching MaaS status ...");
      backendService
        .modelAsAServiceStatus(this.collection_id, this.model_id)
        .then(data => {
          this.maasInfo = data;
          if (data.status == "deploying" || data.status == "creating") {
            this.maasIsInTransition = true;
            setTimeout(this.pollModelAsAServiceStatus, 5000);
          } else {
            this.maasIsInTransition = false;
          }
        })
        .catch(error => backendService.handleError(error));
    },
    getLinkWithRel(rel) {
      if (this.modelInfo === undefined || this.modelInfo.links === undefined) {
        return null;
      }
      for (let link of this.modelInfo.links) {
        if (link.rel == rel) {
          return link;
        }
      }
      return null;
    },
    canWriteToClipboard() {
      return canWriteToClipboard();
    },
    writeToClipboard(text) {
      this.$notify({
        group: "global",
        type: "info",
        duration: 2000, // in ms, -1 = no duration
        text: "Copied to clipboard"
      });
      return writeToClipboard(text);
    }
  },
  computed: {
    ...mapState({
      user: state => state.user
    }),
    ownerOrSuperuser() {
      return Object.keys(this.user).length != 0
        ? this.user.is_superuser
          ? true
          : this.user.username === this.collection.ownerId
        : false;
    },
    model_name() {
      let modelName = null;
      if (this.modelInfo.properties) {
        modelName = this.modelInfo.properties.name
          ? this.modelInfo.properties.name
          : this.modelInfo.properties.title
          ? this.modelInfo.properties.title
          : this.modelInfo.id
          ? this.modelInfo.id
          : null;
      }
      if (modelName == null) {
        modelName = this.modelInfo.title
          ? this.modelInfo.title
          : this.modelInfo.id
          ? this.modelInfo.id
          : "";
      }
      return modelName;
    },
    model_description() {
      let modelDescription = null;
      if (this.modelInfo.properties) {
        modelDescription = this.modelInfo.properties.abstract
          ? this.modelInfo.properties.abstract
          : this.modelInfo.properties.description
          ? this.modelInfo.properties.description
          : null;
      }
      if (modelDescription == null) {
        modelDescription = this.modelInfo.description
          ? this.modelInfo.description
          : this.modelInfo.abstract
          ? this.modelInfo.abstract
          : "";
      }
      return modelDescription;
    },
    model_version() {
      let modelVersion = "not specified";
      if (this.modelInfo.properties) {
        modelVersion = this.modelInfo.properties.version
          ? this.modelInfo.properties.version
          : "not specified";
      }
      return modelVersion;
    },
    workspaces() {
      if (this.$store.state.user["groups"] !== undefined) {
        return Object.keys(this.$store.state.user["groups"]);
      }
      return [];
    },
    tracking_server_experiment_url() {
      // Example: https://mlflow.developer.workspaces.onda-dev.ai-pipeline.org/#/experiments/2
      // TODO the base domain must be provided by the backend
      let dev_domain = "onda-dev.ai-pipeline.org";
      let mlflow_domain = "https://mlflow." + this.workspaces[0] + ".workspaces." + dev_domain;
      return mlflow_domain + "/#/experiments/" + this.experiment_id;
    },
    tracking_server_run_url() {
      // Example: https://mlflow.developer.workspaces.onda-dev.ai-pipeline.org/#/runs/0814ce291ee44543b30a09d7995cfba3
      // TODO the base domain must be provided by the backend
      let dev_domain = "onda-dev.ai-pipeline.org";
      let mlflow_domain = "https://mlflow." + this.workspaces[0] + ".workspaces." + dev_domain;
      return mlflow_domain + "/#/runs/" + this.run_id;
    },
    model_run_details_url() {
      // TODO the base domain must be provided by the backend
      let dev_domain = "onda-dev.ai-pipeline.org";
      let mlflow_domain = "https://mlflow." + this.workspaces[0] + ".workspaces." + dev_domain;
      return mlflow_domain + "/api/2.0/mlflow/runs/get?run_id=" + this.run_id;
    },
    is_authenticated() {
      return Object.keys(this.user).length != 0;
    },
    is_developer() {
      // The user is developer if he has the role "developer" in at least one workspace / group
      if (this.$store.state.user["groups"] !== undefined) {
        for (const [group, roles] of Object.entries(this.$store.state.user["groups"])) {
          console.log("Checking:", group);
          if (roles.includes("developer")) {
            return true;
          }
        }
      }
      return false;
    },
    inference_runtime_href() {
      if (this.modelInfo && this.modelInfo.assets) {
        for (const [key, dict] of Object.entries(this.modelInfo.assets)) {
          console.log("Checking:", key);
          if (dict.roles && dict.roles.includes("ml-model:inference-runtime")) {
            return dict.href;
          }
          if (dict.roles && dict.roles.includes("mlm:model")) {
            return dict.href;
          }
        }
      }
      return undefined;
    },
    experiment_id() {
      // Expected HREF format:
      // s3://<bucket>/<prefix>/<workspace>-modelrepo/<exp_id>/<run_id>/artifacts/model/MLmodel
      let href = this.inference_runtime_href;
      let parts = href.split("/");
      let artifacts_index = parts.indexOf("artifacts");
      return parts[artifacts_index - 2];
    },
    run_id() {
      // Expected HREF format:
      // s3://<bucket>/<prefix>/<workspace>-modelrepo/<exp_id>/<run_id>/artifacts/model/MLmodel
      let href = this.inference_runtime_href;
      let parts = href.split("/");
      let artifacts_index = parts.indexOf("artifacts");
      return parts[artifacts_index - 1];
    },
    deployed() {
      return this.collection.identifier ? this.collection.identifier.endsWith(":deployed") : false;
    },
    tc_name() {
      // Two possibilities:
      // 1. Use the syntax "SPDX-License-Identifier: <license_id>" in properties.license
      // 2. Use a link with relation type "license"
      // Doc: https://github.com/radiantearth/stac-spec/blob/master/item-spec/common-metadata.md#licensing
      if (this.modelInfo.properties && this.modelInfo.properties.license) {
        if (this.modelInfo.properties.license.startsWith("SPDX-License-Identifier:")) {
          return this.modelInfo.properties.license.split(":")[1].trim();
        } else {
          return this.modelInfo.properties.license.trim();
        }
      }
      let tc_info = this.getLinkWithRel("license");
      if (tc_info) {
        return tc_info.title ? tc_info.title : tc_info.href;
      }
      return null;
    },
    tc_url() {
      // Check the license property
      let tc_id = this.tc_name;
      if (tc_id) {
        return "https://spdx.org/licenses/" + tc_id + ".html";
      }
      // Otherwise, check the links with relation type "license"
      let tc_info = this.getLinkWithRel("license");
      return tc_info ? tc_info.href : null;
    }
  },
  filters: {
    pretty: function(value) {
      return JSON.stringify(value, null, 2);
    },
    makeTitle: function(value) {
      const title = value.replace("_", " ");
      let title2 = title.charAt(0).toUpperCase() + title.slice(1);
      if (title2 == "Doi") {
        title2 = "DOI";
      }
      return title2;
    }
  }
};
</script>

<style></style>
