<template>
  <b-row>
    <b-col class="mt-5" align="center">
      <b>This is a system dashboard example.</b>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "InfoPanel",
  props: {}
};
</script>

<style scoped></style>
