import { asbGenericClient, baseURLFromEnv, defaultClientConfig } from "@/api/asb/client";
import axios from "axios";
import { handleServiceError, tokenInterceptor } from "@/api/utils";

export const knowledgeJSONAPIClient = axios.create({
  ...defaultClientConfig,
  baseURL: baseURLFromEnv("knowledge/v1/"),
  headers: {
    "Content-Type": "application/vnd.api+json"
  }
});

export const knowledgeClient = axios.create({
  ...defaultClientConfig,
  baseURL: baseURLFromEnv("knowledge/v1/"),
  headers: {
    "Content-Type": "application/json"
  }
});

knowledgeClient.interceptors.request.use(tokenInterceptor);
knowledgeJSONAPIClient.interceptors.request.use(tokenInterceptor);

// FIXME this methods might be removed

export default {
  handleError: error => handleServiceError("Knowledge Base", error),
  async getDetails() {
    let { data } = await asbGenericClient.get("knowledge/api/details");
    return data;
  },
  async getWorkflowNodes(processorId) {
    let { data } = await knowledgeClient.get(`workflows/${processorId}/nodes`);
    return data;
  },
  async getNodeProcess(nodeId) {
    let { data } = await knowledgeClient.get(`nodes/${nodeId}/process`);
    return data;
  },
  async createCollection(collection) {
    return await knowledgeClient.post("collections", collection);
  },
  async getWorkflowId(workflowIdentifier) {
    let { data } = await knowledgeClient.get(`workflows?filter[identifier]=${workflowIdentifier}`);
    return data.data[0].id;
  },
  async publishWorkflow(workflowId, workflowDescription, workflowThumbnailUrl) {
    let payload = {
      data: {
        type: "workflows",
        id: workflowId,
        attributes: {
          description: workflowDescription ? workflowDescription : "",
          thumbnail_url: workflowThumbnailUrl ? workflowThumbnailUrl : ""
        }
      }
    };

    let { data } = await knowledgeJSONAPIClient.patch(`workflows/${workflowId}/publish`, payload);
    return data;
  },
  async unpublishWorkflow(workflowId) {
    let { data } = await knowledgeClient.patch(`workflows/${workflowId}/unpublish`);
    return data;
  },
  async isPublishedWorkflow(workflowId) {
    let { data } = await knowledgeClient.get(`workflows/${workflowId}/is_published`);
    return data.data["published"];
  },
  async searchMEPProducts(params) {
    let { data } = await knowledgeClient.get(`mep/products`, { params: params });
    return data;
  }
};
