<template>
  <div>
    <eoepca-index-content v-if="eoepcaHomepage" class="mt-0 mb-5"> </eoepca-index-content>
    <eopen-index-content
      v-if="theme_features.includes('homepage-eopen')"
      class="mt-0 mb-5"
    ></eopen-index-content>
    <aiopen-index-content
      v-if="theme_features.includes('homepage-aiopen')"
      class="my-5"
    ></aiopen-index-content>
    <index-content v-if="theme_features.includes('homepage-asb')" class="my-5"></index-content>
    <footer class="text-muted mb-1">
      <b-container>
        <b-row>
          <b-col cols="6">
            <span class="float-left">© {{ year }} Space Applications Services, NV/SA</span>
          </b-col>
          <b-col cols="6">
            <div class="external-links float-right">
              <h6 class="text-secondary">External links</h6>
              <ul>
                <li>
                  <a :href="asbProductURL" target="_blank">ASB Framework product</a>
                </li>
                <li v-if="eoepcaHomepage">
                  <a href="https://eoepca.org/" target="_blank"
                    >Earth Observation Exploitation Platform Common architecture (EOEPCA)</a
                  >
                </li>
                <li>
                  <a :href="spaceappsURL" target="_blank">Space Applications Services website</a>
                </li>
              </ul>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </footer>
  </div>
</template>

<script>
import IndexContent from "@/components/IndexContent";
import EOPENIndexContent from "@/plugins/eopen/components/IndexContent";
import EOEPCAIndex from "@/plugins/eoepca/components/EOEPCAIndex";
import AIOPENIndex from "@/plugins/aiopen/components/AIOPENIndex";
import { mapState } from "vuex";

export default {
  name: "IndexPage",
  components: {
    "index-content": IndexContent,
    "eopen-index-content": EOPENIndexContent,
    "eoepca-index-content": EOEPCAIndex,
    "aiopen-index-content": AIOPENIndex
  },
  data() {
    return {
      spaceappsURL: "https://www.spaceapplications.com",
      asbProductURL: "https://www.spaceapplications.com/products/automated-service-builder-asb/",
      year: new Date().getFullYear()
    };
  },
  computed: {
    ...mapState({
      theme: state => state.instance.theme,
      theme_features: state => state.instance.theme_features
    }),
    eoepcaHomepage() {
      return (
        this.theme_features.includes("homepage-eoepca-develop") ||
        this.theme_features.includes("homepage-eoepca-staging") ||
        this.theme_features.includes("homepage-eoepca-prod")
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.external-links {
  text-align: left;
}
</style>
