<template>
  <b-row class="mt-0" align-h="end" no-gutters>
    <b-col cols="12" v-show="createErrors['nonFieldErrors'].length !== 0">
      <b-alert
        variant="danger"
        v-for="(error, index) in createErrors['nonFieldErrors']"
        :key="index"
        show
      >
        {{ error }}
      </b-alert>
    </b-col>
    <b-col cols="12">
      <b-form-group
        v-for="field in createFields"
        :key="field.name"
        label-align="right"
        label-class="font-weight-bold"
        label-cols="2"
        :label="callWithVm(field.label)"
        :label-for="`${name}-${field.name}`"
        :description="field.description"
      >
        <b-form-input
          :id="`create-input-${name}-${field.name}`"
          v-model="newResource[field.name]"
          :type="field.type"
          :state="createState(field.name)"
          :aria-describedby="`create${capitalize(name)}Feedback`"
          :placeholder="field.placeholder ? callWithVm(field.placeholder) : undefined"
          @input="field.onChange ? field.onChange(newResource) : null"
        ></b-form-input>
        <b-form-invalid-feedback :id="`create${capitalize(name)}Feedback`">
          <span v-for="(error, index) in createErrors[field.name]" :key="index">
            {{ error }}
          </span>
        </b-form-invalid-feedback>
      </b-form-group>
    </b-col>
    <b-col cols="3">
      <b-button
        :variant="error ? 'failed' : 'success'"
        @click="createResource"
        :disabled="creating"
        block
      >
        <span v-if="!creating">Create {{ name }}</span>
        <b-spinner v-else small></b-spinner>
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { capitalize, pluralize } from "@/utils";
import { BAD_REQUEST } from "http-status-codes";
import { mapState } from "vuex";
import { handleServiceError } from "@/api/utils";

export default {
  name: "CreateResourceForm",
  props: {
    service: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    createFields: {
      type: Array,
      required: false,
      default: () => {
        return [
          {
            name: "example",
            label: "Example",
            type: "text",
            onChange: () => console.debug("Slug changing")
          }
        ];
      }
    },
    defaults: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data() {
    return {
      creating: false,
      error: false,
      newResource: {},
      createErrors: {
        nonFieldErrors: []
      }
    };
  },
  methods: {
    capitalize: capitalize,
    pluralize: pluralize,
    createResource() {
      this.createErrors = {
        nonFieldErrors: []
      };
      for (let field of this.createFields) {
        this.createErrors[field.name] = [];
      }
      this.error = false;
      console.debug("createErrors reset", this.createErrors);
      this.creating = true;
      const newResource = {
        ...this.defaults,
        ...this.newResource,
        _jv: {
          type: this.pluralName
        }
      };
      console.debug("new ", this.service, this.name, newResource);
      this.$store
        .dispatch(`${this.service}/post`, [newResource])
        .then(resource => {
          this.$notify({
            group: "global",
            type: "success",
            text: "",
            title: `${capitalize(this.name)} #${resource._jv.id} successfully created`,
            duration: 5000
          });
          this.$router.push({ name: this.name, params: { id: resource._jv.id } });
        })
        .catch(error => {
          this.error = true;
          if (error.response && error.response.status === BAD_REQUEST) {
            for (let formError of error.response.data["errors"]) {
              const pointer = formError.source.pointer;
              if (pointer.startsWith("/data/attributes")) {
                // JSON API error
                const attribute = formError.source.pointer.split("/")[3];
                if (attribute in this.createErrors) {
                  this.createErrors[attribute].push(formError.detail);
                } else {
                  console.warn("Cannot show errors for pointer", pointer);
                }
              } else {
                console.error("Wrong source pointer", pointer);
              }
            }
          } else {
            handleServiceError(this.service, error);
          }
        })
        .finally(() => (this.creating = false));
    },
    createState(attribute) {
      // Show only if server-side errors
      let createError = this.createErrors[attribute];
      return createError && createError.length !== 0 ? false : null;
    },
    callWithVm(func) {
      return func(this);
    }
  },
  computed: {
    ...mapState({
      user: state => state.user
    }),
    verboseName() {
      return this.name;
    },
    pluralName() {
      return this.pluralize(this.name);
    }
  }
};
</script>

<style scoped></style>
