<template>
  <base-admin-page>
    <template v-slot:content>
      <ades-management></ades-management>
    </template>
  </base-admin-page>
</template>

<script>
import AdesManagement from "@/components/admin/AdesManagement";
import BaseAdminPage from "@/pages/admin/BaseAdminPage";

export default {
  name: "AdminAdes",
  components: { BaseAdminPage, AdesManagement }
};
</script>

<style scoped></style>
