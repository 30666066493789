import Dashboard from "@/plugins/dashboards/pages/Dashboard.vue";
import DashboardList from "@/plugins/dashboards/pages/DashboardList";

const routes = [
  {
    path: "/dashboards",
    name: "dashboards",
    component: DashboardList
  },
  {
    path: "/dashboards/:id?",
    name: "dashboard",
    component: Dashboard
  }
];
export default routes;
