<template>
  <b-container class="my-4 px-5" fluid>
    <b-row align-h="center">
      <b-col cols="11">
        <page-title class="mb-2" title="Executions" :fetching="fetching" :no-fetching="false">
          <template v-slot:toolbar>
            <b-btn-toolbar class="float-right"> </b-btn-toolbar>
          </template>
        </page-title>

        <b-row>
          <b-col>
            <b-row>
              <b-col>
                <b-row class="my-2" v-show="maxProgressingExecutions !== 0">
                  <b-col>
                    <b-progress :max="maxProgressingExecutions" height="25px">
                      <b-progress-bar :value="pendingProgress" variant="warning"></b-progress-bar>
                      <b-progress-bar :value="runningProgress" variant="info"></b-progress-bar>
                    </b-progress>
                  </b-col>
                </b-row>
                <b-row v-if="!loading">
                  <b-col class="my-2" cols="12" align="right">
                    <b-form class="float-right" @submit="search" inline>
                      <b-btn-group>
                        <b-form-input
                          class="rounded-0 outline-0"
                          v-model="filter"
                          type="text"
                          placeholder="Search executions"
                        ></b-form-input>
                        <b-btn class="rounded-0" variant="plain" type="submit">
                          <fa-icon icon="search" />
                        </b-btn>
                      </b-btn-group>
                    </b-form>
                  </b-col>
                  <b-col>
                    <template>
                      <b-table
                        v-show="!$_.isEmpty(executions)"
                        :items="Object.values(executions)"
                        :fields="fields"
                        sort-by="createdAt"
                        sort-desc
                        small
                        hover
                        foot-clone
                        fixed
                      >
                        <template v-slot:row-details="data">
                          <b-row class="my-2" align-h="end">
                            <b-col cols="9">
                              <h5 class="no-bold">Input parameters</h5>
                              <b-row>
                                <b-col
                                  class="mt-1"
                                  cols="12"
                                  v-for="(nodeInputs, index) in data.item.inputs"
                                  :key="index"
                                >
                                  <input-table :inputs="nodeInputs.items" disable-all></input-table>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </template>
                        <template v-slot:cell(createdAt)="data">
                          <span>{{ data.value | moment("YYYY-MM-DD HH:mm") }}</span>
                          <b-btn
                            v-if="!$_.isEmpty(data.item.schedule)"
                            variant="link"
                            size="sm"
                            :to="{ name: 'schedule', params: { id: data.item.schedule._jv.id } }"
                          >
                            <fa-icon icon="clock" class="mr-2"></fa-icon>
                            <span>{{ data.item.schedule.identifier }}</span>
                          </b-btn>
                        </template>
                        <template v-slot:cell(toolbar)="data">
                          <!-- we use @click.stop here to prevent emitting of a 'row-clicked' event  -->
                          <b-row>
                            <b-col cols="12" align="right">
                              <b-btn-group class="mr-1 mb-1">
                                <status-badge
                                  class="status-badge-group"
                                  :progress="data.item.progress"
                                  :status="data.item.status"
                                ></status-badge>
                              </b-btn-group>
                              <b-btn-group class="mr-1 mb-1">
                                <b-button
                                  variant="outline-dark"
                                  size="sm"
                                  :to="`/executions/${data.item._jv.id}/report`"
                                >
                                  <fa-icon far icon="file-alt"></fa-icon>
                                  <span class="ml-2">Execution Report</span>
                                </b-button>
                              </b-btn-group>
                              <b-btn-group class="mr-1 mb-1">
                                <b-button
                                  variant="transparent"
                                  size="sm"
                                  @click.stop="data.toggleDetails"
                                >
                                  <fa-icon
                                    :icon="data.detailsShowing ? 'chevron-down' : 'chevron-left'"
                                  ></fa-icon>
                                </b-button>
                              </b-btn-group>
                            </b-col>
                          </b-row>
                        </template>
                      </b-table>
                    </template>
                  </b-col>
                </b-row>
                <empty
                  v-if="$_.isEmpty(executions)"
                  :text="
                    error ? 'Something went wrong while fetching executions' : 'No executions found'
                  "
                  :icon="error ? 'exclamation' : 'folder-open'"
                  class="mt-2"
                ></empty>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <b-pagination
          v-show="!loading && !$_.isEmpty(executions)"
          align="center"
          :total-rows="pagination.count"
          :per-page="pageSize"
          v-model="pagination.page"
          @input="changePage"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import StatusBadge from "@/components/share/StatusBadge";
import InputTable from "@/components/share/InputTable";
import Empty from "@/components/share/Empty";

import { mapState } from "vuex";
import PageTitle from "@/components/share/PageTitle";

export default {
  name: "ExecutionList",
  components: { PageTitle, InputTable, StatusBadge, Empty },
  props: {
    page: String
  },
  data() {
    return {
      filter: "",
      loading: true,
      fetching: false,
      error: false,
      fields: [
        {
          key: "processorId",
          label: "Workflow",
          sortable: true
        },
        {
          key: "ownerId",
          label: "Executed by",
          sortable: true
        },
        {
          key: "createdAt",
          label: "Started at",
          sortable: true
        },
        {
          key: "toolbar",
          label: "",
          sortable: false
        }
      ],
      polling: undefined,
      // Pagination
      pagination: {
        page: 1,
        pages: 1,
        count: 1
      },
      pageSize: 20
    };
  },
  created() {
    this.loading = true;
    this.fetchExecutions();
  },
  methods: {
    fetchExecutions(page = 1, searchFilter = undefined) {
      if (this.$route.name !== "monitoring") {
        // Only poll if in monitoring
        return;
      }
      this.loading = true;
      this.fetching = true;
      const params = { sort: "-createdAt", "page[number]": page, "page[size]": this.pageSize };
      if (searchFilter) {
        params["filter[search]"] = searchFilter;
      }
      // Since we paginate, we need to keep in the store only the data from the requested page,
      // therefore we clear the data already present, probably from the previous pages.
      this.$store.commit(`builder/clearRecords`, { _jv: { type: "executions" } });
      this.$store
        .dispatch("builder/get", ["executions", { params: params }])
        .then(response => {
          this.fetching = false;
          response = response._jv.json;
          this.pagination = response.meta.pagination;
          console.debug("Pagination update", response.meta.pagination);
        })
        .catch(() => {
          this.error = true;
          this.fetching = undefined;
        })
        .finally(() => (this.loading = false));
    },
    isFailed(execution) {
      return execution.status === "failed" || execution.status === "missing_inputs";
    },
    isSuccess(execution) {
      return execution.status === "success";
    },
    isRunning(execution) {
      return execution.status === "running";
    },
    isPending(execution) {
      return execution.status === "pending" || execution.status === "paused";
    },
    changePage(page) {
      console.debug("Changing page to", page);
      this.fetchExecutions(page, this.filter);
    },
    search(event) {
      console.debug("Searching for", this.filter);
      event.preventDefault();
      this.fetchExecutions(1, this.filter);
    }
  },
  computed: {
    numberOfFailures() {
      return Object.values(this.executions).filter(execution => this.isFailed(execution)).length;
    },
    runningExecutions() {
      return Object.values(this.executions).filter(execution => this.isRunning(execution));
    },
    pendingExecutions() {
      return Object.values(this.executions).filter(execution => this.isPending(execution));
    },
    runningProgress() {
      let progress = 0;
      for (let execution of this.runningExecutions) {
        progress += execution.progress;
      }
      if (progress === 0) {
        return progress;
      }
      progress /= this.runningExecutions.length;
      return progress;
    },
    maxProgressingExecutions() {
      return this.runningExecutions.length + this.pendingExecutions.length;
    },
    pendingProgress() {
      let progress = 0;
      for (let execution of this.pendingExecutions) {
        progress += execution.progress;
      }
      if (progress === 0) {
        return progress;
      }
      // progress /= (this.pendingExecutions.length)
      return progress;
    },
    ...mapState({
      user: state => state.user
    }),
    executions() {
      return this.$store.getters[`builder/get`]("executions");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/default/asb.scss";

.status-badge-group {
  width: $font-size-base * 12;
  height: $font-size-base * 2;
}
</style>
