export const SET_DASHBOARDS = "SET_DASHBOARDS";
export const SET_DASHBOARD = "SET_DASHBOARD";
export const ADD_DASHBOARD = "ADD_DASHBOARD";
export const REMOVE_FROM_DASHBOARD = "REMOVE_FROM_DASHBOARD";

export const UPDATE_EDIT_MODE = "UPDATE_EDIT_MODE";
export const UPDATE_SHOW_COMPONENTS = "UPDATE_SHOW_COMPONENTS";
export const ENABLE_EDITOR = "ENABLE_EDITOR";
export const DISABLE_EDITOR = "DISABLE_EDITOR";
export const SET_LAYOUT = "SET_LAYOUT";

// Social Media

export const UPDATE_FILTER = "UPDATE_FILTER";
export const UPDATE_TWEETS = "UPDATE_TWEETS";
export const UPDATE_CURRENT_PAGE = "UPDATE_CURRENT_PAGE";
export const UPDATE_TWEETS_PER_PAGE = "UPDATE_TWEETS_PER_PAGE";
export const UPDATE_SHOW_TWEETS_ON_MAP = "UPDATE_SHOW_TWEETS_ON_MAP";
export const FETCHING_TWEETS = "FETCHING_TWEETS";
export const UPDATE_TOPIC_FILTER = "UPDATE_TOPIC_FILTER";
export const FETCHING_TOPICS = "FETCHING_TOPICS";
export const UPDATE_TOPICS = "UPDATE_TOPICS";
export const UPDATE_TOPIC_FILTER_COLLECTION = "UPDATE_TOPIC_FILTER_COLLECTION";
export const UPDATE_TOPIC_TWEETS_IDS = "UPDATE_TOPIC_TWEETS_IDS";
export const UPDATE_TOPIC_TWEETS = "UPDATE_TOPIC_TWEETS";
export const UPDATE_COMMUNITIES_FILTER = "UPDATE_COMMUNITIES_FILTER";
export const FETCHING_COMMUNITIES = "FETCHING_COMMUNITIES";
export const UPDATE_COMMUNITIES = "UPDATE_COMMUNITIES";
export const UPDATE_TOP_KEYPLAYERS = "UPDATE_TOP_KEYPLAYERS";
export const UPDATE_COMMUNITY_FILTER_COLLECTION = "UPDATE_COMMUNITY_FILTER_COLLECTION";
export const UPDATE_COMMUNITY_USER_ID = "UPDATE_COMMUNITY_USER_ID";
export const UPDATE_COMMUNITY_USER_TWEETS = "UPDATE_COMMUNITY_USER_TWEETS";
export const UPDATE_SELECTED_COMMUNITY_ID = "UPDATE_SELECTED_COMMUNITY_ID";
export const UPDATE_COMMUNITY_USER_HAS_TWEETS = "UPDATE_COMMUNITY_USER_HAS_TWEETS";

// Linked Data

export const UPDATE_GEOLOCALISED_LINKED_DATA_FILTER = "UPDATE_GEOLOCALISED_LINKED_DATA_FILTER";
export const FETCHING_GEOLOCALISED_LINKED_DATA = "FETCHING_GEOLOCALISED_LINKED_DATA";
export const UPDATE_GEOLOCALISED_LINKED_DATA = "UPDATE_GEOLOCALISED_LINKED_DATA";
