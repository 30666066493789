<template>
  <b-container class="px-0">
    <b-modal
      ref="showcredentials"
      id="showcredentials"
      align-h="end"
      hide-footer
      title="Show credentials"
    >
      <b-alert variant="danger" :show="non_field_error != undefined">
        <span>{{ non_field_error }}</span>
      </b-alert>
      <b-form-group
        label="ASB password"
        label-for="asb_password_input"
        description="Please confirm your ASB password"
      >
        <b-form-input v-model="asb_password" id="asb_password_input" type="password">
        </b-form-input>
        <b-form-invalid-feedback :state="fieldState()">
          <span v-for="(error, index) in password_errors" :key="index"> {{ error }}<br /></span>
        </b-form-invalid-feedback>
      </b-form-group>
      <hr />
      <b-row>
        <b-col>
          <page-title subtitle="Datastore credentials" no-divider></page-title>
          <b-form-row>
            <b-col class="col-form-label" cols="2">
              <label for="username"><b>Username:</b></label>
            </b-col>
            <b-col>
              <b-form-input
                id="username"
                type="text"
                plaintext
                :value="user.username"
              ></b-form-input>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col class="col-form-label" cols="2">
              <label for="password"><b>Password:</b></label>
            </b-col>
            <b-col>
              <b-form-input
                id="password"
                type="text"
                plaintext
                :value="datastore_credentials"
              ></b-form-input>
            </b-col>
          </b-form-row>
        </b-col>
      </b-row>
      <hr class="mt-0" />
      <b-button variant="dark" @click="fetchCredentials">
        <span>View datastore password</span>
      </b-button>
    </b-modal>

    <b-alert variant="danger" show>
      <b-row>
        <b-col class="text-center">
          <h5>Please update your password for your account in the datastore</h5>
          <p><a :href="external_datastore_url" target="_blank">Go to the datastore</a></p>
          <b-button variant="dark" size="sm" v-b-modal.showcredentials class="mb-3">
            View login information
          </b-button>
        </b-col>
      </b-row>
    </b-alert>
  </b-container>
</template>

<script>
import { userService } from "@/api/asb";
import { BAD_REQUEST } from "http-status-codes";
import PageTitle from "@/components/share/PageTitle";
import { mapState } from "vuex";

export default {
  name: "DatastoreCredentials",
  components: { PageTitle },
  data() {
    return {
      asb_password: undefined,
      datastore_credentials: "Please enter your ASB password for verification",
      password_errors: [],
      non_field_error: undefined
    };
  },
  methods: {
    fetchCredentials() {
      this.password_errors = [];
      this.non_field_error = undefined;
      userService
        .fetchDatastoreCredentials({ password: this.asb_password })
        .then(response => {
          if (response.data["message"]) {
            this.non_field_error = response.data["message"];
          } else {
            this.datastore_credentials = response.data["datastore_secret"];
          }
        })
        .catch(error => {
          if (error.response && error.response.status === BAD_REQUEST) {
            this.password_errors = error.response.data["password"];
          } else {
            this.$notify({
              group: "global",
              type: "error",
              title: `Failed to fetch credentials`,
              duration: 3000,
              text: error
            });
          }
        });
    },
    fieldState() {
      return this.password_errors.length == 0;
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
      external_datastore_url: state => state.instance.external_datastore_url
    })
  }
};
</script>

<style></style>
