<template>
  <b-container class="my-4">
    <b-modal ref="addWorkspaceModal" hide-footer id="add-workspace" title="Add workspace">
      <b-form-row>
        <b-col cols="12">
          <b-form-input
            id="add-workspace-identifier-input"
            v-model="newWorkspace.identifier"
            type="text"
            placeholder="Enter a unique identifier"
          ></b-form-input>
          <b-form-input
            id="add-workspace-label-input"
            class="mt-2"
            v-model="newWorkspace.label"
            type="text"
            placeholder="Enter a label"
          ></b-form-input>
        </b-col>
        <b-col class="mt-3" cols="12" align="right">
          <b-button class="mr-1" variant="outline-secondary" @click="hideAddWorkspaceModal">
            Cancel
          </b-button>
          <b-button variant="success" @click="addWorkspace" :disabled="creating">
            <span v-if="!creating">Create workspace</span>
            <span v-else>Creating...</span>
          </b-button>
        </b-col>
      </b-form-row>
    </b-modal>
    <page-title title="Workspaces">
      <template v-slot:toolbar>
        <b-form class="float-right" inline>
          <b-btn variant="success" :disabled="loading" v-b-modal.add-workspace>
            <span>New workspace</span>
          </b-btn>
        </b-form>
      </template>
    </page-title>
    <b-row align-h="center">
      <b-col class="my-3" cols="12" lg="9">
        <b-form>
          <b-form-select v-model="selectedGroup" class="mr-2">
            <option :value="undefined" disabled>-- Select workspace --</option>
            <option v-for="group in groups" :key="group.identifier" :value="group.identifier">
              {{ group.label }}
            </option>
          </b-form-select>
        </b-form>
      </b-col>
      <b-col class="mt-3" v-if="selectedGroupMembers" cols="12" lg="9">
        <page-title subtitle="Workspace information" no-divider></page-title>
        <b-form-row class="mb-2">
          <b-col class="col-form-label" cols="2">
            <label>Identifier:</label>
          </b-col>
          <b-col>
            <b-form-input type="text" plaintext :value="selectedGroup" />
          </b-col>
        </b-form-row>
        <page-title subtitle="Workspace members" no-divider></page-title>
        <role-table
          :items="selectedGroupMembers"
          :remainingItems="users"
          itemLabelField="username"
          label="user"
          readonly
        ></role-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { userService } from "@/api/asb";
import PageTitle from "@/components/share/PageTitle";
import RoleTable from "@/components/admin/RoleTable";
import knowledgeService from "@/api/asb/services/knowledge";

export default {
  name: "WorkspaceManagement",
  components: { PageTitle, RoleTable },
  created() {
    this.getGroupsAndUsers();
  },
  data: function() {
    return {
      loading: false,
      creating: false,
      newWorkspace: {
        identifier: undefined,
        label: undefined
      },
      groups: [],
      users: [],
      selectedGroup: undefined,
      selectedGroupMembers: undefined,
      fields: [
        {
          key: "identifier",
          label: "Identifier",
          sortable: true
        },
        {
          key: "label",
          label: "Name",
          sortable: true
        },
        {
          key: "toolbar",
          label: "",
          sortable: false
        }
      ]
    };
  },
  watch: {
    // whenever question changes, this function will run
    selectedGroup: function() {
      this.getGroupMembers();
    }
  },
  methods: {
    getGroupsAndUsers() {
      userService
        .getGroups()
        .then(groups => (this.groups = groups))
        .catch(e => userService.handleError(e));
      userService
        .getUsers()
        .then(users => (this.users = users))
        .catch(e => userService.handleError(e));
    },
    getGroupMembers() {
      this.selectedGroupMembers = undefined;
      userService
        .getGroupMembers(this.selectedGroup)
        .then(members => {
          this.selectedGroupMembers = members;
        })
        .catch(e => userService.handleError(e));
    },
    addWorkspace() {
      this.creating = true;
      const newWorkspace = {
        identifier: this.newWorkspace.identifier.toLowerCase(),
        label: this.newWorkspace.label,
        _jv: {
          type: "workspaces"
        }
      };
      this._createWorkspace(newWorkspace)
        .then(() => {
          this.$notify({
            group: "global",
            type: "success",
            title: `Workspace ${this.newWorkspace.identifier} successfully created`,
            duration: 3000,
            text: ""
          });
          this.hideAddWorkspaceModal();
          this.getGroupsAndUsers();
        })
        .catch(error => {
          knowledgeService.handleError(error);
        })
        .finally(() => (this.creating = false));
    },
    async _createWorkspace(workspace) {
      let knowledgePromise = this.$store.dispatch("knowledge/post", [workspace]);
      let builderPromise = this.$store.dispatch("builder/post", [workspace]);
      let backendPromise = this.$store.dispatch("backend/post", [workspace]);
      let userPromise = userService.createWorkspaceRoles({
        workspace_identifier: workspace.identifier
      });
      return Promise.all([knowledgePromise, builderPromise, backendPromise, userPromise]);
    },
    hideAddWorkspaceModal() {
      this.$bvModal.hide("add-workspace");
    }
  }
};
</script>

<style scoped></style>
