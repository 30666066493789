<template>
  <generic-list
    name="schedule"
    service="builder"
    :filters="filters"
    :archive-text="archiveText"
    :fields="fields"
    paginate
  >
    <template v-slot:resource-label="{ resource }">
      <b-row>
        <b-col align-self="center" class="px-1">
          <router-link
            :to="{ name: 'schedule', params: { id: resource._jv.id } }"
            class="bold-link"
          >
            {{ resource.ownerId }} / {{ resource.title }}
          </router-link>
        </b-col>
        <b-col align-self="center"> </b-col>
        <b-col align-self="center">
          <span>{{ resource.createdAt | moment("YYYY-MM-DD HH:mm") }}</span>
        </b-col>
        <b-col align-self="center">
          {{ _verbalize(resource) }}
        </b-col>
        <b-col align-self="center">
          <h5>
            <b-badge v-if="resource.status === 'enabled'" class="float-right" variant="success"
              >Enabled</b-badge
            >
            <b-badge v-else class="float-right" variant="secondary">Disabled</b-badge>
          </h5>
        </b-col>
      </b-row>
    </template>
  </generic-list>
</template>

<script>
import GenericList from "@/components/generic/GenericList";
import moment from "moment";
export default {
  name: "ScheduleList",
  components: { GenericList },
  data() {
    return {
      // Filters
      filters: [
        { name: "isArchived", value: () => "true", label: "Show archived only" },
        { name: "isArchived", value: () => "false", label: "Hide archived", default: true }
      ],
      // Create
      createFields: [],
      // Archive
      archiveText: "ATTENTION! This schedule will be automatically disabled when archived.",
      fields: ["Owner / Title", "Workflow", "Created at", "Description", "Status"]
    };
  },
  methods: {
    _verbalize(schedule) {
      const dateFormat = "YYYY-MM-DD HH:mm";
      let startTime = moment(schedule.startTime).format(dateFormat);
      let endTime = moment(schedule.endTime).format(dateFormat);
      if (schedule.strategy === "external-trigger") {
        return 'No scheduled execution. Bound to trigger identifier "' + schedule.identifier + '".';
      } else if (schedule.strategy === "single-execution") {
        return "Single execution scheduled on " + startTime;
      } else if (schedule.strategy === "interval-execution") {
        return `Execution every ${schedule.interval} ${schedule.intervalPeriod}
        between ${startTime} and ${endTime}.`;
      } else if (schedule.strategy === "crontab-execution") {
        // NOTE Crontab execution is temp. only used for day of month-like schedule.
        return `Execution at ${schedule.hour}: ${schedule.minute} every
        ${schedule.dayOfMonth} day of month, between  ${startTime}  and  ${endTime}`;
      } else if (schedule.strategy === "on-new-data") {
        return `Execute workflow on new data available from ${schedule.dataUrl}`;
      }
      return `Execution strategy: ${schedule.strategy}.`;
    }
  }
};
</script>

<style scoped></style>
