<template>
  <generic-list
    name="workflow"
    service="knowledge"
    :filters="filters"
    enable-create
    :create-fields="createFields"
    :archive-text="archiveText"
    paginate
    shareable
  >
    <template v-slot:resource-toolbar="{ resource }">
      <b-btn
        v-show="!resource.isArchived"
        class="mx-1"
        variant="info"
        size="sm"
        :to="`/workflows/${resource._jv.id}/schedule`"
        v-if="theme_features.includes('navbar-schedules')"
      >
        Schedule
      </b-btn>
      <b-btn
        v-show="!resource.isArchived"
        class="ml-1"
        variant="danger"
        size="sm"
        :to="`/workflows/${resource._jv.id}/execute`"
      >
        Execute
      </b-btn>
    </template>
  </generic-list>
</template>

<script>
import GenericList from "@/components/generic/GenericList";
import { randomAdjective, slugify } from "@/utils";
import { mapState } from "vuex";
export default {
  name: "WorkflowList",
  components: { GenericList },
  data() {
    return {
      // Filters
      filters: [
        {
          name: "ownerId",
          value: vm => vm.user.username,
          label: "Owned by me"
        },
        {
          name: "workspaces.identifier",
          value: vm => vm.user.username,
          label: "In my workspace"
        },
        {
          name: "isArchived",
          value: () => "true",
          label: "Show archived only"
        },
        {
          name: "isArchived",
          value: () => "false",
          label: "Hide archived",
          default: true
        }
      ],
      // Create
      createFields: [
        {
          name: "label",
          label: () => "Name",
          type: "text",
          onChange: resource => {
            resource.slug = slugify(resource.label, "-");
          },
          placeholder: () => `My ${randomAdjective()} workflow`
        },
        {
          name: "slug",
          label: vm => `${vm.user.username} / `,
          type: "text",
          description: "Workflow slug",
          placeholder: () => `my-${randomAdjective()}-workflow`
        }
      ],
      // Archive
      archiveText:
        "The workflow will be hidden from the workflow listing only. " +
        "Executions associated to this workflow are still accessible."
    };
  },
  computed: {
    ...mapState({
      theme_features: state => state.instance.theme_features
    })
  }
};
</script>

<style scoped></style>
