<template>
  <generic-list
    name="dashboard"
    service="knowledge"
    :filters="filters"
    enable-create
    :archive-text="archiveText"
    :delete-text="deleteText"
    paginate
    deleteable
    shareable
    ref="dashboardsList"
  >
    <template v-slot:title-toolbar>
      <b-btn-group class="ml-2">
        <b-btn variant="outline-success" size="md" :href="dashboard_url">
          View Dashboards
        </b-btn>
      </b-btn-group>
    </template>

    <template v-slot:create-resource-form>
      <b-row align-h="end">
        <b-col cols="12" v-show="createErrors['nonFieldErrors'].length !== 0">
          <b-alert
            variant="danger"
            v-for="(error, index) in createErrors['nonFieldErrors']"
            :key="index"
            show
          >
            {{ error }}
          </b-alert>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Name"
            label-for="dashboard-name-input"
            label-align="right"
            label-cols="2"
            label-class="font-weight-bold"
          >
            <b-form-input
              placeholder="Dashboard name"
              id="dashboard-name-input"
              v-model="newDashboard.verboseName"
              @input="onChange"
              :state="createState('verboseName')"
            >
            </b-form-input>

            <b-form-invalid-feedback :id="`createVerboseNameFeedback`">
              <span v-for="(error, index) in createErrors['verboseName']" :key="index">
                {{ error }}
              </span>
            </b-form-invalid-feedback>
            <b-form-text>Dashboard Slug: {{ newDashboard.slug }} </b-form-text>
          </b-form-group>
          <b-form-group
            label="Description"
            label-for="dashboard-description-input"
            label-align="right"
            label-cols="2"
            label-class="font-weight-bold"
          >
            <b-form-textarea
              placeholder="Dashboard description"
              id="dashboard-description-input"
              v-model="newDashboard.description"
              :state="createState('description')"
            >
            </b-form-textarea>
            <b-form-invalid-feedback :id="`createDescriptionFeedback`">
              <span v-for="(error, index) in createErrors['description']" :key="index">
                {{ error }}
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-button
            :variant="error ? 'failed' : 'success'"
            @click="createResource"
            :disabled="creating"
            block
            class="ml-auto"
          >
            <span v-if="!creating">Create Dashboard</span>
            <b-spinner v-else small></b-spinner>
          </b-button>
        </b-col>
      </b-row>
    </template>
    <template v-slot:resource-label="{ resource }">
      <router-link
        class="bold-link"
        v-b-tooltip.hover
        :title="resource.identifier"
        :to="`dashboards/${resource._jv.id}/`"
      >
        <span>{{ resource.ownerId }} / {{ resource.verboseName }} </span>
      </router-link>
    </template>
  </generic-list>
</template>

<script>
import GenericList from "@/components/generic/GenericList";
import { BAD_REQUEST } from "http-status-codes";
import { slugify } from "@/utils";
import { handleServiceError } from "@/api/utils";
export default {
  name: "DashboardList",
  components: { GenericList },
  data() {
    return {
      dashboard_url: process.env.VUE_APP_DASHBOARDS_APP_BASE_URL,
      filters: [
        {
          name: "ownerId",
          value: vm => vm.user.username,
          label: "Owned by me"
        },
        {
          name: "workspaces.identifier",
          value: vm => vm.user.username,
          label: "In my workspace"
        },
        { name: "isPublic", value: () => "true", label: "Public dashboards" },
        { name: "isPublic", value: () => "false", label: "Private dashboards" },
        { name: "isSystem", value: () => "true", label: "System dashboards" },
        {
          name: "isSystem",
          value: () => "false",
          label: "Personal dashboards"
        },
        {
          name: "isArchived",
          value: () => "true",
          label: "Show archived only"
        },
        {
          name: "isArchived",
          value: () => "false",
          label: "Hide archived",
          default: true
        }
      ],

      // Archive
      archiveText: "The dashboard will be hidden in the Dashboards page",
      deleteText: "The dashboard will be deleted permanently!",
      showDelete: true,

      creating: false,
      error: undefined,
      createErrors: {
        verboseName: [],
        description: [],
        nonFieldErrors: []
      },

      newDashboard: {
        verboseName: undefined,
        slug: undefined,
        description: undefined,
        _jv: {
          type: "dashboards"
        }
      }
    };
  },
  methods: {
    createResource() {
      this.error = false;
      this.creating = true;

      //Clear errors from previous creation attempts
      Object.keys(this.createErrors).forEach(key => (this.createErrors[key] = []));

      this.$store
        .dispatch("knowledge/post", this.newDashboard)
        .then(resource => {
          this.$notify({
            group: "global",
            type: "success",
            text: "",
            title: `Dashboard ${resource.verboseName} successfully created`,
            duration: 5000
          });
          this.$refs.dashboardsList.$refs.new.hide();
          this.newDashboard.verboseName = undefined;
          this.newDashboard.description = undefined;
        })
        .catch(error => {
          this.error = true;
          this.handleError(error);
        })
        .finally(() => {
          this.creating = false;
        });
    },
    onChange() {
      this.newDashboard.slug = slugify(this.newDashboard.verboseName, "-");
    },
    createState(attribute) {
      let createError = this.createErrors[attribute];
      return createError && createError.length !== 0 ? false : null;
    },
    handleError(error) {
      //identical code to createResourceForm.vue, only separated into its own function
      //perhaps this could be a utils.js function? It's a function for JSONAPI error parsing
      if (error.response && error.response.status === BAD_REQUEST) {
        for (let formError of error.response.data["errors"]) {
          const pointer = formError.source.pointer;
          if (pointer.startsWith("/data/attributes")) {
            // JSON API error
            const attribute = formError.source.pointer.split("/")[3];
            if (attribute in this.createErrors) {
              this.createErrors[attribute].push(formError.detail);
            } else {
              console.warn("Cannot show errors for pointer", pointer);
            }
          } else {
            console.error("Wrong source pointer", pointer);
          }
        }
      } else {
        handleServiceError("knowledge", error);
      }
    }
  }
};
</script>

<style scoped></style>
