<template>
  <main role="main">
    <section class="mb-5 jumbotron">
      <b-container class="text-center">
        <img alt="ASB" src="@/assets/asb-full-logo-without-text.png" height="200" />
        <h1 class="mt-3 jumbotron-heading">{{ instance.name }}</h1>
        <p class="lead text-muted">
          ASB is a framework enabling platform and application agnostic solution for implementing
          complex processing chains over globally distributed processing and data resources.<br /><br />
          ASB provides a “low coding” solution to develop a data processing facility based on
          orchestrated workflows.
        </p>
      </b-container>
    </section>
    <section class="py-1 bg-light divider-light">
      <b-container>
        <b-row class="my-5 pb-5" align-v="end">
          <b-col lg="6" md="12">
            <span class="sub-title">Want to be involved?</span>
            <h5 class="mt-2 content">
              <div>
                We are looking for example real-life scenarios. We will be pleased to organize
                demonstration sessions to interested stakeholders.
              </div>
              <div class="mt-4">
                Your feedback will help us ensuring ASB will continue to meet the expectations in
                the future.
              </div>
              <div class="mt-4">
                Please contact
                <b>
                  <a :href="`mailto:${contactEmail}?subject=${emailSubject}`">
                    Leslie Gale<fa-icon class="ml-1 pb-1" icon="paper-plane"></fa-icon> </a
                ></b>
                should you want to know more, be involved, or just be informed on the user-related
                activities.
              </div>
            </h5>
          </b-col>
          <b-col lg="6" md="12" align="right">
            <a :href="spaceappsURL" target="_blank">
              <img
                alt="Space Applications Services"
                src="@/assets/spaceapps-logo.png"
                height="80"
              />
            </a>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </main>
</template>

<script>
export default {
  name: "IndexContent",
  data() {
    return {
      contactEmail: "leslie.gale@spaceapplications.com",
      emailSubject: "ASB Contact",
      spaceappsURL: "https://www.spaceapplications.com",
      asbProductURL: "https://www.spaceapplications.com/products/automated-service-builder-asb/",
      instance: this.$store.state.instance
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/default/asb.scss";

.main-title {
  display: block;
  font-size: $font-size-base * 3.7;
  font-weight: 300;
  color: theme-color("primary");
}

.sub-title {
  display: block;
  font-size: $font-size-base * 3;
  font-weight: 300;
  color: theme-color("primary");
}

.content {
  font-weight: normal;
  font-size: $font-size-base * 1.4;
  text-align: justify;
}

/* Album custom style */

:root {
  --jumbotron-padding-y: 3rem;
}

.jumbotron {
  padding-top: var(--jumbotron-padding-y);
  padding-bottom: var(--jumbotron-padding-y);
  margin-bottom: 0;
  background-color: #fff;
}

@media (min-width: 768px) {
  .jumbotron {
    padding-top: calc(var(--jumbotron-padding-y) * 2);
    padding-bottom: calc(var(--jumbotron-padding-y) * 2);
  }
}

.jumbotron p:last-child {
  margin-bottom: 0;
}

.jumbotron-heading {
  font-weight: 300;
}

.jumbotron .container {
  max-width: 40rem;
}

footer {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

footer p {
  margin-bottom: 0.25rem;
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}
</style>
