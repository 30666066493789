<template>
  <b-row no-gutters>
    <b-col>
      <table class="my-0 table table-bordered">
        <tbody>
          <tr v-for="input in inputs" :key="input.id" v-show="input['user_visible']">
            <th scope="row">{{ input.label }}</th>
            <td>{{ input.value }}</td>
          </tr>
        </tbody>
      </table>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "InputTable",
  props: {
    inputs: Array,
    disableAll: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
table {
  table-layout: fixed;
}
</style>
