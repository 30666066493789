<template>
  <generic-list
    name="process"
    service="knowledge"
    :filters="filters"
    enable-create
    :create-fields="createFields"
    :create-defaults="createDefaults"
    :archive-text="archiveText"
    paginate
    shareable
  >
    <template v-slot:title-toolbar>
      <b-btn-group class="ml-2">
        <b-btn
          variant="outline-success"
          size="md"
          v-b-tooltip.hover
          title="Generate a wrapper to build a new process version"
          :to="{ name: 'process:wrapper:generator' }"
        >
          New wrapper
        </b-btn>
      </b-btn-group>
    </template>
  </generic-list>
</template>

<script>
import GenericList from "@/components/generic/GenericList";
import { randomAdjective, slugify } from "@/utils";
export default {
  name: "ProcessList",
  components: { GenericList },
  data() {
    return {
      // Filters
      filters: [
        { name: "ownerId", value: vm => vm.user.username, label: "Owned by me" },
        { name: "workspaces.identifier", value: vm => vm.user.username, label: "In my workspace" },
        { name: "type", value: () => "wps", label: "User processes (WPS)", default: true },
        { name: "type", value: () => "python", label: "Snippets" },
        { name: "isArchived", value: () => "true", label: "Show archived only" },
        { name: "isArchived", value: () => "false", label: "Hide archived", default: true }
      ],
      // Create
      createFields: [
        {
          name: "label",
          label: () => "Name",
          type: "text",
          onChange: resource => {
            resource.slug = slugify(resource.label, "-");
          },
          placeholder: () => `My ${randomAdjective()} process`
        },
        {
          name: "slug",
          label: vm => `${vm.user.username} / `,
          type: "text",
          description: "Process slug",
          placeholder: () => `my-${randomAdjective()}-process`
        }
      ],
      createDefaults: {
        type: "wps"
      },
      // Archive
      archiveText: "The process versions will be hidden in the workflow editor"
    };
  }
};
</script>

<style scoped></style>
