<template>
  <b-progress :max="1" class="status-badge">
    <b-progress-bar class="pl-1" :value="isInProgress() ? progress : 1" :variant="statusVariant()">
      <span>
        <fa-icon v-if="statusIcon" :icon="statusIcon"></fa-icon>
        <span class="ml-2">{{ statusText }}</span>
      </span>
    </b-progress-bar>
  </b-progress>
</template>

<script>
export default {
  name: "StatusBadge",
  props: {
    status: String,
    progress: Number,
    width: Number
  },
  data() {
    return {
      success: ["success", "successful", "created"],
      pending: ["pending", "paused", "submitting", "credit_calculated"],
      running: ["running", "request_sent"],
      failed: ["failed", "aborted", "stopped", "missing_inputs", "denied"],
      inProgress: ["running", "paused"]
    };
  },
  created() {},
  methods: {
    isInProgress() {
      return this.inProgress.includes(this.status);
    },
    is(from) {
      return from.includes(this.status);
    },
    statusVariant() {
      if (this.is(this.success)) {
        return "generated";
      } else if (this.is(this.pending)) {
        return "pending";
      } else if (this.is(this.running)) {
        return "info";
      } else if (this.is(this.failed)) {
        return "failed";
      } else {
        return "secondary";
      }
    }
  },
  computed: {
    statusText() {
      if (this.is(this.success)) {
        return "Success";
      }
      if (this.isInProgress()) {
        return `${Math.round(this.progress * 100)}%`;
      } else if (this.status) {
        return this.status.replace("_", " ");
      } else {
        return "Unknown status";
      }
    },
    statusIcon() {
      if (this.is(this.success)) {
        return "check";
      } else if (this.is(this.failed)) {
        return "exclamation-triangle";
      }
      return undefined;
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/default/asb.scss";

.status-badge {
  border-radius: 5px !important;
  text-transform: capitalize;
  height: $font-size-base * 2.1;
  font-size: $font-size-base * 0.9 !important;
}

.progress {
  background-color: gray("400");
}
</style>
