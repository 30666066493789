<template>
  <edit-user username="self" readonly></edit-user>
</template>

<script>
import EditUser from "@/pages/admin/users/EditUser";

export default {
  name: "UserProfile",
  components: { EditUser }
};
</script>

<style scoped></style>
