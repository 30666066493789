<template>
  <b-container class="my-4" fluid>
    <b-row>
      <b-col class="mx-5">
        <page-title
          title="Execute Application"
          :subtitle="`${application_title} / ${application_abstract}`"
        >
        </page-title>
        <b-tabs>
          <b-tab title="Execute">
            <b-row>
              <b-col cols="12">
                <h4 class="my-4">Inputs</h4>
                <p v-if="input_types.length > 0">
                  This application supports the following product types:
                  <b-badge variant="primary" class="mr-1" v-for="type in input_types" :key="type">{{
                    type
                  }}</b-badge>
                </p>
              </b-col>
              <b-col cols="12" class="text-center">
                <loading :loading="loading"></loading>
              </b-col>
              <b-col class="mt-2" cols="12">
                <component
                  v-for="(input, id) in application_info.inputs"
                  :key="id"
                  :is="loadComponentName(input.type)"
                  :customSettings="input.customSettings"
                  :inp="input"
                  :inp_id="id"
                  v-on:input_change="(...args) => update_input(args)"
                >
                </component>
              </b-col>
            </b-row>
            <b-row v-if="theme_features.includes('eoepca-tcs') && !disable_tcs">
              <b-col cols="12" class="text-right">
                <b-checkbox
                  class="ml-auto pb-3"
                  :checked="tcs_accepted"
                  @change="updateTCS()"
                  :disabled="tcs_updating"
                >
                  <span v-if="!tcs_updating"
                    >Accept
                    <a :href="application_info.license" target="_blank"
                      >Terms and Conditions</a
                    ></span
                  >
                  <span v-else>
                    <b-spinner small class="mx-2"></b-spinner>
                    <span v-if="!tcs_accepted">Accepting Terms and Conditions</span>
                    <span v-else>Revoking consent for Terms and Conditions</span>
                  </span>
                </b-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group class="d-flex justify-content-end text-right">
                  <span class="mr-2"> Target Platform: </span>
                  <b-form-select
                    v-model="selectedAdes"
                    :options="adesOptions"
                    style="width: 250px"
                  ></b-form-select>
                  <b-button
                    class="execute-button"
                    @click="execute()"
                    type="submit"
                    variant="success"
                    :disabled="disableExecution"
                  >
                    <fa-icon class="mt-1 mr-2 float-left" icon="bolt" />
                    <span v-if="executing">Sending request...</span>

                    <span v-else>Execute</span>
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import PageTitle from "@/components/share/PageTitle.vue";
import backendService from "@/api/asb/services/backend";
import userService from "@/api/asb/services/usermgr";
import Default from "@/components/share/InputFields/Default";
import InputText from "@/components/share/InputFields/InputText";
import UserInteger from "@/components/share/InputFields/UserInteger";
import UserString from "@/components/share/InputFields/UserString";
import CatalogueProduct from "@/components/share/InputFields/CatalogueProduct";
import Loading from "@/components/share/Loading";
import { mapState } from "vuex";
import { isUrl } from "@/api/utils";

export default {
  components: {
    PageTitle,
    Default,
    InputText,
    UserInteger,
    UserString,
    Loading,
    CatalogueProduct
  },
  name: "CWLWorkflowExecutePage",
  data() {
    return {
      collection_id: this.$route.params["id"],
      application_id: this.$route.params["app_id"],
      application_info: {
        inputs: {}
      },
      executing: false,
      allInputsValid: false,
      loading: false,
      ESClient: undefined,
      adesOptions: [
        {
          text: "Default",
          value: "local"
        }
      ],
      selectedAdes: "local",
      input_types: [],
      tcs_updating: false,
      disable_tcs: true
    };
  },
  created() {
    this.createComponentNameList();
    this.loading = true;
    backendService
      .describeApplication(this.collection_id, this.application_id)
      .then(description => {
        this.application_info = description.data;
        for (let inp of Object.values(this.application_info.inputs)) {
          inp.customSettings = {
            default_value: inp.default
          };
        }
        var regex = new RegExp(`FILTER:input-type:(.*)`);
        if (this.application_info.keywords) {
          for (let keyword of this.application_info.keywords.split(",")) {
            keyword = keyword.trim();
            if (regex.test(keyword)) {
              this.input_types.push(regex.exec(keyword)[1]);
            }
          }
        }

        if (isUrl(this.application_info.license)) {
          this.disable_tcs = false;
        }

        this.loading = false;
      })
      .catch(error => backendService.handleError(error))
      .finally();
    backendService.getAvailableAdesInstances().then(instances => {
      this.adesOptions = [
        {
          text: "Default",
          value: "local"
        }
      ];
      for (let instance of instances) {
        this.adesOptions.push({
          value: instance.identifier,
          text: instance.title
        });
      }
    });
  },
  methods: {
    createComponentNameList() {
      let componentNameList = [];
      for (let c in this.$options.components) {
        componentNameList.push(this.$_.kebabCase(c));
      }
      this.componentNameList = componentNameList;
      return;
    },
    loadComponentName(cName) {
      let cNameKebab = this.$_.kebabCase(cName);
      return this.componentNameList.indexOf(cNameKebab) > -1 ? cNameKebab : "input-text";
    },
    update_input(args) {
      for (let input in this.application_info.inputs) {
        if (this.application_info.inputs[input] == args[1]) {
          console.log("updating");
          this.application_info.inputs[input].value = args[2];
          this.application_info.inputs[input].valid = args[3];
        }
      }
      this.validateAllInputs();
    },
    execute() {
      this.executing = true;
      backendService
        .startApplicationExecution(
          this.collection_id,
          this.application_id,
          this.application_info.inputs,
          this.selectedAdes
        )
        .then(() => {
          console.log("execution started!");
          this.$router.push("/monitoring/applications");
        });
    },
    validateAllInputs() {
      for (let input in this.application_info.inputs) {
        if (!this.application_info.inputs[input].valid) {
          this.allInputsValid = false;
          return;
        }
      }
      this.allInputsValid = true;
    },
    updateTCS() {
      this.tcs_updating = true;
      if (this.tcs_accepted) {
        userService
          .revokeConsentForTermsAndConditions(this.application_info.license)
          .then(() => {
            this.$store.dispatch("FETCH_ACCEPTED_TCS");
            this.tcs_updating = false;
          })
          .catch(error => backendService.handleError(error));
      } else {
        userService
          .acceptTermsAndConditions(this.application_info.license)
          .then(() => {
            this.$store.dispatch("FETCH_ACCEPTED_TCS");
            this.tcs_updating = false;
          })
          .catch(error => backendService.handleError(error));
      }
    }
  },
  computed: {
    application_title() {
      return this.application_info.label ? this.application_info.label : "application";
    },
    application_abstract() {
      return this.application_info.doc ? this.application_info.doc : "application";
    },
    ...mapState({
      theme_features: state => state.instance.theme_features,
      accepted_tcs: state => state.accepted_tcs
    }),
    disableExecution() {
      if (this.theme_features.includes("eoepca-tcs") && !this.disable_tcs) {
        return (
          this.executing ||
          !this.allInputsValid ||
          this.selectedAdes == undefined ||
          !this.tcs_accepted ||
          this.tcs_updating
        );
      } else {
        return this.executing || !this.allInputsValid || this.selectedAdes == undefined;
      }
    },
    tcs_accepted() {
      return (
        this.accepted_tcs.includes(this.application_info.license) || this.accepted_tcs.includes("*")
      );
    }
  }
};
</script>

<style></style>
