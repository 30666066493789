<template>
  <div>
    <div class="container introduction">
      <div class="row">
        <div class="col-lg-12" style="text-align: center;">
          <span style="float: inside;margin-right: 20px;">
            <a href="https://eopen-project.eu" target="_blank">
              <img src="@/plugins/eopen/assets/eopen-logo.png" alt="ASB" height="150" />
            </a>
          </span>
          <h2 class="featurette-heading" style="margin: 40px 0 0 0;">
            Welcome to EOPEN<br />
            <span class="text-muted" style="font-size:40%;">
              <span>Powered by</span>
              <a
                href="https://www.spaceapplications.com/products/automated-service-builder-asb/"
                target="_blank"
              >
                <img src="@/assets/asb-full-logo-without-text.png" alt="ASB" height="45" />
              </a>
            </span>
          </h2>
        </div>
      </div>
      <b-row class="mt-3" align-h="center">
        <b-col class="mt-2" cols="12" align="center">
          <b-row class="mt-3" align-h="center">
            <a
              target="_blank"
              href="https://eopen.spaceapplications.com/docs/"
              class="btn btn-primary mx-1"
            >
              User Guide <fa-icon class="ml-1" icon="external-link-alt" />
            </a>
            <a
              target="_blank"
              href="https://eopen-project.eu/introductory-tour/"
              class="btn btn-primary mx-1"
            >
              Video Guides <fa-icon class="ml-1" icon="external-link-alt" />
            </a>
            <a
              target="_blank"
              href="https://eopen-project.eu/public-deliverables/"
              class="btn btn-primary mx-1"
            >
              Project Deliverables <fa-icon class="ml-1" icon="external-link-alt" />
            </a>
          </b-row>
          <br />
          <a
            target="_blank"
            href="https://eopen.spaceapplications.com/gisviewer/"
            class="btn btn-success mx-1"
          >
            Showcase GIS Viewer <fa-icon class="ml-1" icon="external-link-alt" />
          </a>
        </b-col>
      </b-row>
      <br />
      <b-row class="mt-3" align-h="center">
        <span style="font-size: 1rem;">
          Please contact
          <a
            href="mailto:leslie.gale@spaceapplications.com?subject=EOPEN Showcase Contact"
            class="text-principal"
          >
            Leslie Gale <fa-icon class="ml-1" icon="paper-plane" />
          </a>
          should you want to give a try to the EOPEN platform, or just be informed on the related
          activities.
        </span>
      </b-row>
    </div>

    <!-- Marketing messaging and featurettes
        ================================================== -->
    <!-- Wrap the rest of the page in another container to center all the content. -->

    <div class="pb-5 my-5 container marketing divider-light">
      <hr class="featurette-divider" />

      <div class="row featurette">
        <div class="col-md-12">
          <h3 class="featurette-heading" style="margin-top:10px; text-align: center;">
            Consortium Members
          </h3>
          <div class="row">
            <div class="col-md-4" style="text-align: center;">
              <a href="https://www.serco.eu" target="_blank">
                <img
                  src="@/plugins/eopen/assets/consortium/logo_serco-300x150.png"
                  alt="SERCO"
                  height="100"
                />
              </a>
            </div>
            <div class="col-md-4" style="text-align: center;">
              <a href="https://www.hlrs.de" target="_blank">
                <img
                  src="@/plugins/eopen/assets/consortium/logo_hlrs-300x150.png"
                  alt="HLRS"
                  height="100"
                />
              </a>
            </div>
            <div class="col-md-4" style="text-align: center;">
              <a href="http://mklab.iti.gr" target="_blank">
                <img
                  src="@/plugins/eopen/assets/consortium/logo_ITI-300x150.png"
                  alt="ITI"
                  height="100"
                />
              </a>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4" style="text-align: center;">
              <a href="https://en.ilmatieteenlaitos.fi" target="_blank">
                <img
                  src="@/plugins/eopen/assets/consortium/logo_fmi-300x150.png"
                  alt="FMI"
                  height="100"
                />
              </a>
            </div>
            <div class="col-md-4" style="text-align: center;">
              <a href="http://www.noa.gr" target="_blank">
                <img
                  src="@/plugins/eopen/assets/consortium/logo_noa1-300x150.png"
                  alt="NOA"
                  height="100"
                />
              </a>
            </div>
            <div class="col-md-4" style="text-align: center;">
              <a href="http://www.adbve.it" target="_blank">
                <img
                  src="@/plugins/eopen/assets/consortium/logo_AAWA-300x150.png"
                  alt="AAWA"
                  height="100"
                />
              </a>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4" style="text-align: center;">
              <a href="http://egis.korea.ac.kr" target="_blank">
                <img
                  src="@/plugins/eopen/assets/consortium/logo_Korea_University-300x150.png"
                  alt="Korea University"
                  height="100"
                />
              </a>
            </div>
            <div class="col-md-4" style="text-align: center;">
              <a href="http://www.sundosoft.co.kr" target="_blank">
                <img
                  src="@/plugins/eopen/assets/consortium/logo_sundosoft-300x150.png"
                  alt="Sundosoft"
                  height="100"
                />
              </a>
            </div>
            <div class="col-md-4" style="text-align: center;">
              <a href="https://www.spaceapplications.com" target="_blank">
                <img
                  src="@/plugins/eopen/assets/consortium/logo_spaceapps-300x150.png"
                  alt="SpaceApps"
                  height="100"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IndexContent"
};
</script>

<style scoped>
h2.featurette-heading {
  font-family: "Roboto", "Helvetica", "Helvetica Neue";
  font-size: 50px !important;
  margin: 10px 0 20px 0;
  color: #004a98 !important;
  font-weight: normal;
}
</style>
