<template>
  <generic-list
    name="process-version"
    service="knowledge"
    :filters="filters"
    :archive-text="archiveText"
    :identifier-of="identifierOf"
    title="Released process versions"
    proxy-workspaces-through="process"
  >
    <template v-slot:resource-label="{ resource }">
      <router-link
        class="bold-link"
        :to="{ name: 'process', params: { id: resource.process._jv.id } }"
        v-b-tooltip.hover
        :title="resource.process.identifier"
      >
        <span>{{ resource.process.ownerId }} / {{ resource.process.label }}</span>
      </router-link>

      <span class="mx-1">version</span><b>{{ resource.value }}</b>
      <b-badge class="mx-1" variant="success">Released</b-badge>
    </template>
  </generic-list>
</template>

<script>
import GenericList from "@/components/generic/GenericList";

export default {
  name: "ProcessVersionList",
  components: { GenericList },
  data() {
    return {
      // Filters
      filters: [
        { name: "isArchived", value: () => "true", label: "Show archived only" },
        { name: "isArchived", value: () => "false", label: "Hide archived", default: true }
      ],
      // Archive
      archiveText:
        "The process version will be hidden in the workflow editor and in the process page.",
      identifierOf: version =>
        `${version.value} of ${version.process ? version.process.identifier : "unknown process"}`
    };
  }
};
</script>

<style scoped></style>
