<template>
  <b-row class="form-group" v-show="visible">
    <b-col>
      <b-form-row>
        <b-col cols="10">
          <b-form-group
            label-cols-lg="2"
            :label="label"
            :disabled="!editable"
            :description="'description' in inp ? inp.description : ''"
          >
            <template v-if="hasOptions">
              <b-form-select v-model="inputValue" :options="options" @update="update_value">
              </b-form-select>
            </template>
            <template v-else>
              <b-form-input
                v-model="inputValue"
                @update="update_value"
                :state="inputState"
              ></b-form-input>
              <b-form-text v-if="inp.doc">{{ inp.doc }}</b-form-text>
            </template>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-button v-if="customSettings.default_value" @click="reset_default" variant="warning"
            >Reset to default
          </b-button>
        </b-col>
      </b-form-row>
    </b-col>
  </b-row>
</template>

<script>
import InputMixin from "@/components/generic/InputMixin";

export default {
  mixins: [InputMixin],
  name: "UserString",
  computed: {
    hasOptions() {
      return (
        "constraints" in this.inp &&
        this.inp.constraints.constructor == Object &&
        "enum" in this.inp.constraints &&
        this.inp.constraints.enum.length > 0
      );
    },
    options() {
      if (this.hasOptions) {
        let fieldName =
          "enum_labels" in this.inp.constraints && this.inp.constraints.enum_labels.length > 0
            ? "enum_labels"
            : "enum";
        return this.inp.constraints.enum.map((value, i) => ({
          value,
          text: this.inp.constraints[fieldName][i]
        }));
      }
      return [];
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/default/asb.scss";
</style>
