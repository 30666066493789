<template>
  <execution-list :page="page"></execution-list>
</template>

<script>
import ExecutionList from "@/pages/monitoring/ExecutionList";

export default {
  name: "Monitoring",
  components: { ExecutionList },
  props: ["page"]
};
</script>

<style scoped></style>
