<template>
  <b-row class="form-group" v-show="visible">
    <b-col>
      <b-form-row>
        <b-col cols="10">
          <b-form-group
            label-cols-lg="2"
            :label="label"
            :description="'description' in inp ? inp.description : ''"
          >
            <date-range-picker
              ref="picker"
              :id="inp.identifier"
              :placeholder="inp.label"
              class="btn-block"
              opens="center"
              :locale-data="{ firstDay: 1, format: dateFormat }"
              time-picker24-Hour
              min-Date="2019-12-18 00:00"
              v-model="dateRange"
              :disabled="!editable"
            >
              <b-form-text v-if="inp.doc">{{ inp.doc }}</b-form-text>
              <template slot="input" slot-scope="picker">
                <span>
                  {{ picker.startDate | moment(dateFormat) }}
                </span>
                <span> / {{ picker.endDate | moment(dateFormat) }} </span>
              </template>
            </date-range-picker>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-button v-if="customSettings.default_value" @click="reset_default" variant="warning"
            >Reset to default
          </b-button>
        </b-col>
      </b-form-row>
    </b-col>
  </b-row>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import InputMixin from "@/components/generic/InputMixin";

export default {
  mixins: [InputMixin],
  name: "DateRange",
  components: { DateRangePicker },
  data() {
    return {
      dateFormat: "YYYY-MM-DD",
      dateRange: { startDate: undefined, endDate: undefined },
      dateRegex: /\d\d\d\d-\d\d-\d\d( |)\/( |)\d\d\d\d-\d\d-\d\d/
    };
  },
  created() {
    if (this.customSettings.default_value) {
      let match = this.customSettings.default_value.match(this.dateRegex);
      if (match) {
        let start = match[0].split("/")[0].trim();
        let end = match[0].split("/")[1].trim();
        this.dateRange = {
          startDate: new Date(start),
          endDate: new Date(end)
        };
      }
    }
    this.inputValue = this.customSettings.default_value;
    // If there is a default value, pass it to the workflow
    if (this.inputValue) {
      this.inputValue =
        this.dateRange.startDate.toISOString() + "/" + this.dateRange.endDate.toISOString();
      this.update_value();
    }
  },
  watch: {
    dateRange: function() {
      this.inputValue =
        this.dateRange.startDate.toISOString() + "/" + this.dateRange.endDate.toISOString();
      this.update_value();
    }
  }
};
</script>

<style scoped></style>
