<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <b-container fluid>
    <b-row class="my-4 mx-5">
      <b-col>
        <b-row>
          <b-col>
            <page-title
              title="Execution Report"
              :subtitle="execution.identifier"
              :fetching="fetching"
            >
            </page-title>
            <b-row v-if="!$_.isEmpty(workspaces)" class="my-3 divider" no-gutters>
              <b-col class="mb-2" cols="12">
                <span class="text-secondary">Workspaces</span>
              </b-col>
              <b-col cols="12">
                <h4>
                  <b-badge
                    class="mr-1"
                    variant="primary"
                    v-for="workspace in workspaces"
                    :key="workspace._jv.id"
                    disabled
                  >
                    {{ workspace.label }}
                  </b-badge>
                </h4>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-2" align-h="end">
          <b-col cols="2">
            <status-badge :progress="execution.progress" :status="execution.status" />
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col>
            <b-table-lite stacked :items="[execution]" :fields="mainTableFields" small>
              <template v-slot:cell(datastore)>
                <span v-if="isAModel">n/a</span>
                <div
                  v-if="
                    theme_features.includes('execution-files-eoepca-develop') ||
                      theme_features.includes('execution-files-eoepca-staging') ||
                      theme_features.includes('execution-files-eoepca-prod')
                  "
                >
                  <b-button
                    class="btn btn-info btn-sm"
                    :disabled="!datastore_output_url"
                    :href="datastore_output_url"
                    target="_blank"
                    >Browse files</b-button
                  >
                </div>
                <div v-if="theme_features.includes('execution-files-mepwps')">
                  Your execution files can be found in the private folder on your Terrascope Virtual
                  Machine
                </div>
                <div v-if="theme_features.includes('execution-files-asb')">
                  <a :href="datastore_output_url" class="btn mr-1 btn-info btn-sm" target="_blank">
                    Execution files
                  </a>
                </div>
                <div v-if="theme_features.includes('execution-files-eopen')">
                  <a
                    :href="
                      `https://asb.spaceapplications.com/datastore/processor-run-${execution.workflowExecutionId}/`
                    "
                    class="btn mr-1 btn-info btn-sm"
                    target="_blank"
                  >
                    Execution files
                  </a>
                  <a
                    v-if="hasChildDags"
                    :href="
                      `https://asb.spaceapplications.com/datastore/processor-run-${execution.workflowExecutionId}_child/`
                    "
                    class="btn mr-1 btn-info btn-sm"
                    target="_blank"
                  >
                    Child branches
                  </a>
                </div>
              </template>

              <template v-slot:cell(identifier)="data">
                {{ data.item.processIdentifier }}
              </template>
              <template v-slot:cell(processorId)="data">
                {{ data.item.workflowIdentifier }}
              </template>
              <template v-slot:cell(processorVersion)="data">
                {{ data.item.processVersion }}
              </template>
              <template v-slot:cell(workerSelectors)="data">
                <span v-if="data.item.workerSelectors">
                  {{ data.item.workerSelectors[0].label }}
                </span>
              </template>
              <template v-slot:cell(workflowExecutionId)="data">
                {{ data.item.workerLabel }}
              </template>
              <template v-slot:cell(executionStartTime)="data">
                {{ data.item.executionTime }}
              </template>
              <template v-slot:cell(executionEndTime)="data">
                {{ data.item.completionTime }}
              </template>
              <template v-slot:cell(executionDuration)>
                {{ executionDuration }}
              </template>
              <template v-slot:cell(pathToOutputs)="data">
                <span v-if="data.item.executionResults">
                  {{ data.item.executionResults.S3OutputsPath }}</span
                >
                <span v-if="isAModel">n/a</span>
              </template>
            </b-table-lite>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-alert
              :show="execution.errorLog != null"
              variant="danger"
              v-html="formatAlertMessage(execution.errorLog)"
            ></b-alert>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="my-3">
            <h2>Inputs</h2>
          </b-col>
          <b-col cols="12">
            <b-table-lite :items="inputs" :fields="inputsTableFields" bordered striped small>
              <template v-slot:cell(value)="data">
                <span v-if="isS3Link(data.value)">
                  {{ data.value }}
                  <b-link class="ml-2" @click="writeToClipboard(formatDatastoreUrl(data.value))">
                    <fa-icon
                      class="ml-1 fa-light"
                      v-b-tooltip.hover
                      title="Copy link to clipboard"
                      icon="clipboard"
                      size="sm"
                    >
                    </fa-icon>
                  </b-link>
                  <b-link class="ml-2" :href="formatDatastoreUrl(data.value)" target="_blank">
                    <fa-icon
                      class="ml-1 fa-light"
                      v-b-tooltip.hover
                      title="Open in new page"
                      icon="external-link-alt"
                      size="xs"
                    >
                    </fa-icon>
                  </b-link>
                </span>
                <span v-else>
                  {{ getValue(data) }}
                </span>
              </template>
            </b-table-lite>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="my-3">
            <h2>Outputs</h2>
          </b-col>
          <b-col cols="12">
            <b-table-lite :items="outputs" :fields="outputsTableFields" bordered striped small>
              <template v-slot:empty=""> No outputs </template>
              <template v-slot:cell(value)="data">
                <span v-if="isS3Link(data.value)">
                  {{ data.value }}
                  <b-link class="ml-2" @click="writeToClipboard(formatDatastoreUrl(data.value))">
                    <fa-icon
                      class="ml-1 fa-light"
                      v-b-tooltip.hover
                      title="Copy link to clipboard"
                      icon="clipboard"
                      size="sm"
                    >
                    </fa-icon>
                  </b-link>
                  <b-link class="ml-2" :href="formatDatastoreUrl(data.value)" target="_blank">
                    <fa-icon
                      class="ml-1 fa-light"
                      v-b-tooltip.hover
                      title="Open in new page"
                      icon="external-link-alt"
                      size="xs"
                    >
                    </fa-icon>
                  </b-link>
                  <b-button
                    variant="transparent"
                    class="ml-2"
                    size="sm"
                    v-b-toggle.collapse-1-inner
                    title="Expand/Collapse preview"
                  >
                    <span class="when-open"><fa-icon icon="chevron-down"></fa-icon></span>
                    <span class="when-closed"><fa-icon icon="chevron-left"></fa-icon></span>
                  </b-button>
                  <b-collapse id="collapse-1-inner" class="mt-2">
                    <b-img
                      v-if="data.value.toLowerCase().endsWith('.png')"
                      thumbnail
                      fluid
                      center
                      :src="formatDatastoreUrl(data.value)"
                      :alt="data.name"
                    >
                    </b-img>
                  </b-collapse>
                </span>
                <span v-else-if="$_.isString(data.value)">
                  <shrinked-value :value="getValue(data)"></shrinked-value>
                </span>
                <span v-else>
                  {{ getValue(data) }}
                </span>
              </template>
            </b-table-lite>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import StatusBadge from "@/components/share/StatusBadge";
import { mapState } from "vuex";
import PageTitle from "@/components/share/PageTitle";
import SSEClient from "@/api/sse.js";
import { utils } from "jsonapi-vuex";
import { canWriteToClipboard, writeToClipboard } from "@/utils";

export default {
  name: "ApplicationExecutionReport",
  components: {
    PageTitle,
    StatusBadge
  },
  props: {
    isAModel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      executionId: this.$route.params.id,
      workspaces: [],
      workspaceOptions: [],
      fetching: false,
      mainTableFields: [
        {
          key: "identifier",
          label: "Identifier"
        },
        {
          key: "ownerId",
          label: "Executed by"
        },
        {
          key: "createdAt",
          label: "Created at"
        },
        {
          key: "processorId",
          label: "Application"
        },
        {
          key: "processorVersion",
          label: "Application version"
        },
        {
          key: "workerSelectors",
          label: "Target platform"
        },
        {
          key: "executionStartTime",
          label: "Execution start time"
        },
        {
          key: "executionEndTime",
          label: "Execution end time"
        },
        {
          key: "executionDuration",
          label: "Execution duration"
        },
        {
          key: "pathToOutputs",
          label: "Path to output files"
        },
        {
          key: "datastore",
          label: "Datastore"
        }
      ],
      inputsTableFields: [
        {
          key: "label",
          label: "Name",
          thStyle: { width: "20%" }
        },
        {
          key: "value",
          label: "Value"
        }
      ],
      outputsTableFields: [
        {
          key: "label",
          label: "Name",
          thStyle: { width: "20%" }
        },
        {
          key: "value",
          label: "Value"
        }
      ],
      ESClient: undefined
    };
  },
  created() {
    this.fetchApplicationExecution();
    this.connectToSSE();
  },
  beforeRouteLeave(to, from, next) {
    this.ESClient.disconnect();
    next();
  },
  methods: {
    fetchApplicationExecution() {
      let taskType = "ades-tasks";
      if (this.isAModel) {
        taskType = "seldon-tasks";
      }
      this.$store.dispatch("taskmgr/get", `${taskType}/${this.executionId}`);
    },
    techDate(msecs) {
      if (msecs === undefined) return "";
      if (msecs === 0 || msecs === 9000000000000) return "n/a";
      // prettier-ignore
      return new Date(msecs).toISOString().replace("T", " ").substring(0, 19);
    },
    prettyDuration(msecs) {
      // Only print days if non null
      // prettyDuration(5423401000) => "62d 18:30:01"
      // prettyDuration(77735634)   =>     "21:35:35"
      // prettyDuration(5423401)    =>      "1:30:23"
      // prettyDuration(542931)     =>      "0:09:02"

      msecs = Math.floor(msecs);
      if (msecs < 0) return "n/a";
      let pad = (n, z = 2) => ("00" + n).slice(-z);

      let ms = msecs % 1000;
      let rest = (msecs - ms) / 1000;
      let secs = rest % 60;
      rest = (rest - secs) / 60;
      let mins = rest % 60;
      rest = (rest - mins) / 60;
      let hrs = rest % 24;
      let days = (rest - hrs) / 24;

      if (days == 0) {
        return hrs + ":" + pad(mins) + ":" + pad(secs); // + "." + pad(ms, 3);
      }
      return days + "d " + hrs + ":" + pad(mins) + ":" + pad(secs); // + "." + pad(ms, 3);
    },
    connectToSSE() {
      let url = `/backend/live/${this.user.username}/`;
      this.ESClient = new SSEClient(url);
      this.ESClientConnected = true;
      this.ESClient.addMessageHandler(this.handleSSEMessage);
    },
    handleSSEMessage(message) {
      message = message.data;
      // Only handle executionUpdate messages
      if (message.type != "executionUpdate") {
        return;
      }
      if (message.task_id == this.executionId) {
        let updatedExecution = utils.deepCopy(
          this.$store.getters["taskmgr/get"](`tasks/${message.task_id}`)
        );
        updatedExecution.progress = message.progress / 100;
        updatedExecution.status = message.status;
        if (message.progress == "100") {
          console.log("fetching execution again in 15 seconds...");
          new Promise(res => setTimeout(res, 15000)).then(() => {
            this.fetchApplicationExecution();
          });
        }
        this.$store.commit("taskmgr/addRecords", updatedExecution);
      }
    },
    formatAlertMessage(alertMessage) {
      if (alertMessage) {
        return alertMessage.replaceAll("\n", "<br />").replaceAll("  ", "&nbsp;&nbsp;&nbsp;&nbsp;");
      } else {
        return null;
      }
    },
    isS3Link(value) {
      return typeof value === "string" && value.startsWith("s3://aiopen2");
    },
    formatDatastoreUrl(url) {
      console.log(url);
      if (this.isS3Link(url)) {
        if (url.startsWith("s3://aiopen2/")) {
          // TODO: Un-hardcode the URLs
          return url.replace("s3://aiopen2/", "https://aiopen.spaceapplications.com/files/");
        }
      }
      return "";
    },
    canWriteToClipboard() {
      return canWriteToClipboard();
    },
    writeToClipboard(text) {
      this.$notify({
        group: "global",
        type: "info",
        duration: 2000, // in ms, -1 = no duration
        text: "Copied to clipboard"
      });
      return writeToClipboard(text);
    },
    getValue(data) {
      // TODO format this
      let value = data.value;
      if (data.item.type == "geotiff") {
        value = this.formatDatastoreUrl(value);
      }
      return value;
    }
  },
  computed: {
    execution() {
      return this.$store.getters["taskmgr/get"](`tasks/${this.executionId}`);
    },
    executionDuration() {
      if (this.execution.completionTime) {
        let end_time = new Date(this.execution.completionTime).getTime();
        let start_time = new Date(this.execution.executionTime).getTime();
        return this.prettyDuration(end_time - start_time);
      }
      return "";
    },
    inputs() {
      // Should be implemented with this.execution.inputs?.inputs
      if (this.execution.inputs === undefined) return [];
      if (this.execution.inputs.inputs === undefined) return [];
      let new_inputs = [];
      if (this.isAModel) {
        for (const [key, value] of Object.entries(this.execution.inputs.inputs)) {
          console.log("Checking:", key);
          let obj = {
            label: value.name,
            value: value.data
          };
          new_inputs.push(obj);
        }
      } else {
        for (const [key, value] of Object.entries(this.execution.inputs)) {
          let obj = {
            label: key,
            value: value
          };
          new_inputs.push(obj);
        }
      }
      return new_inputs;
    },
    outputs() {
      if (this.execution.executionResults === undefined) return [];
      if (this.execution.executionResults.data === undefined) return [];
      let new_outputs = [];
      for (const [key, value] of Object.entries(this.execution.executionResults.data)) {
        let obj = {
          label: key,
          value: value
        };
        new_outputs.push(obj);
      }
      return new_outputs;
    },
    datastore_output_url() {
      return this.execution.executionResults.S3BucketName
        ? `${this.external_datastore_url}/buckets/${this.execution.executionResults.S3BucketName}/browse`
        : "";
    },
    ...mapState({
      user: state => state.user,
      theme: state => state.instance.theme,
      theme_features: state => state.instance.theme_features,
      external_datastore_url: state => state.instance.external_datastore_url
    })
  }
};
</script>

<style scoped>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
