<template>
  <b-container class="my-4 px-4" fluid>
    <page-title class="mb-3" title="Repositoriy Browser" subtitle="Nexus @ SpaceApps"> </page-title>
    <b-row>
      <b-col>
        {{ content }}
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// TODO This page is still Work In Progress. The objective is to browse docker regsitry to import processes

import PageTitle from "@/components/share/PageTitle";
import axios from "axios";

export default {
  name: "RepoBrowser",
  components: { PageTitle },
  data() {
    return {
      content: {},
      repoClient: undefined
    };
  },
  created() {
    let defaultClientConfig = {
      timeout: 30 * 1000,
      xsrfCookieName: "csrftoken",
      xsrfHeaderName: "X-CSRFToken",
      headers: {
        Authorization: `Basic ${process.env.NEXUS_BASIC_CREDENTIALS}`
      }
    };

    this.repoClient = axios.create(defaultClientConfig);
    this.repoClient
      .get(
        "https://nexus.spaceapplications.com/service/rest/v1/components?repository=docker-asb-procs"
      )
      .then(response => (this.content = response));
  }
};
</script>

<style scoped></style>
