<template>
  <span>
    <empty
      class="mt-5"
      v-show="loading"
      :loading="loading"
      text="Loading..."
      show-loading-content
    />
    <b-embed
      class="load-embed"
      ref="embed"
      @load="loaded"
      type="iframe"
      aspect="16by9"
      :src="src"
      width="100px"
    >
    </b-embed>
  </span>
</template>

<script>
import Empty from "@/components/share/Empty";

export default {
  name: "LoadEmbed",
  components: { Empty },
  props: {
    src: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true
    };
  },
  methods: {
    loaded() {
      this.loading = false;
    }
  }
};
</script>

<style scoped></style>
