<template>
  <b-container class="my-4 px-5" fluid>
    <b-row align="center">
      <b-col>
        <loading :loading="loading"></loading>
      </b-col>
    </b-row>
    <empty
      v-if="!loading && $_.isEmpty(collection)"
      :text="`Something went wrong while fetching collection #${id}`"
      icon="exclamation"
    ></empty>
    <b-row v-if="!loading && !$_.isEmpty(collection)">
      <b-col cols="12">
        <page-title
          :title="`${collection.ownerId} / ${collection.title}`"
          :subtitle="collection.identifier"
        >
        </page-title>
      </b-col>
      <b-col class="mt-4" cols="12">
        <h5>This collection was requested with the following payload</h5>
        <highlight-code lang="javascript">
          {{ collection.requestPayload }}
        </highlight-code>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import PageTitle from "@/components/share/PageTitle";
import knowledgeService from "@/api/asb/services/knowledge";
import Empty from "@/components/share/Empty";

import Loading from "@/components/share/Loading";

export default {
  name: "CollectionPage",
  components: { PageTitle, Empty, Loading },
  data() {
    return {
      id: this.$route.params["id"],
      loading: true
    };
  },
  created() {
    this.loading = true;
    this.$store
      .dispatch("knowledge/get", `collections/${this.id}`)
      .catch(error => knowledgeService.handleError(error))
      .finally(() => (this.loading = false));
  },
  computed: {
    collection() {
      return this.$store.getters["knowledge/get"](`collections/${this.id}`);
    }
  }
};
</script>

<style scoped></style>
