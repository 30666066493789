<template>
  <b-row class="form-group" v-show="visible">
    <b-col>
      <b-form-row>
        <b-col cols="10">
          <b-form-group
            label-cols-lg="2"
            :label="label"
            :disabled="!editable"
            :description="'description' in inp ? inp.description : ''"
          >
            <b-form-input
              v-model="inputValue"
              @update="update_value"
              :state="inputState"
            ></b-form-input>
            <b-form-text v-if="inp.doc">{{ inp.doc }}</b-form-text>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-button
            v-b-toggle="collapse_id"
            @click="refreshCollapsedMap"
            variant="primary"
            class="mr-2"
            >Select a Product
          </b-button>
          <b-button v-if="customSettings.default_value" @click="reset_default" variant="warning"
            >Reset to default
          </b-button>
        </b-col>
      </b-form-row>
    </b-col>

    <b-col cols="12">
      <b-collapse :id="collapse_id" :title="`Select a product for ${inp.label}`" size="xl">
        <catalogue-browser
          ref="catalogueBrowser"
          workflowInput
          v-on:inputSelected="select_input"
        ></catalogue-browser>
        <hr />
      </b-collapse>
    </b-col>
  </b-row>
</template>

<script>
import InputMixin from "@/components/generic/InputMixin";
import CatalogueBrowser from "@/components/products/CatalogueBrowser";

export default {
  mixins: [InputMixin],
  name: "CatalogueProduct",
  components: { CatalogueBrowser },
  methods: {
    select_input(value) {
      console.log("event arrived!");
      // FIXME still hardcoded. Has to be configurable (probably?)
      // this.inputValue = `https://eoepca-staging.spaceapplications.com/resource-catalogue/csw?mode=opensearch&service=CSW&version=3.0.0&request=GetRecords&elementsetname=full&resulttype=results&typenames=csw:Record&recordids=${value}`;
      this.inputValue = `${location.protocol}//${location.host}/resource-catalogue/csw?mode=opensearch&service=CSW&version=3.0.0&request=GetRecords&elementsetname=full&resulttype=results&typenames=csw:Record&recordids=${value}`;
      this.update_value();
      this.$root.$emit(
        "bv::toggle::collapse",
        // `collapse-product-${this.nodeId}-${this.inp._jv.id}`
        this.collapse_id
      );
    },
    refreshCollapsedMap() {
      setTimeout(() => {
        // Redraw to make the tiles load properly
        this.$refs.catalogueBrowser.redrawMap();
      }, 100);
    }
  },
  computed: {
    collapse_id() {
      // ASB workflow inputs will contain a _jv tag
      // CWL applications do not, so the id is built another way
      if ("_jv" in this.inp) {
        return `collapse-product-${this.nodeId}-${this.inp._jv.id}`;
      } else {
        console.log(`collapse-product-${this.inp_id}`);
        return `collapse-product-${this.inp_id}`;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/default/asb.scss";
</style>
