<template>
  <base-admin-page>
    <template v-slot:content>
      <b-container class="m-0 p-2" fluid>
        <b-row no-gutters>
          <b-col cols="12">
            <b-tabs>
              <b-tab v-for="component in components" :key="component.name" :title="component.name">
                <load-embed :src="component.src"></load-embed>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </base-admin-page>
</template>

<script>
import BaseAdminPage from "@/pages/admin/BaseAdminPage";
import LoadEmbed from "@/components/share/LoadEmbed";

export default {
  name: "DatabaseAdminpage",
  components: { BaseAdminPage, LoadEmbed },
  data() {
    return {
      components: [
        {
          name: "Service Builder",
          src: "/builder/admin"
        },
        {
          name: "Knowledge Base",
          src: "/knowledge/admin"
        },
        {
          name: "Resource Manager",
          src: "/resmgr/admin"
        },
        {
          name: "Task Manager",
          src: "/taskmgr/admin"
        },
        {
          name: "User Manager",
          src: "/usermgr/admin"
        },
        {
          name: "Backend",
          src: "/backend/admin"
        },
        {
          name: "Airflow",
          src: "/wengine/admin"
        }
      ]
    };
  }
};
</script>

<style scoped></style>
