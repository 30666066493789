<template>
  <span>
    <fa-icon v-show="brokenConnection" class="broken-icon" icon="unlink"></fa-icon>
    <fa-icon v-show="fetchingData" class="fetching-icon" icon="sync-alt" spin></fa-icon>
    <fa-icon v-show="doneFetching" class="sync-icon" icon="check"></fa-icon>
  </span>
</template>

<script>
export default {
  name: "Fetching",
  props: {
    // true: fetching data, false: data received, undefined: broken connection
    fetching: {
      Boolean
    }
  },
  computed: {
    fetchingData() {
      return !this.brokenConnection && this.fetching;
    },
    doneFetching() {
      return !this.brokenConnection && !this.fetching;
    },
    brokenConnection() {
      return this.fetching === undefined;
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/default/asb.scss";

span {
  font-size: $font-size-base * 1.3;
}

.fetching-icon {
  color: $pending;
}

.sync-icon {
  color: $success;
}
.broken-icon {
  color: $secondary;
}
</style>
