<template>
  <b-container class="my-4 px-5" fluid>
    <b-row>
      <b-col>
        <page-title title="Marketplace"></page-title>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="2" style="border-right: 1px solid lightgrey">
        <h4 class="mt-3">Filter results</h4>
        <b-form @submit="onSubmit">
          <b-form-group id="input-group-search-string" label-for="input-search-string">
            <b-form-input
              id="input-search-string"
              v-model="form.searchString"
              placeholder="Enter text"
              required
            ></b-form-input>
          </b-form-group>
          <b-button type="submit" variant="primary" @click="fetchData">Search</b-button>
        </b-form>

        <hr />

        <b-form v-if="filters_initialized">
          <div v-for="filter of filter_options" :key="filter.key" class="mb-3">
            <h6>{{ filter.name }}</h6>
            <b-form-checkbox
              v-for="option of filter.values"
              :key="option"
              :id="`checkbox-${filter.key}-${option}`"
              :name="`checkbox-${filter.key}-${option}`"
              v-model="filters[filter.key][option]"
              @change="filtersUpdated()"
            >
              {{ option }}
            </b-form-checkbox>
          </div>
          <h6>Creation date</h6>
          <label for="date-start">After:</label>
          <b-form-row>
            <b-col cols="8">
              <b-form-datepicker
                id="date-start"
                v-model="dateFilter.start"
                class="mb-2"
              ></b-form-datepicker>
            </b-col>
            <b-col cols="3">
              <b-button variant="primary" @click="dateFilter.start = ''">Clear</b-button>
            </b-col>
          </b-form-row>
          <label for="date-end">Before:</label>
          <b-form-row>
            <b-col cols="8">
              <b-form-datepicker
                id="date-end"
                v-model="dateFilter.end"
                class="mb-2"
              ></b-form-datepicker>
            </b-col>
            <b-col cols="3">
              <b-button variant="primary" @click="dateFilter.end = ''">Clear</b-button>
            </b-col>
          </b-form-row>
        </b-form>
      </b-col>
      <b-col cols="10">
        <b-row class="text-center mt-3" v-if="data_fetching">
          <b-col>
            <loading :loading="data_fetching"></loading>
          </b-col>
        </b-row>
        <b-row class="text-center align-items-stretch">
          <b-col v-if="!data_fetching && filtered_resources.length == 0">
            <fa-icon icon="folder-open" style="font-size: 4rem" class="mt-5 mb-3" />
            <h5>No resources found</h5></b-col
          >
        </b-row>

        <b-row v-if="!data_fetching">
          <b-col
            sm="12"
            md="6"
            lg="4"
            xl="3"
            v-for="resource in filtered_resources"
            :key="resource.id"
            class="my-2 px-2"
          >
            <b-card
              :header-bg-variant="getResourceColor(resource)"
              header-text-variant="white"
              class="h-100"
            >
              <template #header>
                <b-row>
                  <b-col class="text-left" lg="10">
                    <b>{{ resource.properties.title || resource.title }}</b>
                    <span>{{ getResourceVersion(resource) }}</span>
                  </b-col>
                  <b-col class="text-right" lg="2">
                    <fa-icon
                      :icon="getResourceIcon(resource)"
                      v-b-popover.hover.top="resource.properties.type"
                      class="text-right"
                    />
                  </b-col>
                </b-row>
              </template>
              <b-card-text class="text-center">
                <b-img
                  style="max-height: 100px; max-width: 100%"
                  :src="getResourceLogoUrl(resource)"
                >
                </b-img
                ><br />
              </b-card-text>

              <b-card-text>{{
                resource.properties.description || resource.description
              }}</b-card-text>

              <template #footer>
                <b-button
                  variant="primary"
                  class="mb-0"
                  :to="detailHref(resource)"
                  size="sm"
                  v-b-popover.hover.top="'Display the details'"
                  >Details</b-button
                >
                <b-button
                  v-if="is_authenticated && is_executable(resource)"
                  variant="danger"
                  class="ml-2 mb-0"
                  :to="executeHref(resource)"
                  size="sm"
                  v-b-popover.hover.top="'Open the execution form'"
                  >Execute</b-button
                ><br />
                <span v-if="hasLicence(resource)">
                  <a :href="getUrl(resource.licence)" class="card-link" target="_blank">
                    {{ getLicenceLabel(resource) }}<br />
                  </a>
                </span>
                <span>
                  <a
                    v-if="isUrl(resource.releaseNotes)"
                    :href="resource.releaseNotes"
                    class="card-link"
                    target="_blank"
                    >Release notes</a
                  >
                </span>
              </template>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import PageTitle from "@/components/share/PageTitle";
import Loading from "@/components/share/Loading";
import backendService from "@/api/asb/services/backend";
import { mapState } from "vuex";
import lodash from "lodash";
import { isUrl } from "@/api/utils";
import SSEClient from "@/api/sse.js";

export default {
  name: "Marketplace",
  components: {
    PageTitle,
    Loading
  },
  data() {
    return {
      form: {
        searchString: ""
      },
      filters: {},
      filters_initialized: false,
      dateFilter: {
        start: "",
        end: ""
      },
      SSEClient: undefined,
      SSEClientConnected: false
    };
  },
  created() {
    if (!this.data_fetched) {
      this.fetchData();
      this.connectToSSE();
    } else {
      this.initializeFilters(this.filter_options);
    }
    this.form.searchString = this.query_string;
  },
  methods: {
    fetchData() {
      this.$store.commit("SET_MARKETPLACE_QUERY", this.form.searchString);
      this.$store
        .dispatch("FETCH_MARKETPLACE_DATA")
        .then(() => {})
        .catch(error => {
          backendService.handleError(error);
        });
    },
    onSubmit(event) {
      event.preventDefault();
      console.log("searching");
    },
    getResourceLogoUrl(resource) {
      return resource.logo
        ? resource.logo
        : resource.thumbnail
        ? resource.thumbnail
        : resource.properties.type == "model"
        ? require("@/plugins/aiopen/assets/cropped-aiopenlogo_colored.png")
        : resource.properties.type == "TrainingData"
        ? require("@/plugins/aiopen/assets/cropped-aiopenlogo_colored.png")
        : require("@/assets/asb-full-logo-without-text.png");
    },
    identifierToId(identifier) {
      let id = identifier;
      for (let col of this.published_collections) {
        if (col.identifier == identifier) {
          id = col.id;
        }
      }
      return id;
    },
    detailHref(resource) {
      switch (resource.properties.type) {
        case "model":
          return `/collections/${resource.collection}/model_detail/${resource.id}`;
        case "dataset":
        case "TrainingData":
          return `/collections/${resource.collection}/dataset_detail/${resource.id}`;
        case "workflow":
          return `/workflows/${resource.parentidentifier}/detail/${resource.identifier}`;
        case "EOEPCA Application": // THIS IS A TEMPORARY FIX TO DISPLAY THIS IN THE MARKETPLACE IN A NON-EOEPCA INSTANCE FOR A DEMO
          return `/workflows/${resource.parentidentifier}/detail/${resource.identifier}`;
        default:
          var collId = this.identifierToId(resource.parentidentifier);
          return `/collections/${collId}/detail/${resource.identifier}`;
      }
    },
    executeHref(resource) {
      switch (resource.properties.type) {
        case "model":
          return `/collections/${resource.collection}/model_execute/${resource.id}`;
        case "TrainingData":
          return null; // Training data cannot be executed
        // TODO: Update the HREFs, below
        case "workflow":
          return `/workflows/${resource.parentidentifier}/detail/${resource.identifier}`;
        case "EOEPCA Application": // THIS IS A TEMPORARY FIX TO DISPLAY THIS IN THE MARKETPLACE IN A NON-EOEPCA INSTANCE FOR A DEMO
          return `/workflows/${resource.parentidentifier}/detail/${resource.identifier}`;
        default:
          var collId = this.identifierToId(resource.parentidentifier);
          return `/collections/${collId}/detail/${resource.identifier}`;
      }
    },
    is_executable(resource) {
      return ["model", "workflow", "EOEPCA Application"].includes(resource.properties.type);
    },
    filtersUpdated() {
      // Triggered when a filter is updated
      let filters = [];
      for (let filter of Object.keys(this.filters)) {
        // If every checkbox is unticked or ticked, remove the filter from the filters_enabled.
        if (
          !Object.values(this.filters[filter]).every(val => val === false) &&
          !Object.values(this.filters[filter]).every(val => val === true)
        ) {
          filters.push({
            key: filter,
            type: this.filter_options.find(filter_option => filter_option.key == filter).type,
            values: Object.entries(this.filters[filter])
              .filter(filter => filter[1] === true)
              .map(filter => filter[0])
          });
        }
      }

      this.$store.commit("SET_MARKETPLACE_FILTERS_ENABLED", filters);
    },
    initializeFilters(options) {
      for (let filter of options) {
        this.filters[filter.key] = {};
        for (let value of filter.values) {
          // let enabled_filter = Object.values(this.filters_enabled).filter(enabled_filter => enabled_filter.key = filter.key)
          // enabled_filter.length > 0 ? this.filters[filter.key][value] = enabled_filter[0].values.includes(value) : false\
          this.filters[filter.key][value] = false;
          for (let enabled_filter of this.filters_enabled) {
            if (enabled_filter.key == filter.key) {
              if (enabled_filter.values.includes(value)) {
                this.filters[filter.key][value] = true;
              }
            }
          }
        }
      }
      this.filters_initialized = true;
    },
    getResourceIcon(resource) {
      switch (resource.properties.type) {
        case "application":
          return "cog";
        case "model":
          return "microchip";
        case "dataset":
        case "TrainingData":
          return "globe";
        default:
          return "globe";
      }
    },
    getResourceColor(resource) {
      // See theme colors in assets/scss/default/_bootstrap-variable-override.scss
      switch (resource.properties.type) {
        case "application":
          return "principal";
        case "model":
          return "principal";
        case "dataset":
        case "TrainingData":
          return "generated";
        default:
          return "secondary";
      }
    },
    getResourceVersion(resource) {
      let version = resource.version
        ? resource.version
        : resource.properties.version
        ? resource.properties.version
        : null;
      return version ? version : "";
    },
    isUrl(string) {
      return isUrl(string);
    },
    hasLicence(resource) {
      return this.getLicenceLabel(resource) !== "Licence" || isUrl(resource.licence);
    },
    getLicenceLabel(resource) {
      const regex = new RegExp(`FILTER:licence:(.*)`);
      if (resource.keywords) {
        for (let keyword of resource.keywords) {
          if (regex.test(keyword)) {
            return regex.exec(keyword)[1];
          }
        }
      }

      return "Licence";
    },
    getUrl(licence) {
      return isUrl(licence) ? licence : null;
    },
    connectToSSE() {
      this.ESClient = new SSEClient(`/backend/live/_marketplace/`);
      this.ESClientConnected = true;
      this.ESClient.addMessageHandler(this.handleSSEMessage);
    },
    handleSSEMessage(message) {
      console.warn(message);
      if (message.type != "forceUpdate") {
        return;
      }
      this.$store.dispatch("FETCH_MARKETPLACE_DATA");
    }
  },
  watch: {
    filter_options(options) {
      // initialize filter fields for forms
      this.initializeFilters(options);
    }
  },
  computed: {
    ...mapState({
      all_resources: state => state.marketplace.resources,
      published_collections: state => state.marketplace.collections,
      data_fetched: state => state.marketplace.data_fetched,
      filter_options: state => state.marketplace.filter_options,
      filters_enabled: state => state.marketplace.filters_enabled,
      data_fetching: state => state.marketplace.data_fetching,
      query_string: state => state.marketplace.query
    }),
    is_authenticated() {
      return this.$store.state.user.username !== undefined;
    },
    filtered_resources() {
      let filtered_resources = [];

      let enabled_filters_copy = lodash.cloneDeep(this.filters_enabled);
      //prepare the filters for easier matching
      for (let filter of enabled_filters_copy) {
        if (filter.type === "keyword") {
          filter.values = filter.values.map(value =>
            value != "Not specified" ? `FILTER:${filter.key}:${value}` : "Not specified"
          );
        }
      }

      for (let resource of this.all_resources) {
        let add = true;

        // Check the dates before doing any other checks
        if (this.dateFilter.start !== "") {
          if (new Date(resource.date_creation) < new Date(this.dateFilter.start)) {
            continue;
          }
        }
        if (this.dateFilter.end !== "") {
          if (new Date(resource.date_creation) > new Date(this.dateFilter.end)) {
            continue;
          }
        }

        // Checking keywords and static filters
        for (let filter of enabled_filters_copy) {
          var not_specified = false;
          var value_match = false;

          switch (filter.type) {
            case "properties":
              if (filter.values.includes("Not specified")) {
                // Check if no values of this type are set
                not_specified = resource.properties[filter.key] ? false : true;
              }

              // Check if at least one of the selected values is present in the resource
              value_match = filter.values.includes(resource.properties[filter.key]) ? true : false;

              // if both checks fail, do not add to filtered list
              !not_specified && !value_match ? (add = false) : null;
              break;
            case "static":
              // !filter.values.includes(resource[filter.key]) ? (add = false) : null;
              // break;

              if (filter.values.includes("Not specified")) {
                // Check if no values of this type are set
                not_specified = resource[filter.key] ? false : true;
              }

              // Check if at least one of the selected values is present in the resource
              value_match = filter.values.includes(resource[filter.key]) ? true : false;

              // if both checks fail, do not add to filtered list
              !not_specified && !value_match ? (add = false) : null;
              break;
            case "keyword":
              var not_specified_keyword = false;
              var in_keywords = false;

              if (filter.values.includes("Not specified")) {
                const regex = new RegExp(`FILTER:${filter.key}:.*`);
                // Check if no values of this keyword type are set
                not_specified_keyword = true;
                if (resource.keywords) {
                  not_specified_keyword = resource.keywords.every(keyword => !regex.test(keyword));
                }
              }

              // Check if at least one of the selected keywords is present in the resource
              in_keywords = false;
              if (resource.keywords) {
                in_keywords =
                  filter.values.filter(value => resource.keywords.includes(value)).length > 0
                    ? true
                    : false;
              }

              // if both checks fail, do not add to filtered list
              !not_specified_keyword && !in_keywords ? (add = false) : null;
              break;
            default:
              break;
          }
        }
        //if all filters pass, add it to the list
        if (add) {
          filtered_resources.push(resource);
        }
      }
      return filtered_resources;
    }
  }
};
</script>

<style></style>
