export default {
  props: {
    inp: Object,
    customSettings: {
      type: Object,
      required: false,
      default: () => ({})
    },
    nodeId: String,
    inp_id: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      inputValue: undefined,
      label: ""
    };
  },
  mounted() {
    // If no label is provided, fall back to identifier
    this.label = this.inp.label ? this.inp.label : this.inp_id;
    // Set the default value. Process level first, overwrite if
    // there is a value at the Workflow level
    if (this.inp.defaultValue != "") {
      this.inputValue = this.inp.defaultValue;
    }
    if (this.customSettings.label) {
      this.label = this.customSettings.label;
    }
    if (this.customSettings.default_value) {
      this.inputValue = this.customSettings.default_value;
    }

    // Force an update to check validity for each input.
    // This is needed for invisible or uneditable empty inputs to be treated as valid
    this.update_value();
  },
  computed: {
    visible() {
      if (this.customSettings.user_visible == undefined) {
        return this.inp.userVisible != undefined ? this.inp.userVisible : true;
      } else {
        return this.customSettings.user_visible;
      }
    },
    editable() {
      if (this.customSettings.user_editable == undefined) {
        return this.inp.userEditable != undefined ? this.inp.userEditable : true;
      } else {
        return this.customSettings.user_editable;
      }
    },
    // By default we say a value is valid. Components can override this value
    // to provide their own verification
    inputValid() {
      if (this.inputValue == "") {
        return false;
      }
      return true;
    },
    inputState() {
      return this.inputValid ? undefined : false;
    }
  },
  methods: {
    update_value() {
      let valid = this.inputValid;
      if (!this.visible || !this.editable) {
        valid = true;
      } // If the input is disabled or invisible, consider it valid
      this.$emit("input_change", this.nodeId, this.inp, this.inputValue, valid);
    },
    reset_default() {
      console.log("resetting value to default");
      this.inputValue = this.customSettings.default_value;
    }
  }
};
