<template>
  <b-container>
    <b-modal
      ref="search-apps"
      hide-footer
      id="search-apps"
      title="Search for Applications"
      size="lg"
    >
      <b-row align-h="center" no-gutters>
        <b-col class="my-3" cols="12">
          <b-form inline>
            <label class="sr-only" for="search-query">Name</label>
            <b-form-input
              id="search-query"
              class="mb-2 mr-sm-2 mb-sm-0"
              placeholder="Search query"
              v-model="applicationsSearchQuery"
            ></b-form-input>

            <b-button
              variant="primary"
              @click="searchApplications"
              :disabled="applicationsSearching"
              style="width: 100px;"
            >
              <span v-if="!applicationsSearching">Search</span>
              <b-spinner v-else small> </b-spinner
            ></b-button>
          </b-form>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col>
          <span>{{ message }}</span>
          <b-list-group v-for="(collection, key) in applicationsSearchResult" :key="key">
            <b-list-group-item>
              <router-link
                class="d-flex justify-content-between align-items-center"
                :to="`collections/${applicationsSearchResult[key].id}`"
              >
                <b
                  >{{ applicationsSearchResult[key].owner }} /
                  {{ applicationsSearchResult[key].verbose_name }}</b
                >

                <b-badge variant="success" pill>{{ collection.apps.length }}</b-badge>
              </router-link>
            </b-list-group-item>

            <b-list-group-item
              v-for="app in collection['apps']"
              :key="app.identifier"
              :href="`collections/${applicationsSearchResult[key].id}/detail/${app.identifier}`"
            >
              <router-link
                :to="`collections/${applicationsSearchResult[key].id}/detail/${app.identifier}`"
              >
                <span class="ml-2">
                  - {{ app.name }}
                  <b-badge variant="primary" pill>{{ app.version }}</b-badge>
                </span>
              </router-link>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-modal>
    <generic-list
      name="catalogue-collection"
      service="backend"
      :filters="filters"
      :sortByMultipleFields="['ownerId', 'identifier']"
      enable-create
      :create-fields="createFields"
      :create-defaults="createDefaults"
      paginate
      shareable
      deleteable
      :deleteText="deleteText"
      :archivable="false"
      ownerOrAdminMenuOnly
      v-on:delete-dialog-opened="(...args) => fetchApplications(args)"
    >
      <template v-slot:title-toolbar>
        <b-btn-group class="ml-2">
          <b-btn variant="primary" size="md" v-b-modal.search-apps>
            <fa-icon icon="search" class="mr-2" /> Search for Applications
          </b-btn>
        </b-btn-group>
      </template>
      <template v-slot:resource-label="{ resource }">
        <router-link
          class="bold-link"
          :to="{
            name: `catalogue-collection`,
            params: { id: resource._jv.id }
          }"
          v-b-tooltip.hover
          :title="resource.identifier"
        >
          <span>{{ resource.ownerId }} / {{ resource.verboseName }}</span>
        </router-link>
      </template>
      <template v-slot:extra-delete-info="{ resource }">
        <b-row align-h="center" no-gutters>
          <b-col class="mb-2" cols="12">
            <span v-if="applicationsLoading"
              >Checking collection elements...
              <b-spinner small variant="info" class="ml-2"></b-spinner>
            </span>
            <span v-else> <b>Applications in collection: </b> {{ applicationCount }} </span>
          </b-col>
        </b-row>
      </template>
    </generic-list>
  </b-container>
</template>

<script>
import GenericList from "@/components/generic/GenericList";
import { slugify } from "@/utils";
import backendService from "@/api/asb/services/backend";

export default {
  name: "CatalogueCollectionList",
  components: { GenericList },
  data() {
    return {
      // Filters
      filters: [
        {
          name: "ownerId",
          value: vm => vm.user.username,
          label: "Owned by me"
        },
        {
          name: "workspaces.identifier",
          value: vm => vm.user.username,
          label: "In my workspace"
        }
      ],
      // Create
      createFields: [
        {
          name: "verbose_name",
          label: () => "Name",
          type: "text",
          onChange: resource => {
            resource.slug = slugify(resource.verbose_name, "-");
          },
          placeholder: () => "My new collection"
        },
        {
          name: "abstract",
          label: () => "Description ",
          type: "text",
          description: "Short collection description",
          placeholder: () => "A Collection for EO applications"
        }
      ],
      createDefaults: {},
      deleteText:
        "This will remove the collection and ALL OF ITS ELEMENTS. This action is irreversible!",
      applicationsLoading: false,
      applicationCount: 0,
      applicationsSearchQuery: "",
      applicationsSearchResult: [],
      applicationsSearching: false,
      message: "Please enter a search query and press search."
    };
  },
  methods: {
    fetchApplications(collection) {
      this.applicationsLoading = true;
      let id = collection[0]._jv.id;
      backendService
        .getCollectionApplications(id)
        .then(applications => (this.applicationCount = applications.length))
        .catch(error => backendService.handleError(error))
        .finally(() => (this.applicationsLoading = false));
    },
    searchApplications() {
      console.log(`Searching API with query: ${this.applicationsSearchQuery}`);
      this.applicationsSearching = true;
      this.message = "";
      backendService
        .searchApplicationsForUser(this.applicationsSearchQuery)
        .then(data => {
          this.applicationsSearchResult = data.data;
          console.warn(data.data);
          if (Object.keys(data.data).length == 0) {
            this.message = "No applications matching query found.";
          }
          this.applicationsSearching = false;
        })
        .catch(err => {
          backendService.handleError(err);
          this.applicationsSearching = false;
        });
    }
  }
};
</script>

<style scoped></style>
