import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import axios from "axios";
import Notifications from "vue-notification";
import VueHighlightJS from "vue-highlight.js";
import VueCookies from "vue-cookies";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { BootstrapVue, BIconThreeDots, BIcon } from "bootstrap-vue";
import VueMoment from "vue-moment";
import VueNativeSock from "vue-native-websocket";
import python from "highlight.js/lib/languages/python";
import dockerfile from "highlight.js/lib/languages/dockerfile";
import json from "highlight.js/lib/languages/json";
import bash from "highlight.js/lib/languages/bash";
// highlight.js default theme
import "highlight.js/styles/atom-one-light.css";
import lodash from "lodash";
import "leaflet/dist/leaflet.css";

import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faArchive,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowsAlt,
  faArrowUp,
  faBars,
  faBolt,
  faBuilding,
  faCaretSquareDown,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faClipboard,
  faClock,
  faCloud,
  faCloudUploadAlt,
  faCog,
  faColumns,
  faDatabase,
  faDownload,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFileImport,
  faFolderOpen,
  faGlobe,
  faHdd,
  faInfoCircle,
  faKey,
  faLock,
  faLockOpen,
  faLongArrowAltRight,
  faMap,
  faMapMarked,
  faMapMarkedAlt,
  faMemory,
  faMicrochip,
  faNetworkWired,
  faPaperPlane,
  faPause,
  faPauseCircle,
  faPen,
  faPhone,
  faPhoneAlt,
  faPhoneVolume,
  faPlay,
  faPlayCircle,
  faPlus,
  faPlusCircle,
  faPlusSquare,
  faQuestionCircle,
  faRedo,
  faSearch,
  faShare,
  faShieldAlt,
  faSpinner,
  faSyncAlt,
  faTimes,
  faTimesCircle,
  faTrash,
  faUnlink,
  faUpload,
  faUser,
  faUserCheck,
  faUserCog,
  faUserPlus,
  faUserShield,
  faUserSlash
} from "@fortawesome/free-solid-svg-icons";

// When using Firefox ESR (68 when writing this), Promise.prototype.finally is not a function.
// This is due to an issue in core-js,
// see https://github.com/vuejs/vue-cli/issues/2012#issuecomment-410369818.
// The solutions to override the polyfills given in the issue do not work, therefore we reimplement
// the finally function following the documented behavior,
// as suggested in https://stackoverflow.com/a/60091484.
Promise.prototype.finally =
  Promise.prototype.finally ||
  {
    finally(fn) {
      const onFinally = value => Promise.resolve(fn()).then(() => value);
      return this.then(
        result => onFinally(result),
        reason => onFinally(Promise.reject(reason))
      );
    }
  }.finally;

library.add(
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faArchive,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowsAlt,
  faArrowUp,
  faBars,
  faBolt,
  faBuilding,
  faCaretSquareDown,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faClipboard,
  faClock,
  faCloud,
  faCloudUploadAlt,
  faCog,
  faColumns,
  faDatabase,
  faDownload,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFileImport,
  faFolderOpen,
  faGlobe,
  faHdd,
  faInfoCircle,
  faKey,
  faLock,
  faLockOpen,
  faLongArrowAltRight,
  faMap,
  faMapMarked,
  faMapMarkedAlt,
  faMemory,
  faMicrochip,
  faNetworkWired,
  faPaperPlane,
  faPause,
  faPauseCircle,
  faPen,
  faPhone,
  faPhoneAlt,
  faPhoneVolume,
  faPlay,
  faPlayCircle,
  faPlus,
  faPlusCircle,
  faPlusSquare,
  faQuestionCircle,
  faRedo,
  faSearch,
  faShare,
  faShieldAlt,
  faSpinner,
  faSyncAlt,
  faTimes,
  faTimesCircle,
  faTrash,
  faUnlink,
  faUpload,
  faUser,
  faUserCheck,
  faUserCog,
  faUserPlus,
  faUserShield,
  faUserSlash
);

Vue.component("fa-icon", FontAwesomeIcon);
Vue.component("BIcon", BIcon);
Vue.component("BIconThreeDots", BIconThreeDots);

Vue.config.productionTip = false;

// axios as default http client
Vue.prototype.$http = axios;

Vue.prototype.$_ = lodash;

Vue.use(Notifications);
Vue.use(BootstrapVue);
Vue.use(VueMoment);
Vue.use(VueCookies);

Vue.use(VueHighlightJS, {
  languages: {
    python,
    dockerfile,
    json,
    bash
  }
});

let socketConnectable = false;
if (document.location.protocol === "http:" || document.location.protocol === "https:") {
  const wsProtocol = document.location.protocol.replace("http", "ws");
  const host = document.location.host;
  const liveOrigin = `${wsProtocol}//${host}/builder/live`;
  Vue.use(VueNativeSock, liveOrigin, {
    connectManually: true,
    store: store,
    format: "json",
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 1000 // 1s
  });
  socketConnectable = true;
} else {
  console.error(
    "Current location protocol is not HTTP! Won't try to connect through ws(s):// " +
      "as cannot detect if this secure connection."
  );
}

const vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

// We connect "manually" directly because in some pages (mainly for buckets build status).
// we need to disconnect and reconnect on other ws endpoints.
// We only have access to $disconnect and $connect methods when the connectManually option is set.
// See the ProcessBucket and ProcessPage components for details about connection resets.
if (socketConnectable) {
  vm.$connect();
}
