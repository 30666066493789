<template>
  <b-navbar class="divider-light" id="navbar" toggleable="md" type="light" variant="light" sticky>
    <b-modal ref="login" hide-footer id="login" title="Log in">
      <b-row class="mt-4" align-h="center" no-gutters>
        <b-col class="p-1" cols="10" align="center">
          <b-button
            class="my-1"
            size="lg"
            variant="primary"
            block
            @click="closeLoginModal"
            :to="{ name: 'oidc:login' }"
          >
            Log in with {{ oidcProviderName }}
          </b-button>
          <div class="middle-rule mt-4 mb-1"><span>OR</span></div>
          <b-button
            size="md"
            variant="link"
            class="text-muted"
            @click="closeLoginModal"
            :to="{ name: 'cas:login' }"
          >
            Log in with local account
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
    <b-navbar-brand class="pt-2">
      <router-link to="/">
        <img
          v-if="theme_features.includes('logo-asb')"
          alt="ASB"
          :src="getBrandingLogo()"
          height="50px"
          width="50px"
        />
        <img
          v-if="theme_features.includes('logo-eoepca')"
          alt="EOEPCA"
          src="@/plugins/eoepca/assets/logo6_med.original_v02.png"
          height="16px"
          style="margin-top: 6px"
        />
        <img
          v-if="theme_features.includes('logo-aiopen')"
          alt="AIOPEN"
          src="@/plugins/aiopen/assets/cropped-aiopenlogo_colored.png"
          height="40px"
          style="margin-top: 3px"
        />
      </router-link>
    </b-navbar-brand>

    <b-collapse class="ml-2" is-nav id="nav_collapse">
      <b-navbar-nav>
        <b-nav-item to="/marketplace" class="ml-1"> Marketplace </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav v-if="loggedIn">
        <b-nav-item v-if="theme_features.includes('navbar-processes')" to="/processes" class="ml-1"
          >Processes</b-nav-item
        >
        <b-nav-item v-if="theme_features.includes('navbar-workflows')" to="/workflows" class="ml-1"
          >Workflows</b-nav-item
        >
        <b-nav-item v-if="theme_features.includes('navbar-schedules')" to="/schedules" class="ml-1"
          >Schedules</b-nav-item
        >
        <b-nav-item
          v-if="theme_features.includes('navbar-executions')"
          to="/monitoring"
          class="ml-1"
          >Executions</b-nav-item
        >
        <b-nav-item v-if="theme_features.includes('navbar-jobs')" to="/jobs" class="ml-1"
          >Jobs</b-nav-item
        >
        <b-nav-item
          v-if="theme_features.includes('navbar-collections')"
          to="/mep/collections"
          class="ml-1"
          >Collections</b-nav-item
        >
        <b-nav-item
          v-if="theme_features.includes('navbar-dashboards')"
          to="/dashboards"
          class="ml-1"
          >Dashboards</b-nav-item
        >

        <b-nav-item-dropdown
          text="Products"
          left
          class="ml-1"
          v-if="theme_features.includes('navbar-products')"
        >
          <b-dropdown-item to="/products">Catalogue</b-dropdown-item>
          <!-- <b-dropdown-item href="#">Baskets</b-dropdown-item> -->
        </b-nav-item-dropdown>
        <b-nav-item
          v-if="theme_features.includes('navbar-catalogue-collections')"
          to="/collections"
          class="ml-1"
          >Catalogue Collections</b-nav-item
        >
        <b-nav-item
          v-if="theme_features.includes('navbar-eoepca-executions')"
          to="/monitoring/applications"
          class="ml-1"
          >Executions</b-nav-item
        >
        <b-nav-item
          v-if="theme_features.includes('navbar-model-executions')"
          to="/monitoring/models"
          class="ml-1"
          >Executions</b-nav-item
        >
        <b-nav-item-dropdown
          v-if="theme_features.includes('navbar-model-executions') && isDeveloper"
          class="ml-1"
          left
        >
          <template slot="button-content">
            <span>Development</span>
          </template>
          <b-dropdown-item href="https://onda-dev.ai-pipeline.org/" target="_blank">
            Workspace Controller Page
            <fa-icon
              class="ml-1 fa-light"
              v-b-tooltip.hover
              title="Opens in new tab/page"
              icon="external-link-alt"
              size="xs"
            >
            </fa-icon>
          </b-dropdown-item>
          <b-dropdown-item :href="pdeServerUrl" target="_blank">
            Personal Development Environment (JupyterLab)
            <fa-icon
              class="ml-1 fa-light"
              v-b-tooltip.hover
              title="Opens in new tab/page"
              icon="external-link-alt"
              size="xs"
            >
            </fa-icon>
          </b-dropdown-item>
          <b-dropdown-item :href="modelTrackingServerUrl" target="_blank">
            Workspace Model Tracking Server (MLflow)
            <fa-icon
              class="ml-1 fa-light"
              v-b-tooltip.hover
              title="Opens in new tab/page"
              icon="external-link-alt"
              size="xs"
            >
            </fa-icon>
          </b-dropdown-item>
          <b-dropdown-item :href="workspaceCatalogueUrl" target="_blank">
            Workspace Resource Catalogue
            <fa-icon
              class="ml-1 fa-light"
              v-b-tooltip.hover
              title="Opens in new tab/page"
              icon="external-link-alt"
              size="xs"
            >
            </fa-icon>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <!-- Right aligned nav items -->

      <b-navbar-nav class="ml-auto">
        <b-nav-item
          v-if="loggedIn && showConnectionState"
          v-b-tooltip.hover
          :title="liveConnectionStateText"
        >
          <fetching :fetching="liveConnectionState"></fetching>
        </b-nav-item>
        <b-nav-item v-if="loggedIn && !hideInstanceDetails" v-b-tooltip.hover title="Instance">
          <b-badge class="mr-2" variant="light">{{ instance.name }}</b-badge>
          <b-badge variant="warning">{{ instance["asb_version"] }}</b-badge>
        </b-nav-item>
        <b-nav-item
          v-if="loggedIn && developerMode"
          v-b-tooltip.hover
          title="Developer mode enabled"
        >
          <b-badge variant="info">DEVELOPER</b-badge>
        </b-nav-item>
        <b-nav-item-dropdown class="mr-2" right size="sm">
          <template slot="button-content">
            <fa-icon icon="question-circle" />
          </template>
          <b-dropdown-item href="/docs/" target="_blank">
            Documentation
          </b-dropdown-item>
          <b-dropdown-item
            v-if="theme_features.includes('ades-api')"
            href="/ades/admin/ogc-api/api"
            target="_blank"
          >
            ADES API
          </b-dropdown-item>
          <b-dropdown-item
            v-if="theme_features.includes('eopen-tour')"
            href="https://eopen-project.eu/introductory-tour/"
            target="_blank"
          >
            Video Guides
          </b-dropdown-item>
          <b-dropdown-item
            v-if="theme_features.includes('eopen-deliverables')"
            href="https://eopen-project.eu/public-deliverables/"
            target="_blank"
          >
            Project Deliverables
          </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'index' }"> About </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-form v-if="!loggedIn">
          <span class="text-muted" v-if="authLoading === undefined">Login disabled</span>
          <div v-else-if="!authLoading">
            <b-button
              v-if="oidcEnabled"
              size="sm"
              variant="outline-primary"
              class="my-2 my-sm-0"
              v-b-modal.login
            >
              Log in
            </b-button>
            <b-button v-else size="sm" variant="outline-primary" class="my-2 my-sm-0" to="/login">
              Log in
            </b-button>
          </div>
          <b-spinner
            v-else
            class="mr-1"
            variant="primary"
            small
            v-b-tooltip.hover
            title="Waiting for the authentication service..."
          ></b-spinner>
        </b-nav-form>
        <b-nav-item-dropdown class="mx-1" v-else right>
          <template slot="button-content">
            <span>{{ fullname }} </span>
            <fa-icon
              v-if="!datastore_credentials_changed"
              class="mb-1"
              v-b-tooltip.hover
              title="Action in your account is required"
              color="red"
              icon="exclamation-triangle"
            >
            </fa-icon>
          </template>
          <b-dropdown-item to="/profile">
            Your profile
            <fa-icon
              v-if="!datastore_credentials_changed"
              class="ml-1 mb-1"
              v-b-tooltip.hover
              title="Action in your account is required"
              color="red"
              icon="exclamation-triangle"
            >
            </fa-icon>
          </b-dropdown-item>
          <b-dropdown-item :href="external_datastore_url" target="_blank">
            Your buckets
          </b-dropdown-item>
          <b-dropdown-item to="/resources">Resources Usage</b-dropdown-item>
          <b-dropdown-item to="/settings">Settings</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item v-show="user['is_staff']" to="/admin">Administration</b-dropdown-item>
          <b-dropdown-divider v-show="user['is_staff']"></b-dropdown-divider>
          <b-dropdown-item @click="logout">Logout</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { LOGOUT_USER } from "@/store/action-types";
import { mapState } from "vuex";
import Fetching from "@/components/share/Fetching";

export default {
  name: "NavBar",
  components: { Fetching },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      dashboard_url: process.env.VUE_APP_DASHBOARDS_APP_BASE_URL
    };
  },
  methods: {
    logout: function() {
      this.$store.dispatch(LOGOUT_USER);
    },
    getBrandingLogo() {
      if (this.theme_features.includes("logo-eopen")) {
        return require("@/plugins/eopen/assets/eopen-logo.png");
      }
      if (this.theme_features.includes("logo-asb")) {
        return require("@/assets/asb-small-logo.png");
      }
    },
    closeLoginModal() {
      this.$refs.login.hide();
    }
  },
  computed: {
    fullname() {
      return this.$store.state.user["full_name"] !== undefined
        ? this.$store.state.user["full_name"] + " (" + this.$store.state.user["username"] + ")"
        : this.$store.state.user["username"];
    },
    isDeveloper() {
      // The user is developer if he has the role "developer" in at least one workspace / group
      if (this.$store.state.user["groups"] !== undefined) {
        for (const [group, roles] of Object.entries(this.$store.state.user["groups"])) {
          console.log("Checking:", group);
          if (roles.includes("developer")) {
            return true;
          }
        }
      }
      return false;
    },
    dev_domain() {
      // TODO the base domain must be provided by the backend
      return "onda-dev.ai-pipeline.org";
    },
    workspaces() {
      if (this.$store.state.user["groups"] !== undefined) {
        return Object.keys(this.$store.state.user["groups"]);
      }
      return [];
    },
    modelTrackingServerUrl() {
      return "https://mlflow." + this.workspaces[0] + ".workspaces." + this.dev_domain;
      // When run_id is known (e.g. in the model details page), we can navigate directly to it:
      // var run_id = "bd2cc5eeee954ebcb701a45c964839d4";
      // return "https://mlflow." + this.workspaces[0] + ".workspaces." + dev_domain + "/#/runs/" + run_id;
    },
    pdeServerUrl() {
      var username = this.$store.state.user["username"];
      return (
        "https://" + username + ".jupyter." + this.workspaces[0] + ".workspaces." + this.dev_domain
      );
      // When the model name is known (e.g. in the model details page), we can navigate directly to it:
      // var model_name = "attached003";
      // return "https://" + username + ".jupyter." + this.workspaces[0] + ".workspaces." + dev_domain + "/lab/tree/" + model_name;
    },
    globalCatalogueUrl() {
      return "https://resource-catalogue." + this.dev_domain;
    },
    workspaceCatalogueUrl() {
      return "https://catalogue." + this.workspaces[0] + ".workspaces." + this.dev_domain;
    },
    oidcEnabled() {
      return this.$store.getters.oidcEnabled;
    },
    liveConnectionState() {
      // state:
      //  true: connecting, false: connected, undefined: broken connection
      if (this.socket.connecting) {
        return true;
      } else if (this.socket.isConnected) {
        return false;
      } else {
        return undefined;
      }
    },
    liveConnectionStateText() {
      // true: fetching data, false: data received, undefined: broken connection
      if (this.liveConnectionState === undefined) {
        return "disconnected";
      } else if (this.liveConnectionState) {
        return "connecting..";
      } else {
        return "connected - real-time updates";
      }
    },
    ...mapState({
      authLoading: state => state.authLoading,
      loggedIn: state => state.loggedIn,
      user: state => state.user,
      instance: state => state.instance,
      hideInstanceDetails: state => state.settings.hideInstanceDetails,
      developerMode: state => state.settings.developerMode,
      showConnectionState: state => state.settings.showConnectionState,
      theme: state => state.instance.theme,
      theme_features: state => state.instance.theme_features,
      oidcProviderName: state => state.instance.oidcProviderName,
      socket: state => state.socket,
      datastore_credentials_changed: state => state.datastore_credentials_changed,
      external_datastore_url: state => state.instance.external_datastore_url
    })
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/default/asb.scss";

#navbar {
  z-index: 1;
  height: $navbar-height;
  padding-left: 10px;
}

/*.navbar-divider {
    margin-left: $sidebar-width;

    &.collapsed {
        margin-left: $sidebar-collapsed-width;
    }
}*/
</style>
