<template>
  <b-container class="my-4">
    <b-row align="center" v-if="loading">
      <b-col>
        <loading :loading="loading"></loading>
      </b-col>
    </b-row>
    <div v-else>
      <page-title :title="`Details for ${model_name}`" :subtitle="`${model_description}`">
        <template v-slot:toolbar>
          <b-btn-toolbar class="float-right" justify>
            <slot name="title-toolbar" v-bind:loading="loading"></slot>
            <b-btn-group>
              <b-button
                variant="danger"
                class="ml-2 rounded"
                :to="`/workflows/${workflow_id.split(':').pop()}/execute`"
                v-if="Object.keys(user).length != 0"
                >Execute</b-button
              >
            </b-btn-group>
          </b-btn-toolbar>
        </template>
      </page-title>
      <b-row>
        <b-col cols="10">
          <b-tabs>
            <b-tab title="Properties">
              <b-overlay :show="updating">
                <b-row class="my-3" align-h="center">
                  <b-col>
                    <b-form-row v-if="appInfo.id">
                      <b-col class="col-form-label" cols="2">
                        <label for="description" class="my-0">Title:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input id="description" type="text" plaintext v-model="appInfo.id" />
                      </b-col>
                    </b-form-row>
                    <b-form-row v-if="appInfo.description">
                      <b-col class="col-form-label" cols="2">
                        <label for="version" class="my-0">Description:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="version"
                          type="text"
                          plaintext
                          v-model="appInfo.description"
                        />
                      </b-col>
                    </b-form-row>
                    <b-form-row v-if="appInfo.properties.type">
                      <b-col class="col-form-label" cols="2">
                        <label for="version" class="my-0">Type:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="version"
                          type="text"
                          plaintext
                          v-model="appInfo.properties.type"
                        />
                      </b-col>
                    </b-form-row>
                    <b-form-row v-if="appInfo.stac_version">
                      <b-col class="col-form-label" cols="2">
                        <label for="version" class="my-0">STAC version:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="version"
                          type="text"
                          plaintext
                          v-model="appInfo.stac_version"
                        />
                      </b-col>
                    </b-form-row>
                    <b-form-row v-if="appInfo.collection">
                      <b-col class="col-form-label" cols="2">
                        <label for="version" class="my-0">Collection:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="version"
                          type="text"
                          plaintext
                          v-model="appInfo.collection"
                        />
                      </b-col>
                    </b-form-row>
                    <b-form-row v-if="appInfo.properties.datetime">
                      <b-col class="col-form-label" cols="2">
                        <label for="version" class="my-0">Date and time:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="version"
                          type="text"
                          plaintext
                          v-model="appInfo.properties.datetime"
                        />
                      </b-col>
                    </b-form-row>
                    <b-form-row v-if="appInfo.properties.start_datetime">
                      <b-col class="col-form-label" cols="2">
                        <label for="version" class="my-0">Start:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="version"
                          type="text"
                          plaintext
                          v-model="appInfo.properties.start_datetime"
                        />
                      </b-col>
                    </b-form-row>
                    <b-form-row v-if="appInfo.properties.end_datetime">
                      <b-col class="col-form-label" cols="2">
                        <label for="version" class="my-0">End:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="version"
                          type="text"
                          plaintext
                          v-model="appInfo.properties.end_datetime"
                        />
                      </b-col>
                    </b-form-row>
                    <b-form-row v-if="appInfo.properties.license">
                      <b-col class="col-form-label" cols="2">
                        <label for="version" class="my-0">License:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="version"
                          type="text"
                          plaintext
                          v-model="appInfo.properties.license"
                        />
                      </b-col>
                    </b-form-row>
                    <b-form-row
                      v-for="provider in appInfo.properties.providers"
                      :key="provider.name"
                    >
                      <b-col class="col-form-label" cols="2">
                        <label for="version" class="my-0">Provider:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-row v-for="(detail, name) in provider" :key="name">
                          <b-col class="col-form-label" cols="2">{{ name }}:</b-col>
                          <b-col class="col-form-label" cols="8">{{ detail }}</b-col>
                        </b-form-row>
                      </b-col>
                    </b-form-row>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
            <b-tab title="Geometry">
              <b-overlay :show="updating">
                <b-row class="my-3" align-h="center">
                  <b-col>
                    <b-form-row>
                      <b-col class="col-form-label" cols="2">
                        <label for="description" class="my-0">Type:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="description"
                          type="text"
                          plaintext
                          v-model="appInfo.geometry.type"
                        />
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col class="col-form-label" cols="2">
                        <label for="description" class="my-0">Coordinates:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="description"
                          type="text"
                          plaintext
                          v-model="coordinates"
                        />
                      </b-col>
                    </b-form-row>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
            <b-tab title="Links">
              <b-overlay :show="updating">
                <b-row class="my-3" align-h="center">
                  <b-col>
                    <b-card v-for="link in appInfo.links" :key="link.name">
                      <b-form-row v-if="link.title">
                        <b-col class="col-form-label" cols="2">
                          <label for="description" class="my-0">Title:</label>
                        </b-col>
                        <b-col cols="10">
                          <b-form-input
                            id="description"
                            type="text"
                            plaintext
                            v-model="link.title"
                          />
                        </b-col>
                      </b-form-row>
                      <b-form-row v-if="link.type">
                        <b-col class="col-form-label" cols="2">
                          <label for="description" class="my-0">Type:</label>
                        </b-col>
                        <b-col cols="10">
                          <b-form-input
                            id="description"
                            type="text"
                            plaintext
                            v-model="link.type"
                          />
                        </b-col>
                      </b-form-row>
                      <b-form-row v-if="link.href">
                        <b-col class="col-form-label" cols="2">
                          <label for="description" class="my-0">Href:</label>
                        </b-col>
                        <b-col cols="10">
                          <b-form-input
                            id="description"
                            type="text"
                            plaintext
                            v-model="link.href"
                          />
                        </b-col>
                      </b-form-row>
                      <b-form-row v-if="link.rel">
                        <b-col class="col-form-label" cols="2">
                          <label for="description" class="my-0">Rel:</label>
                        </b-col>
                        <b-col cols="10">
                          <b-form-input id="description" type="text" plaintext v-model="link.rel" />
                        </b-col>
                      </b-form-row>
                    </b-card>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
            <b-tab title="Assets" v-if="Object.keys(appInfo.assets).length !== 0">
              <b-overlay :show="updating">
                <b-row class="my-3" align-h="center">
                  <b-col>
                    <div v-for="(assetData, assetName) in appInfo.assets" :key="assetName">
                      <b-card :title="assetName">
                        <b-form-row v-if="assetData.title">
                          <b-col class="col-form-label" cols="2">
                            <label for="description" class="my-0">Title:</label>
                          </b-col>
                          <b-col cols="10">
                            <b-form-input
                              id="description"
                              type="text"
                              plaintext
                              v-model="assetData.title"
                            />
                          </b-col>
                        </b-form-row>
                        <b-form-row v-if="assetData.type">
                          <b-col class="col-form-label" cols="2">
                            <label for="description" class="my-0">Type:</label>
                          </b-col>
                          <b-col cols="10">
                            <b-form-input
                              id="description"
                              type="text"
                              plaintext
                              v-model="assetData.type"
                            />
                          </b-col>
                        </b-form-row>
                        <b-form-row v-if="assetData.href">
                          <b-col class="col-form-label" cols="2">
                            <label for="description" class="my-0">Href:</label>
                          </b-col>
                          <b-col cols="10">
                            <b-form-input
                              id="description"
                              type="text"
                              plaintext
                              v-model="assetData.href"
                            />
                          </b-col>
                        </b-form-row>
                        <b-form-row v-if="assetData.roles">
                          <b-col class="col-form-label" cols="2">
                            <label for="description" class="my-0">Roles:</label>
                          </b-col>
                          <b-col class="col-form-label" cols="10">
                            {{ assetData.roles }}
                          </b-col>
                        </b-form-row>
                      </b-card>
                    </div>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
            <b-tab title="Extensions" v-if="Object.keys(extensions).length != 0">
              <b-overlay :show="updating">
                <b-row class="my-3" align-h="center">
                  <b-col>
                    <div v-for="(extensionData, extensionName) in extensions" :key="extensionName">
                      <b-card :title="extensionName">
                        <b-form-row
                          v-for="(extensionDataValue, extensionDataName) in extensionData"
                          :key="extensionDataName"
                        >
                          <b-col class="col-form-label" cols="2">
                            <label for="description" class="my-0"
                              >{{ extensionDataName | makeTitle }}:</label
                            >
                          </b-col>
                          <b-col class="col-form-label" cols="10">
                            <label for="description" class="my-0">{{ extensionDataValue }}</label>
                          </b-col>
                        </b-form-row>
                      </b-card>
                    </div>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
            <b-tab title="Raw JSON">
              <div>
                <pre>{{ appInfo | pretty }}</pre>
              </div>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import resourceCatalogueService from "@/api/asb/services/resource-catalogue";
import PageTitle from "@/components/share/PageTitle";
import Loading from "@/components/share/Loading";
import { mapState } from "vuex";

export default {
  name: "WorkflowDetail",
  components: {
    PageTitle,
    Loading
  },
  data() {
    return {
      workflow_id: "",
      collection_id: "",
      loading: true,
      appInfo: {},
      updating: false,
      collection: "",
      extensions: [],
      coordinates: "",
      jsonMsg: ""
    };
  },
  created() {
    this.workflow_id = this.$route.params.workflow_id;
    this.collection_id = this.$route.params.id;
    this.getAppInfo();
  },
  methods: {
    getAppInfo() {
      resourceCatalogueService
        .describeItem(this.collection_id, this.workflow_id)
        .then(data => this.setInfo(data))
        .catch(error => resourceCatalogueService.handleError(error))
        .finally(() => (this.loading = false));
    },
    setInfo(appInfo) {
      this.appInfo = appInfo;
      this.extensions = this.setExtensions(this.appInfo);
      this.coordinates = this.setCoordinates(this.appInfo);
      console.log(this.appInfo);
    },
    setExtensions(appInfo) {
      let extensions = {};
      let mlModel = {};
      let mlAoi = {};
      let sci = {};
      let properties = appInfo.properties;
      for (const [key, value] of Object.entries(properties)) {
        if (key.includes("ml-model:")) {
          let extensionKey = key.replace("ml-model:", "");
          mlModel[extensionKey] = value;
        } else if (key.includes("ml-aoi:")) {
          let extensionKey = key.replace("ml-aoi:", "");
          mlAoi[extensionKey] = value;
        } else if (key.includes("sci:")) {
          let extensionKey = key.replace("sci:", "");
          sci[extensionKey] = value;
        }
      }
      if (Object.keys(mlModel) != 0) {
        extensions["ml-model"] = mlModel;
      }
      if (Object.keys(mlAoi) != 0) {
        extensions["ml-aoi"] = mlAoi;
      }
      if (Object.keys(sci) != 0) {
        extensions["sci"] = sci;
      }
      console.log(extensions);
      return extensions;
    },
    setCoordinates(appInfo) {
      let coordinates = "";
      let rawCoordinates = appInfo.geometry.coordinates[0];
      for (let i = 0; i < rawCoordinates.length; i++) {
        coordinates = coordinates + "[" + appInfo.geometry.coordinates[0][i] + "] ";
      }
      return coordinates;
    }
  },
  computed: {
    ...mapState({
      user: state => state.user
    }),
    model_name() {
      let modelName = "model";
      if (this.appInfo.properties) {
        modelName = this.appInfo.properties.name
          ? this.appInfo.properties.name
          : this.appInfo.properties.title
          ? this.appInfo.properties.title
          : this.appInfo.id
          ? this.appInfo.id
          : "model";
      }
      return modelName;
    },
    model_description() {
      let modelDescription = "model";
      if (this.appInfo.properties) {
        modelDescription = this.appInfo.properties.abstract
          ? this.appInfo.properties.abstract
          : this.appInfo.description
          ? this.appInfo.description
          : "model";
      }
      return modelDescription;
    }
  },
  filters: {
    pretty: function(value) {
      return JSON.stringify(value, null, 2);
    },
    makeTitle: function(value) {
      const title = value.replace("_", " ");
      let title2 = title.charAt(0).toUpperCase() + title.slice(1);
      if (title2 == "Doi") {
        title2 = "DOI";
      }
      return title2;
    }
  }
};
</script>

<style></style>
