import { defaultClientConfig } from "@/api/asb/client";
import axios from "axios";
import { handleServiceError, suffixWithBackslash } from "@/api/utils";

const baseURL = suffixWithBackslash(process.env.VUE_APP_ASB_PROCIMP_BASE_URL);
const apiURL = baseURL + "api/";
export const importClient = axios.create({
  ...defaultClientConfig,
  baseURL: apiURL
});

export default {
  handleError: error => handleServiceError("Importer", error),
  async getProcessBucketDockerfile(bucketId) {
    let { data } = await importClient.get(`process/buckets/${bucketId}/dockerfile`);
    return data;
  },
  async getProcessBucketProperties(bucketId) {
    let { data } = await importClient.get(`process/buckets/${bucketId}/properties`);
    return data;
  },
  async getProcessBucketFiles(bucketId) {
    let { data } = await importClient.get(`process/buckets/${bucketId}/files`);
    return data;
  },
  async removeProcessBucketFile(bucketId, fileId) {
    let data = {
      files: [fileId]
    };
    return await importClient.delete(`process/buckets/${bucketId}/files`, { data: data });
  },
  async dryRunImportProcessBucket(bucketId) {
    let { data } = await importClient.get(`process/buckets/${bucketId}/dry-run-import`);
    return data;
  },
  async importProcessBucket(bucketId) {
    let { data } = await importClient.get(`process/buckets/${bucketId}/import`);
    return data;
  },
  processBucketUploadFilesURL(bucketId) {
    return `${apiURL}process/buckets/${bucketId}/files`;
  },
  processBucketStatusWebSocketURL(bucketId) {
    let wsURL;
    if (baseURL.indexOf("http://") === 0 || baseURL.indexOf("https://") === 0) {
      wsURL = baseURL.replace("http", "ws");
    } else {
      let protocol = window.location.protocol.replace("http", "ws");
      wsURL = `${protocol}//${window.location.hostname}${baseURL}`;
    }

    const url = `${wsURL}ws/process-buckets/${bucketId}/status`;
    console.log(`WebSocket URL for bucket ${bucketId}: ${url}`);
    return url;
  }
};
