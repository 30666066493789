<template>
  <b-container class="mt-4">
    <b-modal
      ref="stopMaaS"
      hide-footer
      id="stopMaaS"
      :title="`Stop ${selected_maas_data.name} Service?`"
    >
      <b-row no-gutters>
        <p>Please confirm (or click outside this dialog to cancel) ...</p>
      </b-row>
      <b-row align-h="end" no-gutters>
        <b-col cols="4">
          <b-button
            class="btn-block"
            variant="danger"
            @click="stopModelAsAService(selected_maas_data)"
          >
            <fa-icon icon="times-circle" class="mt-1 ml-2 float-right" />
            <span>Stop the Service</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-row align="center" v-if="loading">
      <b-col>
        <loading :loading="loading"></loading>
      </b-col>
    </b-row>
    <div v-else>
      <page-title title="Resources Usage" subtitle="Resources usage control and history." />
      <b-row>
        <b-col cols="12">
          <b-tabs>
            <b-tab title="Dynamic Executions">
              <b-overlay :show="updating">
                <b-row class="mt-3" align-h="center">
                  <b-col lg="12" cols="12">
                    <page-title subtitle="Reports" no-divider></page-title>
                    <p>
                      Executions Status and Reports are available via the<b-button
                        variant="outline-primary"
                        class="ml-2 mr-2 mb-0"
                        to="/monitoring/models"
                        size="sm"
                        v-b-popover.hover.top="'Open the dataset details page'"
                        >Executions </b-button
                      >page.
                    </p>
                    <page-title subtitle="History" no-divider></page-title>
                    <span v-if="models_usage.length == 0"
                      >You have not yet execute any model to obtain predictions.</span
                    >
                    <b-table
                      v-else
                      :items="models_usage"
                      :fields="models_usage_fields"
                      small="small"
                      outlined
                      hover
                      sort-by="name"
                      sort-icon-left
                      head-variant="light"
                    >
                      <template #cell(DETAILS)="data">
                        <b-button
                          variant="outline-primary"
                          class="ml-2 mb-0"
                          :to="modelDetailsHref(data.item)"
                          size="sm"
                          v-b-popover.hover.top="'Open the model details page'"
                          >Model&nbsp;Details</b-button
                        >
                        <b-button
                          variant="outline-primary"
                          class="rounded ml-3"
                          :to="modeExecutionReportHref(data.item)"
                          size="sm"
                          v-b-popover.hover.top="'Open the execution report'"
                          >Execution&nbsp;Report</b-button
                        >
                      </template>
                    </b-table>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
            <b-tab title="Models as Services">
              <b-overlay :show="updating">
                <b-row class="mt-3" align-h="center">
                  <b-col lg="12" cols="12">
                    <page-title subtitle="Models running as Services" no-divider></page-title>
                    <p>
                      Models running as services consume cloud resources until they are manually
                      stopped.
                    </p>
                    <span v-if="maas_data.length == 0"
                      >You do not have models running as services at the moment.</span
                    >
                    <b-table
                      v-else
                      :items="maas_data"
                      :fields="maas_data_fields"
                      small="small"
                      outlined
                      hover
                      sort-by="name"
                      sort-icon-left
                      head-variant="light"
                    >
                      <template #cell(RUNTIME)="data">
                        <span class="mr-5">{{ data.value }}</span>
                      </template>
                      <template #cell(ENDPOINT)="data">
                        <b-link :href="data.item.model_proc_url" target="_blank"
                          >{{ data.item.model_proc_id }}
                          <fa-icon
                            class="ml-1 fa-light"
                            v-b-tooltip.hover
                            title="Opens in new tab/page"
                            icon="external-link-alt"
                            size="xs"
                          >
                          </fa-icon>
                        </b-link>
                      </template>
                      <template #cell(DETAILS)="data">
                        <b-button
                          variant="outline-primary"
                          class="ml-2 mb-0"
                          :to="modelDetailsHref(data.item)"
                          size="sm"
                          v-b-popover.hover.top="'Open the model details page'"
                          >Model&nbsp;Details</b-button
                        >
                        <b-button
                          variant="danger"
                          class="rounded ml-3"
                          size="sm"
                          v-b-popover.hover.top="'Stop the service (requires confirmation)'"
                          @click="confirmStopModelAsAService(data.item)"
                        >
                          <fa-icon icon="times-circle" class="mt-1 ml-2 float-right" />
                          Stop&nbsp;the&nbsp;Service</b-button
                        >
                      </template>
                    </b-table>
                  </b-col>
                </b-row>

                <b-row class="mt-5">
                  <b-col lg="12" cols="12">
                    <page-title subtitle="History" no-divider></page-title>
                    <span v-if="maas_usage.length == 0"
                      >You have not yet started a model as a service.</span
                    >
                    <b-table
                      v-else
                      :items="maas_usage"
                      :fields="maas_usage_fields"
                      small="small"
                      outlined
                      hover
                      sort-by="name"
                      sort-icon-left
                      head-variant="light"
                    >
                      <template #cell(DETAILS)="data">
                        <b-button
                          variant="outline-primary"
                          class="ml-2 mb-0"
                          :to="modelDetailsHref(data.item)"
                          size="sm"
                          v-b-popover.hover.top="'Open the model details page'"
                          >Model&nbsp;Details</b-button
                        >
                      </template>
                    </b-table>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
            <b-tab title="Ordered Datasets">
              <b-overlay :show="updating">
                <b-row class="mt-3" align-h="center">
                  <b-col lg="12" cols="12">
                    <page-title
                      subtitle="Training Datasets ordering history"
                      no-divider
                    ></page-title>
                    <span v-if="datasets_usage.length == 0"
                      >You have not yet ordered any training datasets.</span
                    >
                    <b-table
                      v-else
                      :items="datasets_usage"
                      :fields="datasets_usage_fields"
                      small="small"
                      outlined
                      hover
                      sort-by="name"
                      sort-icon-left
                      head-variant="light"
                    >
                      <template #cell(DETAILS)="data">
                        <b-button
                          variant="outline-primary"
                          class="ml-2 mb-0"
                          :to="datasetDetailsHref(data.item)"
                          size="sm"
                          v-b-popover.hover.top="'Open the dataset details page'"
                          >Dataset&nbsp;Details</b-button
                        >
                      </template>
                    </b-table>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import backendService from "@/api/asb/services/backend";
import Loading from "@/components/share/Loading";
import PageTitle from "@/components/share/PageTitle";
import { toDatetime, formatDuration, formatDurationStringShort } from "@/utils.js";

export default {
  name: "Resources",
  components: {
    Loading,
    PageTitle
  },
  data() {
    return {
      loading: true,
      updating: false,
      visible: false,
      maas_data: [],
      maas_data_fields: [
        { key: "name", sortable: true, tdClass: "align-middle" },
        {
          key: "started",
          label: "Start time",
          formatter: toDatetime,
          sortable: true,
          tdClass: "align-middle"
        },
        {
          key: "RUNTIME",
          label: "Duration (active)",
          formatter: this.toDuration,
          sortable: true,
          tdClass: "align-middle"
        },
        // {
        //   key: "executions",
        //   label: "Executed",
        //   sortable: true,
        //   tdClass: "align-middle text-center"
        // },
        // { key: "last_execution", formatter: toDatetime, sortable: true, tdClass: "align-middle" },
        {
          key: "ENDPOINT",
          label: "Process Identifier / Exposed Endpoint",
          tdClass: "align-middle"
        },
        { key: "DETAILS", label: "", tdClass: "align-middle", class: "text-center" }
      ],
      selected_maas_data: {},
      models_usage: [],
      models_usage_fields: [
        { key: "title", sortable: true, tdClass: "align-middle" },
        {
          key: "catalogue_item_version",
          label: "Version",
          sortable: true,
          tdClass: "align-middle"
        },
        {
          key: "owner_id",
          label: "Publisher",
          formatter: this.getUserName,
          sortable: true,
          tdClass: "align-middle"
        },
        { key: "resource_license", label: "License", sortable: true, tdClass: "align-middle" },
        {
          key: "datetime_start",
          label: "Executed On",
          formatter: toDatetime,
          sortable: true,
          tdClass: "align-middle"
        },
        // { key: "S3_PREFIX", label: "S3 Prefix", tdClass: "align-middle" },
        { key: "DETAILS", label: "", tdClass: "align-middle", class: "text-center" }
      ],
      maas_usage: [],
      maas_usage_fields: [
        { key: "title", sortable: true, tdClass: "align-middle" },
        {
          key: "catalogue_item_version",
          label: "Version",
          sortable: true,
          tdClass: "align-middle"
        },
        {
          key: "owner_id",
          label: "Publisher",
          formatter: this.getUserName,
          sortable: true,
          tdClass: "align-middle"
        },
        { key: "resource_license", label: "License", sortable: true, tdClass: "align-middle" },
        {
          key: "datetime_start",
          label: "Started On",
          formatter: toDatetime,
          sortable: true,
          tdClass: "align-middle"
        },
        {
          key: "datetime_end",
          label: "Stopped On",
          formatter: toDatetime,
          sortable: true,
          tdClass: "align-middle"
        },
        {
          key: "RUNTIME",
          label: "Duration",
          formatter: this.toDuration,
          sortable: true,
          tdClass: "align-middle"
        },
        // { key: "S3_PREFIX", label: "S3 Prefix", tdClass: "align-middle" },
        { key: "DETAILS", label: "", tdClass: "align-middle", class: "text-center" }
      ],
      datasets_usage: [],
      datasets_usage_fields: [
        { key: "title", sortable: true, tdClass: "align-middle" },
        {
          key: "catalogue_item_version",
          label: "Version",
          sortable: true,
          tdClass: "align-middle"
        },
        {
          key: "owner_id",
          label: "Publisher",
          formatter: this.getUserName,
          sortable: true,
          tdClass: "align-middle"
        },
        { key: "resource_license", label: "License", sortable: true, tdClass: "align-middle" },
        {
          key: "datetime_start",
          label: "Ordered On",
          formatter: toDatetime,
          sortable: true,
          tdClass: "align-middle"
        },
        // { key: "S3_PREFIX", label: "S3 Prefix", tdClass: "align-middle" },
        { key: "DETAILS", label: "", tdClass: "align-middle", class: "text-center" }
      ]
    };
  },
  activated: function() {
    console.log("activated", this._inactive, this.item);
  },
  deactivated: function() {
    console.log("deactivated", this._inactive, this.item);
  },
  mounted: function() {
    console.log("mounted", this._inactive, this.item);
    this.visible = true;
    this.getModelExecutionsUsage();
    this.getModelServicesUsage();
    this.getTrainingDatasetsUsage();
    this.pollModelAsAServiceStatus();
  },
  destroyed: function() {
    console.log("destroyed", this._inactive, this.item);
    this.visible = false;
  },
  methods: {
    pollModelAsAServiceStatus() {
      console.log("Fetching MaaS status ...");
      // The polling runs forever (as long as the page is displayed)
      if (!this.visible) {
        return;
      }
      backendService
        .getModelsAsAServices()
        .then(data => {
          this.loading = false;
          this.maas_data = data["maas"];
          setTimeout(this.pollModelAsAServiceStatus, 5000);
        })
        .catch(error => backendService.handleError(error))
        .finally(() => (this.loading = false));
    },
    getTrainingDatasetsUsage() {
      console.log("Fetching Training Datasets ordering history ...");
      backendService
        .getTrainingDatasetsUsage()
        .then(data => {
          this.loading = false;
          this.datasets_usage = data["data"];
        })
        .catch(error => backendService.handleError(error))
        .finally(() => (this.loading = false));
    },
    getModelServicesUsage() {
      console.log("Fetching Model Services history ...");
      backendService
        .getModelServicesUsage()
        .then(data => {
          this.loading = false;
          this.maas_usage = data["data"];
        })
        .catch(error => backendService.handleError(error))
        .finally(() => (this.loading = false));
    },
    getModelExecutionsUsage() {
      console.log("Fetching Model Executions history ...");
      backendService
        .getModelExecutionsUsage()
        .then(data => {
          this.loading = false;
          this.models_usage = data["data"];
        })
        .catch(error => backendService.handleError(error))
        .finally(() => (this.loading = false));
    },
    datasetDetailsHref(resource) {
      console.log(resource);
      return (
        "collections/" +
        resource.catalogue_collection_id +
        "/dataset_detail/" +
        resource.catalogue_item_id
      );
    },
    // getModelsAsAServices() {
    //   backendService
    //     .getModelsAsAServices()
    //     .then(data => (this.maas_data = data["maas"]))
    //     .catch(error => backendService.handleError(error))
    //     .finally(() => (this.loading = false));
    // },
    toDuration(_value, _key, item) {
      // Compute the duration since the deployment time till now
      console.log("Creating duration for item", item);
      // ---
      let started = null;
      if (item.started !== undefined && item.started != null) {
        started = new Date(item.started);
      } else if (item.datetime_start !== undefined && item.datetime_start != null) {
        started = new Date(item.datetime_start);
      }
      // ---
      let ended = Date.now();
      if (item.ended !== undefined && item.ended != null) {
        ended = new Date(item.ended);
      } else if (item.datetime_end !== undefined && item.datetime_end != null) {
        ended = new Date(item.datetime_end);
      }
      let diff = ended.valueOf() - started.valueOf();
      console.log("Start and end dates:", started.valueOf(), ended.valueOf(), diff);

      let formattedDuration = formatDuration(diff);
      console.log("Formatted duration: ", formatDurationStringShort(formattedDuration));
      return "" + formatDurationStringShort(formattedDuration);
    },
    getUserName(_value, _key, item) {
      console.log("getUserUname:", _value, _key, item);
      return _value;
    },
    modelDetailsHref(resource) {
      console.log(resource);
      if (resource.collection !== undefined) {
        return "collections/" + resource.collection + "/model_detail/" + resource.id;
      }
      return (
        "collections/" +
        resource.catalogue_collection_id +
        "/model_detail/" +
        resource.catalogue_item_id
      );
    },
    modeExecutionReportHref(resource) {
      // Format: https://localhost:8443/asb/monitoring/models/338/report
      console.log(resource);
      return "monitoring/models/" + resource.external_reference + "/report";
    },
    confirmStopModelAsAService(resource) {
      this.selected_maas_data = resource;
      this.$refs.stopMaaS.show();
    },
    stopModelAsAService(resource) {
      console.log("Undeploying from WPS", resource);
      this.$refs.stopMaaS.hide();
      this.maasIsInTransition = true;
      backendService
        .stopModelAsAService(resource.collection, resource.id)
        // TODO Disable the button then wait for a few seconds before refreshing the table
        .then(this.getModelsAsAServices())
        .catch(error => backendService.handleError(error))
        .finally(() => {
          this.loading = false;
          this.maasIsInTransition = false;
        });
    }
  },
  computed: {}
};
</script>
