<template>
  <b-row>
    <b-col cols="12">
      <span v-html="dashboard.description"></span>
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "DashboardDescription",
  computed: {
    ...mapState({
      dashboard: state => state.dashboards.dashboard
    })
  }
};
</script>

<style scoped></style>
