export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const POP_MESSAGE = "POP_MESSAGE";
export const REMOVE_ALL_MESSAGES = "REMOVE_ALL_MESSAGES";
export const SET_INSTANCE_DETAILS = "SET_INSTANCE_DETAILS";
export const SET_DEVELOPER_MODE = "SET_DEVELOPER_MODE";
export const HIDE_INSTANCE_DETAILS = "HIDE_INSTANCE_DETAILS";
export const SET_AUTH_LOADING = "AUTH_LOADING";
export const SET_SHOW_CONNECTION_STATE = "SET_SHOW_CONNECTION_STATE";
export const SET_ASB_THEME = "SET_ASB_THEME";
export const SET_DATASTORE_CREDENTIALS_CHANGED = "SET_DATASTORE_CREDENTIALS_CHANGED";
export const SET_MARKETPLACE_DATA = "SET_MARKETPLACE_DATA";
export const SET_MARKETPLACE_DATA_FETCHING = "SET_MARKETPLACE_DATA_FETCHING";
export const SET_MARKETPLACE_QUERY = "SET_MARKETPLACE_QUERY";
export const SET_MARKETPLACE_FILTER_OPTIONS = "SET_MARKETPLACE_FILTER_OPTIONS";
export const SET_MARKETPLACE_FILTERS_ENABLED = "SET_MARKETPLACE_FILTERS_ENABLED";
export const SET_MARKETPLACE_COLLECTIONS = "SET_MARKETPLACE_COLLECTIONS";
export const SET_ACCEPTED_TCS = "SET_ACCEPTED_TCS";
