<template>
  <b-container class="mt-4 mb-2">
    <b-row v-if="user">
      <b-col>
        <page-title
          :loading="loading"
          class="mb-3"
          :title="readonly ? 'User profile' : 'Edit user'"
          :subtitle="`${user['username']}`"
        >
          <template v-slot:toolbar>
            <b-button-group v-show="!readonly">
              <b-dropdown class="no-caret" size="lg" variant="transparent" right>
                <template slot="button-content">
                  <fa-icon icon="bars"></fa-icon>
                </template>
                <b-dropdown-item :href="`/usermgr/admin/auth/user/?q=${user.username}`">
                  <fa-icon class="mr-2" icon="user-cog"></fa-icon>
                  <span>Advanced administration</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-button-group>
          </template>
        </page-title>

        <b-row class="mt-5" align-h="center">
          <b-col lg="9" cols="12">
            <page-title subtitle="User information" no-divider></page-title>
            <b-form-row class="mb-2">
              <b-col class="col-form-label" cols="2">
                <label for="username">Username:</label>
              </b-col>
              <b-col>
                <b-form-input id="username" type="text" plaintext :value="user['username']" />
              </b-col>
            </b-form-row>
            <b-form-row class="mb-2">
              <b-col class="col-form-label" cols="2">
                <label for="name">Name:</label>
              </b-col>
              <b-col>
                <b-form-input
                  id="name"
                  type="text"
                  :plaintext="readonly"
                  v-model="user['first_name']"
                />
                <b-form-text> First name </b-form-text>
              </b-col>
              <b-col>
                <b-form-input type="text" :plaintext="readonly" v-model="user['last_name']" />
                <b-form-text> Last name </b-form-text>
              </b-col>
            </b-form-row>
            <b-form-row class="mb-2">
              <b-col class="col-form-label" cols="2">
                <label for="email">Email:</label>
              </b-col>
              <b-col>
                <b-form-input
                  id="email"
                  type="email"
                  :plaintext="readonly"
                  v-model="user['email']"
                />
              </b-col>
            </b-form-row>
            <b-form-row class="mb-2">
              <b-col class="col-form-label" cols="2">
                <label for="name">Login details:</label>
              </b-col>
              <b-col>
                <span>{{ user["date_joined"] | moment("calendar") }}</span>
                <b-form-text> Date joined </b-form-text>
              </b-col>
              <b-col v-if="user['last_login']">
                <span>{{ user["last_login"] | moment("calendar") }}</span>
                <b-form-text> Last login </b-form-text>
              </b-col>
            </b-form-row>
            <page-title subtitle="Security information" no-divider class="mt-4"></page-title>
            <b-row class="my-3">
              <b-col cols="1">
                <label> Password: </label>
              </b-col>
              <b-col>
                <span> <update-password-form class="mr-auto"></update-password-form> </span
              ></b-col>
            </b-row>
            <b-row v-if="!datastore_credentials_changed">
              <b-col>
                <span>
                  <datastore-credentials></datastore-credentials>
                </span>
              </b-col>
            </b-row>
            <page-title subtitle="Global permissions" no-divider></page-title>
            <b-row class="mb-2" align-h="center">
              <b-col cols="12">
                <h4>
                  <b-badge class="mr-1" v-if="user['is_system']" variant="dark"
                    >System user</b-badge
                  >
                  <b-badge class="mr-1" v-if="readonly && user['is_active']" variant="dark"
                    >Active
                  </b-badge>
                  <b-badge class="mr-1" v-if="readonly && !user['is_active']" variant="secondary">
                    Disabled
                  </b-badge>
                  <b-badge class="mr-1" v-show="readonly && user['is_staff']" variant="dark">
                    Admin
                  </b-badge>
                  <b-badge class="mr-1" v-show="readonly && user['is_superuser']" variant="danger">
                    Superuser
                  </b-badge>
                </h4>
              </b-col>
            </b-row>
            <b-form-row v-show="!readonly" class="mb-2">
              <b-col>
                <b-form-checkbox class="mt-1" switch v-model="user['is_active']"
                  >Active
                </b-form-checkbox>
                <b-form-text>
                  Unselect this instead of deleting accounts.
                  {{ user["full_name"] }} will not be able to login if unchecked.
                </b-form-text>
                <b-form-checkbox class="mt-1" switch v-model="user['is_staff']"
                  >Staff</b-form-checkbox
                >
                <b-form-text>
                  Designates whether {{ user["full_name"] }} can access the admin interface.
                </b-form-text>
                <b-form-checkbox class="mt-1" switch v-model="user['is_superuser']"
                  >Superuser
                </b-form-checkbox>
                <b-form-text>
                  Designates whether {{ user["full_name"] }} has all access and permissions without
                  explicitly assigning them.
                </b-form-text>
              </b-col>
            </b-form-row>
          </b-col>
        </b-row>
        <b-row class="mt-3" align-h="center">
          <b-col lg="9" cols="12">
            <page-title subtitle="Workspace roles" no-divider></page-title>
            <b-row class="mt-3">
              <b-col>
                <role-table
                  :items="user.groups"
                  :remainingItems="remainingGroups"
                  label="workspace"
                  v-on:add-item="addGroup"
                  :readonly="readonly"
                ></role-table>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row v-show="!readonly" class="mt-3" align-h="center">
          <b-col lg="9" cols="12" align="right">
            <b-button variant="primary" size="md" @click="saveUser()" :disabled="loading">
              <fa-icon class="mr-2" icon="user-check"></fa-icon>
              <span v-if="loading">Saving...</span>
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-if="theme_features_include_tcs()" class="mt-3" align-h="center">
      <b-col lg="9" cols="12">
        <page-title subtitle="List of accepted Terms and Conditions" no-divider></page-title>
        <span v-if="accepted_tcs.length == 0">You have not accepted any licences yet.</span>
        <b-list-group>
          <b-list-group-item
            v-for="tcs of accepted_tcs"
            :key="tcs"
            class="d-flex justify-content-between align-items-center py-1"
            ><a :href="tcs" target="_blank">{{ tcs }}</a>
            <b-button
              variant="danger"
              @click="revokeTCS(tcs)"
              v-b-popover.hover.top="'Decline consent for this licence'"
              ><fa-icon icon="trash"/></b-button
          ></b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <b-row class="mt-3" v-if="!user">
      <b-col>
        <empty
          :loading="loading"
          :text="`User ${$route.params['username'] ? $route.params['username'] : ''} not found`"
          icon="user-slash"
        ></empty>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import PageTitle from "@/components/share/PageTitle";
import Empty from "@/components/share/Empty";
import { userService, backendService } from "@/api/asb";
import RoleTable from "@/components/admin/RoleTable";
import UpdatePasswordForm from "@/components/admin/UpdatePasswordForm";
import DatastoreCredentials from "@/components/admin/DatastoreCredentials";
import { mapState } from "vuex";

export default {
  name: "AdministrateUser",
  components: {
    RoleTable,
    Empty,
    PageTitle,
    UpdatePasswordForm,
    DatastoreCredentials
  },
  props: {
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    username: {
      type: String,
      required: false
    }
  },
  methods: {
    theme_features_include_tcs() {
      // Search for any feature ending with "-tcs" (e.g. eoepca-tcs, aiopen-tcs, ...)
      return this.theme_features.find(value => /.*-tcs$/.test(value)) != undefined;
    },
    addGroup(groupIdentifier) {
      if (!this.user.groups.hasOwnProperty(groupIdentifier)) {
        this.$set(this.user.groups, groupIdentifier, []);
      }
    },
    saveUser() {
      this.loading = true;
      userService
        .updateUser(this.user)
        .then(() => {
          this.$notify({
            group: "global",
            type: "success",
            title: `User ${this.user["username"]}  updated`,
            duration: 3000,
            text: ""
          });
        })
        .catch(error => userService.handleError(error))
        .finally(() => (this.loading = false));
    },
    revokeTCS(tcs) {
      userService
        .revokeConsentForTermsAndConditions(tcs)
        .then(() => this.$store.dispatch("FETCH_ACCEPTED_TCS"))
        .catch(error => backendService.handleError(error));
    }
  },
  data() {
    return {
      user: undefined,
      groups: [],
      loading: false
    };
  },
  created() {
    let username = this.username || this.$route.params["username"];
    this.loading = true;
    Promise.all([userService.getUser(username), userService.getGroups()])
      .then(([user, groups]) => {
        this.user = user;
        this.groups = groups;
      })
      .catch(e => userService.handleError(e))
      .finally(() => (this.loading = false));
    if (this.theme_features_include_tcs()) {
      this.$store.dispatch("FETCH_ACCEPTED_TCS");
    }
  },
  computed: {
    remainingGroups() {
      // TODO implement filter based on user.groups
      return this.groups;
    },
    ...mapState({
      datastore_credentials_changed: state => state.datastore_credentials_changed,
      theme_features: state => state.instance.theme_features,
      accepted_tcs: state => state.accepted_tcs
    })
  }
};
</script>

<style lang="scss"></style>
