<template>
  <b-container>
    <b-card>
      <b-form-row v-if="contact.name">
        <b-col class="col-form-label" cols="2">
          <label for="description" class="my-0">
            <fa-icon icon="user" class="mt-1 mr-2 float-left" />Name:</label
          >
        </b-col>
        <b-col cols="10">
          <b-form-input id="description" type="text" plaintext v-model="contact.name" />
        </b-col>
      </b-form-row>
      <b-form-row v-if="contact.organization">
        <b-col class="col-form-label" cols="2">
          <label for="description" class="my-0">
            <fa-icon icon="building" class="mt-1 mr-2 float-left" />Organization:</label
          >
        </b-col>
        <b-col cols="10">
          <b-form-input id="description" type="text" plaintext v-model="contact.organization" />
        </b-col>
      </b-form-row>
      <b-form-row v-if="contact.position">
        <b-col class="col-form-label" cols="2">
          <label for="description" class="my-0">Position:</label>
        </b-col>
        <b-col cols="10">
          <b-form-input id="description" type="text" plaintext v-model="contact.position" />
        </b-col>
      </b-form-row>
      <b-form-row v-if="contact.roles">
        <b-col class="col-form-label" cols="2">
          <label for="description" class="my-0">Roles:</label>
        </b-col>
        <b-col cols="10">
          <b-form-input id="description" type="text" plaintext v-model="contact.roles" />
        </b-col>
      </b-form-row>
      <b-form-row v-if="contact.logo">
        <b-col class="col-form-label" cols="2">
          <label for="description" class="my-0">Logo (TODO: Display on the right side):</label>
        </b-col>
        <b-col cols="10">
          <b-form-input id="description" type="text" plaintext v-model="contact.logo" />
        </b-col>
      </b-form-row>
      <b-form-row v-for="(email, index) in contact.emails" :key="'email' + index">
        <b-col class="col-form-label" cols="2">
          <label for="description" class="my-0" style="text-transform: capitalize;">
            <fa-icon icon="envelope" class="mt-1 mr-2 float-left" />{{
              email.roles.join(", ")
            }}:</label
          >
        </b-col>
        <b-col cols="10">
          <b-form-input id="description" type="text" plaintext v-model="email.value" />
        </b-col>
      </b-form-row>
      <b-form-row v-for="(phone, index) in contact.phones" :key="'phone' + index">
        <b-col class="col-form-label" cols="2">
          <label for="description" class="my-0" style="text-transform: capitalize;">
            <fa-icon icon="phone" class="mt-1 mr-2 float-left" />{{
              phone.roles.join(", ")
            }}:</label
          >
        </b-col>
        <b-col cols="10">
          <b-form-input id="description" type="text" plaintext v-model="phone.value" />
        </b-col>
      </b-form-row>
      <b-form-row v-if="contact.addresses">
        <b-col class="col-form-label" cols="2">
          <label for="description" class="my-0">
            <fa-icon icon="map-marked-alt" class="mt-1 mr-2 float-left" />Addresses:</label
          >
        </b-col>
        <b-col cols="10">
          <b-form-input id="description" type="text" plaintext v-model="contact.addresses" />
        </b-col>
      </b-form-row>
      <b-form-row v-if="contact.links">
        <b-col class="col-form-label" cols="2">
          <label for="description" class="my-0">Links:</label>
        </b-col>
        <b-col cols="10">
          <b-table
            :items="contact.links"
            :fields="info_fields"
            small="small"
            outlined
            table-class="w-auto"
            sort-by="key"
            head-variant="light"
          >
            <template #cell(roles)="data">
              <span class="mr-5">{{ data.value.join(", ") }}</span>
            </template>
            <template #cell(value)="data">
              <span class="mr-5">{{ data.value }}</span>
            </template>
          </b-table>
        </b-col>
      </b-form-row>
      <b-form-row v-if="contact.contactInstructions">
        <b-col class="col-form-label" cols="2">
          <label for="description" class="my-0">Contact instructions:</label>
        </b-col>
        <b-col cols="10">
          <b-form-input
            id="description"
            type="text"
            plaintext
            v-model="contact.contactInstructions"
          />
        </b-col>
      </b-form-row>
    </b-card>
  </b-container>
</template>

<script>
// This card renders contact information encoded according to the "contacts" STAC Extension:
// https://github.com/stac-extensions/contacts
export default {
  name: "ContactCard",
  props: {
    contact: Object
  },
  data() {
    return {
      info_fields: ["roles", "value"]
    };
  }
};
</script>
