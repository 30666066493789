<template>
  <b-container class="mt-4">
    <b-row align="center">
      <b-col>
        <loading :loading="loading"></loading>
      </b-col>
    </b-row>
    <b-modal ref="share" hide-footer id="share" :title="`Share ${process.label}`">
      <b-row align-h="end" no-gutters>
        <b-col cols="12">
          <multiselect
            v-model="workspaces"
            :options="workspaceOptions"
            track-by="identifier"
            label="label"
            placeholder="Select workspaces"
            :multiple="true"
            :taggable="true"
          ></multiselect>
          <h4 class="mt-3"></h4>
        </b-col>
        <b-col cols="3">
          <b-button
            class="btn-block"
            :variant="updateFailed ? 'failed' : 'success'"
            @click="share()"
            :disabled="updating"
          >
            <span v-if="!updating">Share process</span>
            <b-spinner v-else small></b-spinner>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <empty
      v-if="!loading && $_.isEmpty(process)"
      :text="`Something went wrong while fetching process #${id}`"
      icon="exclamation"
    ></empty>
    <b-row v-if="!loading && !$_.isEmpty(process)">
      <b-col cols="12">
        <page-title :title="`${process.ownerId} / ${process.label}`" :subtitle="process.identifier">
          <template v-slot:toolbar>
            <b-btn-toolbar class="float-right">
              <b-btn-group>
                <b-btn
                  v-b-tooltip.hover
                  :title="
                    process.isArchived
                      ? 'Sharing is disabled for archived processes'
                      : 'Share this process'
                  "
                  :disabled="process.isArchived"
                  variant="plain"
                  v-b-modal.share
                >
                  Share this process
                </b-btn>
              </b-btn-group>
            </b-btn-toolbar>
          </template>
        </page-title>
        <b-row v-if="!$_.isEmpty(workspaces)" class="my-3 divider" no-gutters>
          <b-col cols="12">
            <span class="text-secondary">Workspaces</span>
          </b-col>
          <b-col cols="12">
            <workspace-badges :workspaces="process.workspaces"></workspace-badges>
          </b-col>
        </b-row>
        <b-row class="my-4">
          <b-col class="text-center mt-1 mb-3 text-pending" cols="12" v-show="process.isArchived">
            <fa-icon icon="exclamation-triangle" class="mr-2" />
            <span>
              Archived process! Existing versions are read-only and modifications are disabled
            </span>
          </b-col>
          <b-col cols="12">
            <span
              class="description"
              v-if="$_.isEmpty(process.description)"
              :class="{ 'text-disabled': $_.isEmpty(process.description) }"
              >No description</span
            >
            <span class="description" v-else>{{ process.description }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-1" lg="6" cols="12">
            <multiselect
              v-if="versions"
              v-model="version"
              :options="versionOptions"
              group-values="versions"
              group-label="section"
              :placeholder="`Switch versions`"
              track-by="_typeWithId"
              label="label"
              @input="changeVersion"
              ><span slot="noResult">
                No version found.
              </span>
              <template slot="singleLabel" slot-scope="{ option }">
                <strong>
                  {{ option.label }}
                </strong>
                <div class="float-right">
                  <b-badge v-if="option.released" variant="success">
                    Released
                  </b-badge>
                  <b-badge v-else variant="warning">
                    Unreleased
                  </b-badge>
                </div>
              </template>
            </multiselect>
            <b-btn-group class="my-2 mx-0 p-0">
              <b-btn
                class="m-0 p-0"
                variant="link"
                :to="{
                  name: 'processes:versions',
                  query: { f: `process__identifier:${process.identifier}` }
                }"
              >
                <fa-icon class="mr-2" icon="external-link-alt" />
                <span>View all released versions</span>
              </b-btn>
              <b-btn
                class="my-0 ml-2 p-0"
                variant="link"
                :to="{
                  name: 'processes:buckets',
                  query: { f: `process__identifier:${process.identifier}` }
                }"
              >
                <fa-icon class="mr-2" icon="external-link-alt" />
                <span>View all unreleased versions</span>
              </b-btn>
            </b-btn-group>
          </b-col>
          <b-col class="mt-1" lg="6" cols="12" align="right">
            <b-btn-group v-show="!process.isArchived">
              <!-- Hiding this create wrapper button if version creation is pending -->
              <b-btn
                v-show="!confirmCreateBucket"
                variant="outline-success"
                :to="{ name: 'process:wrapper:generator' }"
              >
                New wrapper
              </b-btn>
            </b-btn-group>
            <b-btn-group v-show="!process.isArchived" class="ml-2">
              <b-btn
                v-if="confirmCreateBucket"
                variant="plain"
                @click="confirmCreateBucket = false"
              >
                Cancel
              </b-btn>
              <b-btn
                :variant="confirmCreateBucket ? 'outline-pending' : 'success'"
                @click="confirmCreateBucket ? createBucket() : (confirmCreateBucket = true)"
                :disabled="loading || creatingBucket"
              >
                <span v-if="confirmCreateBucket">
                  Click again to create a new unreleased version
                </span>
                <span v-else-if="creatingBucket"> <b-spinner small></b-spinner></span>
                <span v-else>New version</span>
              </b-btn>
            </b-btn-group>
          </b-col>
          <b-col v-if="processImage" class="mt-4" cols="12">
            <b-card>
              <b-row class="text-center">
                <b-col
                  v-b-tooltip.hover
                  :title="
                    `${processImage.allocatedCpus} ${pluralize('CPU', processImage.allocatedCpus)}`
                  "
                >
                  <fa-icon class="mr-2" icon="microchip" />{{ processImage.allocatedCpus }}
                </b-col>
                <b-col v-b-tooltip.hover :title="`${processImage.allocatedMem} MB of RAM`">
                  <fa-icon class="mr-2" icon="memory" />{{ processImage.allocatedMem }} MB
                </b-col>
                <b-col v-b-tooltip.hover :title="`${processImage.allocatedDisk} MB of disk`">
                  <fa-icon class="mr-2" icon="hdd" />{{ processImage.allocatedDisk }} MB
                </b-col>
                <b-col
                  :class="{ 'text-disabled': processImage.allocatedGpus === 0 }"
                  v-b-tooltip.hover
                  :title="
                    `${processImage.allocatedGpus} ${pluralize('GPU', processImage.allocatedGpus)}`
                  "
                >
                  <fa-icon class="mr-2" icon="microchip" />
                  <span v-if="processImage.allocatedGpus === 0">No GPU</span>
                  <span v-else>
                    {{ processImage.allocatedGpus }}
                    {{ pluralize("GPU", processImage.allocatedGpus) }}
                  </span>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <process-bucket
      v-if="version && version.bucketId"
      class="mt-3"
      :bucket-id="version.bucketId"
      :read-only="version.released || process.isArchived"
      :disable-upload="process.isArchived"
      v-on:build-passed="buildPassed"
      v-on:release-passed="releasePassed"
    ></process-bucket>
    <empty v-else-if="version" class="mt-5" text="No files found"></empty>
  </b-container>
</template>

<script>
import PageTitle from "@/components/share/PageTitle";
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";
import { utils } from "jsonapi-vuex";
import knowledgeService from "@/api/asb/services/knowledge";
import resmgrService from "@/api/asb/services/resmgr";
import Loading from "@/components/share/Loading";
import Empty from "@/components/share/Empty";
import ProcessBucket from "@/components/buckets/ProcessBucket";
import { removeJsonApiMeta, pluralize } from "@/utils";
import WorkspaceBadges from "@/components/share/WorkspaceBadges";

export default {
  name: "ProcessPage",
  components: {
    WorkspaceBadges,
    ProcessBucket,
    Empty,
    PageTitle,
    Multiselect,
    Loading
  },
  props: {},
  watch: {
    versions: function() {
      // FIXME not a big fan of using watch for buckets and versions
      //  Maybe do it in fetchData
      console.debug("Received", this.$_.size(this.versions), "versions", this.versions);
      this.versionOptions[0].versions = Object.values(this.versions).map(version => {
        return this.formatProcessVersionToVersion(version);
      });
    },
    buckets: function() {
      // FIXME not a big fan of using watch for buckets and versions
      console.debug("Received", this.$_.size(this.buckets), "buckets", this.buckets);
      this.versionOptions[1].versions = Object.values(this.buckets).map(bucket => {
        return this.formatBucketToVersion(bucket);
      });
    }
  },
  data: function() {
    return {
      id: this.$route.params["id"],
      workspaces: [],
      workspaceOptions: [],
      loading: true,
      confirmCreateBucket: false,
      creatingBucket: false,
      updating: false,
      updateFailed: false,
      version: undefined,
      versionOptions: [
        {
          section: "Released versions",
          versions: []
        },
        {
          section: "Unreleased versions",
          versions: []
        }
      ]
    };
  },
  created() {
    this.loading = true;
    this.fetchData()
      .catch(error => knowledgeService.handleError(error))
      .finally(() => {
        this.loading = false;
      });
  },
  beforeRouteLeave(to, from, next) {
    console.debug("beforeRouteLeave guard");
    // When building versions, we disconnect from the default websocket endpoint,
    // to support the legacy way of the procimp to get build status through specific ws endpoints.
    // Before leaving the process page, we make sure to reconnect to the default ws endpoint.
    console.debug("Reconnecting to default ws endpoint");
    this.$disconnect();
    this.$connect();
    next();
  },
  methods: {
    pluralize: pluralize,
    async fetchData() {
      // workspaces accessible by the user
      let workspaces = await this.$store.dispatch("knowledge/get", "workspaces");
      workspaces = removeJsonApiMeta(workspaces);
      this.workspaceOptions = Object.values(workspaces);
      await this.$store.dispatch("knowledge/get", `processes/${this.id}`);
      this.workspaces = [];
      if (this.process.workspaces) {
        for (let workspaceId in workspaces) {
          if (workspaceId in this.process.workspaces) {
            this.workspaces.push(workspaces[workspaceId]);
          }
        }
      }
      const params = {
        "filter[process.id]": this.id
      };
      const [versions, buckets] = await Promise.all([
        this.$store.dispatch("knowledge/get", ["process-versions", { params: params }]),
        this.$store.dispatch("knowledge/get", ["process-buckets", { params: params }]),
        this.$store.dispatch("resmgr/get", [
          "process-images",
          { params: { "filter[processIdentifier]": this.process.identifier } }
        ])
      ]);
      console.debug("Async received ", this.$_.size(versions), "versions", versions);
      console.debug("Async received ", this.$_.size(buckets), "buckets", buckets);
      return [versions, buckets];
    },
    share() {
      // updating status is handled manually even though jsonapi-vuex provide
      // a status getter for each action. There is an inconsistent behaviour with this getter
      // see issue https://github.com/mrichar1/jsonapi-vuex/issues/75
      this.updating = true;
      let patchedProcess = utils.deepCopy(this.process);
      patchedProcess["_jv"]["relationships"].workspaces = {
        data: this.workspaces.map(workspace => ({
          id: workspace["_jv"].id,
          type: workspace["_jv"].type
        }))
      };
      let action = this.$store.dispatch("knowledge/patch", patchedProcess);
      action
        .then(() => {
          this.updateFailed = false;
          this.$refs.share.hide();
        })
        .catch(error => {
          this.updateFailed = true;
          knowledgeService.handleError(error);
        })
        .finally(() => (this.updating = false));
    },
    createBucket() {
      this.confirmCreateBucket = false;
      this.creatingBucket = true;
      console.log("Creating bucket");
      console.log("Process associated to bucket", this.process);
      const newBucket = {
        process: this.process,
        _jv: {
          type: "process-buckets",
          relationships: {
            process: utils.normToJsonapi(this.process)
          }
        }
      };
      this.$store
        .dispatch("knowledge/post", [newBucket])
        .then(bucket => {
          this.version = this.formatBucketToVersion(bucket);
          this.$notify({
            group: "global",
            type: "success",
            title: `Unreleased version created`,
            duration: 3000,
            text: this.version.label
          });
        })
        .catch(e => {
          knowledgeService.handleError(e);
        })
        .finally(() => {
          setTimeout(() => (this.creatingBucket = false), 300);
        });
    },
    changeVersion() {
      console.debug("Version changed", this.version);
      if (this.version && this.version.released) {
        console.log("Selected a released version");
      } else {
        console.log("Selected a unreleased version");
      }
    },
    formatBucketToVersion(bucket) {
      return {
        _typeWithId: bucket._jv.type + "-" + bucket._jv.id,
        bucketId: bucket._jv.id,
        versionId: bucket.version._jv ? bucket.version._jv.id : undefined,
        label: bucket.value,
        value: bucket.value,
        released: bucket.released
      };
    },
    formatProcessVersionToVersion(version) {
      return {
        _typeWithId: version._jv.type + "-" + version._jv.id,
        bucketId: this.$_.isEmpty(version.bucket) ? undefined : version.bucket._jv.id,
        versionId: version._jv.id,
        label: version.value,
        value: version.value,
        released: true
      };
    },
    buildPassed() {
      console.debug("Processing BUILD PASSED");
    },
    releasePassed() {
      console.debug("Processing RELEASE PASSED with current version", this.version);
      console.debug("Fetching updated bucket...");
      // get latest images to include the newly released one
      this.$store
        .dispatch("resmgr/get", [
          "process-images",
          { params: { "filter[processIdentifier]": this.process.identifier } }
        ])
        .catch(e => {
          resmgrService.handleError(e);
        });
      // get the released version
      this.$store
        .dispatch("knowledge/get", `process-buckets/${this.version.bucketId}`)
        .then(bucket => {
          console.debug("Received released bucket", bucket);
          this.version = this.formatProcessVersionToVersion(bucket.version);
          this.$notify({
            group: "global",
            type: "success",
            title: `${this.version.label} released`,
            duration: 3000
          });
        })
        .catch(e => {
          knowledgeService.handleError(e);
        });
    }
  },
  computed: {
    process() {
      return this.$store.getters["knowledge/get"](`processes/${this.id}`);
    },
    processImage() {
      if (this.version && this.version.released) {
        // Because (identifier, version) are unique together, the following filter on process images
        // will always return one (or zero) element. Since the version has been released, it is safe
        // to assume that this filter will always return one and only one image.
        let images = this.$store.getters["resmgr/get"](
          "process-images",
          `$[?(@.processIdentifier=="${this.process.identifier}" && @.processVersion=="${this.version.value}")]`
        );
        let imageId = Object.keys(images)[0];
        return images[imageId];
      } else {
        return undefined;
      }
    },
    versions() {
      try {
        return this.$store.getters["knowledge/get"](
          `process-versions`,
          `$[?(@.process._jv.id=="${this.id}" && !@.released && !@.isArchived)]`
        );
      } catch (err) {
        return {};
      }
    },
    buckets() {
      try {
        return this.$store.getters["knowledge/get"](
          `process-buckets`,
          `$[?(@.process._jv.id=="${this.id}" && !@.released && !@.isArchived)]`
        );
      } catch (err) {
        return {};
      }
    },
    ...mapState({
      user: state => state.user,
      instance: state => state.instance
    })
  }
};
</script>

<style lang="scss" scoped>
.description {
  font-size: 15px;
}
</style>
