<template>
  <b-row class="form-group" v-show="visible">
    <b-col>
      <b-form-row>
        <b-col cols="10">
          <b-form-group
            label-cols-lg="2"
            :label="label"
            :readonly="!inp.userEditable"
            :description="
              `The password will be encrypted using 
              ${inp.constraints.enc_method}/${inp.constraints.enc_hash_function}
               before being submitted to the server.
          Encrypted version: ${passwordEnc.substring(0, 75)}...'`
            "
          >
            <div class="input-group-append">
              <b-form-input
                class="form-control"
                :type="inputType"
                v-model="passwordClear"
                @change="encrypt()"
              />
              <b-form-text v-if="inp.doc">{{ inp.doc }}</b-form-text>
              <span class="input-group-text">
                <fa-icon :icon="eyeIcon" aria-hidden="true" @click="showHidePassword()"></fa-icon>
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-button v-if="customSettings.default_value" @click="reset_default" variant="warning"
            >Reset to default
          </b-button>
        </b-col>
      </b-form-row>
    </b-col>
  </b-row>
</template>
<script>
// https://medium.com/@DannyAziz97/rsa-encryption-with-js-python-7e031cbb66bb
// https://www.npmjs.com/package/node-forge
var forge = require("node-forge");
forge.options.usePureJavaScript = true;

/*
Constraints required to encrypt the input string (with example values):
{
  "enc_method": "RSA",
  "enc_hash_function": "SHA-256",
  "enc_public_key": [
    "-----BEGIN PUBLIC KEY-----",
    "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvLZrceau4S2qyp+mf2Q3",
    "9ab8cuhihctorzmbinrW5HKQLUJGgS/Lgp9kzGQ9A2wZQLUCxH44D1QO3KQzbf5g",
    "3UMvE5+U6xwBMvUyP2NF7iNBjK5bxsUZuf68ZXhaiLOWpwc1Thm904mY2PBo+et+",
    "tlyb3xOIot1j4V1+RlVIwa4wVIYyKuFWxpBgy39UeAbvXuSuujtAL6T+2YAdL/mq",
    "MmHHGC6DrPtG29OFld0E3FfVWqhHR1f8ZVkJ/QYS76lXzJ3UHDycA3iWsKulj0b9",
    "UI1mLe3yPFZx3B9Y3hWK7lbLuWEzeCj9366bY5bCn9KjkvmJ85LdTvBPVe7UbvHt",
    "QQIDAQAB",
    "-----END PUBLIC KEY-----"
  ]
}
*/
import InputMixin from "@/components/generic/InputMixin";

export default {
  mixins: [InputMixin],
  name: "EncryptedPassword",
  props: {
    inp: Object
  },
  data() {
    return {
      inputType: "password",
      eyeIcon: "eye-slash",
      passwordClear: "",
      passwordEnc: ""
    };
  },
  created: function() {
    this.inp.constraints.enc_method = this.inp.constraints.enc_method.toUpperCase();
    this.inp.constraints.enc_hash_function = this.inp.constraints.enc_hash_function.toUpperCase();
    this.passwordClear = this.inp.defaultValue;
    this.inp.value = "";
    this.encrypt();
  },
  methods: {
    isDisabled(inp) {
      return this.disableAll || !inp["user_editable"];
    },
    showHidePassword() {
      if (this.inputType == "password") {
        this.inputType = "text";
        this.eyeIcon = "eye";
      } else {
        this.inputType = "password";
        this.eyeIcon = "eye-slash";
      }
    },
    encrypt() {
      console.log("Encrypting the password");

      var method = this.inp.constraints.enc_method;
      if (method == "RSA") {
        method = "RSA-OAEP"; // OAEP is the default if RSA is used
      }
      var hash_function = this.inp.constraints.enc_hash_function;
      if (hash_function == "SHA") {
        hash_function = "SHA-256"; // SHA-256 is the default
      }
      var md = null;
      var mgf1 = null;
      if (hash_function == "SHA-256") {
        md = forge.md.sha256.create();
        mgf1 = forge.mgf1.create();
      }

      // Create a public key using the PEM found in "this.inp.constraints.enc_public_key"
      var pem = this.inp.constraints.enc_public_key.join("\n");
      var publicKey = forge.pki.publicKeyFromPem(pem);
      var password_enc_bin = publicKey.encrypt(this.passwordClear, method, {
        md: md,
        mgf1: mgf1
      });
      this.passwordEnc = forge.util.encode64(password_enc_bin);
      this.inp.defaultValue = this.passwordEnc;
    }
  }
};
</script>

<style scoped></style>
