import { baseURLFromEnv, defaultClientConfig } from "@/api/asb/client";
import axios from "axios";
import { handleServiceError, tokenInterceptor } from "@/api/utils";

export const resourceCatalogueClient = axios.create({
  ...defaultClientConfig,
  "Content-Type": "application/json",
  baseURL: baseURLFromEnv("resource-catalogue/stac/")
});

resourceCatalogueClient.interceptors.request.use(tokenInterceptor);

export default {
  handleError: error => handleServiceError("Backend", error),
  async describeItem(collection_id, model_id) {
    let { data } = await resourceCatalogueClient.get(
      `collections/${collection_id}/items/${model_id}?f=json`
    );
    return data;
  }
};
