<template>
  <h5 class="mt-1">
    <span class="mt-1" v-for="(value, index) in workspacesAndCounters(workspaces)" :key="index">
      <span v-if="index === 0">
        <b-badge
          v-for="workspace of value"
          :key="workspace._jv.id"
          :variant="badgeVariant"
          class="mr-1"
          v-b-tooltip.bottom
          :title="workspace.identifier"
        >
          {{ workspace.label }}
        </b-badge>
      </span>
      <span
        v-else-if="index === 1 && value"
        v-b-tooltip.right
        :title="`In ${value} more ${pluralize('workspace', value)}`"
      >
        <b-badge variant="plain">
          <fa-icon icon="plus-circle" class="mr-1" /><span>{{ value }}</span>
        </b-badge>
      </span>
      <span
        v-else-if="index === 2 && value"
        v-b-tooltip.right
        :title="`In ${value} more private ${pluralize('workspace', value)}`"
      >
        <b-badge variant="plain">
          <fa-icon icon="user-shield" class="mr-1" /><span>{{ value }}</span>
        </b-badge>
      </span>
    </span>
    <span v-show="note && $_.size(workspaces)">
      <b-badge variant="plain" class="note">
        <span>{{ note }}</span>
      </b-badge>
    </span>
  </h5>
</template>

<script>
import { pluralize } from "@/utils";

export default {
  name: "WorkspaceBadges",
  props: {
    workspaces: {
      type: Object,
      required: true
    },
    maxBadges: {
      type: Number,
      required: false,
      default: 50
    },
    badgeVariant: {
      type: String,
      required: false,
      default: "primary"
    },
    note: {
      type: String,
      required: false,
      default: undefined
    }
  },
  methods: {
    pluralize: pluralize,
    workspacesAndCounters(workspaces) {
      console.debug("Filtering only user accessible workspaces", workspaces);
      let accessibleWorkspaces = [];
      let privateCounter = 0;
      let numberOfWorkspaces = this.$_.size(workspaces);
      for (let workspaceId in workspaces) {
        // If the workspace object is empty, it means that the user does not have permissions
        // to access/view it:
        // filtered from the workspaces list and therefore not in the store.
        if (!this.$_.isEmpty(workspaces[workspaceId])) {
          accessibleWorkspaces.push(workspaces[workspaceId]);
        } else {
          privateCounter++;
        }
      }
      let accessibleCounter = numberOfWorkspaces - privateCounter;
      // hidden is (numberOfWorkspaces - private) - shown (which is the max)
      let hiddenCounter =
        accessibleCounter > this.maxBadges ? accessibleCounter - this.maxBadges : 0;
      return [accessibleWorkspaces.slice(0, this.maxBadges), hiddenCounter, privateCounter];
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/default/asb.scss";
.note {
  color: $gray-600;
  font-size: $font-size-base * 0.8;
  font-weight: normal;
}
</style>
