import Vue from "vue";
import Router from "vue-router";
import WorkflowList from "@/pages/workflows/WorkflowList";
import WorkflowPage from "@/pages/workflows/WorkflowPage";
import ExecutionReport from "@/pages/monitoring/ExecutionReport";
import Monitoring from "@/pages/monitoring/Monitoring";
import PageNotFound from "@/pages/PageNotFound";
import ProcessWrapperGenerator from "@/pages/processes/ProcessWrapperGenerator";
import ProcessList from "@/pages/processes/ProcessList";
import ProcessPage from "@/pages/processes/ProcessPage";
import EditUser from "@/pages/admin/users/EditUser";
import UserProfile from "@/pages/UserProfile";
import RepoBrowser from "@/pages/processes/RepoBrowser";
import IndexPage from "@/pages/IndexPage";
import { ADD_MESSAGE, LOGOUT } from "@/store/mutation-types";
import { TOKEN_COOKIE_NAME } from "@/api/utils";
import store from "@/store";
import HomePage from "@/pages/HomePage";
import AdminUsers from "@/pages/admin/users/AdminUsers";
import AdminWorkspaces from "@/pages/admin/users/AdminWorkspaces";
import UserResources from "@/pages/UserResources";
import UserSettings from "@/pages/UserSettings";
import ProcessBucketPage from "@/pages/buckets/ProcessBucketPage";
import WorkflowExecutePage from "@/pages/workflows/WorkflowExecutePage";
import LoginRedirect from "@/pages/LoginRedirect";
import { TOKEN_LOGIN } from "@/store/action-types";
import ScheduleList from "@/pages/schedules/ScheduleList";
import DatabaseAdminPage from "@/pages/admin/DatabaseAdminPage";
import ProcessVersionList from "@/pages/processes/ProcessVersionList";
import ProcessBucketList from "@/pages/processes/ProcessBucketList";
import JobList from "@/pages/jobs/JobList";
import CollectionPage from "@/plugins/mep/pages/CollectionPage";
import RequestCollection from "@/plugins/mep/pages/RequestCollection";
import CollectionList from "@/plugins/mep/pages/CollectionList";
import SecretList from "@/pages/secrets/SecretList";
import CatalogueBrowser from "@/components/products/CatalogueBrowser";
import CatalogueCollectionList from "@/pages/collections/CatalogueCollectionList";
import CollectionDetail from "@/pages/collections/CollectionDetail";
import CWLWorkflowExecutePage from "@/pages/workflows/CWLWorkflowExecutePage";
import ApplicationExecutionList from "@/pages/monitoring/ApplicationExecutionList";
import ApplicationExecutionReport from "@/pages/monitoring/ApplicationExecutionReport";
import ApplicationDetail from "@/pages/collections/ApplicationDetail";
import ModelDetail from "@/pages/collections/ModelDetail";
import ModelExecutePage from "@/pages/collections/ModelExecutePage";
import DatasetDetail from "@/pages/collections/DatasetDetail";
import WorkflowDetail from "@/pages/workflows/WorkflowDetail";
import Marketplace from "@/pages/marketplace/Marketplace.vue";
import AdminAdes from "@/pages/admin/AdesManagement.vue";

import DashboardRoutes from "@/plugins/dashboards/routes.js";

Vue.use(Router);

const collections = [
  {
    path: "/collections/",
    name: "catalogue-collections",
    component: CatalogueCollectionList
  },
  {
    path: "/collections/:id",
    name: "catalogue-collection",
    component: CollectionDetail
  },
  {
    // The ID passed here is for the Catalogue Collection
    // the application is in. The identifier of the application to
    // be executed is passed through the store
    path: "/collections/:id/execute/:app_id",
    name: "catalogue-application:execute",
    component: CWLWorkflowExecutePage
  },
  {
    path: "/collections/:id/detail/:app_id",
    name: "catalogue-application:detail",
    component: ApplicationDetail
  },
  {
    path: "/collections/:id/model_execute/:model_id",
    name: "catalogue-model:execute",
    component: ModelExecutePage
  },
  {
    path: "/collections/:id/model_detail/:model_id",
    name: "catalogue-model:detail",
    component: ModelDetail
  },
  {
    path: "/collections/:id/dataset_detail/:dataset_id",
    name: "catalogue-dataset:detail",
    component: DatasetDetail
  }
];

const mep_collections = [
  {
    path: "/mep/collections/new",
    name: "collection:new",
    component: RequestCollection
  },
  {
    path: "/mep/collections/:id",
    name: "collection",
    component: CollectionPage
  },
  {
    path: "/mep/collections",
    name: "collections",
    component: CollectionList
  }
];

const processors = [
  {
    path: "/workflows",
    name: "workflows",
    component: WorkflowList
  },
  {
    path: "/workflows/:id",
    name: "workflow",
    component: WorkflowPage
  },
  {
    path: "/workflows/:id/execute",
    name: "workflow:execute",
    component: WorkflowExecutePage
  },
  {
    path: "/workflows/:id/detail/:workflow_id",
    name: "workflow:detail",
    component: WorkflowDetail
  },
  {
    path: "/workflows/:id/schedule",
    name: "workflow:schedule",
    component: WorkflowExecutePage,
    props: { showScheduleForm: true }
  }
];

const secrets = [
  {
    path: "/secrets",
    name: "secrets",
    component: SecretList
  }
];

const processes = [
  {
    path: "/processes",
    name: "processes",
    component: ProcessList
  },
  {
    path: "/processes/:id",
    name: "process",
    component: ProcessPage
  },
  {
    path: "/process-versions",
    name: "processes:versions",
    component: ProcessVersionList
  },
  {
    path: "/process-buckets",
    name: "processes:buckets",
    component: ProcessBucketList
  },
  {
    path: "/processes/wrapper/generator",
    name: "process:wrapper:generator",
    component: ProcessWrapperGenerator
  },
  {
    path: "/processes/search/repos",
    name: "process:search:repos",
    component: RepoBrowser
  }
];

// Those pages are no longer in use since the introduction of process versions,
// and will probably be removed at one point.
const buckets = [
  {
    path: "/buckets/:id",
    name: "bucket",
    component: ProcessBucketPage
  }
];

const monitoring = [
  {
    path: "/monitoring",
    name: "monitoring",
    component: Monitoring,
    props: route => ({ page: route.query.page })
  },
  {
    path: "/monitoring/applications",
    name: "monitoring:applications",
    component: ApplicationExecutionList
  },
  {
    path: "/monitoring/applications/:id/report",
    name: "monitoring:application:report",
    component: ApplicationExecutionReport
  },
  {
    path: "/monitoring/models",
    name: "monitoring:models",
    component: ApplicationExecutionList,
    props: { isAModel: true }
  },
  {
    path: "/monitoring/models/:id/report",
    name: "monitoring:model:report",
    component: ApplicationExecutionReport,
    props: { isAModel: true }
  },
  {
    path: "/schedules",
    name: "schedules",
    component: ScheduleList
  },
  {
    path: "/schedules/:id",
    name: "schedule",
    component: WorkflowExecutePage,
    props: { editSchedule: true }
  },
  {
    path: "/executions/:id/report",
    name: "monitoring:execution:report",
    component: ExecutionReport
  },
  {
    path: "/jobs",
    name: "jobs",
    component: JobList
  }
];

const products = [
  {
    path: "/products",
    name: "products",
    component: CatalogueBrowser
  }
];

const marketplace = [
  {
    path: "/marketplace",
    name: "marketplace",
    component: Marketplace
  }
];

const admin = [
  {
    path: "/admin/users/:username",
    name: "admin:user:edit",
    component: EditUser
  },
  {
    alias: "/admin",
    path: "/admin/users",
    name: "admin:users",
    component: AdminUsers
  },
  {
    path: "/admin/workspaces",
    name: "admin:workspaces",
    component: AdminWorkspaces
  },
  {
    path: "/admin/database",
    name: "admin:database",
    component: DatabaseAdminPage
  },
  {
    path: "/admin/ades",
    name: "admin:ades",
    component: AdminAdes
  }
];

const root = [
  // NOTE app login is currently disabled in favor of CAS or OIDC
  // {
  //   path: "/app/login",
  //   name: "app:login",
  //   component: LoginPage
  // },
  {
    path: "/login",
    alias: "/cas/login",
    name: "cas:login",
    component: LoginRedirect
  },
  {
    path: "/oidc/login",
    name: "oidc:login",
    component: LoginRedirect,
    props: { oidcRedirect: true }
  },
  {
    path: "/profile",
    name: "profile",
    component: UserProfile
  },
  {
    path: "/resources",
    name: "resources",
    component: UserResources
  },
  {
    path: "/settings",
    name: "settings",
    component: UserSettings
  },
  {
    path: "/",
    alias: "/index",
    name: "index",
    component: IndexPage,
    beforeEnter: (to, from, next) => {
      // We try to fetch the token in case the user already auth through the User Manager,
      // but not in the webapp.
      store.dispatch(TOKEN_LOGIN, {}).finally(() => {
        if (store.state.loggedIn) {
          next({ name: "home" });
        } else {
          next();
        }
      });
    }
  },
  {
    path: "/home",
    name: "home",
    component: HomePage
  },
  {
    path: "*",
    component: PageNotFound
  }
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    ...root,
    ...processors,
    ...processes,
    ...buckets,
    ...products,
    ...monitoring,
    ...admin,
    ...collections,
    ...mep_collections,
    ...secrets,
    ...marketplace,
    ...DashboardRoutes
  ]
});

const UNRESTRICTED_ROUTES = [
  "index",
  "login",
  "cas:login",
  "oidc:login",
  "marketplace",
  "catalogue-application:detail",
  "catalogue-model:detail",
  "catalogue-dataset:detail",
  "workflow:detail"
];

export const isRestrictedRoute = routeName => !UNRESTRICTED_ROUTES.includes(routeName);

router.beforeEach((to, from, next) => {
  if (!Vue.$cookies.get(TOKEN_COOKIE_NAME)) {
    store.commit(LOGOUT);
  }
  if (isRestrictedRoute(to.name) && !store.state.loggedIn) {
    store.commit(ADD_MESSAGE, {
      content: `You are being redirected to the login page to access ${
        to.name ? to.name : "this"
      } page`,
      type: "warning"
    });
    next({ name: "cas:login", query: { next: to.fullPath } });
  }
  next();
});

export default router;
