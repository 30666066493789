<template>
  <b-container class="component-bar" :class="{ 'expand-top': !navBarVisible }" fluid>
    <b-form-row>
      <b-col class="title" cols="12">
        <span>Add components to<br />{{ dashboardName }} dashboard</span>
      </b-col>
      <b-col cols="12">
        <b-card
          v-for="(component, index) in components"
          :key="index"
          :sub-title="component.name"
          class="mb-2"
          tag="article"
        >
          <b-card-text>
            <span v-html="component.description"></span>
          </b-card-text>

          <b-button
            variant="info"
            size="sm"
            class="no-border-radius"
            @click="addToDashboard(component)"
            >Add to dashboard
          </b-button>
        </b-card>
      </b-col>
    </b-form-row>
  </b-container>
</template>

<script>
import { components } from "@/plugins/dashboards/components/components";
import { mapState } from "vuex";
import { ADD_TO_DASHBOARD } from "@/plugins/dashboards/store/action-types";

export default {
  name: "ComponentBar",
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  data() {
    return {
      components: components,
      navBarVisible: true
    };
  },
  methods: {
    onScroll() {
      this.navBarVisible = document.documentElement.scrollTop < 50;
    },
    addToDashboard(component) {
      this.$store.dispatch(`dashboards/${ADD_TO_DASHBOARD}`, component).then(() => {
        this.$root.$emit("scrollToBottom");
      });
    }
  },
  computed: {
    ...mapState({
      dashboardName: state => state.dashboards.dashboard.verboseName
    })
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/default/style.scss";

.component-bar {
  // position: fixed;
  background: $body-bg;
  top: 0;
  right: 0;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding: 55px 10px 10px 10px;
  margin: 0;
  border-left: 1px solid $gray-100;
  -webkit-box-shadow: -5px 0px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -5px 0px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: -3px 0px 3px 0px rgba(0, 0, 0, 0.1);
}

.title {
  color: $gray-600;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 15px;
  text-align: right;
}

.expand-top {
  padding: 20px 10px 10px 10px;
  transition: all 0.5s ease;
}
</style>
