<template>
  <b-container fluid>
    <b-row>
      <b-col cols="8">
        <l-map ref="productMap" :zoom="zoom" :center="center" style="z-index: 0">
          <l-tile-layer :url="tile_url" :attribution="attribution"></l-tile-layer>
          <l-geo-json
            :geojson="geojson"
            :options="options"
            :options-style="styleFunction"
          ></l-geo-json>
          <div v-for="feature in showFeature" :key="feature.id">
            <l-image-overlay :url="feature.imgUrl" :bounds="feature.geometry" />
          </div>
        </l-map>
      </b-col>
      <b-col cols="4">
        <b-overlay :show="fetching">
          <h4 class="mt-3">Search parameters</h4>
          <hr />
          <b-form>
            <h5>Date range</h5>
            <b-form-row class="mb-3">
              <b-col cols="6">
                <label for="start-datepicker">Start Date</label>
                <b-form-datepicker
                  id="start-datepicker"
                  v-model="query_params.start_date"
                ></b-form-datepicker>
              </b-col>
              <b-col cols="6">
                <label for="end-datepicker">End Date</label>
                <b-form-datepicker
                  id="end-datepicker"
                  v-model="query_params.end_date"
                ></b-form-datepicker>
              </b-col>
            </b-form-row>
            <h5>Area of interest</h5>

            <b-form-row class="mb-3">
              <b-col cols="3" v-for="input in bboxCoords" :key="input.key">
                <b-form-group
                  :label="input.label"
                  :invalid-feedback="coordinateInvalidFeedback(input)"
                >
                  <b-form-input
                    :placeholder="input.placeholder"
                    v-model="input.value"
                    @change="updateBBOX"
                    :state="coordinateInputState(input)"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>
            <h5>Options</h5>
            <b-form-row class="mb-3">
              <b-col cols="5">
                <label for="catalogue_collection">Collection to search</label>
                <b-form-select id="catalogue_collection" v-model="selected_catalogue">
                  <b-form-select-option
                    v-for="collection in catalogue_collections"
                    :key="collection.id"
                    :value="collection[1].identifier"
                  >
                    {{ collection[1].verboseName }}
                  </b-form-select-option>
                </b-form-select>
              </b-col>
              <b-col cols="3">
                <label for="max_results">Max amount of results</label>
                <b-form-spinbutton
                  id="max_results"
                  v-model="query_params.max_results"
                  min="5"
                  max="10"
                  step="5"
                >
                </b-form-spinbutton>
              </b-col>
            </b-form-row>
            <b-button
              variant="primary"
              class="mx-0 mb-3"
              :disabled="!validInputs"
              @click="fetchNewProducts"
              >Search for products
            </b-button>
          </b-form>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="8" class="mt-2">
        <b-overlay :show="fetching">
          <b-table
            ref="productsTable"
            striped
            hover
            outlined
            small
            noCollapse
            :items="geojson"
            :fields="table_fields"
            class="pl-0"
            show-empty
            selectable
            :select-mode="selectMode"
            selected-variant="primary"
            @row-selected="onRowSelected"
          >
            <template #empty="">
              <h4>
                Please select your parameters and click on "Search for products"
              </h4>
            </template>
            <template #cell(geometry)="data">
              {{ data.item.geometry.type }}
            </template>
            <template #cell(properties)="data">
              <b>Datetime:</b> {{ data.item.properties.datetime }} |
              <b>Updated:</b>
              {{ data.item.properties.recordUpdated }}
            </template>
          </b-table>
        </b-overlay>
        <b-row>
          <b-col>
            <p v-if="results_matched">
              Showing results {{ resultStartIndex }}-{{ resultEndIndex }} out of
              {{ results_matched }}
            </p>
            <b-button
              variant="primary"
              @click="fetchPreviousProducts"
              class="mr-2"
              :disabled="!previousProductsAvailable"
            >
              &lt;&lt; Fetch previous
              {{ itemsLeftPrevious }} products
            </b-button>
            <b-button
              variant="primary"
              @click="fetchNextProducts"
              :disabled="!nextProductsAvailable"
              >Fetch next {{ itemsLeftNext }} products &gt;&gt;
            </b-button>
            <b-button
              v-if="basketPage"
              class="float-right"
              variant="primary"
              @click="addToBasket"
              :disabled="selected.length == 0"
              >Add selected products to basket</b-button
            >
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="4" class="mt-2" v-if="selected.length == 1">
        <b-row>
          <b-col>
            <feature-detail :feature="selected[0]"></feature-detail>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" v-if="workflowInput">
            <b-button
              class="float-right"
              variant="primary"
              @click="selectInputForWorkflow"
              :disabled="!productSelected"
              >Select as input</b-button
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from "axios";
import L from "leaflet";
import { LMap, LTileLayer, LGeoJson, LImageOverlay } from "vue2-leaflet";
import "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
// Doc: https://github.com/justinmanley/leaflet-draw-toolbar
import "leaflet-toolbar/dist/leaflet.toolbar.js";
import "leaflet-draw-toolbar/dist/leaflet.draw-toolbar.js";
import "leaflet-toolbar/dist/leaflet.toolbar.css";
import "leaflet-draw-toolbar/dist/leaflet.draw-toolbar.css";
import FeatureDetail from "@/components/products/FeatureDetail.vue";

export default {
  name: "CatalogueBrowser",
  components: { LMap, LTileLayer, LGeoJson, LImageOverlay, FeatureDetail },
  props: {
    // These PROPS are used to determine the environment this component is being used in
    // Either to select a product for a workflow execution or to create a product basket
    // this is relevant for displaying different options in the table of results

    // Indicates if the CatalogueBrowser is being used to create product baskets
    basketPage: {
      type: Boolean,
      default: false
    },
    // Indicates if the CatalogueBrowser is being used to select workflow inputs.
    workflowInput: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tile_url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 4,
      center: [53.554923, 10.219088],

      // COPY PASTED FROM EOEPCA SERVICE. Check uses
      areaOfInterest: null,
      editableLayers: null,
      showFeature: [],
      bboxCoords: {
        left_long: {
          value: "0.000",
          placeholder: "Left Longitude",
          label: "Left Longitude"
        },
        top_lat: {
          value: "0.000",
          placeholder: "Top Latitude",
          label: "Top Latitude"
        },
        right_long: {
          value: "0.000",
          placeholder: "Right Longitude",
          label: "Right Longitude"
        },
        bottom_lat: {
          value: "0.000",
          placeholder: "Bottom Latitude",
          label: "Bottom Latitude"
        }
      },
      query_params: {
        start_date: undefined,
        end_date: undefined,
        max_results: 10,
        startindex: 0
      },
      geojson: null,
      table_fields: ["id", "type", "geometry", "properties"],
      selected: [],
      results_requested: undefined,
      results_matched: undefined,
      results_returned: undefined,
      float_regex: /^-?([0-9]+)\.?([0-9]*)$/,
      fetching: false,
      catalogue_collections: [],
      selected_catalogue: ""
    };
  },
  created() {
    this.$store
      .dispatch("backend/get", "catalogue-collections")
      .then(collections => {
        const collections_without_jv = Object.entries(collections);
        collections_without_jv.pop();
        this.catalogue_collections = collections_without_jv;
        if (this.catalogue_collections.length > 0) {
          this.selected_catalogue = this.catalogue_collections[0][1].identifier;
        }
      })
      .catch(error => {
        this.$notify({
          group: "global",
          type: "error",
          title: "Failed to fetch the available Catalogue collections",
          duration: 3000,
          text: error
        });
      });
  },
  mounted() {
    this.$nextTick(() => {
      // Leaflet map
      const map = this.$refs.productMap.mapObject;

      const editableLayers = new L.FeatureGroup(); // Layer which will contain the BBOX
      map.addLayer(editableLayers);

      // BBox draw toolbar
      var drawControl = new L.Control.Draw({
        position: "topleft",
        draw: {
          // Disable toolbar item by setting it to false
          polygon: false,
          polyline: false,
          circle: false, // Do not allow drawing circles (not supported by the service in the backend)
          rectangle: {
            shapeOptions: {
              color: "#97009c",
              weight: 2
            }
          },
          marker: false,
          circlemarker: false
        },
        edit: {
          featureGroup: editableLayers
        }
      });
      map.addControl(drawControl);

      let _this = this;

      map.on(L.Draw.Event.CREATED, function(e) {
        //event called whenever user draws on map
        var layer = e.layer;

        editableLayers.clearLayers(); // remove previous BBox
        editableLayers.addLayer(layer); // add freshly drawn Bbox
        _this.areaOfInterest = layer.toGeoJSON().geometry.coordinates; // get coords
        // reasign coords in this.bboxCoords
        _this.bboxCoords.left_long.value = _this.areaOfInterest[0][1][0];
        _this.bboxCoords.top_lat.value = _this.areaOfInterest[0][1][1];
        _this.bboxCoords.right_long.value = _this.areaOfInterest[0][3][0];
        _this.bboxCoords.bottom_lat.value = _this.areaOfInterest[0][3][1];
      });

      map.on(L.Draw.Event.EDITED, function(e) {
        //event called whenever user edits on map
        e.layers.eachLayer(function(layer) {
          _this.areaOfInterest = layer.toGeoJSON().geometry.coordinates; // get coords
          // reasign coords in this.bboxCoords
          _this.bboxCoords.left_long.value = _this.areaOfInterest[0][1][0];
          _this.bboxCoords.top_lat.value = _this.areaOfInterest[0][1][1];
          _this.bboxCoords.right_long.value = _this.areaOfInterest[0][3][0];
          _this.bboxCoords.bottom_lat.value = _this.areaOfInterest[0][3][1];
        });
      });

      map.on(L.Draw.Event.DELETED, function() {
        //event called whenever user removes a drawn bbox
        _this.areaOfInterest = null;
        _this.bboxCoords.left_long.value = "0.000";
        _this.bboxCoords.top_lat.value = "0.000";
        _this.bboxCoords.right_long.value = "0.000";
        _this.bboxCoords.bottom_lat.value = "0.000";
      });
      this.editableLayers = editableLayers;
    });
  },
  methods: {
    updateBBOX() {
      // method called whenever user edits bbox coordinates in side fields. Updates editableLayers (visibility of bbox)

      // Only update the BBOX when the coordinate inputs are in a valid state
      for (const [key, input] of Object.entries(this.bboxCoords)) {
        if (!this.isFloat(input.value)) {
          console.log("invalid value for input: " + key);
          return;
        }
      }

      var bounds = [
        [parseFloat(this.bboxCoords.top_lat.value), parseFloat(this.bboxCoords.right_long.value)],
        [parseFloat(this.bboxCoords.bottom_lat.value), parseFloat(this.bboxCoords.left_long.value)]
      ];
      this.editableLayers.clearLayers();
      L.rectangle(bounds, { color: "#97009c", weight: 2 }).addTo(this.editableLayers);
    },
    coordinateInputState(input) {
      return this.isFloat(input.value) ? undefined : false;
    },
    coordinateInvalidFeedback(input) {
      if (this.coordinateInputState(input) == undefined) {
        return "";
      } else {
        return "Please define a valid coordinate value";
      }
    },
    isFloat(value) {
      let match = value.toString().match(this.float_regex);
      return match ? true : false;
    },
    async fetchNewProducts() {
      this.query_params.startindex = 0;
      this.fetchProducts();
    },
    async fetchProducts() {
      this.fetching = true;
      this.results_requested = this.query_params.max_results;
      axios
        .get(this.catalogue_url, {
          params: {
            bbox: this.formatBBOX(),
            datetime: this.formatDatetime(),
            limit: this.query_params.max_results,
            f: "JSON",
            offset: this.query_params.startindex,
            //We don't want applications to be returned.
            type: "dataset"
          }
        })
        .then(response => {
          this.geojson = response.data.features;
          this.results_matched = response.data.numberMatched;
          this.results_returned = response.data.numberReturned;
          this.fetching = false;
        })
        .catch(error => {
          this.$notify({
            group: "global",
            type: "error",
            title: "Failed to reach Catalogue",
            duration: 3000,
            text: error
          });
          this.fetching = false;
        });
    },
    formatBBOX() {
      let bb = this.bboxCoords;
      return `${bb.left_long.value},${bb.top_lat.value},${bb.right_long.value},${bb.bottom_lat.value}`;
    },
    formatDatetime() {
      // 2018-02-12T00:00:00Z/2018-03-18T12:31:12Z
      return `${this.query_params.start_date}T00:00:00Z/${this.query_params.end_date}T00:00:00Z`;
    },
    fetchNextProducts() {
      this.query_params.startindex = this.resultEndIndex;
      this.fetchProducts();
    },
    fetchPreviousProducts() {
      // Make sure the startindex is not negative. The Catalogue server does not like that
      if (this.query_params.max_results >= this.query_params.startindex) {
        this.query_params.startindex = 0;
      } else {
        this.query_params.startindex -= this.query_params.max_results;
      }
      this.fetchProducts();
    },
    selectInputForWorkflow() {
      console.log("input selected in browser");
      this.$emit("inputSelected", this.selected[0].id);
    },
    addToBasket() {
      console.log("Adding to basket not implemented yet!");
    },
    onRowSelected(items) {
      this.selected = items;

      // Zoom to the product on the map
      if (items.length == 1) {
        let south_west = L.latLng(
          items[0].geometry.coordinates[0][0][1],
          items[0].geometry.coordinates[0][0][0]
        );
        items[0].geometry.coordinates[0][0];
        let north_east = L.latLng(
          items[0].geometry.coordinates[0][2][1],
          items[0].geometry.coordinates[0][2][0]
        );
        let bounds = L.latLngBounds(south_west, north_east);
        this.$refs.productMap.mapObject.fitBounds(bounds, { maxZoom: 7 });
      }
    },
    selectFeature(feature) {
      // Deselect the feature when clicked again if there are other selected features
      if (this.selected.includes(feature)) {
        if (this.selected.length > 1) {
          this.selected = this.selected.filter(selectedFeature => selectedFeature != feature);
          for (let i = 0; i < this.geojson.length; i++) {
            if (this.geojson[i].id == feature.id) {
              this.$refs.productsTable.unselectRow(i);
            }
          }
        }
        // Add the feature to the selected features
      } else {
        // If only one feature is allowed to be selected, clear the array of the old selection first
        if (this.selectMode == "single") {
          this.selected = [];
        }

        this.selected.push(feature);
        for (let i = 0; i < this.geojson.length; i++) {
          if (this.geojson[i].id == feature.id) {
            this.$refs.productsTable.selectRow(i);
          }
        }
      }
    },
    redrawMap() {
      this.$refs.productMap.mapObject.invalidateSize();
    }
  },
  computed: {
    options() {
      return {
        onEachFeature: this.onEachFeatureFunction
      };
    },
    onEachFeatureFunction() {
      return (feature, layer) => {
        let _this = this;
        layer.on({
          click: function() {
            _this.selectFeature(feature);
            console.log(layer.getBounds());
            _this.$refs.productMap.mapObject.fitBounds(layer.getBounds(), {
              maxZoom: 7
            });
          }
        });
      };
    },
    styleFunction() {
      const selected = this.selected;
      return feature => {
        if (selected.includes(feature)) {
          return {
            weight: 2,
            color: "#00a4c7",
            opacity: 1,
            fillColor: "#00dbff",
            fillOpacity: 0.3
          };
        } else {
          return {
            weight: 2,
            color: "#008b23",
            opacity: 1,
            fillColor: "#43b957",
            fillOpacity: 0.3
          };
        }
      };
    },
    validInputs() {
      // Check if all inputs have been provided and are valid
      if (this.query_params.start_date == undefined) {
        return false;
      }
      if (this.query_params.end_date == undefined) {
        return false;
      }
      if (this.selected_catalogue == undefined) {
        return false;
      }
      for (const [key, input] of Object.entries(this.bboxCoords)) {
        if (!this.isFloat(input.value)) {
          console.log("invalid value for input: " + key);
          return false;
        }
      }
      return true;
    },
    catalogue_url() {
      return `/resource-catalogue/collections/${this.selected_catalogue}/items`;
    },
    previousProductsAvailable() {
      return this.itemsLeftPrevious > 0;
    },
    nextProductsAvailable() {
      return (
        this.itemsLeftNext > 0 && this.results_matched != undefined && this.results_matched != 0
      );
    },
    resultStartIndex() {
      return this.query_params.startindex + 1;
    },
    resultEndIndex() {
      if (this.query_params.startindex + this.results_requested >= this.results_matched) {
        return this.results_matched;
      }
      return this.query_params.startindex + this.results_requested;
    },
    itemsLeftNext() {
      if (this.resultEndIndex + this.query_params.max_results >= this.results_matched) {
        return this.results_matched - this.resultEndIndex;
      }
      return this.query_params.max_results;
    },
    itemsLeftPrevious() {
      if (this.query_params.startindex - this.query_params.max_results < 0) {
        return this.query_params.startindex;
      }
      return this.query_params.max_results;
    },
    selectMode() {
      if (this.basketPage) {
        return "range";
      }
      if (this.workflowInput) {
        return "single";
      }
      return "single";
    },
    productSelected() {
      return this.selected.length == 0 ? false : true;
    }
  }
};
</script>

<style></style>
