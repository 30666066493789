import { baseURLFromEnv, defaultClientConfig } from "@/api/asb/client";
import axios from "axios";
import { handleServiceError, tokenInterceptor } from "@/api/utils";

export const backendJSONAPIClient = axios.create({
  ...defaultClientConfig,
  baseURL: baseURLFromEnv("backend/v1/"),
  headers: {
    "Content-Type": "application/vnd.api+json"
  }
});

export const backendClient = axios.create({
  ...defaultClientConfig,
  "Content-Type": "application/json",
  baseURL: baseURLFromEnv("backend/v1/")
});

export const catalogueBackendClient = axios.create({
  ...defaultClientConfig,
  "Content-Type": "application/json",
  baseURL: baseURLFromEnv("backend/catalogue/stac/")
});

export const workspaceBackendClient = axios.create({
  ...defaultClientConfig,
  "Content-Type": "application/json",
  baseURL: baseURLFromEnv("backend/workspaces/")
});

backendClient.interceptors.request.use(tokenInterceptor);
catalogueBackendClient.interceptors.request.use(tokenInterceptor);
backendJSONAPIClient.interceptors.request.use(tokenInterceptor);
workspaceBackendClient.interceptors.request.use(tokenInterceptor);

export default {
  handleError: error => handleServiceError("Backend", error),
  async getCollectionApplications(collection_id) {
    let { data } = await backendClient.get(`catalogue-collections/${collection_id}/applications`);
    return data.data;
  },
  async createCWLDownloadLink(collection_id, app_identifier) {
    let post_data = {
      data: {
        type: "catalogue-collection-view-sets",
        attributes: {
          cwl_identifier: app_identifier
        }
      }
    };
    let { data } = await backendJSONAPIClient.post(
      `catalogue-collections/${collection_id}/download_cwl`,
      post_data
    );
    return data.data;
  },
  async deleteApplication(collection_id, app_identifier) {
    let post_data = {
      data: {
        type: "catalogue-collection-view-sets",
        attributes: {
          cwl_identifier: app_identifier
        }
      }
    };
    let { data } = await backendJSONAPIClient.post(
      `catalogue-collections/${collection_id}/delete_app`,
      post_data
    );
    return data;
  },
  async describeApplication(collection_id, app_identifier) {
    let post_data = {
      data: {
        type: "catalogue-collection-view-sets",
        attributes: {
          cwl_identifier: app_identifier
        }
      }
    };
    let { data } = await backendJSONAPIClient.post(
      `catalogue-collections/${collection_id}/describe_application`,
      post_data
    );
    return data;
  },
  async describeItem(collection_id, item_id) {
    let { data } = await catalogueBackendClient.get(
      `collections/${collection_id}/items/${item_id}`
    );
    return data;
  },
  // Trained models
  async describeModelRun(workspace, experiment_id, run_id) {
    let { data } = await workspaceBackendClient.get(
      `${workspace}/experiments/${experiment_id}/runs/${run_id}`
    );
    return data;
  },
  async startModelAsAService(collection_id, model_id) {
    let { data } = await catalogueBackendClient.get(
      `collections/${collection_id}/models/${model_id}/start`
    );
    return data;
  },
  async stopModelAsAService(collection_id, model_id) {
    let { data } = await catalogueBackendClient.get(
      `collections/${collection_id}/models/${model_id}/stop`
    );
    return data;
  },
  async modelAsAServiceStatus(collection_id, model_id) {
    let { data } = await catalogueBackendClient.get(
      `collections/${collection_id}/models/${model_id}/status`
    );
    return data;
  },
  async getModelsAsAServices() {
    let { data } = await catalogueBackendClient.get("models/started");
    return data;
  },
  async getModelServicesUsage() {
    let { data } = await catalogueBackendClient.get("maas/usage");
    return data;
  },
  async getModelExecutionsUsage() {
    let { data } = await catalogueBackendClient.get("models/usage");
    return data;
  },
  // Training datasets
  async orderTrainingDataset(collection_id, dataset_id) {
    let { data } = await catalogueBackendClient.get(
      `collections/${collection_id}/datasets/${dataset_id}/order`
    );
    return data;
  },
  async trainingDatasetOrders(collection_id, dataset_id) {
    let { data } = await catalogueBackendClient.get(
      `collections/${collection_id}/datasets/${dataset_id}/orders`
    );
    return data;
  },
  async getTrainingDatasetsUsage() {
    let { data } = await catalogueBackendClient.get("datasets/usage");
    return data;
  },
  // ---
  async updateApplication(collection_id, app_identifier, data) {
    console.log(data);
    let post_data = {
      data: {
        type: "catalogue-collection-view-sets",
        attributes: {
          cwl_identifier: app_identifier,
          author_affiliation: data.author[0]["s:affiliation"]
            ? data.author[0]["s:affiliation"]
            : "",
          author_email: data.author[0]["s:email"] ? data.author[0]["s:email"] : "",
          author_name: data.author[0]["s:name"] ? data.author[0]["s:name"] : "",
          license_url: data.license ? data.license : "",
          logo_url: data.logo ? data.logo : "",
          release_notes_url: data.releaseNotes ? data.releaseNotes : "",
          keywords: data.keywords ? data.keywords : [],
          description: data.doc ? data.doc : "",
          collection: data.collection,
          date_created: data.dateCreated ? data.dateCreated : "",
          inputs: data.inputs
            ? Object.entries(data.inputs).map(
                e =>
                  `${e[0]}::${e[1].label ? e[1].label : ""}::${e[1].doc ? e[1].doc : ""}::${
                    e[1].default ? e[1].default : ""
                  }`
              )
            : []
        }
      }
    };
    return await backendJSONAPIClient.post(
      `catalogue-collections/${collection_id}/update_application`,
      post_data
    );
  },
  async startApplicationExecution(collection_id, app_identifier, inputs, selectedADES) {
    let new_inputs = [];
    for (let inp in inputs) {
      new_inputs.push({
        identifier: inp,
        value: inputs[inp].value
      });
    }
    console.log(new_inputs);
    let post_data = {
      data: {
        type: "catalogue-collection-view-sets",
        attributes: {
          cwl_identifier: app_identifier,
          inputs: new_inputs,
          ades_id: selectedADES
        }
      }
    };

    let { data } = await backendJSONAPIClient.post(
      `catalogue-collections/${collection_id}/execute`,
      post_data
    );
    return data;
  },
  async startModelExecution(collection_id, model_id, inputsJson, collection) {
    let post_data = {
      data: {
        type: "catalogue-collection-view-sets",
        attributes: {
          identifier: model_id,
          inputs: inputsJson,
          collection: collection
        }
      }
    };

    let { data } = await backendJSONAPIClient.post(
      `catalogue-collections/${collection_id}/execute_model`,
      post_data
    );
    return data;
  },
  async getCollectionsWithWritePermissions() {
    let { data } = await backendJSONAPIClient.get(
      "catalogue-collections/collections_with_write_permissions"
    );
    return data;
  },
  async getPublishedResources(query) {
    return backendJSONAPIClient.get("catalogue-collections/published_resources", {
      params: { query: query }
    });
  },
  async getAvailableAdesInstances() {
    let { data } = await backendJSONAPIClient.get("available-ades");
    return data;
  },
  async registerNewAdesInstance(url) {
    let post_data = {
      ades_url: url
    };
    let { data } = await backendClient.post("available-ades/register", post_data);
    return data;
  },
  async deleteAdesInstance(identifier) {
    let post_data = {
      ades_id: identifier
    };
    let { data } = await backendClient.post("available-ades/unregister", post_data);
    return data;
  },
  async refreshAdesInstance(identifier) {
    let post_data = {
      ades_id: identifier
    };
    let { data } = await backendClient.post("available-ades/refresh", post_data);
    return data;
  },
  async searchApplicationsForUser(query) {
    let { data } = await backendJSONAPIClient.get(
      "catalogue-collections/search_applications_available_to_user",
      {
        params: { query: query }
      }
    );
    return data;
  }
};
