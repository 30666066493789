<template>
  <b-container class="my-4">
    <b-row align="center" v-if="loading">
      <b-col>
        <loading :loading="loading"></loading>
      </b-col>
    </b-row>
    <div v-else>
      <page-title :title="`Details for ${appInfo.label}`" :subtitle="appInfo.doc">
        <template v-slot:badge align-v="center">
          <b-badge variant="secondary" align-v="center" class="ml-2 font-weight-normal"
            ><fa-icon icon="lock" class="mr-2" />Private</b-badge
          >
        </template>
        <template v-slot:toolbar>
          <b-btn-toolbar class="float-right" justify>
            <slot name="title-toolbar" v-bind:loading="loading"></slot>
            <b-btn-group class="ml-2" v-if="ownerOrSuperuser">
              <b-button
                variant="primary"
                class="rounded"
                v-if="published"
                @click="showUnpublishModal()"
              >
                <b-badge variant="success" class="mr-2 font-weight-normal" style="font-size: 0.7rem"
                  ><fa-icon icon="lock-open" class="mr-2" />Published</b-badge
                >
                Unpublish</b-button
              >
              <b-button variant="warning" v-else-if="toBePublished" @click="showUnpublishModal()">
                <b-badge variant="primary" class="mr-2 font-weight-normal" style="font-size: 0.7rem"
                  ><fa-icon icon="eye" class="mr-2" />In Review</b-badge
                >
                Cancel publishing</b-button
              >
              <b-button variant="success" class="rounded" v-else @click="publishApplication()">
                <b-badge variant="success" class="mr-2 font-weight-normal" style="font-size: 0.7rem"
                  ><fa-icon icon="lock" class="mr-2" />Private</b-badge
                >
                Publish to Marketplace</b-button
              >
            </b-btn-group>
            <b-btn-group>
              <b-button
                variant="danger"
                class="ml-2 rounded"
                :to="`/collections/${collection_id}/execute/${application_id}`"
                v-if="Object.keys(user).length != 0"
                >Execute</b-button
              >
            </b-btn-group>
          </b-btn-toolbar>
        </template>
      </page-title>
      <b-row>
        <b-modal
          ref="unpublish-application"
          hide-footer
          id="unpublish-application"
          :title="`Unpublish ${this.appInfo.label}`"
        >
          <b-row align-h="end" no-gutters>
            <b-col cols="12">
              <b-form>
                <b-form-row>
                  <b-col class="col-form-label">
                    <label for="target-collection" class="my-0"
                      >Select a collection to place the application in:</label
                    >
                  </b-col>
                  <b-form-select
                    id="target-collection"
                    v-model="selectedCollection"
                    :options="collectionOptions"
                    v-if="ownerOrSuperuser"
                  >
                  </b-form-select>
                </b-form-row>
                <b-form-row>
                  <b-button variant="primary" class="ml-auto mt-2" @click="confirmUpdate()">
                    Unpublish
                  </b-button>
                </b-form-row>
              </b-form>
            </b-col>
          </b-row>
        </b-modal>
        <b-col cols="10">
          <b-tabs>
            <b-tab title="Inputs/Outputs">
              <b-overlay :show="updating">
                <b-row>
                  <b-col cols="12" class="mt-3">
                    <page-title subtitle="Application Inputs" no-divider></page-title>
                    <ul class="mb-1">
                      <li v-for="(input, key) in appInfo.inputs" :key="key" class="mb-2">
                        <span v-if="!editing">
                          <span v-if="input.label">{{ input.label }}</span>
                          <span v-else>{{ key }}</span>
                          <b-badge variant="primary">{{ input.type }}</b-badge>
                          <ul>
                            <li v-if="input.doc">Description: {{ input.doc }}</li>
                            <li v-if="input.default">Default: {{ input.default }}</li>
                          </ul>
                        </span>
                        <span v-else>
                          {{ key }}
                          <b-badge variant="primary">{{ input.type }}</b-badge>
                          <b-form-row class="my-1">
                            <b-col class="col-form-label" cols="1">
                              <label for="`input-${input}-label`" class="my-0">Label:</label>
                            </b-col>
                            <b-col cols="10">
                              <b-form-input
                                id="`input-${input}-label`"
                                type="text"
                                :plaintext="!editing"
                                v-model="input.label"
                              />
                            </b-col>
                          </b-form-row>
                          <b-form-row class="my-1">
                            <b-col class="col-form-label" cols="1">
                              <label for="`input-${input}-doc`" class="my-0">Description:</label>
                            </b-col>
                            <b-col cols="10">
                              <b-form-input
                                id="`input-${input}-doc`"
                                type="text"
                                :plaintext="!editing"
                                v-model="input.doc"
                              />
                            </b-col>
                          </b-form-row>
                          <b-form-row>
                            <b-col class="col-form-label" cols="1">
                              <label for="`input-${input}-default`" class="my-0">Default:</label>
                            </b-col>
                            <b-col cols="10">
                              <b-form-input
                                id="`input-${input}-default`"
                                type="text"
                                :plaintext="!editing"
                                v-model="input.default"
                              />
                            </b-col>
                          </b-form-row>
                        </span>
                      </li>
                    </ul>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" class="mt-3">
                    <page-title subtitle="Application Outputs" no-divider></page-title>
                    <ul class="mb-1">
                      <li v-for="output in appInfo.outputs" :key="output.label" class="mb-2">
                        <span>{{ output.label }}</span>
                        <span>{{ output.id }}</span>
                        <b-badge variant="primary">{{ output.type }}</b-badge>
                      </li>
                    </ul>
                  </b-col>
                </b-row>
                <b-row v-if="ownerOrSuperuser">
                  <b-col cols="7" class="text-right ml-auto">
                    <b-button
                      v-if="!editing && (!published || user.is_superuser)"
                      variant="primary"
                      @click="enableEdit()"
                      >Edit properties</b-button
                    >
                    <div v-if="editing">
                      <b-button
                        variant="primary"
                        @click="confirmUpdate()"
                        style=""
                        :disabled="updating"
                        class="w-25"
                        ><span v-if="updating"><b-spinner small></b-spinner></span>
                        <span v-else>Save changes</span></b-button
                      >
                      <b-button
                        variant="secondary"
                        @click="cancelUpdate()"
                        :disabled="updating"
                        class="w-25"
                        >Discard changes</b-button
                      >
                    </div>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
            <b-tab title="Properties">
              <b-overlay :show="updating">
                <b-row class="my-3" align-h="center">
                  <b-col>
                    <b-form-row>
                      <b-col class="col-form-label" cols="2">
                        <label for="description" class="my-0">Description:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="description"
                          type="text"
                          :plaintext="!editing"
                          v-model="appInfo.doc"
                        />
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col class="col-form-label" cols="2">
                        <label for="version" class="my-0">Version:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="version"
                          type="text"
                          plaintext
                          v-model="appInfo.softwareVersion"
                        />
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col class="col-form-label" cols="2">
                        <label for="creationDate" class="my-0">Creation date:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-datepicker
                          v-if="editing"
                          id="date-created"
                          v-model="appInfo.dateCreated"
                        ></b-form-datepicker>
                        <b-form-input
                          v-else
                          id="version"
                          type="text"
                          plaintext
                          v-model="appInfo.dateCreated"
                        />
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col class="col-form-label" cols="2">
                        <label for="releaseNotes" class="my-0">Release notes:</label>
                      </b-col>
                      <b-col cols="10">
                        <a
                          v-if="isUrl(appInfo.releaseNotes) && !editing"
                          :href="appInfo.releaseNotes"
                          target="_blank"
                          >{{ appInfo.releaseNotes }}</a
                        >
                        <b-form-input
                          v-else
                          id="releaseNotes"
                          type="text"
                          :plaintext="!editing"
                          v-model="appInfo.releaseNotes"
                        />
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col class="col-form-label" cols="2">
                        <label for="version" class="my-0">Keywords:</label>
                      </b-col>
                      <b-col cols="10">
                        <span v-if="!editing">
                          <b-badge
                            variant="primary"
                            class="ml-1"
                            v-for="keyword in freeKeywords"
                            :key="keyword"
                            >{{ keyword }}</b-badge
                          ></span
                        >
                        <b-form-tags
                          v-if="editing"
                          input-id="free_keywords"
                          separator=",;"
                          placeholder="Enter new keywords separated by comma or semicolon"
                          v-model="freeKeywords"
                          tag-variant="primary"
                          class="mb-2"
                        ></b-form-tags>
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col class="col-form-label" cols="2">
                        <label for="new_keywords" class="my-0">Facets:</label>
                      </b-col>
                      <b-col cols="10">
                        <div
                          v-for="filter_type in resource_filter_types"
                          :key="filter_type.identifier"
                        >
                          <label
                            v-if="editing || typedKeywords[filter_type.identifier]"
                            :for="`keywords_${filter_type.identifier}`"
                            class="my-0"
                            ><b-badge variant="success">{{ filter_type.label }}</b-badge> :</label
                          >
                          <span v-if="!editing">
                            <b-badge
                              variant="primary"
                              class="ml-1"
                              v-for="keyword in typedKeywords[filter_type.identifier]"
                              :key="keyword"
                              >{{ keyword }}</b-badge
                            ></span
                          >

                          <b-form-tags
                            v-if="editing"
                            :input-id="`keywords_${filter_type.identifier}`"
                            separator=",;"
                            placeholder="Enter new keywords separated by comma or semicolon"
                            v-model="typedKeywords[filter_type.identifier]"
                            tag-variant="primary"
                            class="mb-2"
                          ></b-form-tags>
                        </div>
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col class="col-form-label" cols="2">
                        <label for="license_name" class="my-0">Licence name:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="licence_name"
                          type="text"
                          :plaintext="!editing"
                          v-model="licenceName"
                        />
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col class="col-form-label" cols="2">
                        <label for="license" class="my-0">Licence URL:</label>
                      </b-col>
                      <b-col cols="10">
                        <a
                          v-if="isUrl(appInfo.license) && !editing"
                          :href="appInfo.license"
                          target="_blank"
                          >{{ appInfo.license }}</a
                        >
                        <b-form-input
                          v-else
                          id="license"
                          type="text"
                          :plaintext="!editing"
                          v-model="appInfo.license"
                          :state="isUrl(appInfo.license)"
                        />
                        <b-form-invalid-feedback id="input-live-feedback" class="mb-3">
                          You have not specified Terms & Conditions (licence) for this application.
                          Any user will be able to execute this application without restrictions.
                        </b-form-invalid-feedback>
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col class="col-form-label" cols="2">
                        <label for="author_name" class="my-0">Author name:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="author_name"
                          type="text"
                          :plaintext="!editing"
                          v-model="appInfo.author[0]['s:name']"
                        />
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col class="col-form-label" cols="2">
                        <label for="author_affiliation" class="my-0">Author affiliation:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="author_affiliation"
                          type="text"
                          :plaintext="!editing"
                          v-model="appInfo.author[0]['s:affiliation']"
                        />
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col class="col-form-label" cols="2">
                        <label for="author_email" class="my-0">Author email:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="author_email"
                          type="text"
                          :plaintext="!editing"
                          v-model="appInfo.author[0]['s:email']"
                        />
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col class="col-form-label" cols="2">
                        <label for="logo" class="my-0">Logo URL:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="logo"
                          type="text"
                          :plaintext="!editing"
                          v-model="appInfo.logo"
                        />
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col class="col-form-label" cols="2">
                        <label for="collection" class="my-0">Collection:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-select
                          v-model="selectedCollection"
                          :options="collectionOptions"
                          v-if="ownerOrSuperuser && editing"
                        >
                        </b-form-select>
                        <b-form-input
                          v-else
                          plaintext
                          :value="this.collection.verboseName"
                        ></b-form-input>
                      </b-col>
                    </b-form-row>
                  </b-col>
                </b-row>
                <b-row v-if="ownerOrSuperuser">
                  <b-col cols="7" class="text-right ml-auto">
                    <b-button
                      v-if="!editing && (!published || user.is_superuser)"
                      variant="primary"
                      @click="enableEdit()"
                      >Edit properties</b-button
                    >
                    <div v-if="editing">
                      <b-button
                        variant="primary"
                        @click="confirmUpdate()"
                        style=""
                        :disabled="updating"
                        class="w-25"
                        ><span v-if="updating"><b-spinner small></b-spinner></span>
                        <span v-else>Save changes</span></b-button
                      >
                      <b-button
                        variant="secondary"
                        @click="cancelUpdate()"
                        :disabled="updating"
                        class="w-25"
                        >Discard changes</b-button
                      >
                    </div>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
            <b-tab title="CWL" v-if="yaml_cwl !== null">
              <b-overlay :show="updating">
                <highlight-code>
                  {{ yaml_cwl }}
                </highlight-code>
              </b-overlay>
            </b-tab>
          </b-tabs>
        </b-col>
        <b-col cols="2">
          <b-img :src="appInfo.logo" fluid class="mt-3"></b-img>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import backendService from "@/api/asb/services/backend";
import PageTitle from "@/components/share/PageTitle";
import Loading from "@/components/share/Loading";
import { mapState } from "vuex";
import YAML from "json-to-pretty-yaml";
import lodash from "lodash";
import { isUrl } from "@/api/utils";

export default {
  name: "ApplicationDetail",
  components: {
    PageTitle,
    Loading
  },
  data() {
    return {
      application_id: "",
      selectedCollection: "",
      collectionOptions: [],
      loading: true,
      appInfo: {
        author: [
          {
            "s:name": "",
            "s:affiliation": "",
            "s:email": ""
          }
        ]
      },
      editing: false,
      updating: false,
      oldAppInfo: {},
      collection: "",
      freeKeywords: [],
      oldFreeKeywords: [],
      typedKeywords: {},
      oldKeywords: {},
      licenceName: "",
      oldLicenceName: ""
    };
  },
  created() {
    this.application_id = this.$route.params.app_id;
    this.collection_id = this.$route.params.id;
    this.$store.dispatch("backend/get", "resource-filter-types");
    this.getAppInfo();
    this.fetchCollection();
    this.fetchOptions();
  },
  methods: {
    getAppInfo() {
      backendService
        .describeApplication(this.collection_id, this.application_id)
        .then(data => this.setInfo(data.data))
        .catch(error => backendService.handleError(error))
        .finally(() => (this.loading = false));
    },
    enableEdit() {
      this.editing = true;
      this.oldAppInfo = lodash.cloneDeep(this.appInfo); // Create a backup for when the user cancels the update
      this.oldKeywords = lodash.cloneDeep(this.typedKeywords);
      this.oldLicenceName = lodash.cloneDeep(this.licenceName);
    },
    confirmUpdate() {
      this.updating = true;
      this.appInfo = { collection: this.selectedCollection, ...this.appInfo };
      this.appInfo.keywords = this.encodeKeywords();
      backendService
        .updateApplication(this.collection_id, this.application_id, this.appInfo)
        .then(() => {
          this.$notify({
            group: "global",
            type: "success",
            title: `Application info updated`,
            duration: 3000,
            text: ""
          });
          if (this.collection.identifier == this.appInfo.collection) {
            this.getAppInfo();
          } else {
            this.$router.push(`/collections/${this.collection_id}`);
          }
        })
        .catch(error => backendService.handleError(error))
        .finally(() => {
          this.updating = false;
          this.editing = false;
        });
    },
    cancelUpdate() {
      this.editing = false;
      this.appInfo = this.oldAppInfo;
      this.typedKeywords = this.oldKeywords;
      this.licenceName = this.oldLicenceName;
    },
    setInfo(appInfo) {
      if (!Object.keys(appInfo).includes("s:author")) {
        appInfo = {
          author: [{ "s:name": "", "s:affiliation": "", "s:email": "" }],
          ...appInfo
        };
      }

      // Empty the old keyword lists
      this.typedKeywords = {};
      this.freeKeywords = [];

      if (appInfo.keywords) {
        let keyword_list = appInfo.keywords.split(",").map(val => val.trim());
        const regex = /FILTER:([^:]*):(.*)/; // Match any FILTER:<type>:<value> keyword

        for (let keyword of keyword_list) {
          if (regex.test(keyword)) {
            let group = regex.exec(keyword);

            if (group[1] == "licence") {
              this.licenceName = group[2];
            } else {
              this.typedKeywords[group[1]]
                ? this.typedKeywords[group[1]].push(group[2])
                : (this.typedKeywords[group[1]] = [group[2]]);
            }
          } else {
            this.freeKeywords.push(keyword);
          }
        }
      }

      this.appInfo = appInfo;
    },
    fetchCollection() {
      this.$store
        .dispatch("backend/get", `catalogue-collections/${this.collection_id}`)
        .then(data => {
          this.selectedCollection = data.identifier;
          this.collection = data;
        })
        .catch(() => console.log("Authentication needed"));
    },
    fetchOptions() {
      backendService
        .getCollectionsWithWritePermissions()
        .then(data => {
          let options = [];
          data.data.forEach(col =>
            options.push({
              value: col.identifier,
              text: `${col.identifier.split(":")[0]} / ${col.verbose_name}`
            })
          );
          this.collectionOptions = options.sort((a, b) => a.text.localeCompare(b.text));
        })
        .catch(() => console.log("Authentication needed"));
    },
    publishApplication() {
      // The backend will decide whether the app should go to published or topublish
      this.selectedCollection = `${this.collection.identifier.split(":")[0]}:published`;
      this.confirmUpdate();
    },
    showUnpublishModal() {
      console.log("unpublishing!");
      this.$bvModal.show("unpublish-application");
    },
    encodeKeywords() {
      // Convert entered keywords into the form expected in the backend
      // e.g. FILTER:<filter_type>:<filter_value>
      let encoded_typed_keywords = Object.entries(this.typedKeywords)
        .map(val => val[1].map(keyword => `FILTER:${val[0]}:${keyword}`))
        .flat();

      let all_keywords = [...encoded_typed_keywords, ...this.freeKeywords];

      if (this.licenceName !== "") {
        all_keywords.push(`FILTER:licence:${this.licenceName}`);
      }

      return all_keywords;
    },
    isUrl(string) {
      return isUrl(string);
    }
  },
  computed: {
    ...mapState({
      user: state => state.user
    }),
    ownerOrSuperuser() {
      return Object.keys(this.user).length != 0
        ? this.user.is_superuser
          ? true
          : this.user.username === this.collection.ownerId
        : false;
    },
    resource_filter_types() {
      return this.$store.getters["backend/get"]("resource-filter-types");
    },
    yaml_cwl() {
      return this.appInfo.original_cwl ? YAML.stringify(this.appInfo.original_cwl) : null;
    },
    published() {
      return this.collection.identifier ? this.collection.identifier.endsWith(":published") : false;
    },
    toBePublished() {
      return this.collection.identifier ? this.collection.identifier.endsWith(":topublish") : false;
    }
  }
};
</script>

<style></style>
