export default class SSEClient {
  constructor(streamUrl, max_retries_on_failure = 5, format = "JSON") {
    this.url = streamUrl; // URL to connect the EventSource to
    this.externalMessageHandler = undefined; // Handler defined in Vue component
    this.externalErrorHandler = undefined; // Error handler defined in Vue component
    this.messageFormat = format; // allows for parsing the message before sending it to the external handler

    this.eventSource = new EventSource(this.url);
    this.eventSource.onerror = this.errorHandler;
    this.eventSource.onmessage = this.messageHandler;

    this.reconnect_attempts = 0;
    this.max_retries_on_failure = max_retries_on_failure;
  }

  addMessageHandler(handler) {
    this.externalMessageHandler = handler; // Set an external handler to be executed after the internal one
  }

  // Internal message handler
  messageHandler = message => {
    // If this handler is triggered we know the connection is restored
    this.reconnect_attempts = 0;

    // Format the message before sending it to the handler
    if (this.messageFormat === "JSON") {
      message = JSON.parse(message.data);
    }

    // Call the external handler
    if (this.externalMessageHandler) {
      this.externalMessageHandler(message);
    }
  };

  addErrorHandler(handler) {
    this.externalErrorHandler = handler; // Set an external error handler to be executed
  }

  // Automatically retry the connection a given amount of times
  // If connection is not restored, call the external error handler if set
  errorHandler = error => {
    if (this.reconnect_attempts >= this.max_retries_on_failure) {
      if (this.externalErrorHandler) {
        this.externalErrorHandler(error);
      }
    } else {
      this.reconnect_attempts += 1;
      // Wait for 2 seconds before retrying
      setTimeout(() => {
        this.reconnect();
      }, 2000);
    }
  };

  // Create a new eventSource object when the previous one crashes
  reconnect() {
    console.log(`Reconnecting client to ${this.url} (attempt ${this.reconnect_attempts})`);
    this.eventSource = new EventSource(this.url);
    this.eventSource.onmessage = this.messageHandler;
    this.eventSource.onerror = this.errorHandler;
  }

  // Disconnect from the stream
  disconnect() {
    console.log(`Disconnecting from ${this.url}`);
    this.eventSource.close();
  }
}
