<template>
  <b-container class="my-4 px-5" fluid>
    <b-row align="center">
      <b-col>
        <loading :loading="loading"></loading>
      </b-col>
    </b-row>
    <b-modal ref="share" hide-footer id="share" :title="`Share ${workflow.label}`">
      <b-row align-h="end" no-gutters>
        <b-col cols="12">
          <multiselect
            v-model="workspaces"
            :options="workspaceOptions"
            track-by="identifier"
            label="label"
            placeholder="Select workspaces"
            :multiple="true"
            :taggable="true"
          ></multiselect>
          <h4 class="mt-3"></h4>
        </b-col>
        <b-col cols="4">
          <b-button
            class="btn-block"
            :variant="updateFailed ? 'failed' : 'success'"
            @click="share()"
            :disabled="updating"
          >
            <span v-if="!updating">Share workflow</span>
            <b-spinner v-else small></b-spinner>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      ref="publish"
      hide-footer
      id="publish"
      :title="`Publish ${workflow.label} on the Marketplace`"
    >
      <b-row align-h="end" no-gutters>
        <b-col cols="12">
          <b-form-group
            label="Description"
            label-align="right"
            label-class="font-weight-bold"
            label-cols="3"
          >
            <b-form-input v-model="workflowDescription"> </b-form-input>
          </b-form-group>
          <b-form-group
            label="Thumbnail URL"
            label-align="right"
            label-class="font-weight-bold"
            label-cols="3"
          >
            <b-form-input v-model="workflowThumbnailUrl"> </b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-button
            class="btn-block"
            variant="success"
            @click="publishWorkflow()"
            :disabled="updating"
          >
            <span v-if="!updating">Publish workflow</span>
            <b-spinner v-else small></b-spinner>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <empty
      v-if="!loading && $_.isEmpty(workflow)"
      :text="`Something went wrong while fetching workflow #${id}`"
      icon="exclamation"
    ></empty>
    <b-row v-if="!loading && !$_.isEmpty(workflow)">
      <b-col cols="12">
        <page-title
          :title="`${workflow.ownerId} / ${workflow.label}`"
          :subtitle="workflow.identifier"
        >
          <template v-slot:toolbar>
            <b-btn-toolbar class="float-right">
              <b-btn-group
                class="mr-3"
                v-if="ownerOrSuperuser && theme_features.includes('workflow-publishing')"
              >
                <b-button
                  variant="primary"
                  class="rounded"
                  v-if="isPublished"
                  @click="unPublishWorkflow()"
                  :disabled="publishing"
                >
                  <b-badge
                    variant="success"
                    class="mr-2 font-weight-normal"
                    style="font-size: 0.7rem"
                    ><fa-icon icon="lock-open" class="mr-2" />Published</b-badge
                  >
                  Unpublish
                </b-button>
                <b-button
                  variant="success"
                  class="rounded"
                  v-else
                  :disabled="publishing"
                  v-b-modal.publish
                >
                  <b-badge
                    variant="success"
                    class="mr-2 font-weight-normal"
                    style="font-size: 0.7rem"
                    ><fa-icon icon="lock" class="mr-2" />Private</b-badge
                  >
                  Publish to Marketplace</b-button
                >
              </b-btn-group>
              <b-btn-group>
                <b-btn
                  v-b-tooltip.hover
                  :title="
                    workflow.isArchived
                      ? 'Sharing is disabled for archived workflows'
                      : 'Share this workflow'
                  "
                  :disabled="workflow.isArchived"
                  variant="plain"
                  v-b-modal.share
                >
                  Share this workflow
                </b-btn>
              </b-btn-group>
              <b-btn-group class="ml-3" v-show="!workflow.isArchived">
                <a
                  v-b-tooltip.hover
                  title="Open the workflow editor in a new detached page"
                  class="btn btn-plain"
                  :href="`/knowledge/processorEditor/${workflow._jv.id}`"
                  target="_blank"
                >
                  <fa-icon icon="external-link-alt" class="mr-1"></fa-icon>
                  <span>Detached Editor</span>
                </a>
                <b-btn
                  variant="info"
                  :to="`/workflows/${workflow._jv.id}/schedule`"
                  v-if="theme_features.includes('navbar-schedules')"
                >
                  Schedule
                </b-btn>
                <b-btn variant="danger" :to="`/workflows/${workflow._jv.id}/execute`">
                  Execute
                </b-btn>
              </b-btn-group>
            </b-btn-toolbar>
          </template>
        </page-title>

        <b-row v-if="!$_.isEmpty(workspaces)" class="my-3 divider" no-gutters>
          <b-col class="mb-2" cols="12">
            <span class="text-secondary">Workspaces</span>
          </b-col>
          <b-col cols="12">
            <workspace-badges :workspaces="workflow.workspaces"></workspace-badges>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center mt-1 mb-3 text-pending" cols="12" v-show="workflow.isArchived">
            <fa-icon icon="exclamation-triangle" class="mr-2" />
            <span> Archived workflow! Modifications are disabled </span>
          </b-col>
          <b-col cols="12">
            <b-alert variant="warning" show dismissible>
              <span
                >If you are encountering scrolling issues with the editor, use the
                <a :href="`/knowledge/processorEditor/${workflow._jv.id}`" target="_blank">
                  <span>detached version</span>&nbsp;
                  <fa-icon icon="external-link-alt" class="mr-1"></fa-icon>.
                </a>
              </span>
            </b-alert>
          </b-col>
          <b-col class="my-3" cols="12">
            {{ workflow.shortDescription }}
          </b-col>
          <b-col cols="12">
            <load-embed :src="`/knowledge/processorEditor/${workflow._jv.id}`"></load-embed>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import PageTitle from "@/components/share/PageTitle";
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";
import { utils } from "jsonapi-vuex";
import knowledgeService from "@/api/asb/services/knowledge";
import Loading from "@/components/share/Loading";
import Empty from "@/components/share/Empty";
import LoadEmbed from "@/components/share/LoadEmbed";
import { removeJsonApiMeta } from "@/utils";
import WorkspaceBadges from "@/components/share/WorkspaceBadges";

export default {
  name: "WorkflowPage",
  components: {
    WorkspaceBadges,
    Empty,
    PageTitle,
    Multiselect,
    Loading,
    LoadEmbed
  },
  props: {},
  data: function() {
    return {
      id: this.$route.params["id"],
      workspaces: [],
      workspaceOptions: [],
      loading: true,
      updating: false,
      updateFailed: false,
      publishing: false,
      isPublished: false,
      checkingPublication: true,
      workflowDescription: "",
      workflowThumbnailUrl: ""
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.$store.dispatch("knowledge/get", "workspaces").then(data => {
        const workspaces = removeJsonApiMeta(data);
        this.workspaceOptions = Object.values(workspaces);
        this.$store
          .dispatch("knowledge/get", `processors/${this.id}`)
          .then(() => {
            this.workspaces = [];
            if (this.workflow.workspaces) {
              for (let workspaceId in workspaces) {
                if (workspaceId in this.workflow.workspaces) {
                  this.workspaces.push(workspaces[workspaceId]);
                }
              }
            }
            this.checkPublication();
          })
          .catch(error => knowledgeService.handleError(error))
          .finally(() => (this.loading = false));
      });
    },
    share() {
      // updating status is handled manually event though jsonapi-vuex provide
      // a status getter for each action. There is an inconstistent behavious with this getter
      // see issue https://github.com/mrichar1/jsonapi-vuex/issues/75
      this.updating = true;
      let patchedWorkflow = utils.deepCopy(this.workflow);
      patchedWorkflow["_jv"]["relationships"].workspaces = {
        data: this.workspaces.map(workspace => ({
          id: workspace["_jv"].id,
          type: workspace["_jv"].type
        }))
      };
      let action = this.$store.dispatch("knowledge/patch", patchedWorkflow);
      action
        .then(() => {
          this.updateFailed = false;
          this.$refs.share.hide();
        })
        .catch(error => {
          this.updateFailed = true;
          knowledgeService.handleError(error);
        })
        .finally(() => (this.updating = false));
    },
    publishWorkflow() {
      this.publishing = true;
      knowledgeService
        .publishWorkflow(this.id, this.workflowDescription, this.workflowThumbnailUrl)
        .then(() => {
          this.isPublished = true;
          this.publishing = false;
          this.fetchData();
          this.$refs.publish.hide();
        })
        .catch(err => knowledgeService.handleError(err));
    },
    unPublishWorkflow() {
      this.publishing = true;
      knowledgeService
        .unpublishWorkflow(this.id)
        .then(() => {
          this.isPublished = false;
          this.publishing = false;
          this.fetchData();
        })
        .catch(err => knowledgeService.handleError(err));
    },
    checkPublication() {
      knowledgeService.isPublishedWorkflow(this.id).then(data => {
        this.isPublished = data;
      });
    }
  },
  watch: {
    workflow(workflow) {
      if (workflow.description) {
        try {
          let json_description = JSON.parse(workflow.description);
          this.workflowDescription = json_description["description"];
          this.workflowThumbnailUrl = json_description["thumbnail_url"];
        } catch (e) {
          console.log("Failed to parse description");
        }
      }
    }
  },
  computed: {
    workflow() {
      return this.$store.getters["knowledge/get"](`processors/${this.id}`);
    },
    ...mapState({
      user: state => state.user,
      theme: state => state.instance.theme,
      theme_features: state => state.instance.theme_features
    }),
    ownerOrSuperuser() {
      return Object.keys(this.user).length != 0
        ? this.user.is_superuser
          ? true
          : this.user.username === this.collection.ownerId
        : false;
    }
  }
};
</script>

<style lang="scss" scoped></style>
