<template>
  <b-container>
    <b-card>
      <b-row
        class="my-3"
        align-h="center"
        v-for="(concept, index) in theme.concepts"
        :key="'concept' + index"
      >
        <b-col cols="3">
          <label for="description" class="my-0"> {{ conceptPrefix(concept) }}</label>
        </b-col>
        <b-col cols="3">
          <b-link :href="conceptUrl(concept)" target="_blank"
            >{{ concept.name }}
            <fa-icon
              class="ml-1 fa-light"
              v-b-tooltip.hover
              title="Opens in new tab/page"
              icon="external-link-alt"
              size="xs"
            >
            </fa-icon>
          </b-link>
        </b-col>
        <b-col cols="6">{{ concept.description }} </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
// This card renders information encoded according to the "themes" STAC Extension:
// https://github.com/stac-extensions/themes
export default {
  name: "ThemeConceptsCard",
  props: {
    theme: Object
  },
  methods: {
    conceptPrefix(concept) {
      return concept.id.split("::")[0];
    },
    conceptUrl(concept) {
      if (concept.url) {
        return concept.url;
      }
      // https://www.geonames.org + geonames::2976077 => https://www.geonames.org/2976077
      return concept.id.replace(this.conceptPrefix(concept) + "::", this.theme.scheme + "/");
    }
  }
};
</script>
