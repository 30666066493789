export function slugify(str, sep = "_") {
  // Source: https://gist.github.com/mathewbyrne/1280286
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/-,:;";
  var to = "aaaaeeeeiiiioooouuuunc______";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }
  str = str
    .replace(/[^a-z0-9_ ]/g, "") // remove invalid chars
    .replace(/\s+/g, sep); // collapse whitespaces and replace with _
  return str;
}

export function removeJsonApiMeta(response) {
  const data = { ...response, _jv: undefined };
  delete data._jv;
  return data;
}

export function arrayUnique(array) {
  let a = array.concat();
  for (let i = 0; i < a.length; ++i) {
    for (let j = i + 1; j < a.length; ++j) {
      if (a[i].identifier === a[j].identifier) a.splice(j--, 1);
    }
  }
  return a;
}

export function pluralize(word, count = 2) {
  // default of count set to 2 to automatically pluralize.
  // obviously this function should be carefully used as it is a dummy suffixer,
  // which fits current use cases.
  // Nonetheless, this function can be changed to support more cases, for example:
  // https://github.com/blakeembrey/pluralize
  // https://stackoverflow.com/questions/27194359/javascript-pluralize-an-english-string
  if (Math.abs(count) <= 1) {
    return word;
  } else {
    if (word.endsWith("s")) return `${word}es`;
    else return `${word}s`;
  }
}

export function capitalize(value) {
  if (!value) {
    return "";
  }
  return (
    value
      .toString()
      .charAt(0)
      .toUpperCase() + value.slice(1)
  );
}

export function randomAdjective() {
  const adjectives = ["impressive", "beautiful", "elaborate", "extravagant", "striking"];
  return adjectives[Math.floor(Math.random() * adjectives.length)];
}

export function canWriteToClipboard() {
  navigator.permissions.query({ name: "write-on-clipboard" }).then(result => {
    let canWrite = result.state == "granted" || result.state == "prompt";
    console.log("Can write to clipboard:", canWrite);
    return canWrite;
  });
}

export function writeToClipboard(text) {
  console.log("Content to copy to clipboard:", text);
  navigator.clipboard
    .writeText(text)
    .then(() => {
      console.log("Content copied to clipboard");
    })
    .catch(error => {
      console.error("Failed to copy content to clipboard:", error);
    });
}

export const toDatetime = epoch => {
  if (epoch == undefined || epoch == null) {
    return "";
  }
  return new Date(epoch).toLocaleString();
};

// ==================== Generated with AI ===================

// Normally, we can use moment and moment-duration-format
// But we are stuck with Node v14 ...

// For Node.js v14, you can use the built-in util.promisify() function to work with
// durations without relying on external libraries.
// Here's how you can format a duration:

// const { promisify } = require("util");

export function formatDuration(milliseconds) {
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  return {
    days,
    hours: hours % 24,
    minutes: minutes % 60,
    seconds: seconds % 60
  };
}

export function formatDurationStringLong(duration) {
  // Generate long version: 1 hours, 32 minutes, 42 seconds
  const parts = [];
  if (duration.days > 0) parts.push(`${duration.days} days`);
  if (duration.hours > 0) parts.push(`${duration.hours} hours`);
  if (duration.minutes > 0) parts.push(`${duration.minutes} minutes`);
  if (duration.seconds > 0) parts.push(`${duration.seconds} seconds`);
  return parts.join(", ");
}

export function formatDurationStringShort(duration) {
  // Generate short version: 1 days, 1:32:42
  // Minutes and seconds are padded
  const parts = [];
  parts.push(`${duration.days} days `);
  parts.push(`${duration.hours}:`);
  parts.push(`${duration.minutes}:`.padStart(3, "0"));
  parts.push(`${duration.seconds}`.padStart(2, "0"));
  return parts.join("");
}

function formatDurationString(duration) {
  return formatDurationStringShort(duration);
}

// Example usage:
const durationMs = 5 * 60 * 60 * 1000 + 30 * 60 * 1000; // 5 hours and 30 minutes
const formattedDuration = formatDuration(durationMs);
console.log(formatDurationString(formattedDuration));
// Output: 5 hours, 30 minutes

const complexDurationMs = 2 * 24 * 60 * 60 * 1000 + 8 * 60 * 60 * 1000 + 30 * 60 * 1000 + 45 * 1000;
const complexFormattedDuration = formatDuration(complexDurationMs);
console.log(formatDurationString(complexFormattedDuration));
// Output: 2 days, 8 hours, 30 minutes, 45 seconds
