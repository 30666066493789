import { baseURLFromEnv, defaultClientConfig } from "@/api/asb/client";
import axios from "axios";
import { handleServiceError, tokenInterceptor } from "@/api/utils";

export const builderJSONAPIClient = axios.create({
  ...defaultClientConfig,
  baseURL: baseURLFromEnv("builder/v1/"),
  headers: {
    "Content-Type": "application/vnd.api+json"
  }
});

export const builderClient = axios.create({
  ...defaultClientConfig,
  "Content-Type": "application/json",
  baseURL: baseURLFromEnv("builder/v1/")
});

builderClient.interceptors.request.use(tokenInterceptor);
builderJSONAPIClient.interceptors.request.use(tokenInterceptor);

// FIXME this methods might be removed

export default {
  handleError: error => handleServiceError("Service Builder", error),
  async executeProductOrder(productOrderId) {
    let { data } = await builderClient.patch(`executions/${productOrderId}/execute`);
    return data;
  },
  async createLegacySchedule(payload) {
    let { data } = await builderClient.post(`schedules`, payload);
    return data;
  },
  async updateLegacySchedule(payload, scheduleId) {
    let { data } = await builderClient.patch(`schedules/${scheduleId}`, payload);
    return data;
  },
  async getExecutionReportPDF(executionId) {
    let { data } = await builderClient.get(`executions/${executionId}/pdf`);
    return data;
  }
};
