<docs>
  This component is used for different forms and pages of the ASB Framework.
  It supports the legacy style forms to schedule and execute workflow.
  Note that there is a lot of boilerplate code since the execution and schedule format
  (inputs format, variable names, etc.) is different due to design decision.
  Discussion about the redesign of the execution flow is made in ASBFW-261.
</docs>
<template>
  <b-container class="my-4" fluid>
    <empty
      class="mx-5"
      v-if="error || loading"
      :loading="loading"
      :text="(editSchedule ? 'Schedule' : 'Workflow') + ' not found'"
    ></empty>
    <b-row v-else>
      <b-col class="mx-5">
        <page-title :title="pageTitle" :subtitle="pageSubTitle">
          <template slot="toolbar">
            <b-form-checkbox
              switch
              v-model="enableSchedule"
              style="z-index: 0"
              v-if="theme_features.includes('navbar-schedules')"
              >Schedule</b-form-checkbox
            >
          </template>
        </page-title>
        <b-tabs>
          <b-tab title="Execute">
            <b-row align-h="center">
              <b-col cols="12">
                <h4 class="my-4">Inputs</h4>
              </b-col>
              <b-col class="mt-2" cols="12" v-for="(node, nodeId) in inputs" v-bind:key="nodeId">
                <div v-for="(inp, inpId) in node.unconnectedInputs" v-bind:key="inpId">
                  <component
                    :is="loadComponentName(inp.dataType.label)"
                    :inp="inp"
                    :customSettings="node.customInputsSettings[inpId]"
                    :nodeId="nodeId"
                    :inp_id="inpId"
                    v-on:input_change="(...args) => update_input(args)"
                  >
                  </component>
                </div>
              </b-col>
            </b-row>
            <b-row v-if="enableSchedule" class="mt-4">
              <b-col cols="12">
                <h4>Schedule Configuration</h4>
              </b-col>
              <b-col cols="12">
                <b-form-group label-cols-lg="3" label="Title">
                  <b-form-input v-model="schedule.title"></b-form-input>
                </b-form-group>
                <b-form-group label-cols-lg="3" label="Status">
                  <b-form-select
                    v-model="schedule.status"
                    :options="scheduleOptions.status"
                  ></b-form-select>
                </b-form-group>
                <b-form-group label-cols-lg="3" label="Strategy">
                  <b-form-select
                    v-model="schedule.strategy"
                    :options="scheduleOptions.strategy"
                    @input="changeStrategy"
                  ></b-form-select>
                </b-form-group>
                <b-form-row
                  v-show="
                    schedule.strategy !== 'external-trigger' && schedule.strategy !== 'on-new-data'
                  "
                >
                  <b-col lg="3">
                    <span> Date </span>
                  </b-col>
                  <b-col>
                    <date-range-picker
                      ref="picker"
                      class="btn-block"
                      opens="center"
                      :locale-data="{ firstDay: 1, format: dateFormat }"
                      time-picker24-Hour
                      min-Date="2019-12-18 00:00"
                      :single-date-picker="schedule.strategy === 'single-execution'"
                      time-picker
                      @update="changeDateRange"
                      v-model="schedule.dateRange"
                    >
                      <template slot="input" slot-scope="picker">
                        <span v-show="schedule.strategy !== 'crontab-execution'">
                          {{ picker.startDate | moment(dateFormat) }}
                        </span>
                        <span v-show="schedule.strategy === 'interval-execution'">
                          - {{ picker.endDate | moment(dateFormat) }}
                        </span>
                        <span v-show="schedule.strategy === 'crontab-execution'">
                          Between
                          {{ picker.startDate | moment("YYYY-MM-DD") }} and
                          {{ picker.endDate | moment("YYYY-MM-DD") }} at
                          {{ picker.startDate | moment("HH:mm") }}
                        </span>
                      </template>
                    </date-range-picker>
                  </b-col>
                </b-form-row>

                <b-form-group
                  v-show="schedule.strategy === 'interval-execution'"
                  class="mt-3"
                  label-cols-lg="3"
                  label="Interval"
                >
                  <b-form-row>
                    <b-col cols="6">
                      <b-form-group description="Interval below 10 minutes are not allowed">
                        <b-form-input type="number" v-model="schedule.interval"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-select
                        v-model="schedule.intervalPeriod"
                        :options="scheduleOptions.intervalPeriod"
                      ></b-form-select>
                    </b-col>
                  </b-form-row>
                </b-form-group>
                <b-form-group
                  v-show="schedule.strategy === 'crontab-execution'"
                  class="mt-3"
                  label-cols-lg="3"
                  label="Days in Month"
                >
                  <b-form-input v-model="schedule.monthDays"></b-form-input>
                </b-form-group>
                <b-form-group
                  v-show="schedule.strategy === 'external-trigger'"
                  class="mt-3"
                  label-cols-lg="3"
                  label="Trigger Identifier"
                  description="Use this identifier to execute the configured workflow via the API"
                >
                  <b-form-input
                    v-if="schedule.identifier"
                    v-model="schedule.identifier"
                    disabled
                  ></b-form-input>
                  <span v-else><i>The trigger identifier will be available after creation</i></span>
                </b-form-group>
                <b-form-group
                  v-show="schedule.strategy === 'on-new-data'"
                  class="mt-3"
                  label-cols-lg="3"
                  label="Trigger on new data from"
                  description="Data fetching URL"
                >
                  <b-form-input v-model="schedule.dataUrl"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-row>
                  <b-col>
                    <b-alert :show="!execution_allowed" variant="warning" class="text-center">
                      You are not allowed to execute this workflow. Please check your credit score
                      on the Marketplace.
                    </b-alert>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="mt-2" align="right">
                    <b-button
                      class="execute-button"
                      @click="enableSchedule ? createOrUpdateSchedule() : execute()"
                      type="submit"
                      :variant="submitVariant"
                      :disabled="executing || !execution_allowed || !all_inputs_valid"
                    >
                      <fa-icon class="mt-1 float-left" :icon="enableSchedule ? 'clock' : 'bolt'" />
                      <span v-if="executing">Sending request...</span>
                      <span v-else>
                        <span v-if="enableSchedule">
                          <span v-if="editSchedule">Update&nbsp;</span><span>Schedule</span>
                        </span>
                        <span v-else>Execute</span>
                      </span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab
            v-if="this.theme_features.includes('workflow-page-search-products')"
            title="Search products"
          >
            <search-products></search-products>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { builderService, knowledgeService, userService } from "@/api/asb";
import Empty from "@/components/share/Empty";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import PageTitle from "@/components/share/PageTitle";
import DateRangePicker from "vue2-daterange-picker";
import { mapState } from "vuex";
import moment from "moment";
import SearchProducts from "@/plugins/mep/components/SearchProducts";

import BoundingBox from "@/components/share/InputFields/BoundingBox";
import CatalogueProduct from "@/components/share/InputFields/CatalogueProduct";
import CommonFormatPicture from "@/components/share/InputFields/CommonFormatPicture";
import CsvString from "@/components/share/InputFields/CsvString";
import DateRangeList from "@/components/share/InputFields/DateRangeList";
import DateRange from "@/components/share/InputFields/DateRange";
import Date from "@/components/share/InputFields/Date";
import Default from "@/components/share/InputFields/Default";
import EncryptedPassword from "@/components/share/InputFields/EncryptedPassword";
import InputColor from "@/components/share/InputFields/InputColor";
import InputImage from "@/components/share/InputFields/InputImage";
import InputText from "@/components/share/InputFields/InputText";
import Latitude from "@/components/share/InputFields/Latitude";
import Longitude from "@/components/share/InputFields/Longitude";
import ObjectType from "@/components/share/InputFields/ObjectType";
import SensingTimeStartType from "@/components/share/InputFields/SensingTimeStartType";
import SensingTimeStopType from "@/components/share/InputFields/SensingTimeStopType";
import UserInteger from "@/components/share/InputFields/UserInteger";
import UserLabel from "@/components/share/InputFields/UserLabel";
import UserString from "@/components/share/InputFields/UserString";
import WktString from "@/components/share/InputFields/WktString";
import XmlText from "@/components/share/InputFields/XmlText";

export default {
  name: "ExecuteFormPage",
  components: {
    SearchProducts,
    PageTitle,
    Empty,
    BoundingBox,
    CatalogueProduct,
    CommonFormatPicture,
    CsvString,
    DateRangeList,
    DateRange,
    DateRangePicker,
    Date,
    Default,
    EncryptedPassword,
    InputColor,
    InputImage,
    InputText,
    Latitude,
    Longitude,
    ObjectType,
    SensingTimeStartType,
    SensingTimeStopType,
    UserInteger,
    UserLabel,
    UserString,
    WktString,
    XmlText
  },
  props: {
    showScheduleForm: {
      type: Boolean,
      default: false
    },
    editSchedule: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      enableSchedule: this.showScheduleForm || this.editSchedule,
      loading: false,
      executing: false,
      error: false,
      updateError: false,
      workflowId: this.editSchedule ? undefined : this.$route.params.id,
      scheduleId: !this.editSchedule ? undefined : this.$route.params.id,
      execution_allowed: undefined,
      workflow: {
        data: {},
        inputs: [],
        input_values: {}
      },
      all_inputs_valid: false,
      // Input fields to include in the execution payload
      // case cases included for JSONAPI compatibility.
      inputExecPayloadFields: [
        ["identifier", "identifier"],
        ["label", "label"],
        ["description", "description"],
        // ["default_value", "defaultValue"],
        ["user_visible", "userVisible"],
        ["user_editable", "userEditable"],
        ["data_type", "dataType"]
      ],
      nodeProcessLabels: {},
      // Schedule data
      dateFormat: "YYYY-MM-DD HH:mm",
      scheduleOptions: {
        status: [
          { value: "enabled", text: "Enabled" },
          { value: "disabled", text: "Disabled" }
        ],
        strategy: [
          { value: "single-execution", text: "Single execution" },
          {
            value: "interval-execution",
            text: "Fixed interval within date range"
          },
          {
            value: "crontab-execution",
            text: "Fixed month days within date range"
          },
          ...(this.theme == "eopen"
            ? [
                {
                  value: "external-trigger",
                  text: "External trigger (no automatic execution)"
                },
                { value: "on-new-data", text: "Products availability" }
              ]
            : [])
        ],
        intervalPeriod: [
          { value: "hours", text: "Hours" },
          { value: "days", text: "Days" },
          { value: "minutes", text: "Minutes" }
        ]
      },
      schedule: {
        identifier: undefined,
        title: undefined,
        status: "enabled",
        strategy: "single-execution",
        dateRange: { startDate: moment(), endDate: moment() },
        interval: 1,
        intervalPeriod: "days",
        monthDays: "2,4,6",
        dataUrl: undefined
      }
    };
  },
  created() {
    this.createComponentNameList();
    const fetchFunc = this.editSchedule ? this._fetchSchedule : this._fetchWorkflow;
    fetchFunc();
    userService
      .checkExecutionAllowed()
      .then(data => {
        this.execution_allowed = data.data.execution_allowed;
      })
      .catch(() => {
        this.execution_allowed = true;
      });
  },
  methods: {
    createComponentNameList() {
      let componentNameList = [];
      for (let c in this.$options.components) {
        componentNameList.push(this.$_.kebabCase(c));
      }
      this.componentNameList = componentNameList;
      return;
    },
    loadComponentName(cName) {
      let cNameKebab = this.$_.kebabCase(cName);
      return this.componentNameList.indexOf(cNameKebab) > -1 ? cNameKebab : "input-text";
    },
    _fetchSchedule() {
      this.loading = true;
      this.fetchScheduleData()
        .then(schedule => {
          console.warn(schedule);
          this.schedule.identifier = schedule.identifier;
          this.schedule.workflowId = schedule.processorId;
          this.schedule.inputs = schedule.inputs;
          this.schedule.title = schedule.title;
          this.schedule.status = schedule.status;
          this.schedule.strategy = schedule.strategy;
          this.schedule.dateRange = {
            startDate: schedule.startTime,
            endDate: schedule.endTime
          };
          this.schedule.interval = schedule.interval;
          this.schedule.intervalPeriod = schedule.intervalPeriod;
          this.schedule.monthDays = schedule.dayOfMonth;
          this.schedule.dataUrl = schedule.dataUrl;
          knowledgeService.getWorkflowId(this.schedule.workflowId).then(id => {
            this.workflowId = id;
            this.fetchWorkflowData().then(workflow => {
              this.workflow = workflow;
            });
          });
        })
        .catch(error => {
          builderService.handleError(error);
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    _fetchWorkflow() {
      this.loading = true;
      this.fetchWorkflowData()
        .then(workflow => {
          this.workflow = workflow;
          this.schedule.title = `${this.workflow.data.label} Schedule`;
          this.workflow.title = `${this.workflow.data.label} Workflow`;
        })
        .catch(error => {
          knowledgeService.handleError(error);
          this.error = true;
        })
        .finally(() => (this.loading = false));
    },
    async fetchWorkflowData() {
      let workflow = {
        data: {},
        inputs: [],
        input_values: {}
      };
      this.$store.dispatch("knowledge/get", `data-types`);
      const dataPromise = this.$store.dispatch("knowledge/get", `processors/${this.workflowId}`);
      const inputsPromise = this.$store.dispatch(
        "knowledge/get",
        `workflows/${this.workflowId}/nodes`
      );

      let [data, inputs] = await Promise.all([dataPromise, inputsPromise]);

      workflow.data = data;
      workflow.inputs = inputs;
      return workflow;
    },
    async fetchScheduleData() {
      this.$store.dispatch("knowledge/get", `data-types`);
      return this.$store.dispatch("builder/get", `schedules/${this.scheduleId}`);
    },
    buildExecutionPayload() {
      let payload = {
        processor_id: this.workflow.data.identifier,
        processor_version: this.workflow.data.version,
        inputs: []
      };
      for (var n_id in this.inputs) {
        if (n_id.match(/[0-9]+/)) {
          let nodePayload = {
            items: [],
            node_id: parseInt(n_id)
          };
          for (var id in this.inputs[n_id].unconnectedInputs) {
            let fields = {};
            for (let attr of this.inputExecPayloadFields) {
              fields[attr[0]] = this.inputs[n_id].unconnectedInputs[id][attr[1]];
              if (fields[attr[0]].constructor == Object && "_jv" in fields[attr[0]])
                delete fields[attr[0]]["_jv"];
            }

            if (this.inputs[n_id].customInputsSettings[id]) {
              if ("label" in this.inputs[n_id].customInputsSettings[id]) {
                fields["label"] = this.inputs[n_id].customInputsSettings[id]["label"];
              }
              if ("user_visible" in this.inputs[n_id].customInputsSettings[id]) {
                fields["user_visible"] = this.inputs[n_id].customInputsSettings[id]["user_visible"];
              }
            }

            fields["value"] = this.workflow.input_values[n_id]
              ? this.workflow.input_values[n_id][id]
                ? this.workflow.input_values[n_id][id][0]
                : ""
              : "";
            nodePayload.items.push(fields);
          }
          payload.inputs.push(nodePayload);
        }
      }
      return payload;
    },
    buildScheduleInputsPayload() {
      let payload = {
        tasks: [],
        workflow: {
          identifier: this.workflow.data.identifier
        }
      };
      // Ugly copy-paste because schedule and execution use node and task for the same thing...
      // Also items => inputs...
      for (var n_id in this.inputs) {
        if (n_id.match(/[0-9]+/)) {
          let nodePayload = {
            inputs: [],
            task_id: parseInt(n_id)
          };
          for (var id in this.inputs[n_id].unconnectedInputs) {
            let fields = {};
            for (let attr of this.inputExecPayloadFields) {
              fields[attr[0]] = this.inputs[n_id].unconnectedInputs[id][attr[1]];
              if (fields[attr[0]].constructor == Object && "_jv" in fields[attr[0]])
                delete fields[attr[0]]["_jv"];
            }

            fields["value"] = this.workflow.input_values[n_id]
              ? this.workflow.input_values[n_id][id][0]
              : "";
            nodePayload.inputs.push(fields);
          }
          payload.tasks.push(nodePayload);
        }
      }
      return payload;
    },
    execute() {
      this.executing = true;
      const newExecution = {
        ...this.buildExecutionPayload(),
        _jv: {
          type: "executions"
        }
      };
      console.debug("newExecution", newExecution);
      this.$store
        .dispatch("builder/post", [newExecution])
        .then(execution => {
          this.$notify({
            group: "global",
            type: "success",
            title: `${execution.identifier} successfully created`,
            duration: 3000,
            text: ""
          });
          // fire execution, before redirection to monitoring
          builderService
            .executeProductOrder(execution._jv.id)
            .catch(error => builderService.handleError(error));
          this.$router.push("/monitoring");
        })
        .catch(error => builderService.handleError(error))
        .finally(() => {
          // Wait a bit before reactivating the exec button, to avoid spam
          setTimeout(() => (this.executing = false), 600);
        });
    },
    createOrUpdateSchedule() {
      this.updateError = false;
      if (this.editSchedule) {
        this.updateSchedule();
      } else {
        this.createSchedule();
      }
    },
    updateSchedule() {
      let payload = {
        strategy: this.schedule.strategy,
        status: this.schedule.status,
        title: this.schedule.title,
        interval_period: this.schedule.intervalPeriod,
        interval: this.schedule.interval,
        day_of_month: this.schedule.monthDays,
        start_time: this.schedule.dateRange.startDate,
        end_time: this.schedule.dateRange.endDate,
        inputs: this.buildScheduleInputsPayload(),
        hour: this.schedule.dateRange.startDate.hour,
        minute: this.schedule.dateRange.startDate.minute,
        data_url: this.schedule.dataUrl
      };
      this.executing = true;
      builderService
        .updateLegacySchedule(payload, this.scheduleId)
        .then(schedule => {
          this.$notify({
            group: "global",
            type: "success",
            title: `${schedule.data.attributes.identifier} successfully updated`,
            duration: 3000,
            text: ""
          });
        })
        .catch(error => {
          builderService.handleError(error);
          this.updateError = true;
        })
        .finally(() => (this.executing = false));
    },
    createSchedule() {
      let payload = {
        processor_id: this.workflow.data.identifier,
        processor_version: this.workflow.data.version,
        strategy: this.schedule.strategy,
        status: this.schedule.status,
        title: this.schedule.title,
        interval_period: this.schedule.intervalPeriod,
        interval: this.schedule.interval,
        day_of_month: this.schedule.monthDays,
        start_time: this.schedule.dateRange.startDate,
        end_time: this.schedule.dateRange.endDate,
        inputs: this.buildScheduleInputsPayload(),
        hour: this.schedule.dateRange.startDate.hour,
        minute: this.schedule.dateRange.startDate.minute,
        data_url: this.schedule.dataUrl
      };
      this.executing = true;
      builderService
        .createLegacySchedule(payload)
        .then(schedule => {
          this.$notify({
            group: "global",
            type: "success",
            title: `${schedule.data.attributes.identifier} successfully created`,
            duration: 3000,
            text: ""
          });
          this.$router.push("/schedules");
        })
        .catch(error => {
          builderService.handleError(error);
          this.updateError = true;
        })
        .finally(() => (this.executing = false));
    },
    getNodeProcessLabel(nodeId) {
      knowledgeService
        .getNodeProcess(nodeId)
        .then(process => (this.nodeProcessLabels[nodeId] = process["label"]))
        .catch(e => {
          knowledgeService.handleError(e);
          this.nodeProcessLabels[nodeId] = nodeId;
        })
        .finally(() => this.$forceUpdate());
    },
    changeStrategy(value) {
      this.schedule.strategy = value;
      console.debug(`New stategy ${this.schedule.strategy}`);
    },
    changeDateRange() {
      console.debug(
        "New date range: ",
        this.schedule.dateRange.startDate,
        this.schedule.dateRange.endDate
      );
    },
    // Handler for when an input is updated
    update_input(args) {
      const [node_id, inp, value, valid] = args;
      // Initialize the object if it did not exist yet
      if (!this.workflow.input_values[node_id]) {
        this.workflow.input_values[node_id] = {};
      }
      // Insert the updated value for the node + input combination
      this.workflow.input_values[node_id][inp._jv.id] = [value, valid];
      this.updateValidInputState();
    },
    updateValidInputState() {
      this.all_inputs_valid = Object.values(this.workflow.input_values)
        .map(node =>
          Object.values(node)
            .map(input_valid => input_valid[1])
            .every(valid => valid)
        )
        .every(valid => valid);
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
      theme: state => state.instance.theme,
      theme_features: state => state.instance.theme_features
    }),
    inputs() {
      if (this.editSchedule && this.schedule.inputs) {
        if (this.schedule.inputs.constructor == Object) {
          return this.workflow.inputs;
        } else {
          console.warn("No inputs found in schedule");
          return {};
        }
      } else {
        if (this.workflow.inputs.constructor == Object) {
          return this.workflow.inputs;
        } else {
          console.warn("No inputs found in workflow");
          return {};
        }
      }
    },
    pageTitle() {
      if (this.editSchedule) {
        return `Schedule ${this.schedule.identifier}`;
      } else {
        return `${this.enableSchedule ? "Schedule" : "Execute"} Workflow`;
      }
    },
    pageSubTitle() {
      if (!this.editSchedule) {
        return this.workflow.data.label;
      }
      return "";
    },
    submitVariant() {
      if (this.updateError) {
        return "danger";
      } else {
        return this.enableSchedule ? "warning" : "generated";
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/default/asb.scss";

.execute-button {
  width: $font-size-base * 12;
}
</style>
