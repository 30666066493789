<template>
  <span v-bind:class="{ toggleable: toggleable }">
    <fa-icon class="true" v-if="value" icon="check-circle"></fa-icon>
    <fa-icon class="false" v-else icon="times-circle"></fa-icon>
  </span>
</template>

<script>
export default {
  name: "BooleanIcon",
  props: {
    value: Boolean,
    toggleable: {
      type: Boolean
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/default/asb.scss";

.false {
  color: gray("600");
}

.true {
  color: theme-color("success");
}

.toggleable {
  cursor: pointer;
}
</style>
