<template>
  <b-container class="mt-4">
    <empty text="This page does not exist"></empty>
  </b-container>
</template>

<script>
import Empty from "@/components/share/Empty";
export default {
  name: "PageNotFound",
  components: { Empty }
};
</script>

<style scoped></style>
