import { asbGenericClient, baseURLFromEnv, defaultClientConfig } from "@/api/asb/client";
import axios from "axios";
import { handleServiceError, tokenInterceptor } from "@/api/utils";

export const resmgrJSONAPIClient = axios.create({
  ...defaultClientConfig,
  baseURL: baseURLFromEnv("resmgr/v1/"),
  headers: {
    "Content-Type": "application/vnd.api+json"
  }
});

export const resmgrClient = axios.create({
  ...defaultClientConfig,
  "Content-Type": "application/json",
  baseURL: baseURLFromEnv("resmgr/v1/")
});

resmgrClient.interceptors.request.use(tokenInterceptor);
resmgrJSONAPIClient.interceptors.request.use(tokenInterceptor);

export default {
  handleError: error => handleServiceError("Resource Manager", error),
  async getDetails() {
    let { data } = await asbGenericClient.get("resmgr/api/details");
    return data;
  }
};
