import { baseURLFromEnv, defaultClientConfig } from "@/api/asb/client";
import axios from "axios";
import { handleServiceError, tokenInterceptor } from "@/api/utils";

export const keystoreJSONAPIClient = axios.create({
  ...defaultClientConfig,
  baseURL: baseURLFromEnv("keystore/v1/"),
  headers: {
    "Content-Type": "application/vnd.api+json"
  }
});

export const keystoreClient = axios.create({
  ...defaultClientConfig,
  baseURL: baseURLFromEnv("keystore/v1/")
});

keystoreClient.interceptors.request.use(tokenInterceptor);
keystoreJSONAPIClient.interceptors.request.use(tokenInterceptor);

// FIXME this methods might be removed

export default {
  handleError: error => handleServiceError("Keystore", error)
};
