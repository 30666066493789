<template>
  <b-row class="my-5 mx-4">
    <b-col class="mb-3" cols="12">
      <page-title title="ADES Instances"> </page-title>
    </b-col>
    <b-col cols="12" class="my-2">
      <h5>Register new ADES instance</h5>
      <b-form inline>
        <b-form-input
          id="ades-url-input"
          placeholder="<ades_base_url>/admin/wps3"
          class="mr-2"
          v-model="newAdesUrl"
          style="width: 50%"
        >
        </b-form-input>
        <b-btn
          variant="success"
          @click="registerNewAdesInstance"
          :disabled="loading || newAdesUrl == ''"
          >Add ADES Instance</b-btn
        >
      </b-form>
    </b-col>
    <b-col cols="12">
      <b-table
        v-if="ades_instances.length !== 0"
        :items="ades_instances"
        empty-filtered-text="No ADES instances registered."
        small
        foot-clone
        hover
        fixed
        show-empty
        :fields="fields"
      >
        <template v-slot:cell(toolbar)="data">
          <b-dropdown class="float-right no-caret" size="sm" variant="transparent" right>
            <template slot="button-content">
              <fa-icon icon="bars"></fa-icon>
            </template>
            <b-dropdown-item @click="refreshAdesInstance(data.item)">
              <fa-icon class="mr-3" icon="sync-alt"></fa-icon>
              <span>Refresh</span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteAdesInstance(data.item)">
              <fa-icon class="mr-3" icon="trash"></fa-icon>
              <span>Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <empty
        v-if="ades_instances.length === 0"
        text="No ADES instances found"
        :loading="loading"
        no-icon
      ></empty>
    </b-col>
  </b-row>
</template>

<script>
import Empty from "@/components/share/Empty";
import PageTitle from "@/components/share/PageTitle";
import { backendService } from "@/api/asb";

export default {
  name: "AdesManagement",
  components: { Empty, PageTitle },
  created() {
    this.getADESInstances();
  },
  data: function() {
    return {
      loading: false,
      ades_instances: [],
      newAdesUrl: "",
      fields: [
        {
          key: "title",
          label: "Name",
          sortable: true
        },
        {
          key: "url",
          label: "Url",
          sortable: true,

          thStyle: { width: "33%" }
        },
        {
          key: "abstract",
          label: "Description",
          sortable: true,
          thStyle: { width: "33%" }
        },
        {
          key: "toolbar",
          label: "",
          sortable: false,
          thStyle: { width: "5%" }
        }
      ]
    };
  },
  methods: {
    getADESInstances: function() {
      this.loading = true;
      backendService
        .getAvailableAdesInstances()
        .then(ades_instances => {
          this.ades_instances = ades_instances;
        })
        .catch(e => {
          // If cannot get users, disable add user function
          backendService.handleError(e);
        })
        .finally(() => (this.loading = false));
    },
    registerNewAdesInstance() {
      this.loading = true;
      backendService.registerNewAdesInstance(this.newAdesUrl).then(() => this.getADESInstances());
    },
    deleteAdesInstance(instance) {
      this.loading = true;
      backendService.deleteAdesInstance(instance.identifier).then(() => this.getADESInstances());
    },
    refreshAdesInstance(instance) {
      console.log(instance);
      this.loading = true;
      backendService.refreshAdesInstance(instance.identifier).then(() => this.getADESInstances());
    }
  }
};
</script>

<style scoped></style>
