import Vue from "vue";
import Vuex from "vuex";
import * as mutationTypes from "@/store/mutation-types";
import * as actionTypes from "@/store/action-types";
import createPersistedState from "vuex-persistedstate";
import { userService, backendService } from "@/api/asb";
import { keystoreJSONAPIClient } from "@/api/asb/services/keystore";
import { knowledgeJSONAPIClient } from "@/api/asb/services/knowledge";
import { builderJSONAPIClient } from "@/api/asb/services/builder";
import { resmgrJSONAPIClient } from "@/api/asb/services/resmgr";
import { backendJSONAPIClient } from "@/api/asb/services/backend";
import { taskmgrJSONAPIClient } from "@/api/asb/services/taskmgr";
import { jsonapiModule, utils } from "jsonapi-vuex";
import { getLoginUrl, getLogoutUrl, TOKEN_COOKIE_NAME, organiseData } from "@/api/utils";
import dashboards from "@/plugins/dashboards/store/modules/dashboards";

Vue.use(Vuex);

// This configuration for the jsonapi modules must be shared.
// The upstream library reference the configuration globally, for all modules.
// Therefore it is impossible to use different version for different modules.
let jsonapiConf = {
  preserveJson: true,
  clearOnUpdate: false,
  // only unique or modified attributes are kept
  cleanPatch: true,
  // we still keep relationships when doing the clean patch
  cleanPatchProps: ["relationships"]
};

export default new Vuex.Store({
  plugins: [
    createPersistedState({ key: "user", paths: ["user"] }),
    createPersistedState({ key: "loggedIn", paths: ["loggedIn"] }),
    createPersistedState({ key: "settings", paths: ["settings"] })
  ],
  state: {
    // authLoading: true -> loading, false -> success, undefined -> disabled/failed
    authLoading: true,
    settings: {
      developerMode: false,
      hideInstanceDetails: true,
      showConnectionState: true
    },
    loggedIn: false,
    user: {},
    datastore_credentials_changed: true,
    messages: [],
    message: undefined,
    instance: {
      name: "",
      theme: undefined,
      theme_features: [],
      oidcProviderName: undefined,
      administratrorName: "Space Applications Services",
      administratrorEmail: "sys_eos@spaceapplications.com",
      external_datastore_url: undefined,
      user_s3_buckets_enabled: false
    },
    socket: {
      connecting: true,
      isConnected: false,
      message: "",
      reconnectError: false
    },
    marketplace: {
      resources: [],
      collections: [],
      data_fetched: false,
      data_fetching: false,
      filter_options: [],
      filters_enabled: [],
      text_query: ""
    },
    accepted_tcs: [] // EOEPCA specific
  },
  mutations: {
    [mutationTypes.LOGIN](state, user) {
      state.loggedIn = true;
      state.user = user;
    },
    [mutationTypes.LOGOUT](state) {
      state.loggedIn = false;
      state.user = {};
    },
    [mutationTypes.ADD_MESSAGE](state, { content, type }) {
      state.messages.push({
        content: content,
        type: type
      });
    },
    [mutationTypes.POP_MESSAGE](state) {
      state.message = state.messages.pop();
    },
    [mutationTypes.REMOVE_ALL_MESSAGES](state) {
      state.message = undefined;
      state.messages = [];
    },
    [mutationTypes.SET_INSTANCE_DETAILS](state, instance) {
      state.instance = instance;
    },
    [mutationTypes.SET_AUTH_LOADING](state, value) {
      state.authLoading = value;
    },
    [mutationTypes.SET_DEVELOPER_MODE](state, developerMode) {
      state.settings.developerMode = developerMode;
    },
    [mutationTypes.HIDE_INSTANCE_DETAILS](state, hideInstanceDetails) {
      state.settings.hideInstanceDetails = hideInstanceDetails;
    },
    [mutationTypes.SET_SHOW_CONNECTION_STATE](state, showConnectionState) {
      state.settings.showConnectionState = showConnectionState;
    },
    [mutationTypes.SET_ASB_THEME](state, theme) {
      state.instance.theme = theme;
    },
    [mutationTypes.SET_DATASTORE_CREDENTIALS_CHANGED](state, changed) {
      state.datastore_credentials_changed = changed;
    },
    [mutationTypes.SET_MARKETPLACE_DATA](state, data) {
      state.marketplace.resources = data.sort((a, b) => {
        a.title = a.title ? a.title : a.id ? a.id : a.identifier;
        b.title = b.title ? b.title : b.id ? b.id : b.identifier;
        a.title.localeCompare(b.title);
      });
      state.marketplace.data_fetched = true;
    },
    [mutationTypes.SET_MARKETPLACE_FILTER_OPTIONS](state, filter_options) {
      state.marketplace.filter_options = filter_options;
    },
    [mutationTypes.SET_MARKETPLACE_FILTERS_ENABLED](state, filters) {
      state.marketplace.filters_enabled = filters;
    },
    [mutationTypes.SET_MARKETPLACE_COLLECTIONS](state, collections) {
      state.marketplace.collections = collections;
    },
    [mutationTypes.SET_MARKETPLACE_DATA_FETCHING](state, fetching) {
      state.marketplace.data_fetching = fetching;
    },
    [mutationTypes.SET_MARKETPLACE_QUERY](state, query) {
      state.marketplace.query = query;
    },
    [mutationTypes.SET_ACCEPTED_TCS](state, tcs) {
      state.accepted_tcs = tcs;
    },
    SOCKET_ONOPEN(state, event) {
      console.warn("We are live!");
      let socket = event.currentTarget;
      Vue.prototype.$socket = socket;
      state.socket.connecting = false;
      state.socket.isConnected = true;
    },
    SOCKET_ONCLOSE(state) {
      state.socket.connecting = false;
      state.socket.isConnected = false;
    },
    SOCKET_ONERROR(state, event) {
      console.error(state, event);
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE(state, message) {
      state.socket.message = message;
      console.debug("Recv live message:", message);
      let data = message.data;
      let normalized = utils.jsonapiToNorm(data);
      console.debug("Recv data (normalized): ", normalized);
      // The live endpoints is only connected to the Service Builder.
      // If records from other components must be (live) updated, then this must be refactored.
      this.commit("builder/addRecords", normalized);
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT(state, count) {
      console.info("Reconnecting...", state, count);
      state.socket.connecting = true;
    },
    SOCKET_RECONNECT_ERROR(state) {
      state.socket.reconnectError = true;
    }
  },
  actions: {
    async [actionTypes.TOKEN_LOGIN]({ dispatch }, { username, password }) {
      let token;
      if (username === undefined) {
        token = await userService.getToken();
      } else {
        token = await userService.postToken(username, password);
      }
      // By default, User Manager returns a token valid for one day
      Vue.$cookies.set(TOKEN_COOKIE_NAME, token, "1d");
      await dispatch(actionTypes.RETRIEVE_USER);
    },
    async [actionTypes.RETRIEVE_USER]({ commit, dispatch, state }) {
      let user = await userService.getUser("self");
      commit(mutationTypes.LOGIN, user);
      if (user !== undefined) {
        if (state.instance.user_s3_buckets_enabled) {
          dispatch(actionTypes.CHECK_DATASTORE_CREDENTIALS_CHANGED);
        }
        if (state.instance.theme_features.filter(feature => feature.endsWith("-tcs")).length != 0) {
          dispatch("FETCH_ACCEPTED_TCS");
        }
      }
      return user;
    },
    [actionTypes.LOGOUT_USER]({ commit, getters }) {
      Vue.$cookies.remove(TOKEN_COOKIE_NAME);
      commit(mutationTypes.LOGOUT);
      window.location.href = getters.logoutUrl;
    },
    [actionTypes.GET_INSTANCE_DETAILS]({ commit }) {
      userService
        .getDetails()
        .then(details => {
          commit(mutationTypes.SET_INSTANCE_DETAILS, details["instance"]);
          commit(mutationTypes.SET_AUTH_LOADING, false);
          console.warn(details.instance.user_s3_buckets_enabled);
        })
        .catch(() => {
          commit(mutationTypes.SET_AUTH_LOADING, undefined);
        });
    },
    [actionTypes.CHECK_DATASTORE_CREDENTIALS_CHANGED]({ commit }) {
      userService
        .checkDatastoreCredentials()
        .then(changed => {
          // If the response has no "changed" variable, we disable the message for the user
          // Because the datastore is not enabled.
          if (changed["data"]["message"]) {
            commit(mutationTypes.SET_DATASTORE_CREDENTIALS_CHANGED, true);
          } else {
            commit(mutationTypes.SET_DATASTORE_CREDENTIALS_CHANGED, changed["data"]["changed"]);
          }
        })
        .catch(() => {
          commit(mutationTypes.SET_DATASTORE_CREDENTIALS_CHANGED, true);
        });
    },
    async [actionTypes.FETCH_MARKETPLACE_DATA]({ commit, state }) {
      console.log("Fetching new marketplace data");
      commit(mutationTypes.SET_MARKETPLACE_DATA_FETCHING, true);
      return this.dispatch("backend/get", "resource-filter-types")
        .then(() => {
          backendService
            .getPublishedResources(state.marketplace.query)
            .then(data => {
              let organisedData = organiseData(
                data.data.data.resources,
                state.backend["resource-filter-types"]
              );
              commit(mutationTypes.SET_MARKETPLACE_DATA, organisedData.resources);
              commit(mutationTypes.SET_MARKETPLACE_COLLECTIONS, data.data.data.collections);
              commit(mutationTypes.SET_MARKETPLACE_FILTER_OPTIONS, organisedData.filter_options);
              commit(mutationTypes.SET_MARKETPLACE_DATA_FETCHING, false);
            })
            .catch(error => {
              commit(mutationTypes.SET_MARKETPLACE_DATA, []);
              commit(mutationTypes.SET_MARKETPLACE_DATA_FETCHING, false);
              backendService.handleError(error);
            });
        })
        .catch(error => {
          commit(mutationTypes.SET_MARKETPLACE_DATA, []);
          commit(mutationTypes.SET_MARKETPLACE_DATA_FETCHING, false);
          backendService.handleError(error);
        });
    },
    async [actionTypes.FETCH_ACCEPTED_TCS]({ commit }) {
      userService
        .getAcceptedTermsAndConditions()
        .then(data => {
          commit(mutationTypes.SET_ACCEPTED_TCS, data);
        })
        .catch(error => userService.handleError(error));
    }
  },
  getters: {
    oidcEnabled: state => {
      return state.instance.oidcProviderName !== undefined;
    },
    loginUrl: (state, getters) => {
      return getLoginUrl(getters.oidcEnabled);
    },
    logoutUrl: (state, getters) => {
      return getLogoutUrl(getters.oidcEnabled);
    }
  },
  modules: {
    keystore: jsonapiModule(keystoreJSONAPIClient, jsonapiConf),
    knowledge: jsonapiModule(knowledgeJSONAPIClient, jsonapiConf),
    builder: jsonapiModule(builderJSONAPIClient, jsonapiConf),
    resmgr: jsonapiModule(resmgrJSONAPIClient, jsonapiConf),
    backend: jsonapiModule(backendJSONAPIClient, jsonapiConf),
    taskmgr: jsonapiModule(taskmgrJSONAPIClient, jsonapiConf),
    dashboards: dashboards
  }
});
