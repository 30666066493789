import _backendService from "./services/backend";
import _builderService from "./services/builder";
import _keystoreService from "./services/keystore";
import _knowledgeService from "./services/knowledge";
import _importService from "./services/import";
import _userService from "./services/usermgr";

export const backendService = _backendService;
export const builderService = _builderService;
export const keystoreService = _keystoreService;
export const knowledgeService = _knowledgeService;
export const importService = _importService;
export const userService = _userService;

// FIXME so of the endpoints needs to be removed since we use jsonapi-vuex

export const asbService = {
  async getExecution(executionId) {
    let execution = {
      data: {},
      processor: {}
    };
    execution.data = await builderService.getExecution(executionId);
    let filters = {
      identifier: execution.data.processor_id,
      version: execution.data.processor_version,
      mission: execution.data.mission_id
    };
    let processors = await knowledgeService.getProcessors(filters);
    if (processors.length === 0) {
      console.error("No processor found associated to execution", executionId);
    } else if (processors.length > 1) {
      console.warn(
        "Found more than one processor with the following filter id",
        filters,
        ", taking the first one only"
      );
    }
    if (processors.length > 0) {
      execution.processor = processors[0];
    }
    return execution;
  }
};

export default {
  asbService: asbService,
  backendService: backendService,
  builderService: builderService,
  keystoreService: keystoreService,
  knowledgeService: knowledgeService,
  importService: importService,
  userService: userService
};
