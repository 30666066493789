<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <b-container fluid>
    <b-modal
      ref="cog-preview"
      hide-footer
      id="cog-preview"
      :title="`Preview COG for ${selectedPreviewCOG.key}`"
      size="xl"
    >
      <b-row align-h="end" no-gutters>
        <b-col cols="12 text-center" style="height: 600px; margin-bottom: 50px">
          <COGMap :tiff_url="formatDatastoreUrl(selectedPreviewCOG.value)"></COGMap>
        </b-col>
      </b-row>
      <b-row align-h="end" no-gutters>
        <b-col cols="3"> </b-col>
      </b-row>
    </b-modal>
    <b-row class="my-4 mx-5">
      <b-col>
        <b-row>
          <b-modal ref="share" hide-footer id="share" :title="`Share ${execution.identifier}`">
            <b-row align-h="end" no-gutters>
              <b-col cols="12">
                <multiselect
                  v-model="workspaces"
                  :options="workspaceOptions"
                  track-by="identifier"
                  label="label"
                  placeholder="Select workspaces"
                  :multiple="true"
                  :taggable="true"
                ></multiselect>
                <h4 class="mt-3"></h4>
              </b-col>
              <b-col cols="4">
                <b-button
                  class="btn-block"
                  :variant="updateFailed ? 'failed' : 'success'"
                  @click="share()"
                  :disabled="updating"
                >
                  <span v-if="!updating">Share execution</span>
                  <b-spinner v-else small></b-spinner>
                </b-button>
              </b-col>
            </b-row>
          </b-modal>
          <b-col>
            <page-title
              title="Execution Report"
              :subtitle="execution.identifier"
              :fetching="fetching"
            >
              <template v-slot:toolbar>
                <b-btn-toolbar class="float-right">
                  <b-btn-group>
                    <b-btn variant="plain" class="mr-2" v-b-modal.share>Share this execution</b-btn>
                  </b-btn-group>
                  <b-btn-group>
                    <b-button
                      variant="success"
                      @click="downloadPDF()"
                      type="export"
                      :disabled="exporting"
                    >
                      <fa-icon class="mr-2" icon="download"></fa-icon>
                      <span v-if="exporting">Generating...</span>
                      <span v-else>Download PDF</span>
                    </b-button>
                  </b-btn-group>
                </b-btn-toolbar>
              </template>
            </page-title>
            <b-row v-if="!$_.isEmpty(workspaces)" class="my-3 divider" no-gutters>
              <b-col class="mb-2" cols="12">
                <span class="text-secondary">Workspaces</span>
              </b-col>
              <b-col cols="12">
                <h4>
                  <b-badge
                    class="mr-1"
                    variant="primary"
                    v-for="workspace in workspaces"
                    :key="workspace._jv.id"
                    disabled
                  >
                    {{ workspace.label }}
                  </b-badge>
                </h4>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-2" align-h="end">
          <b-col cols="2">
            <status-badge :progress="execution.progress" :status="execution.status" />
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col>
            <b-table-lite stacked :items="[execution]" :fields="mainTableFields" small>
              <template v-slot:cell(datastore)>
                <div v-if="theme_features.includes('execution-files-mepwps')">
                  Your execution files can be found in the private folder on your Terrascope Virtual
                  Machine
                </div>
                <div v-if="theme_features.includes('execution-files-asb')">
                  <a :href="datastore_output_url" class="btn mr-1 btn-info btn-sm" target="_blank">
                    Execution files
                  </a>
                </div>
                <div v-if="theme_features.includes('execution-files-eopen')">
                  <a
                    :href="
                      `https://asb.spaceapplications.com/datastore/processor-run-${execution.workflowExecutionId}/`
                    "
                    class="btn mr-1 btn-info btn-sm"
                    target="_blank"
                  >
                    Execution files
                  </a>
                  <a
                    v-if="hasChildDags"
                    :href="
                      `https://asb.spaceapplications.com/datastore/processor-run-${execution.workflowExecutionId}_child/`
                    "
                    class="btn mr-1 btn-info btn-sm"
                    target="_blank"
                  >
                    Child branches
                  </a>
                </div>
              </template>

              <!--<template v-slot:cell(datastore)>-->
              <!--Access through SFTP: <br />sftp -oPort=22098-->
              <!--user1@193.191.168.8:/datastore/workflow-run-{{-->
              <!--execution.workflowExecutionId-->
              <!--}}-->
              <!--</template>-->
              <template v-slot:cell(executionStartTime)>
                {{ executionStartTime }}
              </template>
              <template v-slot:cell(executionEndTime)>
                {{ executionEndTime }}
              </template>
              <template v-slot:cell(executionDuration)>
                {{ executionDuration }}
              </template>
            </b-table-lite>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="my-3">
            <h2>Inputs</h2>
          </b-col>
          <b-col cols="12">
            <b-table-lite
              :items="inputs"
              :fields="inputsTableFields"
              bordered
              striped
              small
              fixed
            ></b-table-lite>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12" class="my-3">
            <h2>Outputs</h2>
          </b-col>
          <b-col cols="12">
            <b-table-lite :items="outputs" :fields="outputsTableFields" bordered striped small>
              <template v-slot:empty=""> No outputs </template>
              <template v-slot:cell(value)="data">
                <span v-if="$_.isString(data.value) && data.value.startsWith('/data')">
                  <a :href="data.value" target="_blank">{{ data.value }}</a>
                </span>
                <span v-else-if="$_.isString(data.value)">
                  <shrinked-value :value="getValue(data)"></shrinked-value>
                </span>
                <span v-else>
                  {{ getValue(data) }}
                </span>
              </template>
              <template v-slot:cell(type)="data">
                {{ data.item.type_label }}
              </template>
              <template v-slot:cell(actions)="data">
                <b-btn-group>
                  <b-btn
                    v-if="data.item.type == 'geotiff'"
                    variant="primary"
                    v-b-modal.cog-preview
                    @click="selectedPreviewCOG = data.item"
                    v-b-tooltip.hover
                    title="Preview"
                    ><fa-icon icon="eye"></fa-icon
                  ></b-btn>
                  <b-btn
                    v-if="data.item.type == 'geotiff'"
                    variant="success"
                    v-b-tooltip.hover
                    title="Download"
                    :href="formatDatastoreUrl(data.item.value)"
                    target="_blank"
                  >
                    <fa-icon icon="download"></fa-icon
                  ></b-btn>
                </b-btn-group>
              </template>
            </b-table-lite>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12" class="my-3">
            <h2>Execution Times and Status</h2>
          </b-col>
          <b-col cols="12">
            <b-table :items="tasks" :fields="tasksTableFields" bordered striped small>
              <template v-slot:empty=""> No tasks </template>
              <template v-slot:cell(worker_selectors)="data">
                <h6>
                  <b-badge v-for="(ws, index) in data.value" :key="index">{{ ws.label }}</b-badge>
                </h6>
              </template>
              <template v-slot:cell(start_date)="data">
                <span>{{ data.value | moment("YYYY-MM-DD HH:mm:ss (Z)") }}</span>
              </template>
              <template v-slot:cell(end_date)="data">
                <span>{{ data.value | moment("YYYY-MM-DD HH:mm:ss (Z)") }}</span>
              </template>
              <template v-slot:cell(duration)="data">
                <span>{{ prettyDuration(data.value * 1000) }}</span>
              </template>
            </b-table>
          </b-col>
          <b-container fluid class="border ml-3 mr-3">
            <b-row>
              <b-button variant="transparent" size="sm" @click.stop="graphFit">
                <fa-icon icon="arrows-alt" />
                <span class="ml-2">Fit</span>
              </b-button>
              <b-button variant="transparent" size="sm" @click.stop="graphLeftToRight">
                <fa-icon icon="arrow-right" />
                <span class="ml-2">Left to Right</span>
              </b-button>
              <b-button variant="transparent" size="sm" @click.stop="graphTopToBottom">
                <fa-icon icon="arrow-down" />
                <span class="ml-2">Top to Bottom</span>
              </b-button>
            </b-row>
            <b-row cols="12" style="height: 300px">
              <vue-cytoscape
                style="width: 100%; height: 300px"
                ref="cy"
                v-on:ready="graphFit"
                :config="graphConfig"
                :preConfig="graphPreConfig"
                :afterCreated="graphAfterCreated"
              >
                <cy-element v-for="def in graphData" :key="`${def.data.id}`" :definition="def" />
              </vue-cytoscape>
            </b-row>
            <pre v-if="developerMode" class="dev-mode mb-3">
              {{ graphData }}
            </pre>
          </b-container>
          <b-container id="timeline-container" fluid class="border mt-3 ml-3 mr-3">
            <vue-apex-charts
              height="95%"
              :type="timelineOptions.chart.type"
              :options="timelineOptions"
              :series="timelineData"
            />
            <pre v-if="developerMode" class="dev-mode mb-3">
              {{ timelineData }}
            </pre>
            <pre v-if="developerMode" class="dev-mode mb-3">
              {{ execution }}
            </pre>
          </b-container>
        </b-row>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col cols="11" align-self="center">
        <generic-list
          v-if="this.theme_features.includes('execution-report-jobs') && showJobs"
          name="job"
          service="builder"
          title="Related Jobs"
          paginate
          fluid
          :sort-by="jobSortBy"
          :sort-desc="jobSortDesc"
          :filters="jobFilters"
          :hide-filters="jobHideFilters"
        >
          <template v-slot:resource-label="{ resource }">
            <b-row>
              <b-col cols="4" align-self="center" class="px-1">
                <status-badge :progress="resource.progress" :status="resource.status" />
              </b-col>
              <b-col align-self="center">
                <span>
                  {{ resource.ownerId }} | {{ resource.identifier }}
                  executed at
                  {{ resource.createdAt }}
                </span>
              </b-col>
            </b-row>
          </template>
        </generic-list>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { builderService } from "@/api/asb";
// import { canWriteToClipboard, writeToClipboard } from "@/utils";
import StatusBadge from "@/components/share/StatusBadge";
import GenericList from "@/components/generic/GenericList";
import Multiselect from "vue-multiselect";
import { utils } from "jsonapi-vuex";
import { mapState } from "vuex";
import PageTitle from "@/components/share/PageTitle";
import { VueCytoscape, CyElement } from "vue-cytoscape";
import dagre from "cytoscape-dagre";
import VueApexCharts from "vue-apexcharts";
import ShrinkedValue from "@/components/share/ShrinkedValue";
import COGMap from "@/components/products/COGMap.vue";
// import NavBarVue from '../../components/share/NavBar.vue';

const graphConfig = {
  minZoom: 0.25,
  maxZoom: 1.25,
  motionBlur: true,
  style: [
    {
      selector: "node",
      style: {
        label: "data(id)",
        // Displaying the label of each node
        //"content": "data(label)",
        // Use a color that match the task status
        "background-color": "data(color)",
        "font-family": "Titillium Web, sans-serif, Arial, sans-serif"
      }
    },
    {
      selector: "edge",
      style: {
        width: 5,
        "curve-style": "bezier",
        "line-color": "#ccc",
        "arrow-scale": 1.5,
        "target-arrow-color": "#aaa",
        "target-arrow-shape": "triangle"
      }
    }
  ],
  layout: {
    // http://js.cytoscape.org/#layouts
    // https://github.com/cytoscape/cytoscape.js-dagre => API
    name: "dagre",
    directed: "true",
    rankDir: "TB",
    padding: 10,
    fit: true,
    nodeDimensionsIncludeLabels: true
  }
};

export default {
  name: "ExecutionReport",
  components: {
    ShrinkedValue,
    PageTitle,
    StatusBadge,
    VueCytoscape,
    CyElement,
    VueApexCharts,
    Multiselect,
    GenericList,
    COGMap
  },
  props: {},
  data() {
    return {
      executionId: this.$route.params.id,
      workspaces: [],
      workspaceOptions: [],
      fetching: false,
      updating: false,
      updateFailed: false,
      exporting: false,
      mainTableFields: [
        {
          key: "identifier",
          label: "Identifier"
        },
        {
          key: "ownerId",
          label: "Executed by"
        },
        {
          key: "createdAt",
          label: "Created at"
        },
        {
          key: "processorId",
          label: "Workflow"
        },
        {
          key: "processorVersion",
          label: "Workflow version"
        },
        {
          key: "workflowExecutionId",
          label: "Engine reference"
        },
        {
          key: "executionStartTime",
          label: "Execution start time"
        },
        {
          key: "executionEndTime",
          label: "Execution end time"
        },
        {
          key: "executionDuration",
          label: "Execution duration"
        },
        //{
        //  key: "status",
        //  label: "Status"
        //},
        {
          key: "datastore",
          label: "Datastore"
        }
      ],
      inputsTableFields: ["label", "value"],
      outputsTableFields: ["task_id", "key", "type", "value", "actions"],
      tasksTableFields: [
        "task_id",
        "worker_selectors",
        "start_date",
        "end_date",
        "duration",
        "state"
      ],
      // ApexCharts does not support color names (for the time being)
      statusColor: {
        pending: "#D3D3D3", //"lightgray",
        queued: "#D3D3D3", //"lightgray",
        skipped: "#FFC0CB", //"pink",
        running: "#90EE90", //"lightgreen",
        retry: "#FFFF00", //"yellow",
        "no status": "#808080", //"gray",
        success: "#006400", //"darkgreen",
        failed: "#FF0000", //"red",
        upstream_failed: "#FFA500" //"orange"
      },
      graphConfig,
      graphData: [], // Computed in graphPrepareData()
      timelineOptions: {
        chart: {
          height: 250,
          type: "rangeBar"
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        grid: {
          show: false
        },
        xaxis: {
          type: "datetime",
          labels: {
            format: String,
            formatter: this.timelineLabelsFormatter
          }
        },
        tooltip: {
          enabled: true,
          custom: this.timelineTooltipsFormatter
        }
      },
      timelineData: [], // Computed in timelinePrepareData()
      executionStartTime: null,
      executionEndTime: null,
      executionDuration: null,
      // Related Jobs list data
      showJobs: false,
      jobFilters: [],
      jobSortBy: "createdAt",
      jobSortDesc: true,
      jobHideFilters: true,
      outputDataTypes: undefined,
      selectedPreviewCOG: {}
    };
  },
  watch: {
    execution: function() {
      console.debug("Execution changed! Regenerating report.");
      this.generateReport();
      // Updating filters to contain new data
      this.jobFilters = [
        {
          name: "workflowIdentifier",
          value: () => this.execution.workflowExecutionId,
          label: "Workflow Identifier",
          default: true
        }
      ];
      this.showJobs = true;
      this.fetchOutputTypes();
    }
  },
  created() {
    this.fetchAndGenerateReport();
  },
  updated() {
    // TODO: Find a better way to limit the height of the graph viewport.
    let graphContainer = document.getElementById("cytoscape-div");
    graphContainer.style["min-height"] = null;
    graphContainer.style.height = "100%";
    this.$refs.cy.instance.resize();
    this.graphFit();
  },
  methods: {
    async fetchData() {
      let workspaces = await this.$store.dispatch("builder/get", "workspaces");
      this.workspaceOptions = Object.values(workspaces);
      await this.$store.dispatch("builder/get", `executions/${this.executionId}`);
      this.workspaces = [];
      if (this.execution.workspaces) {
        for (let workspaceId in workspaces) {
          if (workspaceId in this.execution.workspaces) {
            this.workspaces.push(workspaces[workspaceId]);
          }
        }
      }
    },
    fetchAndGenerateReport() {
      this.fetchData()
        .then(() => {
          this.generateReport();
        })
        .catch(error => builderService.handleError(error));
    },
    fetchOutputTypes() {
      if (this.execution.processorId) {
        this.$store
          .dispatch("knowledge/get", `workflows?filter[identifier]=${this.execution.processorId}`)
          .then(workflows => {
            let id = Object.keys(workflows).filter(key => key != "_jv")[0];
            this.$store.dispatch("knowledge/get", `workflows/${id}/outputs`).then(data => {
              this.outputDataTypes = data._jv;
            });
          });
      }
    },
    share() {
      this.updating = true;
      let patchedExecution = utils.deepCopy(this.execution);
      patchedExecution["_jv"]["relationships"].workspaces = {
        data: this.workspaces.map(workspace => ({
          id: workspace["_jv"].id,
          type: workspace["_jv"].type
        }))
      };
      this.$store
        .dispatch("builder/patch", patchedExecution)
        .then(() => {
          this.updateFailed = false;
          this.$refs.share.hide();
        })
        .catch(error => {
          this.updateFailed = true;
          builderService.handleError(error);
        })
        .finally(() => (this.updating = false));
    },
    generateReport() {
      this.graphData = this.graphPrepareData();
      this.timelineData = this.timelinePrepareData();
      this.timelineFit();
      // Wait for the workflow graph data to be initialised to update the layout
      this.$nextTick().then(() => this.graphLeftToRight());
    },
    prettyDate(str) {
      return new Date(str).toUTCString();
    },
    techDate(msecs) {
      if (msecs === undefined) return "";
      if (msecs === 0 || msecs === 9000000000000) return "n/a";
      // prettier-ignore
      return new Date(msecs).toISOString().replace("T", " ").substring(0, 19);
    },
    isoDate(msecs) {
      return new Date(msecs).toISOString();
    },
    prettyDuration(msecs) {
      // Only print days if non null
      // prettyDuration(5423401000) => "62d 18:30:01"
      // prettyDuration(77735634)   =>     "21:35:35"
      // prettyDuration(5423401)    =>      "1:30:23"
      // prettyDuration(542931)     =>      "0:09:02"

      msecs = Math.floor(msecs);
      if (msecs < 0) return "n/a";
      let pad = (n, z = 2) => ("00" + n).slice(-z);

      let ms = msecs % 1000;
      let rest = (msecs - ms) / 1000;
      let secs = rest % 60;
      rest = (rest - secs) / 60;
      let mins = rest % 60;
      rest = (rest - mins) / 60;
      let hrs = rest % 24;
      let days = (rest - hrs) / 24;

      if (days == 0) {
        return hrs + ":" + pad(mins) + ":" + pad(secs); // + "." + pad(ms, 3);
      }
      return days + "d " + hrs + ":" + pad(mins) + ":" + pad(secs); // + "." + pad(ms, 3);
    },
    camelToSpace(str) {
      /* Transform a camelCase string to a sentence */
      /* Ex: "parentIdentifier"  -> "Parent identifier" */
      //Add space on all uppercase letters
      let result = str.replace(/([A-Z])/g, " $1").toLowerCase();
      //Trim leading and trailing spaces
      result = result.trim();
      //Uppercase first letter
      return result.charAt(0).toUpperCase() + result.slice(1);
    },
    graphPreConfig(cytoscape) {
      console.debug("Cytoscape pre-config:", cytoscape);
      cytoscape.use(dagre);
    },
    graphAfterCreated(cy = null) {
      console.debug("Cytoscape after-created:", cy);
    },
    graphFit() {
      if (this.$refs.cy === undefined) return;
      this.$refs.cy.instance.fit();
    },
    graphLeftToRight() {
      console.debug("Graph left to right");
      this.graphConfig.layout.rankDir = "LR";
      this.$refs.cy.instance.layout(this.graphConfig.layout).run();
    },
    graphTopToBottom() {
      console.debug("Graph top to bottom");
      this.graphConfig.layout.rankDir = "TB";
      this.$refs.cy.instance.layout(this.graphConfig.layout).run();
    },
    graphPrepareData() {
      // Compute the data to show the workflow DAG
      console.debug("Preparing graph data with execution", this.execution);
      let graphData = [];
      if (!this.execution.executionResults || !this.execution.executionResults.info) {
        return;
      }
      // The nodes must be pushed in the array before the edges
      for (let i in this.execution.executionResults.tasks) {
        let task = this.execution.executionResults.tasks[i];
        graphData.push({
          group: "nodes",
          data: {
            id: task.task_id,
            label: task.task_label,
            state: task.state,
            color: this.statusColor[task.state]
          },
          // scratchpad data (usually temp or nonserialisable data)
          scratch: {
            foo: "bar"
          },
          // Not allowed to put the comment next to each key/value because of the linter
          // whether the element is selected (default false)
          selected: false,
          // whether the selection state is mutable (default true)
          selectable: true,
          // when locked a node's position is immutable (default false)
          locked: false,
          // whether the node can be grabbed and moved by the user
          grabbable: true,
          // a space separated list of class names that the element has
          classes: ""
        });
      }
      for (let taskId in this.execution.executionResults.info.data.streams) {
        let task = this.execution.executionResults.info.data.streams[taskId];
        console.debug("Task:", task);
        for (let j in task.downstream_task_ids) {
          console.debug("Task downstream:", task.downstream_task_ids[j]);
          graphData.push({
            group: "edges",
            data: {
              id: "edge|" + taskId + "|" + task.downstream_task_ids[j],
              source: taskId,
              target: task.downstream_task_ids[j]
            }
          });
        }
      }
      return graphData;
    },
    timelinePrepareData() {
      if (!this.execution.executionResults || !this.execution.executionResults.info) {
        return [];
      }
      // Compute the data to show the timeline
      let timelineData = [];
      let executionStartTime = 9000000000000;
      let executionEndTime = 0;
      for (let i in this.execution.executionResults.tasks) {
        let task = this.execution.executionResults.tasks[i];
        let startingTime = new Date(task.start_date).getTime();
        let endingTime = new Date(task.end_date).getTime();
        executionStartTime = Math.min(executionStartTime, startingTime);
        executionEndTime = Math.max(executionEndTime, endingTime);
        timelineData.push({
          x: task.task_id, //task_label,
          y: [startingTime, endingTime],
          fillColor: this.statusColor[task.state],
          status: task.state
        });
      }
      // Making sure the tasks are displayed chronologically
      timelineData = timelineData.sort(function(a, b) {
        return a.y[0] - b.y[1];
      });
      this.executionStartTime = this.techDate(executionStartTime);
      this.executionEndTime = this.techDate(executionEndTime);
      this.executionDuration = this.prettyDuration(executionEndTime - executionStartTime);
      // prettier-ignore
      return [{
        data: timelineData
      }];
    },
    timelineLabelsFormatter(value, timestamp, index) {
      console.debug("Timeline Labels Formatter:", value, timestamp, index);
      return this.techDate(value);
    },
    timelineTooltipsFormatter({ series, seriesIndex, dataPointIndex, w }) {
      console.debug("Timeline Tooltips Formatter:", series, seriesIndex, w);
      let data = w.config.series[0].data[dataPointIndex];
      let duration = this.prettyDuration(data.y[1] - data.y[0]);
      //console.debug(data);
      // prettier-ignore
      return (
        "<div>" +
        "<table class=\"table table-sm table-borderless\" style=\"margin: 0px;\">" +
        "<tr><th class=\"text-center text-light bg-dark\" colspan=\"2\">" + data.x + "</th></tr>" +
        "<tr><td class=\"text-right\">From :</td><td>" + this.techDate(data.y[0]) + "</td></tr>" +
        "<tr><td class=\"text-right\">To :</td><td>" + this.techDate(data.y[1]) + "</td></tr>" +
        "<tr><td class=\"text-right\">Duration :</td><td>" + duration + "</td></tr>" +
        "<tr><td class=\"text-right\">Status :</td><td>" + data.status + "</td></tr>" +
        "</table>" +
        "</div>");
    },
    timelineFit() {
      if (this.timelineData === undefined || this.timelineData.length === 0) {
        return;
      }
      // Adapt the height of the timeline chart to the amount of lines to be displayed
      let container = document.getElementById("timeline-container");
      let newHeight = 50 * this.timelineData[0].data.length + 50;
      console.debug("Timeline data, new height:", this.timelineData, newHeight);
      container.style.height = newHeight + "px";
    },
    workerSelectorsOfTask(task_slug_id) {
      for (let task of this.execution.workflowExecutionOrder.tasks) {
        if (task["task_slug_id"] === task_slug_id) {
          return task["worker_selectors"];
        }
      }
      console.error("Worker selectors for task", task_slug_id, "not found in execution");
      return [];
    },
    downloadPDF() {
      this.exporting = true;
      builderService
        .getExecutionReportPDF(this.executionId)
        .then(response => {
          this.downloadFile(response);
        })
        .catch(error => builderService.handleError(error))
        .finally(() => {
          setTimeout(() => (this.exporting = false), 600);
        });
    },
    downloadFile(data) {
      if (!data) {
        return;
      }
      let url = window.URL.createObjectURL(new Blob([data]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", `ExecutionReport${this.executionId}.pdf`);
      document.body.appendChild(link);
      link.click();
    },
    formatDatastoreUrl(url) {
      if (url) {
        let url_parts = url.split("/");
        let host_parts = url_parts[2].split("."); //split the domain/subdomains
        host_parts.push("files"); // add the extension for the endpoint that serves files directly
        host_parts.push(host_parts.shift()); // Move the bucket name to the end of the array
        host_parts[0] = host_parts[0].replace("datastore-api", "datastore"); // replace

        let host =
          "https://" +
          host_parts.slice(0, host_parts.length - 2).join(".") +
          "/" +
          host_parts.slice(host_parts.length - 2).join("/"); // format the domain again
        return host + "/" + url_parts.slice(3).join("/"); // add workflow specific parts again
      }
      return "";
    },
    getValue(data) {
      // TODO format this
      let value = data.value;
      if (data.item.type == "geotiff") {
        value = this.formatDatastoreUrl(value);
      }
      return value;
    }
  },
  computed: {
    developerMode() {
      return this.$store.state.settings.developerMode;
    },
    inputs() {
      let inputs = [];
      if (this.execution.inputs === undefined) {
        return inputs;
      }
      for (let node of this.execution.inputs) {
        for (let input of node.items) {
          input.node = node.node_id;
          if (input.user_visible) inputs.push(input);
        }
      }
      console.debug("Inputs:", this.execution, inputs);
      return inputs;
    },
    outputs() {
      if (
        this.execution.executionResults &&
        this.execution.executionResults.data &&
        this.execution.workflowExecutionOrder
      ) {
        let new_outputs = this.execution.executionResults.data;
        if (this.outputDataTypes) {
          for (let output of new_outputs) {
            for (let task of this.execution.workflowExecutionOrder.tasks) {
              if (output.task_id === task.task_slug_id) {
                console.warn("found a matching task");
                output["type"] = this.outputDataTypes[`${task.identifier} ${task.version}`][
                  output.key
                ]["type"];
                output["type_label"] = this.outputDataTypes[`${task.identifier} ${task.version}`][
                  output.key
                ]["label"];
              }
            }
          }
        }
        return [...new_outputs];
      } else {
        return [];
      }
    },
    tasks() {
      if (
        this.execution.workflowExecutionOrder &&
        this.execution.executionResults &&
        this.execution.executionResults.tasks
      ) {
        for (let task of this.execution.executionResults.tasks) {
          task["worker_selectors"] = this.workerSelectorsOfTask(task["task_id"]);
        }
        return this.execution.executionResults.tasks;
      } else {
        return [];
      }
    },
    execution() {
      return this.$store.getters["builder/get"](`executions/${this.executionId}`);
    },
    datastore_output_url() {
      return `${this.external_datastore_url}/user-${this.user.username}/workflow-runs/${this.executionId}`;
    },
    ...mapState({
      user: state => state.user,
      theme: state => state.instance.theme,
      theme_features: state => state.instance.theme_features,
      external_datastore_url: state => state.instance.external_datastore_url
    }),
    dagType() {
      if (
        this.execution.workflowExecutionOrder &&
        this.execution.workflowExecutionOrder.workflow &&
        this.execution.workflowExecutionOrder.workflow["dag-type"]
      ) {
        return this.execution.workflowExecutionOrder.workflow["dag-type"];
      }
      return "";
    },

    hasChildDags() {
      // The workflow has child DAGs if it contains a Splitter / Joiner pair
      return this.dagType === "dynamic-list-splitter";
    }
  }
};
</script>

<style scoped></style>
