<template>
  <span>
    <b-btn class="toggle" v-show="showButton && expand" @click="toggle()" variant="link">
      {{ buttonContent }}
    </b-btn>
    <br v-show="showButton && expand" />
    <span>{{ shrinkedValue }}</span>
    <span v-show="showButton && !expand">...</span>
    <b-btn class="toggle" v-show="showButton" @click="toggle()" variant="link">
      {{ buttonContent }}
    </b-btn>
  </span>
</template>

<script>
export default {
  name: "ShrinkedValue",
  props: {
    value: {
      type: String,
      required: true
    },
    maxLength: {
      type: Number,
      default: 150,
      required: false
    }
  },
  data() {
    return {
      expand: false
    };
  },
  methods: {
    toggle() {
      this.expand = !this.expand;
      // Scroll back to the element
      this.$el.scrollIntoView();
    }
  },
  computed: {
    showButton() {
      return this.value.length > this.maxLength;
    },
    shrinkedValue() {
      return this.expand && this.value.length > this.maxLength
        ? this.value
        : this.value.slice(0, this.maxLength);
    },
    buttonContent() {
      return this.expand ? "Show less" : "Show more";
    }
  }
};
</script>

<style scoped></style>
