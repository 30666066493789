<template>
  <b-container class="mt-4">
    <page-title class="mb-5" title="Framework"></page-title>
    <b-form-row class="mt-4" v-if="user['is_staff']">
      <b-col cols="3">
        <h4 for="name">Theme</h4>
      </b-col>
      <b-col class="mt-1">
        <b-row>
          <b-col cols="12">
            <span> {{ instance.theme }}</span>
          </b-col>
        </b-row>
      </b-col>
    </b-form-row>
    <b-form-row class="mt-4" v-if="user['is_staff']">
      <b-col cols="3">
        <h4 for="name">OIDC Provider</h4>
      </b-col>
      <b-col class="mt-1">
        <b-row>
          <b-col cols="12">
            <span v-if="instance.oidcProviderName"> {{ instance.oidcProviderName }}</span>
            <span class="text-muted" v-else>Not enabled</span>
          </b-col>
        </b-row>
      </b-col>
    </b-form-row>
    <b-form-row class="mt-4">
      <b-col cols="3">
        <h4 for="name">Instance</h4>
      </b-col>
      <b-col class="mt-1">
        <b-row>
          <b-col cols="12">
            <span>{{ instance.name }}</span>
          </b-col>
        </b-row>
      </b-col>
    </b-form-row>
    <b-form-row class="mt-4">
      <b-col cols="3">
        <h4 for="name">Version</h4>
      </b-col>
      <b-col class="mt-1">
        <b-row>
          <b-col cols="12">
            <span>
              <b>{{ instance["asb_version"] }}</b
              ><br />
              <div class="text-muted">
                <span>built on {{ instance["build_timestamp"] }}</span
                >&nbsp;
                <span>(base built on {{ instance["base_build_timestamp"] }})</span>
              </div>
            </span>
          </b-col>
        </b-row>
      </b-col>
    </b-form-row>
    <page-title class="my-5" title="Settings"></page-title>
    <b-form-row v-if="user['is_staff']">
      <b-col cols="3">
        <h4 for="name">Developer mode</h4>
      </b-col>
      <b-col class="mt-1">
        <b-row>
          <b-col cols="12">
            <b-form-checkbox switch type="text" v-model="developerMode"></b-form-checkbox>
            <b-form-text> Enable developer mode </b-form-text>
          </b-col>
        </b-row>
      </b-col>
    </b-form-row>
    <b-form-row class="mt-4">
      <b-col cols="3">
        <h4 for="name">Connection state</h4>
      </b-col>
      <b-col>
        <b-row>
          <b-col cols="12">
            <b-form-checkbox switch type="text" v-model="showConnectionState"></b-form-checkbox>
            <b-form-text>
              Show connection state in the navigation bar
            </b-form-text>
          </b-col>
        </b-row>
      </b-col>
    </b-form-row>
    <b-form-row class="mt-4">
      <b-col cols="3">
        <h4 for="name">Instance details</h4>
      </b-col>
      <b-col class="mt-1">
        <b-row>
          <b-col cols="12">
            <b-form-checkbox
              switch
              id="show-instance-details"
              type="text"
              v-model="showInstanceDetails"
            >
            </b-form-checkbox>
            <b-form-text>
              Show instance details in the navigation bar
            </b-form-text>
          </b-col>
        </b-row>
      </b-col>
    </b-form-row>
  </b-container>
</template>

<script>
import PageTitle from "@/components/share/PageTitle";
import { mapState } from "vuex";
import {
  HIDE_INSTANCE_DETAILS,
  SET_DEVELOPER_MODE,
  SET_SHOW_CONNECTION_STATE
} from "@/store/mutation-types";

export default {
  name: "Settings",
  components: { PageTitle },
  data() {
    return {};
  },
  methods: {},
  computed: {
    ...mapState(["user", "instance"]),
    developerMode: {
      set(value) {
        this.$store.commit(SET_DEVELOPER_MODE, value);
      },
      get() {
        return this.$store.state.settings.developerMode;
      }
    },
    showConnectionState: {
      set(value) {
        this.$store.commit(SET_SHOW_CONNECTION_STATE, value);
      },
      get() {
        return this.$store.state.settings.showConnectionState;
      }
    },
    showInstanceDetails: {
      set(value) {
        this.$store.commit(HIDE_INSTANCE_DETAILS, !value);
      },
      get() {
        return !this.$store.state.settings.hideInstanceDetails;
      }
    }
  }
};
</script>

<style scoped></style>
