<template>
  <b-container fluid class="my-4 px-5">
    <page-title
      class="my-3"
      title="Wrapper Generator"
      subtitle="Create a wrapper to import a new process version"
    >
      <template v-slot:toolbar>
        <b-btn-toolbar class="float-right">
          <b-btn-group>
            <b-btn variant="success" @click="downloadWrapper()">
              <fa-icon class="mr-2" icon="download"></fa-icon>
              <span>Download wrapper</span>
            </b-btn>
          </b-btn-group>
        </b-btn-toolbar>
      </template>
    </page-title>
    <b-row>
      <b-col lg="12" xl="6">
        <b-card header="Inputs" class="mt-2">
          <b-row v-if="inputs.length !== 0" class="mb-1">
            <b-col sm="4">
              <h6>Name</h6>
            </b-col>
            <b-col sm="4">
              <h6>Data type</h6>
            </b-col>
            <b-col sm="4">
              <h6>Default value</h6>
            </b-col>
          </b-row>
          <empty class="m-0 p-0" v-if="inputs.length === 0" text="No inputs" no-icon></empty>
          <b-row class="mt-2" v-for="(input, index) in inputs" :key="input._key">
            <b-col sm="4">
              <b-form-input
                v-model="input.name"
                placeholder="Input name"
                type="text"
              ></b-form-input>
            </b-col>
            <b-col sm="4">
              <multiselect
                v-model="input.dataType"
                :options="Object.values(dataTypes)"
                placeholder="Select data type"
                label="label"
                :custom-track-by="getDataTypeFullId"
                :disabled="fetching"
              ></multiselect>
            </b-col>
            <b-col sm="3">
              <b-form-input
                placeholder="Default value"
                type="text"
                v-model="input.defaultValue"
              ></b-form-input>
            </b-col>
            <b-col sm="1" align="right">
              <b-btn variant="danger" @click="removeInput(index)">
                <fa-icon icon="times"></fa-icon>
              </b-btn>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col align="right">
              <b-btn
                class="add-btn"
                variant="outline-success"
                @click="addInput()"
                :disabled="fetching"
              >
                <fa-icon icon="plus"></fa-icon>
                <span class="ml-2">Add input</span>
              </b-btn>
            </b-col>
          </b-row>
        </b-card>
        <b-card header="Ouputs" class="mt-2">
          <b-row v-if="outputs.length !== 0" class="mb-1">
            <b-col sm="6">
              <h6>Name</h6>
            </b-col>
            <b-col sm="6">
              <h6>Data type</h6>
            </b-col>
          </b-row>
          <empty class="m-0 p-0" v-if="outputs.length === 0" text="No outputs" no-icon></empty>
          <b-row class="mt-2" v-for="(output, index) in outputs" :key="output._key">
            <b-col sm="6">
              <b-form-input
                placeholder="Output name"
                type="text"
                v-model="output.name"
              ></b-form-input>
            </b-col>
            <b-col sm="5">
              <multiselect
                v-model="output.dataType"
                :options="Object.values(dataTypes)"
                placeholder="Select data type"
                label="label"
                :custom-track-by="getDataTypeFullId"
                :disabled="fetching"
              ></multiselect>
            </b-col>
            <b-col sm="1" align="end">
              <b-btn class="float-right" variant="danger" @click="removeOutput(index)">
                <fa-icon icon="times"></fa-icon>
              </b-btn>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col align="right">
              <b-btn
                class="add-btn"
                variant="outline-success"
                @click="addOutput()"
                :disabled="fetching"
              >
                <fa-icon icon="plus"></fa-icon>
                <span class="ml-2">Add output</span>
              </b-btn>
            </b-col>
          </b-row>
        </b-card>
        <b-card header="Main dependency" class="mt-2">
          <b-form-row>
            <b-col align="center">
              <empty
                v-if="fetching"
                class="m-0 p-0"
                text="Fetching dependencies..."
                no-icon
              ></empty>
              <empty
                class="m-0 p-0"
                v-else-if="mainSoftwareDependencies.length === 0"
                text="No main dependencies"
                no-icon
              ></empty>
              <multiselect
                v-else
                v-model="mainDep"
                :options="mainSoftwareDependencies"
                placeholder="Select main dependency"
                label="label"
                track-by="identifier"
                @input="changeMainDep"
              >
                <span slot="noResult">
                  No dependencies found.
                </span>
              </multiselect>
            </b-col>
          </b-form-row>
        </b-card>
        <b-card header="Software dependencies" class="mt-2">
          <b-form-row>
            <b-col align="center">
              <empty
                v-if="fetching"
                class="m-0 p-0"
                text="Fetching dependencies..."
                no-icon
              ></empty>
              <empty
                class="m-0 p-0"
                v-else-if="softwareDependencies.length === 0"
                text="No software dependencies"
                no-icon
              ></empty>
              <multiselect
                v-else
                v-model="softwareDeps"
                :options="softwareDependencies"
                track-by="identifier"
                label="label"
                placeholder="Select software dependencies"
                :multiple="true"
                @input="changeSoftDeps"
              ></multiselect>
              <b-row class="mt-3">
                <b-col
                  v-for="(dep, index) in missingRequirements"
                  :key="index"
                  class="text-danger"
                  cols="12"
                  align="left"
                >
                  Missing requirement <b>{{ dep.label }}</b> of {{ mainDep ? mainDep.label : "" }}
                </b-col>
              </b-row>
            </b-col>
          </b-form-row>
        </b-card>
        <b-card header="Resources" class="mt-2">
          <b>RAM</b>
          <vue-slider
            class="mb-5 p-3"
            :data="resourceOptions.ram"
            v-model="resources.ram"
            :marks="sizeMark"
          />
          <b>Disk</b>
          <vue-slider
            class="mb-5 p-3"
            :data="resourceOptions.disk"
            v-model="resources.disk"
            :marks="sizeMark"
          />
          <b>CPU</b>
          <vue-slider class="mb-4 p-3" :data="resourceOptions.cpu" v-model="resources.cpu" marks />
          <b>GPU</b>
          <vue-slider class="mb-4 p-3" :data="resourceOptions.gpu" v-model="resources.gpu" marks />
        </b-card>
      </b-col>
      <b-col lg="12" xl="6">
        <b-card header="process_wrapper.py">
          <process-wrapper-template
            :inputs="inputs"
            :outputs="outputs"
            :resources="resources"
            :main-dep="mainDep"
            :software-deps="softwareDeps"
            ref="processWrapper"
          ></process-wrapper-template>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ProcessWrapperTemplate from "@/components/processes/ProcessWrapperTemplate";
import PageTitle from "@/components/share/PageTitle";
import { knowledgeService } from "@/api/asb";
import Empty from "@/components/share/Empty";
import Multiselect from "vue-multiselect";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";
import { saveAs } from "file-saver";
import { arrayUnique } from "@/utils";

// Default data type is 45/User String
const DEFAULT_DATA_TYPE_ID = 45;

export default {
  name: "ProcessWrapperGenerator",
  components: { Empty, PageTitle, ProcessWrapperTemplate, Multiselect, VueSlider },
  data() {
    return {
      mainDep: undefined,
      softwareDeps: [],
      missingRequirements: [],
      // Counters to track i/o creation, to avoid using array index as key.
      // We cannot use the array index as we remove elements on the fly,
      // and Vue needs to track the component using a unique identifier.
      inputCounter: 1,
      outputCounter: 1,
      inputs: [],
      outputs: [],
      fetching: true,
      resources: {
        ram: 1,
        disk: 1,
        cpu: 1,
        gpu: 0
      },
      resourceOptions: {
        ram: [1, 2, 4, 7, 15],
        disk: [1, 10, 20, 50, 100],
        cpu: [1, 2, 4, 6, 8, 16, 32],
        gpu: [0, 1, 2]
      },
      sizeMark: val => ({
        label: `${val}GB`
      })
    };
  },
  created() {
    this.fetchData()
      .catch(e => knowledgeService.handleError(e))
      .finally(() => (this.fetching = false));
  },
  methods: {
    async fetchData() {
      await Promise.all([
        this.$store.dispatch("knowledge/get", "data-types"),
        this.$store.dispatch("knowledge/get", "software-dependencies")
      ]);
    },
    addInput() {
      const _key = this.inputCounter++;
      this.inputs.push({
        _key: _key,
        name: `Input ${_key}`,
        dataType: this.dataTypes[DEFAULT_DATA_TYPE_ID],
        defaultValue: ""
      });
    },
    addOutput() {
      const _key = this.outputCounter++;
      this.outputs.push({
        _key: _key,
        name: `Output ${_key}`,
        dataType: this.dataTypes[DEFAULT_DATA_TYPE_ID]
      });
    },
    removeInput(index) {
      this.$delete(this.inputs, index);
    },
    removeOutput(index) {
      this.$delete(this.outputs, index);
    },
    getDataTypeFullId(dataType) {
      return dataType._jv.id + "/" + dataType.label;
    },
    downloadWrapper() {
      let data = this.$refs.processWrapper.$el.innerText;
      let mimetype = "text/x-python"; // or: "application/x-python-code"
      let blob = new Blob([data], { type: mimetype + ";charset=utf-8" });
      saveAs(blob, "process_wrapper.py");
      return false;
    },
    changeMainDep(mainDep) {
      this.missingRequirements = [];
      if (mainDep) {
        console.debug("Main dep", mainDep);
        let requirements = Object.values(mainDep.requirements);
        console.debug("Main dep requirements", requirements);
        console.debug("User selected deps: ", this.softwareDeps);
        this.softwareDeps = arrayUnique([...this.softwareDeps, ...requirements]);
        console.debug("Merged", this.softwareDeps);
      }
    },
    changeSoftDeps(softDeps) {
      console.debug("Soft deps changed", softDeps);
      this.missingRequirements = [];
      if (this.mainDep) {
        Object.values(this.mainDep.requirements).forEach(dep => {
          if (!this.softwareDeps.some(sd => sd.identifier === dep.identifier)) {
            this.missingRequirements.push(dep);
          }
        });
      }
    }
  },
  computed: {
    dataTypes() {
      return this.$store.getters["knowledge/get"]("data-types");
    },
    mainSoftwareDependencies() {
      return Object.values(this.$store.getters["knowledge/get"]("software-dependencies")).filter(
        s => s.isMain
      );
    },
    softwareDependencies() {
      return Object.values(this.$store.getters["knowledge/get"]("software-dependencies")).filter(
        s => !s.isMain
      );
    }
  }
};
</script>

<style scoped>
.add-btn {
  text-transform: uppercase;
  font-size: 0.8rem;
  width: 10rem;
}
</style>
