<template>
  <b-container class="px-0">
    <b-modal
      align-h="end"
      hide-footer
      ref="changepassword"
      id="changepassword"
      title="Change password"
    >
      <b-form-group
        v-for="input in password_inputs"
        :key="input.id"
        :label="input.label"
        :label-for="`${input.id}_input`"
        label-class="font-weight-bold"
        :description="input.text"
      >
        <b-form-input
          :id="`${input.id}_input`"
          v-model="input.value"
          :state="fieldState(input.name)"
          type="password"
        ></b-form-input>
        <b-form-invalid-feedback :state="fieldState(input.name)">
          <span v-for="(error, index) in input.errors" :key="index"> {{ error }}<br /> </span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-button variant="dark" @click="updatePassword">Update password</b-button>
    </b-modal>

    <b-button class="mb-3" size="sm" variant="dark" v-b-modal.changepassword
      >Change password</b-button
    >
  </b-container>
</template>

<script>
import { userService } from "@/api/asb";
import { BAD_REQUEST } from "http-status-codes";

export default {
  name: "UpdatePasswordForm",
  methods: {
    updatePassword() {
      // Clear previous errors
      this.password_inputs.forEach(input => (input.errors = []));

      let data = {
        current_password: this.password_inputs[0].value,
        new_password: this.password_inputs[1].value,
        confirm_new_password: this.password_inputs[2].value
      };
      userService
        .updatePassword(data)
        .then(() => {
          this.$notify({
            group: "global",
            type: "success",
            title: `Password updated successfully`,
            duration: 3000,
            text: ""
          });
          this.password_inputs.forEach(input => (input.value = ""));
          this.$bvModal.hide("changepassword");
        })
        .catch(error => {
          if (error.response && error.response.status === BAD_REQUEST) {
            this.parseErrors(error);
          } else {
            userService.handleError(error);
          }
        });
    },
    parseErrors(errors) {
      let data = errors.response.data;

      for (let i in this.password_inputs) {
        let input = this.password_inputs[i];
        input.errors = data[input.name] ? data[input.name] : [];
      }

      if (data["non_field_errors"]) {
        data["non_field_errors"].forEach(error => this.password_inputs[1].errors.push(error));
      }
    },

    // Check if a given field has an error associated with it
    field_error(password_field) {
      for (let input in this.password_inputs) {
        if (this.password_inputs[input].name === password_field) {
          return this.password_inputs[input].errors != 0;
        }
      }
    },
    fieldState(password_field) {
      if (this.field_error(password_field)) {
        console.log(password_field);
        return false;
      }
      if (password_field === "new_password") {
        return this.passwordLength;
      }
      if (password_field === "confirm_new_password") {
        return this.validInput;
      }
      return null;
    }
  },
  data() {
    return {
      password_inputs: [
        {
          name: "current_password",
          label: "Current password",
          text: "Please enter your current password.",
          value: "",
          errors: []
        },
        {
          name: "new_password",
          label: "New password",
          text: "Please enter your new password.",
          value: "",
          errors: []
        },
        {
          name: "confirm_new_password",
          label: "Confirm new password",
          text: "Please confirm your new password.",
          value: "",
          errors: []
        }
      ]
    };
  },
  computed: {
    passwordsMatch() {
      return this.password_inputs[1].value === this.password_inputs[2].value ? true : false;
    },
    passwordLength() {
      return this.password_inputs[1].value.length >= 8 ? true : null;
    },
    validInput() {
      return this.passwordsMatch && this.passwordLength;
    }
  }
};
</script>
