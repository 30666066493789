import axios from "axios";
import { handleServiceError, tokenInterceptor } from "@/api/utils";
import { asbGenericClient, baseURLFromEnv, defaultClientConfig } from "@/api/asb/client";

export const userClient = axios.create({
  ...defaultClientConfig,
  baseURL: baseURLFromEnv("usermgr/v1/")
});

userClient.interceptors.request.use(tokenInterceptor);

export default {
  handleError: error => handleServiceError("User Manager", error),
  async getDetails() {
    let { data } = await asbGenericClient.get("usermgr/api/details");
    return data;
  },
  async getToken() {
    let { data } = await userClient.get("token/");
    return data.token;
  },
  async postToken(username, password) {
    let { data } = await userClient.post("token/", { username: username, password: password });
    return data.token;
  },
  async getUsers() {
    let { data } = await userClient.get("users/");
    return data;
  },
  async getUser(username) {
    let { data } = await userClient.get(`users/${username}/`);
    return data;
  },
  async createUser(user) {
    return await userClient.post("users/", user);
  },
  async updateUser(user) {
    return await userClient.put(`users/${user.username}/`, user);
  },
  async getGroups() {
    let { data } = await userClient.get(`groups/`);
    return data;
  },
  async createWorkspaceRoles(data) {
    return await userClient.post(`groups/`, data);
  },
  async getGroupMembers(group) {
    let { data } = await userClient.get(`groups/${group}/members/`);
    return data;
  },
  async updatePassword(password_data) {
    return await userClient.patch("change_password/", password_data);
  },
  async checkExecutionAllowed() {
    return await userClient.get("permissioncheck/");
  },
  async checkDatastoreCredentials() {
    return await userClient.get("check_datastore/");
  },
  async fetchDatastoreCredentials(password_data) {
    return await userClient.post("datastore_credentials/", password_data);
  },
  async getAcceptedTermsAndConditions() {
    let { data } = await userClient.get("terms-and-conditions/");
    return data;
  },
  async acceptTermsAndConditions(tcs) {
    let post_data = {
      terms_and_conditions_id: tcs
    };
    let { data } = await userClient.post("terms-and-conditions/accept/", post_data);
    return data;
  },
  async revokeConsentForTermsAndConditions(tcs) {
    let post_data = {
      terms_and_conditions_id: tcs
    };
    let { data } = await userClient.post("terms-and-conditions/revoke/", post_data);
    return data;
  }
};
