import * as HttpStatus from "http-status-codes";
import Vue from "vue";

export { HttpStatus };

export const TOKEN_COOKIE_NAME = "asbglobaltoken";

export const handleServiceError = (serviceName, error) => {
  // console.error(error);
  const response = error.response;
  let detail = error.message;
  let type = "error";
  if (response) {
    if (response.status < 500) type = "warn";
    if (response.status === HttpStatus.FORBIDDEN || response.status === HttpStatus.UNAUTHORIZED) {
      detail = "You do not have permission to perform this action.";
    } else if (response.status === HttpStatus.BAD_REQUEST) {
      detail = "The request you made is incorrect.";
    } else if (
      [HttpStatus.BAD_GATEWAY, HttpStatus.GATEWAY_TIMEOUT, HttpStatus.REQUEST_TIMEOUT].includes(
        response.status
      )
    ) {
      detail =
        "The service could not be contacted. " +
        "This may be a temporary network issue or the service may be down.";
    } else {
      detail = `${response.statusText} (${response.status})`;
    }
    if (response.data) {
      if (response.data.detail) {
        detail = `${response.data.detail}`;
      } else if (response.data.errors) {
        for (error of response.data.errors) {
          detail = error.detail;
        }
      }
    }
  }
  const title = type === "error" ? `Error` : `Warning`;
  Vue.notify({
    group: "global",
    type: type,
    title: title,
    duration: 20000, // in ms, -1 = no duration
    text: detail
  });
  return response;
};

export const tokenInterceptor = config => {
  let token = Vue.$cookies.get(TOKEN_COOKIE_NAME);
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
};

export const suffixWithBackslash = baseURL => (baseURL.endsWith("/") ? baseURL : baseURL + "/");

export const getLoginUrl = oidcEnabled => {
  let url;
  if (oidcEnabled) {
    url = "/usermgr/oidc/authenticate";
  } else {
    url = "/usermgr/cas/login";
  }
  console.debug(`loginUrl oidcEnabled: ${oidcEnabled} and using ${url}`);
  return url;
};

export const getLogoutUrl = oidcEnabled => {
  let url;
  if (oidcEnabled) {
    url = "/usermgr/oidc/logout";
  } else {
    url = "/usermgr/cas/logout";
  }
  console.debug(`logoutUrl oidcEnabled: ${oidcEnabled} and using ${url}`);
  return url;
};

export const isUrl = string => {
  return string ? string.startsWith("http://") || string.startsWith("https://") : false;
};

// Functions for marketplace data
export const organiseData = (resources, filter_types) => {
  // This function extracts the options for the filters defined here.
  console.log(resources);
  console.warn(filter_types);
  let filters = [];
  filters.push({
    key: "type",
    name: "Resource Type",
    values: new Set(),
    type: "properties"
  });
  filters.push({
    key: "author",
    name: "Author",
    values: new Set(),
    type: "static"
  });
  filters.push({
    key: "email",
    name: "Email",
    values: new Set(),
    type: "static"
  });
  filters.push({
    key: "organization",
    name: "Organization",
    values: new Set(),
    type: "static"
  });
  filters.push({
    key: "ml-model:learning_approach",
    name: "ML Learning Approach",
    values: new Set(),
    type: "properties"
  });
  filters.push({
    key: "ml-model:architecture",
    name: "ML Architecture",
    values: new Set(),
    type: "properties"
  });
  filters.push({
    key: "ml-model:prediction_type",
    name: "ML Prediction Type",
    values: new Set(),
    type: "properties"
  });

  filters.push({
    key: "licence",
    name: "Licence",
    values: new Set(),
    type: "keyword"
  });
  if (filter_types) {
    for (let f of Object.values(filter_types)) {
      filters.push({ key: f.identifier, name: f.label, values: new Set(), type: "keyword" });
    }
  }

  for (let resource of resources) {
    if (resource.links) {
      for (let link of resource.links) {
        if (link["name"] == "license") {
          resource.licence = link["url"];
        }
        if (link["name"] == "releaseNotes") {
          resource.releaseNotes = link["url"];
        }
        // Add links here that should be filterable
        // if (link["name"] == "<key>") {
        //   app.<key> = link["<field>"]
        // }
      }
    }
    if (resource.assets) {
      // extract the thumbnail and logo URLs from the assets, if present
      for (let asset of Object.values(resource.assets)) {
        if ("roles" in asset && asset.roles.includes("thumbnail")) {
          if ("href" in asset && asset.href.startsWith("http")) {
            resource.thumbnail = asset.href;
          }
        }
        if ("roles" in asset && asset.roles.includes("logo")) {
          if ("href" in asset && asset.href.startsWith("http")) {
            resource.logo = asset.href;
          }
        }
      }
    }

    for (let filter of filters) {
      let valueFound = false;
      if (filter.type == "keyword") {
        // Add FILTER:<>:<>
        var regex = new RegExp(`FILTER:${filter.key}:(.*)`);
        for (let keyword in resource.properties.keywords) {
          if (regex.test(resource.properties.keywords[keyword])) {
            let groups = regex.exec(resource.properties.keywords[keyword]);
            filter.values.add(groups[1]); // add the value of the found filter
            valueFound = true;
          }
        }
      } else if (filter.type == "properties") {
        // Add the value of a key of the app
        // (for values not stored in the keywords)
        if (resource.properties[filter.key]) {
          filter.values.add(resource.properties[filter.key]);
          valueFound = true;
        }
      } else {
        if (!valueFound && resource[filter.key]) {
          filter.values.add(resource[filter.key]);
          valueFound = true;
        }
      }
      if (!valueFound) {
        if (!("Not specified" in filter.values)) {
          filter.values.add("Not specified");
        }
      }
    }
    resource.version = parseSoftwareVersion(resource);
  }

  // Only return filters for which there are more than 1 values
  filters = filters.filter(filter => filter.values.size > 1);
  return {
    resources: resources,
    filter_options: filters
  };
};

export const parseSoftwareVersion = resource => {
  let version = "";
  if (resource.keywords) {
    for (let keyword of resource.keywords) {
      if (/softwareVersion:(.*)/.test(keyword)) {
        version = keyword.split(":")[1];
        break;
      }
    }
  }

  return version;
};

export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
