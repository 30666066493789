// import TweetsFilter from "@/components/tweets/TweetsFilter";
// import TweetsList from "@/components/tweets/TweetsList";
// import LeafletMap from "./LeafletMap";
import InfoPanel from "./InfoPanel";
// import Dygraph from "@/components/charts/Dygraph";
// import ClimGridChart from "@/components/charts/ClimGridChart";
// import ClimGridStatistics from "@/components/charts/ClimGridStatistics";
// import OpenLayersMap from "@/components/maps/OpenLayersMap";
// import MattermostButtons from "@/components/messaging/MattermostButtons";
// import MattermostInline from "@/components/messaging/MattermostInline";
import DashboardDescription from "./DashboardDescription";
// import TopicsFilter from "@/components/tweets/TopicsFilter";
// import TopicsList from "@/components/tweets/TopicsList";
// import TopicTweetsList from "@/components/tweets/TopicTweetsList";
// import CommunitiesFilter from "@/components/tweets/CommunitiesFilter";
// import CommunitiesGraph from "@/components/tweets/CommunitiesGraph";
// import TopKeyPlayersList from "@/components/tweets/TopKeyPlayersList";
// import TopKeyPlayerCard from "@/components/tweets/TopKeyPlayerCard";
// import UserTweetsList from "@/components/tweets/UserTweetsList";
// import GeolocalisedLinkedData from "@/components/maps/GeolocalisedLinkedData";
import GeoTIFFViewer from "@/components/products/GeoTIFFViewer.vue";
import COGMap from "@/components/products/COGMap.vue";

export const vueComponents = {
  // Generic
  DashboardDescription,
  // EOPEN Components
  // TweetsFilter,
  // TweetsList,
  // LeafletMap,
  InfoPanel,
  GeoTIFFViewer,
  COGMap
  // Dygraph,
  // ClimGridChart,
  // ClimGridStatistics,
  // OpenLayersMap,
  // MattermostButtons,
  // MattermostInline,
  // TopicsFilter,
  // TopicsList,
  // GeolocalisedLinkedData,
  // TopicTweetsList,
  // CommunitiesFilter,
  // CommunitiesGraph,
  // TopKeyPlayersList,
  // TopKeyPlayerCard,
  // UserTweetsList
};

export const components = [
  // {
  //   name: "Tweets Filter",
  //   vueComponent: "TweetsFilter",
  //   description: "",
  //   w: 2,
  //   h: 16
  // },
  // {
  //   name: "Tweets List",
  //   vueComponent: "TweetsList",
  //   description: "",
  //   w: 6,
  //   h: 16
  // },
  // {
  //   name: "Map (Leaflet)",
  //   vueComponent: "LeafletMap",
  //   description: "",
  //   w: 4,
  //   h: 19
  // },
  {
    name: "Information Panel",
    vueComponent: "InfoPanel",
    description: "",
    w: 12,
    h: 5
  },
  {
    name: "Dashboard Description",
    vueComponent: "DashboardDescription",
    description: "Show the description of the current dashboard<br/>",
    w: 8,
    h: 3
  },
  {
    name: "GeoTIFF Viewer",
    vueComponent: "GeoTIFFViewer",
    description: "Visualize a GeoTIFF/COG",
    w: 7,
    h: 20
  },
  {
    name: "COG Map",
    vueComponent: "COGMap",
    description: "Visualize a GeoTIFF/COG",
    w: 7,
    h: 20
  }
  // {
  //   name: "Weather Data Example (Mockup)",
  //   vueComponent: "Dygraph",
  //   w: 10,
  //   h: 16
  // },
  // {
  //   name: "ClimGrid Time Series",
  //   vueComponent: "ClimGridChart",
  //   w: 10,
  //   h: 16
  // },
  // {
  //   name: "ClimGrid Statistics",
  //   vueComponent: "ClimGridStatistics",
  //   w: 10,
  //   h: 16
  // },
  // {
  //   name: "Topics Filter",
  //   vueComponent: "TopicsFilter",
  //   description: "",
  //   w: 3,
  //   h: 5
  // },
  // {
  //   name: "Topics List",
  //   vueComponent: "TopicsList",
  //   description: "",
  //   w: 5,
  //   h: 5
  // },
  // {
  //   name: "Topic Tweets List",
  //   vueComponent: "TopicTweetsList",
  //   description: "",
  //   w: 4,
  //   h: 5
  // },
  // {
  //   name: "Geolocalised Linked Data",
  //   vueComponent: "GeolocalisedLinkedData",
  //   description: "",
  //   w: 10,
  //   h: 16
  // },
  // {
  //   name: "Communities Filter",
  //   vueComponent: "CommunitiesFilter",
  //   description: "",
  //   w: 2,
  //   h: 10
  // },
  // {
  //   name: "Communities Graph",
  //   vueComponent: "CommunitiesGraph",
  //   description: "",
  //   w: 6,
  //   h: 10
  // },
  // {
  //   name: "Top Key Players List",
  //   vueComponent: "TopKeyPlayersList",
  //   description: "",
  //   w: 2,
  //   h: 10
  // },
  // {
  //   name: "Top Key Player Card",
  //   vueComponent: "TopKeyPlayerCard",
  //   description: "",
  //   w: 4,
  //   h: 10
  // },
  // {
  //   name: "User Tweets List",
  //   vueComponent: "UserTweetsList",
  //   description: "",
  //   w: 2,
  //   h: 10
  // }
];
