<template>
  <div>
    <!-- Sidebar  -->
    <nav id="sidebar" :class="{ collapsed: collapsed }">
      <div class="sidebar-header">
        <span class="title"> {{ title }}</span>
        <span class="short-title">{{ shortTitle }}</span>
      </div>
      <ul class="list-unstyled">
        <!-- TODO Following snippet enable dropdown capabilities. -->
        <!-- TODO It must be refactored to be used with sidebar items and subitems -->
        <!--<li v-for="item in items">-->
        <!--<router-link v-b-toggle="'homeSubmenu'"-->
        <!--:to="item.to"-->
        <!--data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">-->
        <!--<fa-icon v-if="item.icon" :icon="item.icon"></fa-icon>-->
        <!--<span v-show="!collapsed" class="ml-2">{{ item.label }}</span>-->
        <!--</router-link>-->
        <!--<b-collapse id="homeSubmenu">-->
        <!--<ul class="list-unstyled">-->
        <!--<li v-for="subItem in item.items">-->
        <!--<router-link :to="subItem.to">-->
        <!--<fa-icon v-if="item.icon" :icon="item.icon"></fa-icon>-->
        <!--<span class="ml-2">{{ subItem.label }}</span>-->
        <!--</router-link>-->
        <!--</li>-->
        <!--</ul>-->
        <!--</b-collapse>-->
        <!--</li>-->
        <slot name="top"> </slot>
        <li
          v-for="(item, index) in items"
          :key="`sidebar-item-${index}`"
          :id="`sidebar-item-${index}`"
        >
          <b-tooltip
            v-if="collapsed"
            :target="`sidebar-item-${index}`"
            :title="item.label"
            placement="right"
          >
          </b-tooltip>
          <router-link class="nav-item" :to="item.to">
            <i class="item-icon">
              <fa-icon class="item-icon" :icon="item.icon ? item.icon : 'cog'"></fa-icon>
            </i>
            <span v-show="!collapsed">{{ item.label }}</span>
          </router-link>
        </li>
      </ul>
    </nav>
    <b-button id="sidebarCollapse" class="collapse-btn" @click="collapsed = !collapsed">
      <span v-if="!collapsed"><fa-icon icon="angle-double-left"></fa-icon></span>
      <fa-icon icon="angle-double-right" v-else>CS</fa-icon>
    </b-button>
    <!-- Page Content  -->
    <b-container id="content" class="m-0 p-0" :class="{ collapsed: collapsed }" fluid>
      <b-row no-gutters>
        <b-col cols="12">
          <slot name="content"> </slot>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "SideBar",
  props: {
    // TODO create type description here, might be interesting to switch to TypeScript to avoid such situation
    items: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: undefined
    },
    shortTitle: {
      type: String,
      required: false,
      default: undefined
    }
    // items: [
    // {
    //     label: 'Users',
    //     icon: 'user',
    //     to: {name: 'admin:users'},
    // },
    // ]
  },
  data() {
    return {
      collapsed: false
    };
  }
};
</script>

<style lang="scss" scoped>
/*
 * Sidebar
 */
@import "@/assets/scss/default/asb.scss";

.collapse-btn {
  font-size: $font-size-base * 1.3;
  left: 15px;
  bottom: 10px;
  position: fixed;
  background: transparent;
  color: gray("600");
  border-width: 0;

  &:focus,
  &:active,
  &:hover,
  &:visited {
    outline: none !important;
    box-shadow: none !important;
    background: transparent !important;
    color: black !important;
  }
}

#sidebar {
  position: fixed;
  min-width: $sidebar-width;
  max-width: $sidebar-width;
  height: 100%;
  /* Uncomment if want to animate sidebar */
  /*transition: all 0.3s;*/
  background: $sidebar-bg-color;
  border-right: $sidebar-border-width solid $sidebar-border-color;

  .sidebar-header {
    text-align: center;
    height: 50px;
    padding: 20px 0 40px 0;
    color: gray("600");
    font-variant: small-caps;
    font-size: $font-size-base * 1.2;
    font-weight: bold;

    .short-title {
      display: none;
    }
  }

  ul li .nav-item {
    padding: 10px 0 10px 20px;
    font-size: 1.1em;
    text-decoration: none;
    display: block;
    color: gray("900");

    &:hover {
      cursor: pointer;
      background: gray("300");
    }

    .item-icon {
      width: 30px;
    }
  }

  &.collapsed {
    min-width: $sidebar-collapsed-width;
    max-width: $sidebar-collapsed-width;

    ul li a {
      padding: 10px;
      text-align: center;
    }

    .sidebar-header {
      .title {
        display: none;
      }

      .short-title {
        display: block;
      }
    }

    .dropdown-toggle::after {
      top: auto;
      right: 50%;
      -webkit-transform: translateX(50%);
      -ms-transform: translateX(50%);
      transform: translateX(50%);
    }
  }
}

a[data-toggle="collapse"] {
  position: relative;
}

a[aria-expanded="true"] {
  background: gray("300");
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

ul ul a {
  font-size: 0.95em !important;
  background: gray("200");
}

#content {
  /* Uncomment if want to animate sidebar */
  /*transition: all 0.3s;*/
  padding-left: $sidebar-width !important;

  &.collapsed {
    padding-left: $sidebar-collapsed-width !important;
  }

  .sidebar-header {
    .title,
    .short-title {
      display: block;
    }
  }
}

@include media-breakpoint-down(sm) {
  #content {
    padding-left: 0 !important;
  }

  #sidebar {
    min-width: 80px;
    max-width: 80px;
    text-align: center;
    margin-left: -80px !important;
    padding-top: 20px;

    &.collapsed {
      margin-left: 0 !important;
    }

    .title {
      display: none;
    }
  }

  .dropdown-toggle::after {
    top: auto;
    bottom: 10px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
  }

  .collapse-btn {
    position: sticky;
    top: 0;
  }
}
</style>
