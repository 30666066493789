<template>
  <b-form @submit="filterResources">
    <b-row>
      <b-col>
        <b-input-group>
          <b-form-input
            class="rounded-0 outline-0"
            v-model="search"
            type="text"
            :placeholder="placeholder ? placeholder : 'Filter resources'"
            :disabled="disabled || loading"
          ></b-form-input>

          <b-button-group class="ml-2 rounded-0 outline-0">
            <b-dropdown
              v-show="!$_.isEmpty(filters)"
              text="Filters"
              ref="dropdown"
              class="rounded-0"
              variant="plain"
              right
              :disabled="disabled || loading"
            >
              <b-dropdown-item
                v-for="(filter, index) in filters"
                :key="index"
                :disabled="alreadyAdded(filters[index])"
                @click="addFilter(filters[index])"
              >
                {{ filters[index].label }}
              </b-dropdown-item>
            </b-dropdown>
            <b-btn variant="plain" type="submit" :disabled="disabled || loading">
              <fa-icon icon="search" />
            </b-btn>
          </b-button-group>
        </b-input-group>
      </b-col>
    </b-row>
    <b-btn
      v-for="(value, filter, index) in userFilters"
      :key="filter"
      v-show="value"
      variant="plain"
      size="sm"
      class="mr-2 my-2"
      :disabled="disabled || loading"
      @click="removeFilterAt(index)"
    >
      <fa-icon icon="times" class="mr-1" />
      <span>{{ filter }}:{{ value }}</span>
    </b-btn>
    <b-form-checkbox
      class="my-2 float-right"
      v-model="autoReload"
      @input="autoReload ? filterResources() : null"
      :disabled="disabled || loading"
      switch
    >
      Auto reload
    </b-form-checkbox>
  </b-form>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ResourceFilters",
  props: {
    filters: {
      type: Array,
      required: false,
      // Entry example:
      // { name: "isArchived", value: () => "false", label: "Hide archived", default: true }
      // value function should always return a string, even for booleans:
      // everything is parsed from the search filter.
      default: () => []
    },
    loading: {
      type: Boolean,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      search: "",
      autoReload: false
    };
  },
  created() {
    // Avoid auto reload when adding default filters
    this.autoReload = false;
    let urlSearch = this.$route.query["f"];
    if (urlSearch) {
      this.search = urlSearch;
    } else {
      for (let filter of this.filters) {
        if (filter.default) {
          this.addFilter(filter);
        }
      }
    }
    this.autoReload = true;
    this.$emit("ready");
  },
  methods: {
    addFilter(filter) {
      // FIXME addFilter should be change to setFilter i.e. we completely erase the
      //  search field when user change the filter
      if (!this.alreadyAdded(filter)) {
        console.debug("Add filter", filter);
        this.search = `${filter.name}:${filter.value(this)} ${this.search}`;
        if (this.autoReload) {
          this.filterResources();
        }
      }
    },
    removeFilterAt(index) {
      let userFilters = this.search.split(" ");
      userFilters.splice(index, 1);
      this.search = userFilters.join(" ");
      if (this.autoReload) {
        this.filterResources();
      }
    },
    filterResources(event = undefined) {
      console.debug("Emit filter event");
      if (event) {
        event.preventDefault();
      }
      this.$emit("filter");
    },
    alreadyAdded(filter) {
      console.error(`checking ${filter}`);
      return this.userFilters[filter.name] === filter.value(this);
    }
  },
  computed: {
    ...mapState({
      user: state => state.user
    }),
    userFilters() {
      let filters = {};
      let rawUserFilters = this.search.split(" ");
      for (let index in rawUserFilters) {
        let [name, value] = rawUserFilters[index].split(":");
        if (value === undefined) {
          // if the value is undefined it means that the string is not a filter name:value,
          // therefore we stop and we consider the rest of the string as the search filter
          filters.search = rawUserFilters.slice(index, rawUserFilters.length).join(" ");
          break;
        } else {
          console.debug("Found user filter:", name, value);
          if (!filters[name]) {
            filters[name] = value;
          } else {
            console.warn(`Duplicate filter ${name}!`);
          }
        }
      }
      console.debug("User filters", filters);
      return filters;
    }
  }
};
</script>

<style scoped></style>
