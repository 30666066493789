<template>
  <b-container class="px-0" fluid>
    <notifications
      class="notification-group mb-1"
      classes="notifications"
      group="global"
      :max="3"
      width="500px"
      position="top right"
    />

    <nav-bar></nav-bar>
    <b-row class="mb-5" no-gutters>
      <b-col cols="12">
        <b-alert
          class="message m-0"
          v-for="(message, index) in readMessages"
          :key="index"
          :variant="message.type"
          dismissible
          show
        >
          <span>{{ message.content }}</span>
        </b-alert>
      </b-col>
      <b-col cols="12">
        <router-view></router-view>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import NavBar from "@/components/share/NavBar";

import { mapState } from "vuex";
import { POP_MESSAGE } from "@/store/mutation-types";
import {
  GET_INSTANCE_DETAILS,
  CHECK_DATASTORE_CREDENTIALS_CHANGED,
  RETRIEVE_USER
} from "@/store/action-types";

export default {
  created() {
    this.$store.dispatch(GET_INSTANCE_DETAILS);
    this.$store.dispatch(CHECK_DATASTORE_CREDENTIALS_CHANGED);
    this.$store.dispatch(RETRIEVE_USER);
  },
  data() {
    return {};
  },
  components: { NavBar },
  computed: {
    readMessages() {
      // FIXME not very efficient to pop all messages, to read all messages...
      let messages = [];
      let numberOfMessages = this.$store.state.messages.length;
      while (numberOfMessages > 0) {
        this.$store.commit(POP_MESSAGE);
        messages.push(this.$store.state.message);
        numberOfMessages--;
      }
      return messages;
    },
    ...mapState({
      sidebar: state => state.sidebar
    })
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/default/asb.scss";

.message {
  border-radius: 0;
  font-size: $font-size-base * 1.2;
}

.tab-pane {
  outline: none;
}

.notification-group {
  margin-top: $navbar-height * 1.1;
}

.notifications {
  padding: 15px 20px 15px 20px;
  margin: 5px 20px 5px 20px;
  font-size: $font-size-base * 1.05;
  color: black;
  border: 1px solid black;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.1);

  &.success {
    border-color: theme-color("success");
    background: lighten(theme-color("success"), 47%);
  }
  &.warn {
    border-color: theme-color("warning");
    background: lighten(theme-color("warning"), 47%);
  }
  &.error {
    border-color: theme-color("danger");
    background: lighten(theme-color("danger"), 47%);
  }
}
</style>
