<template>
  <b-container class="my-4">
    <b-row align="center" v-if="loading">
      <b-col>
        <loading :loading="loading"></loading>
      </b-col>
    </b-row>
    <div v-else>
      <page-title :title="item_name" :subtitle="item_description">
        <template v-slot:toolbar>
          <b-btn-toolbar class="float-right" justify>
            <slot name="title-toolbar" v-bind:loading="loading"></slot>
            <b-btn-group v-if="is_authenticated && is_developer">
              <b-button
                v-if="past_orders.length != 0"
                variant="outline-success"
                class="ml-2 rounded"
                type="submit"
                @click="usageTabIsActive = true"
                v-b-popover.hover.top="'You hare already ordered this dataset'"
                >Ordered dataset
              </b-button>
              <b-button
                v-if="past_orders.length == 0"
                variant="outline-secondary"
                class="ml-2 rounded"
                type="submit"
                @click="usageTabIsActive = true"
                v-b-popover.hover.top="'Display the dataset usage panel'"
                >Order this dataset
              </b-button>
            </b-btn-group>
          </b-btn-toolbar>
        </template>
      </page-title>
      <b-row>
        <b-col cols="10">
          <b-tabs>
            <b-tab title="Properties">
              <b-overlay :show="updating">
                <b-row class="my-3" align-h="center">
                  <b-col v-if="itemInfo.properties">
                    <b-form-row>
                      <b-col class="col-form-label" cols="2">
                        <label for="title" class="my-0">Title:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input id="title" type="text" plaintext v-model="item_name" />
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col class="col-form-label" cols="2">
                        <label for="version" class="my-0">Description:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="version"
                          type="text"
                          plaintext
                          v-model="item_description"
                        />
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col class="col-form-label" cols="2">
                        <label for="version" class="my-0">Version:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input id="version" type="text" plaintext v-model="item_version" />
                      </b-col>
                    </b-form-row>
                    <!-- <b-form-row v-if="itemInfo.properties.type">
                      <b-col class="col-form-label" cols="2">
                        <label for="version" class="my-0">Type:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="version"
                          type="text"
                          plaintext
                          v-model="itemInfo.properties.type"
                        />
                      </b-col>
                    </b-form-row> -->
                    <!-- <b-form-row v-if="itemInfo.stac_version">
                      <b-col class="col-form-label" cols="2">
                        <label for="version" class="my-0">STAC version:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="version"
                          type="text"
                          plaintext
                          v-model="itemInfo.stac_version"
                        />
                      </b-col>
                    </b-form-row> -->
                    <b-form-row v-if="itemInfo.collection && is_developer">
                      <b-col class="col-form-label" cols="2">
                        <label for="version" class="my-0">Collection:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="version"
                          type="text"
                          plaintext
                          v-model="itemInfo.collection.split(':')[0]"
                        />
                      </b-col>
                    </b-form-row>
                    <b-form-row v-if="itemInfo.properties.datetime">
                      <b-col class="col-form-label" cols="2">
                        <label for="version" class="my-0">Date and time:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="version"
                          type="text"
                          plaintext
                          v-model="itemInfo.properties.datetime"
                        />
                      </b-col>
                    </b-form-row>
                    <b-form-row v-if="itemInfo.properties.start_datetime">
                      <b-col class="col-form-label" cols="2">
                        <label for="version" class="my-0">Start:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="version"
                          type="text"
                          plaintext
                          v-model="itemInfo.properties.start_datetime"
                        />
                      </b-col>
                    </b-form-row>
                    <b-form-row v-if="itemInfo.properties.end_datetime">
                      <b-col class="col-form-label" cols="2">
                        <label for="version" class="my-0">End:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="version"
                          type="text"
                          plaintext
                          v-model="itemInfo.properties.end_datetime"
                        />
                      </b-col>
                    </b-form-row>
                    <b-form-row v-if="tc_url">
                      <b-col class="col-form-label" cols="2">
                        <label for="version" class="my-0">License:</label>
                      </b-col>
                      <b-col class="col-form-label" cols="10">
                        <b-form-row>
                          <b-link :href="tc_url" target="_blank">
                            {{ tc_name }}
                            <fa-icon
                              class="ml-1 fa-light"
                              v-b-tooltip.hover
                              title="Opens in new tab/page"
                              icon="external-link-alt"
                              size="xs"
                            >
                            </fa-icon>
                          </b-link>
                        </b-form-row>
                      </b-col>
                    </b-form-row>
                    <b-form-row
                      v-for="(provider, index) in itemInfo.properties.providers"
                      :key="'provider' + index"
                    >
                      <b-col class="col-form-label" cols="2">
                        <label for="version" class="my-0">Provider:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-row v-for="(detail, name) in provider" :key="'detail' + name">
                          <b-col class="col-form-label" cols="2">{{ name }}:</b-col>
                          <b-col class="col-form-label" cols="8">{{ detail }}</b-col>
                        </b-form-row>
                      </b-col>
                    </b-form-row>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
            <b-tab title="Geometry" v-if="false">
              <b-overlay :show="updating">
                <b-row class="my-3" align-h="center">
                  <b-col>
                    <b-form-row>
                      <b-col class="col-form-label" cols="2">
                        <label for="description" class="my-0">Type:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="description"
                          type="text"
                          plaintext
                          v-model="itemInfo.geometry.type"
                        />
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col class="col-form-label" cols="2">
                        <label for="description" class="my-0">Coordinates:</label>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          id="description"
                          type="text"
                          plaintext
                          v-model="coordinates"
                        />
                      </b-col>
                    </b-form-row>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
            <b-tab title="Themes" v-if="itemInfo.properties && itemInfo.properties.themes">
              <b-overlay :show="updating">
                <b-row
                  class="my-3"
                  align-h="center"
                  v-for="(theme, index) in itemInfo.properties.themes"
                  :key="'theme' + index"
                >
                  <b-col>
                    <theme-concepts-card :theme="theme"> </theme-concepts-card>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
            <b-tab title="Contacts" v-if="itemInfo.properties && itemInfo.properties.contacts">
              <b-overlay :show="updating">
                <b-row
                  class="my-3"
                  align-h="center"
                  v-for="(contact, index) in itemInfo.properties.contacts"
                  :key="'contact' + index"
                >
                  <b-col>
                    <contact-card :contact="contact"> </contact-card>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
            <b-tab title="Links" v-if="is_developer">
              <b-overlay :show="updating">
                <b-row class="my-3" align-h="center">
                  <b-col>
                    <b-card v-for="(link, index) in itemInfo.links" :key="'link' + index">
                      <b-form-row v-if="link.title">
                        <b-col class="col-form-label" cols="2">
                          <label for="description" class="my-0">Title:</label>
                        </b-col>
                        <b-col cols="10">
                          <b-form-input
                            id="description"
                            type="text"
                            plaintext
                            v-model="link.title"
                          />
                        </b-col>
                      </b-form-row>
                      <b-form-row v-if="link.type">
                        <b-col class="col-form-label" cols="2">
                          <label for="description" class="my-0">Type:</label>
                        </b-col>
                        <b-col cols="10">
                          <b-form-input
                            id="description"
                            type="text"
                            plaintext
                            v-model="link.type"
                          />
                        </b-col>
                      </b-form-row>
                      <b-form-row v-if="link.href">
                        <b-col class="col-form-label" cols="2">
                          <label for="description" class="my-0">Href:</label>
                        </b-col>
                        <b-col cols="10">
                          <b-form-input
                            id="description"
                            type="text"
                            plaintext
                            v-model="link.href"
                          />
                        </b-col>
                      </b-form-row>
                      <b-form-row v-if="link.rel">
                        <b-col class="col-form-label" cols="2">
                          <label for="description" class="my-0">Rel:</label>
                        </b-col>
                        <b-col cols="10">
                          <b-form-input id="description" type="text" plaintext v-model="link.rel" />
                        </b-col>
                      </b-form-row>
                    </b-card>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
            <b-tab title="Assets" v-if="is_developer">
              <b-overlay :show="updating">
                <b-row class="my-3" align-h="center">
                  <b-col>
                    <div
                      v-for="(assetData, assetName) in itemInfo.assets"
                      :key="'asset' + assetName"
                    >
                      <b-card :title="assetName">
                        <b-form-row v-if="assetData.title">
                          <b-col class="col-form-label" cols="2">
                            <label for="description" class="my-0">Title:</label>
                          </b-col>
                          <b-col cols="10">
                            <b-form-input
                              id="description"
                              type="text"
                              plaintext
                              v-model="assetData.title"
                            />
                          </b-col>
                        </b-form-row>
                        <b-form-row v-if="assetData.type">
                          <b-col class="col-form-label" cols="2">
                            <label for="description" class="my-0">Type:</label>
                          </b-col>
                          <b-col cols="10">
                            <b-form-input
                              id="description"
                              type="text"
                              plaintext
                              v-model="assetData.type"
                            />
                          </b-col>
                        </b-form-row>
                        <b-form-row v-if="assetData.href">
                          <b-col class="col-form-label" cols="2">
                            <label for="description" class="my-0">Href:</label>
                          </b-col>
                          <b-col cols="10">
                            <b-form-input
                              id="description"
                              type="text"
                              plaintext
                              v-model="assetData.href"
                            />
                          </b-col>
                        </b-form-row>
                        <b-form-row v-if="assetData.roles">
                          <b-col class="col-form-label" cols="2">
                            <label for="description" class="my-0">Roles:</label>
                          </b-col>
                          <b-col class="col-form-label" cols="10">
                            {{ assetData.roles }}
                          </b-col>
                        </b-form-row>
                      </b-card>
                    </div>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
            <b-tab title="Extensions" v-if="is_developer">
              <b-overlay :show="updating">
                <b-row class="my-3" align-h="center">
                  <b-col>
                    <div
                      v-for="(extensionData, extensionName) in extensions"
                      :key="'ext-' + extensionName"
                    >
                      <b-card :title="extensionName">
                        <b-form-row
                          v-for="(extensionDataValue, extensionDataName) in extensionData"
                          :key="'ext-data-' + extensionDataName"
                        >
                          <b-col class="col-form-label" cols="2">
                            <label for="description" class="my-0"
                              >{{ extensionDataName | makeTitle }}:</label
                            >
                          </b-col>
                          <b-col class="col-form-label" cols="10">
                            <label for="description" class="my-0">{{ extensionDataValue }}</label>
                          </b-col>
                        </b-form-row>
                      </b-card>
                    </div>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
            <b-tab
              title="Dataset Usage"
              v-if="is_authenticated"
              v-bind:active.sync="usageTabIsActive"
            >
              <b-overlay :show="updating">
                <b-row v-if="usageInfo && itemInfo.properties">
                  <b-col cols="12" class="mt-3">
                    <page-title subtitle="Use this dataset" no-divider></page-title>
                    <b-alert variant="success" show title="Note" v-if="past_orders.length != 0">
                      <p class="mb-1"><b>Note</b></p>
                      <p class="mb-1">
                        <b-form-group
                          label="You have ordered this training dataset on:"
                          label-for="past-orders"
                          label-cols="auto"
                          class="mb-0"
                        >
                          <b-form-select
                            id="past-orders"
                            v-model="selected_past_order"
                            :options="past_orders"
                            text-field="datetime_start_str"
                            class="alert-success"
                            style="border-color: #28a745;"
                          ></b-form-select>
                        </b-form-group>
                      </p>
                      <p class="mb-1">
                        Ordering again the same dataset results in a new copy created in your
                        workspace bucket.<br />
                      </p>
                      <p class="mb-1">
                        Ask for a fresh copy if the previous one has been altered.
                      </p>
                      <!-- p class="mb-1">
                        This has a cost in terms of data transfer and storage.
                      </p -->
                      <p class="mt-0 mb-0">
                        See your past orders in your<b-button
                          variant="outline-success"
                          class="ml-2 mr-2 mb-0"
                          to="/resources"
                          size="sm"
                          v-b-popover.hover.top="'Open the resources usage page'"
                          >Resources&nbsp;Usage </b-button
                        >page.
                      </p>
                    </b-alert>
                    <p>Duplicate this dataset in your workspace to develop and train new models.</p>
                    <p>
                      The dataset is made accessible in your object store.
                    </p>
                    <p>
                      The S3 prefix includes the dataset publisher, name, version (if known) and the
                      order time:
                    </p>
                    <p>
                      <code>{{ datasetS3Prefix }}</code>
                    </p>
                    <b-checkbox
                      class="ml-auto pb-3"
                      :checked="tcs_accepted"
                      @change="updateTCS()"
                      :disabled="tcs_updating"
                      v-if="tc_url && theme_features.includes('aiopen-tcs') && !disable_tcs"
                    >
                      <span v-if="!tcs_updating"
                        >Accept Terms and Conditions:
                        <b-link :href="tc_url" target="_blank">
                          {{ tc_name }}
                          <fa-icon
                            class="ml-1 fa-light"
                            v-b-tooltip.hover
                            title="Opens in new tab/page"
                            icon="external-link-alt"
                            size="xs"
                          >
                          </fa-icon> </b-link
                      ></span>
                      <span v-else>
                        <b-spinner small class="mx-2"></b-spinner>
                        <span v-if="!tcs_accepted">Accepting Terms and Conditions</span>
                        <span v-else>Revoking consent for Terms and Conditions</span>
                      </span>
                    </b-checkbox>
                    <b-button
                      class="rounded"
                      variant="danger"
                      v-b-popover.hover.top="'Order this dataset (without confirmation)'"
                      @click="orderDataset()"
                      :disabled="ordering_disabled"
                    >
                      Order Dataset</b-button
                    >
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
            <b-tab title="Raw JSON" v-if="is_developer">
              <div>
                <pre>{{ itemInfo | pretty }}</pre>
              </div>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import backendService from "@/api/asb/services/backend";
import userService from "@/api/asb/services/usermgr";
import PageTitle from "@/components/share/PageTitle";
import Loading from "@/components/share/Loading";
import ContactCard from "@/components/share/STAC/ContactCard";
import ThemeConceptsCard from "@/components/share/STAC/ThemeConceptsCard";
import { mapState } from "vuex";
import { canWriteToClipboard, writeToClipboard, toDatetime } from "@/utils";

export default {
  name: "DatasetDetail",
  components: {
    PageTitle,
    Loading,
    ContactCard,
    ThemeConceptsCard
  },
  data() {
    return {
      dataset_id: "",
      collection_id: "",
      loading: true,
      itemInfo: {},
      signature: {},
      params_fields: [{ key: "key", label: "Name" }, "value"],
      metrics: [],
      metrics_fields: [{ key: "key", label: "Name" }, "value"],
      updating: false,
      collection: "",
      extensions: [],
      extensions_schemas: {},
      coordinates: "",
      usageInfo: {},
      usageTabIsActive: false,
      jsonMsg: "",
      tcs_updating: false,
      disable_tcs: false,
      past_orders: [],
      selected_past_order: null
    };
  },
  created() {
    this.dataset_id = this.$route.params.dataset_id;
    this.collection_id = this.$route.params.id;
    this.getItemInfo();
  },
  methods: {
    formatAlertMessage(alertMessage) {
      return alertMessage.replace("\n", "<br />");
    },
    getItemInfo() {
      backendService
        .describeItem(this.collection_id, this.dataset_id)
        .then(data => this.initInfo(data))
        .catch(error => backendService.handleError(error))
        .finally(() => (this.loading = false));

      backendService
        .trainingDatasetOrders(this.collection_id, this.dataset_id)
        .then(data => {
          this.past_orders = data["data"];
          // FIXME: Sort the past orders from most recent to oldest
          for (let order of this.past_orders) {
            order.datetime_start_str = toDatetime(order.datetime_start);
          }
        })
        .catch(error => backendService.handleError(error))
        .finally(() => (this.loading = false));
    },
    initInfo(itemInfo) {
      this.itemInfo = itemInfo;
      this.extensions = this.getExtensions(this.itemInfo);
      this.coordinates = this.getCoordinates(this.itemInfo);
      this.signature = this.getSignature(this.itemInfo);
      console.log(this.itemInfo);
    },
    getSTACExtensionTitle(schema_url) {
      if (this.extensions_schemas[schema_url] === undefined) {
        this.fetchSTACExtensionSchema(schema_url);
      }
      return this.extenions_schema[schema_url].title;
    },
    fetchSTACExtensionSchema(schema_url) {
      fetch(schema_url)
        .then(response => {
          response.json().then(data => {
            this.extensions_schemas[schema_url] = data;
          });
        })
        .catch(err => {
          console.error(err);
        });
    },
    getExtensions(itemInfo) {
      let extensions = {};
      let mlModel = {};
      let dlModel = {};
      let mlAoi = {};
      let sci = {};
      let properties = itemInfo.properties;
      for (const [key, value] of Object.entries(properties)) {
        if (key.startsWith("ml-model:")) {
          let extensionKey = key.replace("ml-model:", "");
          mlModel[extensionKey] = value;
        } else if (key.startsWith("dlm:")) {
          let extensionKey = key.replace("dlm:", "");
          dlModel[extensionKey] = value;
        } else if (key.startsWith("ml-aoi:")) {
          let extensionKey = key.replace("ml-aoi:", "");
          mlAoi[extensionKey] = value;
        } else if (key.startsWith("sci:")) {
          let extensionKey = key.replace("sci:", "");
          sci[extensionKey] = value;
        }
      }
      if (Object.keys(mlModel) != 0) {
        extensions["ml-model"] = mlModel;
      }
      if (Object.keys(dlModel) != 0) {
        extensions["dlm"] = dlModel;
      }
      if (Object.keys(mlAoi) != 0) {
        extensions["ml-aoi"] = mlAoi;
      }
      if (Object.keys(sci) != 0) {
        extensions["sci"] = sci;
      }
      console.log(extensions);
      return extensions;
    },
    getCoordinates(itemInfo) {
      let coordinates = "";
      let rawCoordinates = itemInfo.geometry.coordinates[0];
      for (let i = 0; i < rawCoordinates.length; i++) {
        coordinates = coordinates + "[" + itemInfo.geometry.coordinates[0][i] + "] ";
      }
      return coordinates;
    },
    getSignature(itemInfo) {
      console.log("getSignature", itemInfo);
      let signature = {};
      if ("dlm:inputs" in itemInfo.properties) {
        signature.inputs = itemInfo.properties["dlm:inputs"];
      }
      if ("dlm:outputs" in itemInfo.properties) {
        signature.outputs = itemInfo.properties["dlm:outputs"];
      }
      console.log("Signature", signature);
      return signature;
    },
    getLinkWithRel(rel) {
      if (this.itemInfo === undefined || this.itemInfo.links === undefined) {
        return null;
      }
      for (let link of this.itemInfo.links) {
        if (link.rel == rel) {
          return link;
        }
      }
      return null;
    },
    canWriteToClipboard() {
      return canWriteToClipboard();
    },
    writeToClipboard(text) {
      this.$notify({
        group: "global",
        type: "info",
        duration: 2000, // in ms, -1 = no duration
        text: "Copied to clipboard"
      });
      return writeToClipboard(text);
    },
    updateTCS() {
      this.tcs_updating = true;
      if (this.tcs_accepted) {
        userService
          .revokeConsentForTermsAndConditions(this.tc_url)
          .then(() => {
            this.$store.dispatch("FETCH_ACCEPTED_TCS");
            this.tcs_updating = false;
          })
          .catch(error => backendService.handleError(error));
      } else {
        userService
          .acceptTermsAndConditions(this.tc_url)
          .then(() => {
            this.$store.dispatch("FETCH_ACCEPTED_TCS");
            this.tcs_updating = false;
          })
          .catch(error => backendService.handleError(error));
      }
    },
    orderDataset() {
      console.log("Ordering Training Dataset ...");
      backendService
        .orderTrainingDataset(this.collection_id, this.dataset_id)
        .then(data => {
          // TODO: Update the data with new ordering information
          // this.itemInfo = data;
          // this.pollModelAsAServiceStatus();
          console.log(data);
        })
        .catch(error => backendService.handleError(error))
        .finally(() => (this.loading = false));
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
      theme_features: state => state.instance.theme_features,
      accepted_tcs: state => state.accepted_tcs
    }),
    ownerOrSuperuser() {
      return Object.keys(this.user).length != 0
        ? this.user.is_superuser
          ? true
          : this.user.username === this.collection.ownerId
        : false;
    },
    item_name() {
      let itemName = null;
      if (this.itemInfo.properties) {
        itemName = this.itemInfo.properties.name
          ? this.itemInfo.properties.name
          : this.itemInfo.properties.title
          ? this.itemInfo.properties.title
          : this.itemInfo.id
          ? this.itemInfo.id
          : null;
      }
      if (itemName == null) {
        itemName = this.itemInfo.title
          ? this.itemInfo.title
          : this.itemInfo.id
          ? this.itemInfo.id
          : "";
      }
      return itemName;
    },
    item_description() {
      let itemDescription = null;
      if (this.itemInfo.properties) {
        itemDescription = this.itemInfo.properties.abstract
          ? this.itemInfo.properties.abstract
          : this.itemInfo.properties.description
          ? this.itemInfo.properties.description
          : null;
      }
      if (itemDescription == null) {
        itemDescription = this.itemInfo.description
          ? this.itemInfo.description
          : this.itemInfo.abstract
          ? this.itemInfo.abstract
          : "";
      }
      return itemDescription;
    },
    item_version() {
      let itemVersion = "not specified";
      if (this.itemInfo.properties) {
        itemVersion = this.itemInfo.properties.version
          ? this.itemInfo.properties.version
          : "not specified";
      }
      return itemVersion;
    },
    workspaces() {
      if (this.$store.state.user["groups"] !== undefined) {
        return Object.keys(this.$store.state.user["groups"]);
      }
      return [];
    },
    is_authenticated() {
      return Object.keys(this.user).length != 0;
    },
    is_developer() {
      // The user is developer if he has the role "developer" in at least one workspace / group
      if (this.$store.state.user["groups"] !== undefined) {
        for (const [group, roles] of Object.entries(this.$store.state.user["groups"])) {
          console.log("Checking:", group);
          if (roles.includes("developer")) {
            return true;
          }
        }
      }
      return false;
    },
    tcs_accepted() {
      if (this.tc_url == null) {
        // No license specified so TC accepted by default
        return true;
      }
      console.log("License URL:", this.tc_url);
      console.log("Accepted TCs:", this.accepted_tcs);
      return this.accepted_tcs.includes(this.tc_url) || this.accepted_tcs.includes("*");
    },
    ordering_disabled() {
      return this.theme_features.includes("aiopen-tcs")
        ? !this.tcs_accepted || this.executing
        : false | this.executing;
    },
    tc_name() {
      // Two possibilities:
      // 1. Use the syntax "SPDX-License-Identifier: <license_id>" in properties.license
      // 2. Use a link with relation type "license"
      // Doc: https://github.com/radiantearth/stac-spec/blob/master/item-spec/common-metadata.md#licensing
      if (this.itemInfo.properties && this.itemInfo.properties.license) {
        if (this.itemInfo.properties.license.startsWith("SPDX-License-Identifier:")) {
          return this.itemInfo.properties.license.split(":")[1].trim();
        } else {
          return this.itemInfo.properties.license.trim();
        }
      }
      let tc_info = this.getLinkWithRel("license");
      if (tc_info) {
        return tc_info.title ? tc_info.title : tc_info.href;
      }
      return null;
    },
    tc_url() {
      // Check the license property
      let tc_id = this.tc_name;
      if (tc_id) {
        return "https://spdx.org/licenses/" + tc_id + ".html";
      }
      // Otherwise, check the links with relation type "license"
      let tc_info = this.getLinkWithRel("license");
      return tc_info ? tc_info.href : null;
    },
    orderDatetimes() {
      // Build a list of past order dates
      console.log("Past orders:", this.past_orders);
      let datetimes = this.past_orders.map(x => toDatetime(x.datetime_start)).join(", ");
      return datetimes;
    },
    publisher() {
      return this.itemInfo.collection.split(":")[0];
    },
    datasetS3Prefix() {
      console.log("Building dataset S3 prefix");
      if (this.itemInfo.properties.version) {
        return (
          "/ordered_datasets/" +
          this.publisher +
          "/" +
          this.itemInfo.id +
          "/" +
          this.itemInfo.properties.version +
          "/<order-timestamp>"
        );
      }
      return "/ordered_datasets/" + this.publisher + "/" + this.itemInfo.id + "/<order-timestamp>";
    }
  },
  filters: {
    pretty: function(value) {
      return JSON.stringify(value, null, 2);
    },
    makeTitle: function(value) {
      const title = value.replace("_", " ");
      let title2 = title.charAt(0).toUpperCase() + title.slice(1);
      if (title2 == "Doi") {
        title2 = "DOI";
      }
      return title2;
    }
  }
};
</script>

<style></style>
