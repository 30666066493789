<template>
  <b-row class="my-5 mx-4">
    <b-modal ref="addUserModal" hide-footer id="add-user" title="Add user">
      <b-form-row>
        <b-col cols="12">
          <b-form-input
            id="add-user-input"
            v-model="newUsername"
            type="text"
            :state="addUserState"
            aria-describedby="addUserFeedback"
            placeholder="Enter a username"
          ></b-form-input>
          <b-form-invalid-feedback id="addUserFeedback">
            <span v-for="(error, index) in addUserErrors['username']" :key="index">
              {{ error }}
            </span>
          </b-form-invalid-feedback>
        </b-col>
        <b-col class="mt-3" cols="12" align="right">
          <b-button class="mr-1" variant="outline-secondary" @click="hideAddUserModal"
            >Cancel</b-button
          >
          <b-button variant="success" @click="addUser" :disabled="creatingUser">
            <span v-if="!creatingUser">Create user</span>
            <span v-else>Adding...</span>
          </b-button>
        </b-col>
      </b-form-row>
    </b-modal>
    <b-col class="mb-3" cols="12">
      <page-title title="Users">
        <template v-slot:toolbar>
          <b-form class="float-right" inline>
            <b-form-input placeholder="Filter..." class="mr-2" v-model="filter"></b-form-input>
            <b-btn variant="success" :disabled="disableAddUser" v-b-modal.add-user>
              <fa-icon class="mr-2" icon="user-plus"></fa-icon>
              <span>New user</span>
            </b-btn>
          </b-form>
        </template>
      </page-title>
    </b-col>
    <b-col cols="12">
      <b-table
        v-if="users.length !== 0"
        :items="users"
        empty-filtered-text="No users matching your filter"
        small
        foot-clone
        hover
        fixed
        show-empty
        :filter="filter"
        :fields="fields"
      >
        <template v-slot:cell(is_active)="data">
          <boolean-icon v-model="data.value" />
        </template>
        <template v-slot:cell(is_staff)="data">
          <boolean-icon v-model="data.value" />
        </template>
        <template v-slot:cell(is_superuser)="data">
          <boolean-icon v-model="data.value" />
        </template>
        <template v-slot:cell(groups)="data">
          <div v-for="(roles, workspaceName) in data.value" :key="workspaceName">
            <span class="mr-1">{{ workspaceName }}</span>
            <role-badge
              class="ml-1"
              v-for="(role, index) in roles"
              :role="role"
              :key="index"
            ></role-badge>
          </div>
        </template>
        <template v-slot:cell(toolbar)="data">
          <b-dropdown class="float-right no-caret" size="sm" variant="transparent" right>
            <template slot="button-content">
              <fa-icon icon="bars"></fa-icon>
            </template>
            <b-dropdown-item :to="`/admin/users/${data.item.username}`">
              <fa-icon class="mr-3" icon="pen"></fa-icon>
              <span>Edit</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <empty v-if="users.length === 0" text="No users found" :loading="loading" no-icon></empty>
    </b-col>
  </b-row>
</template>

<script>
import BooleanIcon from "@/components/share/BooleanIcon";
import RoleBadge from "@/components/share/RoleBadge";
import Empty from "@/components/share/Empty";
import PageTitle from "@/components/share/PageTitle";
import { userService } from "@/api/asb";
import { BAD_REQUEST } from "http-status-codes";

export default {
  name: "UserManagement",
  components: { BooleanIcon, Empty, RoleBadge, PageTitle },
  created() {
    this.getUsers();
  },
  data: function() {
    return {
      loading: false,
      creatingUser: false,
      disableAddUser: true,
      filter: "",
      users: [],
      newUsername: "",
      addUserErrors: {
        username: []
      },
      fields: [
        {
          key: "username",
          label: "Username",
          sortable: true
        },
        {
          key: "first_name",
          label: "First name",
          sortable: true
        },
        {
          key: "last_name",
          label: "Last name",
          sortable: true
        },
        {
          key: "email",
          label: "Email",
          sortable: true
        },
        {
          key: "is_active",
          label: "Active",
          sortable: true
        },
        {
          key: "is_staff",
          label: "Admin",
          sortable: true
        },
        {
          key: "is_superuser",
          label: "Superuser",
          sortable: true
        },
        {
          key: "groups",
          label: "Workspaces",
          sortable: true
        },
        {
          key: "toolbar",
          label: "",
          sortable: false
        }
      ]
    };
  },
  methods: {
    addUser() {
      this.addUserErrors = { username: [] };
      this.creatingUser = true;
      userService
        .createUser({ username: this.newUsername })
        .then(() => {
          this.$notify({
            group: "global",
            type: "success",
            title: `User ${this.newUsername} successfully created`,
            duration: 3000,
            text: ""
          });
          this.$router.push({ name: "admin:user:edit", params: { username: this.newUsername } });
        })
        .catch(error => {
          if (error.response && error.response.status === BAD_REQUEST) {
            this.addUserErrors = error.response.data;
          } else {
            userService.handleError(error);
          }
        })
        .finally(() => (this.creatingUser = false));
    },
    hideAddUserModal() {
      this.$refs.addUserModal.hide();
    },
    getUsers: function() {
      this.loading = true;
      userService
        .getUsers()
        .then(users => {
          this.disableAddUser = false;
          this.users = users;
        })
        .catch(e => {
          // If cannot get users, disable add user function
          this.disableAddUser = true;
          userService.handleError(e);
        })
        .finally(() => (this.loading = false));
    }
  },
  computed: {
    addUserState() {
      // Show only if server-side errors
      return this.addUserErrors["username"].length !== 0 ? false : null;
    }
  }
};
</script>

<style scoped></style>
