<template>
  <b-container fluid class="mt-5 mx-0 p-0">
    <b-row class="m-0 p-0" no-gutters>
      <b-col cols="12" class="m-0 p-0" align="center">
        <index-page />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import IndexPage from "@/pages/IndexPage";
export default {
  name: "HomePage",
  components: { IndexPage }
};
</script>

<style scoped>
.title {
  font-variant: small-caps;
}
</style>
