<template>
  <b-container class="mt-4">
    <b-modal ref="new" hide-footer id="new" title="Store secret">
      <b-row no-gutters>
        <b-col cols="12">
          <b-row class="mt-4" align-h="end" no-gutters>
            <b-col cols="12">
              <b-alert variant="danger" :show="createSecretErrors['nonFieldErrors'].length !== 0">
                Failed with the following errors:
                <ul>
                  <li v-for="(error, index) in createSecretErrors['nonFieldErrors']" :key="index">
                    {{ error }}
                  </li>
                </ul>
              </b-alert>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label-align="left"
                label-class="font-weight-bold"
                label="Secret tag"
                label-for="secret-tag"
                description="Please create a tag that describes the secret"
              >
                <b-form-input
                  id="secret-tag"
                  v-model="newSecret.tag"
                  type="text"
                  :state="createSecretState('tag')"
                  aria-describedby="createSecretFeedback"
                ></b-form-input>
                <b-form-invalid-feedback id="createSecretFeedback">
                  <span v-for="(error, index) in createSecretErrors['tag']" :key="index">
                    {{ error }}
                  </span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                label-align="left"
                label-class="font-weight-bold"
                label="Secret value"
                description="Please insert here the secret"
                label-for="secret-value"
              >
                <b-form-input
                  id="secret-value"
                  v-model="newSecret.value"
                  type="text"
                  :state="createSecretState('value')"
                  aria-describedby="createSecretFeedback"
                ></b-form-input>
                <b-form-invalid-feedback id="createSecretFeedback">
                  <span v-for="(error, index) in createSecretErrors['value']" :key="index">
                    {{ error }}
                  </span>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-button
                :variant="error ? 'failed' : 'success'"
                @click="createSecret"
                :disabled="creating"
                block
              >
                <span v-if="!creating">Store secret</span>
                <b-spinner v-else small></b-spinner>
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
    <page-title title="Secrets" :loading="loading">
      <template v-slot:toolbar>
        <b-btn-toolbar class="float-right" justify>
          <b-btn-group class="ml-2">
            <b-btn variant="success" size="md" v-b-modal.new :disabled="loading">New secret</b-btn>
          </b-btn-group>
        </b-btn-toolbar>
      </template>
    </page-title>
    <b-row v-if="!loading">
      <b-col>
        <b-tabs>
          <b-tab title="All" active>
            <b-row>
              <b-col class="my-2" cols="12" align="right">
                <b-form class="float-right" @submit="search" inline>
                  <b-btn-group>
                    <b-form-input
                      class="rounded-0 outline-0"
                      v-model="filter"
                      type="text"
                      placeholder="Search secrets"
                    ></b-form-input>
                    <b-btn class="rounded-0" variant="plain" type="submit">
                      <fa-icon icon="search" />
                    </b-btn>
                  </b-btn-group>
                </b-form>
              </b-col>
              <b-col cols="12">
                <b-table
                  v-if="secrets.length !== 0"
                  :items="Object.values(secrets)"
                  empty-filtered-text="No secrets matching your filter"
                  fixed
                  thead-class="d-none"
                  :fields="fields"
                >
                  <template v-slot:cell(detail)="data">
                    <div>
                      <router-link
                        class="bold-link"
                        :to="{ name: 'secrets', params: { id: data.item._jv.id } }"
                      >
                        {{ data.item.tag }}
                      </router-link>
                      <div class="subtitle">{{ data.item.tag }}</div>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <empty
              v-if="$_.isEmpty(secrets)"
              :text="error ? 'Something went wrong while fetching secrets' : 'No secrets found'"
              :icon="error ? 'exclamation' : 'folder-open'"
              class="mt-2"
            ></empty>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <b-pagination
          v-show="!loading && !$_.isEmpty(secrets)"
          align="center"
          :total-rows="pagination.count"
          :per-page="pageSize"
          v-model="pagination.page"
          @input="changePage"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from "vuex";
import PageTitle from "@/components/share/PageTitle";
import knowledgeService from "@/api/asb/services/knowledge";
import Empty from "@/components/share/Empty";
import { BAD_REQUEST } from "http-status-codes";
import { removeJsonApiMeta } from "@/utils";

export default {
  name: "SecretList",
  components: { PageTitle, Empty },
  data: function() {
    return {
      secrets: {},
      newSecret: {
        tag: "",
        value: ""
      },
      createSecretErrors: {
        tag: [],
        value: [],
        nonFieldErrors: []
      },
      loading: true,
      creating: false,
      error: false,
      filter: "",
      fields: [
        {
          key: "detail",
          label: "",
          sortable: true
        },
        {
          key: "toolbar",
          label: "",
          sortable: false
        }
      ],
      // Pagination
      pagination: {
        page: 1,
        pages: 1,
        count: 1
      },
      pageSize: 20
    };
  },
  created() {
    this.loading = true;
    this.$store
      .dispatch("knowledge/get", "secrets")
      .then(() => {
        this.fetchSecrets();
      })
      .catch(error => knowledgeService.handleError(error));
  },
  methods: {
    fetchSecrets(page = 1, searchFilter = undefined) {
      this.loading = true;
      const params = { "page[number]": page, "page[size]": this.pageSize };
      if (searchFilter) {
        params["filter[search]"] = searchFilter;
      }
      this.$store
        .dispatch("knowledge/search", ["secrets", { params: params }])
        .then(response => {
          this.secrets = removeJsonApiMeta(response);
          response = response._jv.json;
          this.pagination = response.meta.pagination;
          console.debug("Pagination update", response.meta.pagination);
        })
        .catch(error => {
          this.error = true;
          knowledgeService.handleError(error);
        })
        .finally(() => (this.loading = false));
    },
    createSecret() {
      this.createSecretErrors = {
        tag: [],
        value: [],
        nonFieldErrors: []
      };
      this.creating = true;
      const newSecret = {
        ...this.newSecret,
        _jv: {
          type: "secrets"
        }
      };
      console.debug("newSecret", newSecret);
      this.$store
        .dispatch("knowledge/post", [newSecret])
        .then(secret => {
          this.$notify({
            group: "global",
            type: "success",
            title: `${secret.tag} successfully created`,
            duration: 3000,
            text: ""
          });
          this.$router.push({ name: "secret", params: { id: secret._jv.id } });
        })
        .catch(error => {
          this.error = true;
          if (error.response && error.response.status === BAD_REQUEST) {
            for (let formError of error.response.data["errors"]) {
              const pointer = formError.source.pointer;
              if (pointer.startsWith("/data/attributes")) {
                // JSON API error
                const attribute = formError.source.pointer.split("/")[3];
                if (attribute in this.createProcessErrors) {
                  this.createProcessErrors[attribute].push(formError.detail);
                } else {
                  console.warn("Cannot show errors for pointer", pointer);
                }
              } else {
                console.error("Wrong source pointer", pointer);
              }
            }
          }
          knowledgeService.handleError(error);
        })
        .finally(() => (this.creating = false));
    },
    createSecretState(attribute) {
      // Show only if server-side errors
      return this.createSecretErrors[attribute].length !== 0 ? false : null;
    },
    changePage(page) {
      console.debug("Changing page to", page);
      this.fetchSecrets(page, this.filter);
    },
    search(event) {
      console.debug("Searching for", this.filter);
      event.preventDefault();
      this.fetchSecrets(1, this.filter);
    }
  },
  computed: {
    ...mapState({
      user: state => state.user
    })
  }
};
</script>

<style lang="scss" scoped></style>
