export const formatDashboard = dashboard => {
  let formattedDashboard = {};
  formattedDashboard.verboseName = dashboard.verboseName;
  formattedDashboard.is_editable = dashboard.isEditable;
  formattedDashboard.is_system = dashboard.isSystem;
  formattedDashboard.id = dashboard._jv.id;
  formattedDashboard.description = "";
  formattedDashboard.layout = [];
  let component;
  let i;

  for (i in dashboard.components) {
    component = dashboard.components[i];
    formattedDashboard.layout.push({
      component: component.name,
      x: component.x,
      y: component.y,
      w: component.w,
      h: component.h,
      i: "" + i,
      url: component._jv.links.self
    });
    if (component.name === "DashboardDescription") {
      formattedDashboard.description = component.jsonProperties.text;
    }
  }

  return formattedDashboard;
};

export const filterSystemDashboards = (dashboards, isSystem) => {
  let filteredDashboards = [];

  //Check if dashboards are set yet. They might be undefined if the GET requests are not finished yet.
  if (dashboards) {
    Object.values(dashboards).forEach(dashboard => {
      if (dashboard.isSystem === isSystem) {
        filteredDashboards.push(dashboard);
      }
    });
  }
  return filteredDashboards;
};

export default {
  async addComponentToLayout(layout, component) {
    let ID = 0,
      Y = 0,
      X = 0;
    layout.forEach(o => {
      let tmp = o.y + o.h;
      if (tmp > Y) {
        Y = tmp;
      }
      if (o.i > ID) {
        ID = o.i;
      }
    });
    ID++;

    const inserted = {
      component: component.vueComponent,
      i: "" + ID,
      h: component.h,
      w: component.w,
      x: X,
      y: Y
    };

    layout.push(inserted);
    return layout;
  }
};
