<template>
  <base-admin-page>
    <template v-slot:content>
      <workspace-management></workspace-management>
    </template>
  </base-admin-page>
</template>

<script>
import WorkspaceManagement from "@/components/admin/WorkspaceManagement";
import BaseAdminPage from "@/pages/admin/BaseAdminPage";

export default {
  name: "AdminGroups",
  components: { BaseAdminPage, WorkspaceManagement }
};
</script>

<style scoped></style>
