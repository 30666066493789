<template>
  <b-row class="form-group" v-show="visible">
    <b-col>
      <b-form-row>
        <b-col cols="10">
          <b-form-group
            label-cols-lg="2"
            :label="label"
            :disabled="!editable"
            :description="'description' in inp ? inp.description : ''"
          >
            <b-form-input v-model="inputValue" @update="update_value"></b-form-input>
            <b-form-text v-if="inp.doc">{{ inp.doc }}</b-form-text>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-button v-if="customSettings.default_value" @click="reset_default" variant="warning"
            >Reset to default
          </b-button>
        </b-col>
      </b-form-row>
    </b-col>
  </b-row>
</template>

<script>
import InputMixin from "@/components/generic/InputMixin";

export default {
  mixins: [InputMixin],
  name: "ObjectType"
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/default/asb.scss";
</style>
