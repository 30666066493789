<template>
  <b-container fluid>
    <b-row class="bg-primary text-white py-2">
      <b-col class="mx-2">
        <b-form inline>
          <b-form-input
            v-model="tiff_url"
            placeholder="Enter your Geotiff url"
            style="width: 70%"
          ></b-form-input>
          <b-button
            variant="success"
            class="ml-2"
            style="width: 125px"
            @click="setMapTiff(tiff_url)"
            :disabled="fetchingGeotiff"
            ><span v-if="!fetchingGeotiff">Display Geotiff </span
            ><b-spinner small v-else></b-spinner
          ></b-button>
        </b-form>
      </b-col>
    </b-row>
    <b-row class="px-0" style="height: calc(100% - 50px);">
      <b-col style="height: 800px;" class="px-0">
        <COGMap :tiff_url="map_tiff_url"></COGMap>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import COGMap from "@/components/products/COGMap.vue";

export default {
  name: "GeoTIFFViewer",
  components: { COGMap },
  props: {
    init_tiff_url: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      cog_tile_server: process.env.VUE_APP_COG_TILESERVER_URL,
      tiff_url: "",
      fetchingGeotiff: false,
      map: undefined,
      map_tiff_url: ""
    };
  },
  mounted() {
    this.tiff_url = this.init_tiff_url ? this.init_tiff_url.trim() : "";
    this.map_tiff_url = this.tiff_url;
  },
  methods: {
    setMapTiff(tiff_url) {
      this.map_tiff_url = tiff_url.trim();
    }
  }
};
</script>

<style scoped></style>
