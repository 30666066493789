<template>
  <generic-list
    name="collection"
    service="knowledge"
    :filters="filters"
    :sortBy="sortBy"
    :sortDesc="sortDesc"
    paginate
  >
    <template v-slot:resource-label="{ resource }">
      <b-row no-gutters>
        <b-col cols="8">
          <router-link
            class="bold-link"
            :to="{ name: 'collection', params: { id: resource._jv.id } }"
            v-b-tooltip.hover
            :title="resource.identifier"
          >
            <span>{{ resource.title }} ({{ resource.identifier }})</span>
          </router-link>
          &nbsp;
          <span>
            request by <i>{{ resource.ownerId }}</i>
          </span>
        </b-col>
        <b-col cols="3">
          <status-badge :status="resource.status" width="10"></status-badge>
        </b-col>
      </b-row>
    </template>
    <template v-slot:title-toolbar>
      <b-btn-group class="ml-2">
        <b-btn
          variant="outline-success"
          size="md"
          v-b-tooltip.hover
          title="Request a new collection"
          :to="{ name: 'collection:new' }"
        >
          Request a collection
        </b-btn>
      </b-btn-group>
    </template>
  </generic-list>
</template>

<script>
import GenericList from "@/components/generic/GenericList";
import StatusBadge from "../../../components/share/StatusBadge";

export default {
  name: "CollectionList",
  components: { StatusBadge, GenericList },
  data() {
    return {
      // Filters
      filters: [{ name: "ownerId", value: vm => vm.user.username, label: "Owned by me" }],
      sortBy: "createdAt",
      sortDesc: true
    };
  }
};
</script>

<style scoped></style>
