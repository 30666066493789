import {
  CHANGE_DASHBOARD,
  GET_DASHBOARDS,
  ADD_TO_DASHBOARD,
  RELOAD_DASHBOARD,
  UPDATE_DASHBOARD
} from "@/plugins/dashboards/store/action-types";
import {
  DISABLE_EDITOR,
  ENABLE_EDITOR,
  SET_DASHBOARD,
  SET_DASHBOARDS,
  UPDATE_EDIT_MODE,
  UPDATE_SHOW_COMPONENTS,
  REMOVE_FROM_DASHBOARD,
  SET_LAYOUT
} from "@/plugins/dashboards/store/mutation-types";
import { knowledgeJSONAPIClient } from "@/api/asb/services/knowledge";
import { jsonapiModule, utils } from "jsonapi-vuex";
import { formatDashboard, filterSystemDashboards } from "@/plugins/dashboards/api/dashboards";
import api from "@/plugins/dashboards/api//dashboards";
import { slugify } from "@/utils";
let jsonapiConf = {
  preserveJson: true,
  clearOnUpdate: false,
  // only unique or modified attributes are kept
  cleanPatch: true,
  // we still keep relationships when doing the clean patch
  cleanPatchProps: ["relationships"]
};
const state = {
  defaultDashboardName: "home",
  editor: {
    // Whether editor access should be enabled
    enabled: false,
    editing: false,
    // Show components list (only if editing enabled)
    showComponents: true
  },
  dashboard: {
    is_editable: false,
    layout: [],
    isSystem: true
  },

  dashboards: []
};

const getters = {
  showComponentBar: state =>
    state.dashboard.is_editable && state.editor.editing && state.editor.showComponents,
  // userDashboards: state => state.jv.dashboards.filter(dashboard => !dashboard.isSystem),
  userDashboards: state => filterSystemDashboards(state.jv.dashboards, false),
  systemDashboards: state => filterSystemDashboards(state.jv.dashboards, true)
};

const mutations = {
  [UPDATE_EDIT_MODE](state, value) {
    state.editor.editing = value;
  },
  [UPDATE_SHOW_COMPONENTS](state, value) {
    state.editor.showComponents = value;
  },
  [SET_DASHBOARD](state, dashboard) {
    state.dashboard = dashboard;
  },
  [SET_DASHBOARDS](state, dashboards) {
    state.dashboards = dashboards;
  },
  [ENABLE_EDITOR](state) {
    state.editor.enabled = true;
  },
  [DISABLE_EDITOR](state) {
    state.editor.enabled = false;
  },
  [REMOVE_FROM_DASHBOARD](state, layout) {
    state.dashboard.layout.splice(state.dashboard.layout.indexOf(layout), 1);
  },
  [SET_LAYOUT](state, layout) {
    state.dashboard.layout = layout;
  }
};

const actions = {
  [CHANGE_DASHBOARD]({ commit }, dashboardId) {
    if (dashboardId === undefined) {
      console.error("dashboard is undefined");
    } else {
      console.log(`Changing dashboard to ${dashboardId}`);

      //If the record is already present in the store
      if (
        !(
          this.getters["knowledge/get"]({
            _jv: {
              type: "dashboards",
              id: dashboardId
            }
          }).isPublic === undefined
        )
      ) {
        let url = "dashboards";

        if (
          this.getters["knowledge/get"]({
            _jv: {
              type: "dashboards",
              id: dashboardId
            }
          }).isPublic
        ) {
          url = "public-dashboards";
        }

        this.dispatch("knowledge/get", `${url}/${dashboardId}`)
          .then(dashboard => commit(SET_DASHBOARD, formatDashboard(dashboard)))
          .catch(error =>
            console.error("Error while fetching dashboard ", dashboardId, ": ", error)
          );
      } else {
        //If the record is NOT yet present in the store, try fetching from both endpoints
        this.dispatch("knowledge/get", `dashboards/${dashboardId}`)
          .then(dashboard => commit(SET_DASHBOARD, formatDashboard(dashboard)))
          .catch(() => {
            this.dispatch("knowledge/get", `public-dashboards/${dashboardId}`)
              .then(dashboard => commit(SET_DASHBOARD, formatDashboard(dashboard)))
              .catch(error => console.log("Error fetching dashboard: ", error));
          });
      }
    }
  },
  [GET_DASHBOARDS]() {
    console.log("Getting dashboards");
    this.dispatch("knowledge/get", "dashboards");
    this.dispatch("knowledge/get", "public-dashboards");
  },
  [ADD_TO_DASHBOARD]({ commit }, component) {
    console.warn(state);
    let layout = state.dashboard.layout.slice();
    api
      .addComponentToLayout(layout, component)
      .then(layout => {
        commit(SET_LAYOUT, layout);
      })
      .catch(error => console.error("error while adding component to dashboard", error));
  },
  [UPDATE_DASHBOARD]({ commit }) {
    //FETCH AND PATCH DASHBOARD WITH NEW NAME/SLUG
    this.dispatch("knowledge/get", `dashboards/${state.dashboard.id}`)
      .then(dashboard => {
        dashboard["verboseName"] = state.dashboard.verboseName;
        dashboard["slug"] = slugify(state.dashboard.verboseName);
        dashboard["description"] = undefined;
        this.dispatch("knowledge/patch", dashboard).then(storedDashboard => {
          // UPDATE POSITIONS OF EXISTING COMPONENTS
          Object.values(storedDashboard.components).forEach(storedComponent => {
            for (let component of state.dashboard.layout) {
              if (component.i === storedComponent._jv.id) {
                let updatedComponent = utils.deepCopy(storedComponent);
                updatedComponent.x = component.x;
                updatedComponent.y = component.y;
                updatedComponent.w = component.w;
                updatedComponent.h = component.h;
                this.dispatch("knowledge/patch", updatedComponent);
              }
            }
          });

          //CHECK IF THE COMPONENTS ARE STILL PRESENT IN THE NEW DASHBOARD LAYOUT
          Object.values(storedDashboard.components).forEach(storedComponent => {
            let toBeDeleted = true;
            for (let component of state.dashboard.layout) {
              if (component.i === storedComponent._jv.id) {
                toBeDeleted = false;
                break;
              }
            }
            if (toBeDeleted) {
              this.dispatch("knowledge/delete", storedComponent);
            }
          });

          //ADD NEW COMPONENTS TO THE DASHBOARD
          for (let component of state.dashboard.layout) {
            if (!component.hasOwnProperty("url")) {
              let newComponent = {
                name: component.component,
                x: component.x,
                y: component.y,
                w: component.w,
                h: component.h,
                json_properties: {},
                dashboard: {
                  type: "dashboards",
                  id: storedDashboard._jv.id
                },
                _jv: {
                  type: "components"
                }
              };
              this.dispatch("knowledge/post", newComponent);
            }
          }
        });
      })
      .then(commit(UPDATE_EDIT_MODE, false));
  },
  [RELOAD_DASHBOARD]({ dispatch }) {
    dispatch(CHANGE_DASHBOARD, state.dashboard.id);
  }
};

const modules = {
  knowledge: jsonapiModule(knowledgeJSONAPIClient, jsonapiConf)
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules
};
