import axios from "axios";
import { suffixWithBackslash } from "@/api/utils";

export const DEFAULT_TIMEOUT = 30;

const ASB_BASE_URL = suffixWithBackslash(process.env.VUE_APP_ASB_BASE_URL);

export const defaultClientConfig = {
  baseURL: ASB_BASE_URL,
  timeout: DEFAULT_TIMEOUT * 1000,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken"
};

export const baseURLFromEnv = path => ASB_BASE_URL + path;

export const asbGenericClient = axios.create(defaultClientConfig);
