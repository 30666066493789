<template>
  <b-container class="my-4" fluid>
    <b-row>
      <b-col class="mx-5">
        <page-title title="Execute Model" :subtitle="`${model_name} / ${model_description}`">
        </page-title>
        <b-tabs v-model="tabIndex">
          <b-tab title="Input Form">
            <b-row>
              <b-col cols="6">
                <h4 class="my-4">Inputs</h4>
              </b-col>
              <b-col cols="5">
                <b-overlay :show="updating">
                  <b-form-group class="d-flex justify-content-end text-right my-4">
                    <b-button
                      class="execute-button"
                      @click="encode_and_execute()"
                      type="submit"
                      variant="danger"
                      :disabled="execution_disabled"
                    >
                      <fa-icon class="mt-1 mr-2 float-left" icon="bolt" />
                      <span>Execute</span>
                    </b-button>
                    <!--<b-button
                      class="execute-button"
                      @click="
                        () => {
                          encode_inputs();
                          tabIndex = 1;
                        }
                      "
                      type="submit"
                      variant="outline-primary"
                    >
                      <span>Encode</span>
                      <fa-icon class="mt-1 ml-2 float-right" icon="arrow-right" />
                    </b-button>-->
                    <b-row
                      class="my-2"
                      v-if="tc_url && theme_features.includes('aiopen-tcs') && !disable_tcs"
                    >
                      <b-col cols="12" class="text-right">
                        <b-checkbox
                          class="ml-auto pb-3"
                          :checked="tcs_accepted"
                          @change="updateTCS()"
                          :disabled="tcs_updating"
                        >
                          <span v-if="!tcs_updating"
                            >Accept Terms and Conditions:
                            <b-link :href="tc_url" target="_blank">
                              {{ tc_name }}
                              <fa-icon
                                class="ml-1 fa-light"
                                v-b-tooltip.hover
                                title="Opens in new tab/page"
                                icon="external-link-alt"
                                size="xs"
                              >
                              </fa-icon> </b-link
                          ></span>
                          <span v-else>
                            <b-spinner small class="mx-2"></b-spinner>
                            <span v-if="!tcs_accepted">Accepting Terms and Conditions</span>
                            <span v-else>Revoking consent for Terms and Conditions</span>
                          </span>
                        </b-checkbox>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-overlay>
              </b-col>
            </b-row>
            <b-row v-if="false">
              <b-col cols="9">
                <b-form-group label-cols-lg="2" label="Content type">
                  <b-form-radio-group
                    v-model="selectedContentType"
                    :options="contentTypeOptions"
                  ></b-form-radio-group>
                </b-form-group>
              </b-col>
              <b-col cols="3"> Input Encoding </b-col>
            </b-row>
            <b-row class="mt-2" cols="12" v-for="(inp, key) in signature.inputs" :key="key">
              <b-col cols="9">
                <b-form-group
                  label-cols-lg="2"
                  :label="`${inp.label} (${inp.data_type})`"
                  :description="'description' in inp ? inp.description : ''"
                >
                  <b-form-file
                    v-if="inputIsImage(inp)"
                    v-model="inp.inputValue"
                    placeholder="Choose a file (click or drag-and-drop here)"
                  ></b-form-file>
                  <b-form-input
                    v-else
                    v-model="inp.inputValue"
                    @update="inp.update_value"
                    :state="inp.inputState"
                  ></b-form-input>

                  <b-form-text v-if="inp.doc">{{ inp.doc }}</b-form-text>
                  <b-form-text v-if="inp['tensor-spec']"
                    >Data type: {{ inp["tensor-spec"].dtype }}, shape:
                    {{ inp["tensor-spec"].shape }}</b-form-text
                  >
                </b-form-group>
              </b-col>
              <b-col cols="2" v-if="false">
                <b-form-select v-model="inp.encoding" :options="inputEncodingOptions">
                  <template #first>
                    <b-form-select-option :value="null" disabled
                      >-- Input encoding --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-col>
            </b-row>
          </b-tab>
          <!--<b-tab title="Encoded Inputs">
            <b-row>
              <b-col cols="6">
                <h4 class="my-4">Encoded Inputs</h4>
              </b-col>
              <b-col cols="5">
                <b-overlay :show="updating">
                  <b-form-group class="d-flex justify-content-end text-right my-4">
                    <!- -<span class="mr-2"> Target Platform: </span>
                    <b-form-select
                      v-model="selectedAdes"
                      :options="adesOptions"
                      style="width: 250px"
                    ></b-form-select>- ->
                    <b-button
                      class="execute-button"
                      @click="execute()"
                      type="submit"
                      variant="danger"
                    >
                      <fa-icon class="mt-1 mr-2 float-left" icon="bolt" />
                      <span>Execute</span>
                    </b-button>
                  </b-form-group>
                </b-overlay>
              </b-col>
            </b-row>
            <b-overlay :show="updating">
              <b-row class="my-3" align-h="center">
                <b-col>
                  <b-form-row>
                    <b-form-textarea
                      id="textarea"
                      placeholder="Enter execution inputs formatted in JSON"
                      rows="8"
                      max-rows="35"
                      v-model="executionInputsJson"
                      class="mt-3 mb-3"
                    ></b-form-textarea>
                  </b-form-row>
                </b-col>
              </b-row>
            </b-overlay>
          </b-tab>-->
        </b-tabs>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import backendService from "@/api/asb/services/backend";
import userService from "@/api/asb/services/usermgr";
import PageTitle from "@/components/share/PageTitle";
import { mapState } from "vuex";
import { toBase64 } from "@/api/utils";

export default {
  name: "ModelExecutePage",
  components: {
    PageTitle
  },
  data() {
    return {
      tabIndex: 0,
      model_id: "",
      collection_id: "",
      loading: true,
      modelInfo: {},
      signature: {},
      updating: false,
      executing: false,
      executionInputsJson: "",
      selectedContentType: "pd",
      tcs_updating: false,
      disable_tcs: false,
      contentTypeOptions: [
        { text: "Pandas (default)", value: "pd" },
        { text: "Other", value: "other", disabled: true }
      ],
      inputEncodingOptions: [
        { text: "Numpy array (default)", value: "np" },
        { text: "Other", value: "other", disabled: true }
      ]
    };
  },
  created() {
    this.model_id = this.$route.params.model_id;
    this.collection_id = this.$route.params.id;
    this.getModelInfo();
  },
  methods: {
    getModelInfo() {
      backendService
        .describeItem(this.collection_id, this.model_id)
        .then(data => this.setInfo(data))
        .catch(error => backendService.handleError(error))
        .finally(() => (this.loading = false));
    },
    setInfo(modelInfo) {
      this.modelInfo = modelInfo;
      // this.modelName = this.setModelName();
      // this.modelDescription = this.setModelDescription();
      this.signature = this.setSignature(this.modelInfo);
      console.log(this.modelInfo);
    },
    setSignature(modelInfo) {
      // Function duplicated from ModelDetail.vue => Have a single definition?
      console.log("setSignature", modelInfo);
      let signature = {};
      if (modelInfo.properties["dlm:inputs"]) {
        signature.inputs = modelInfo.properties["dlm:inputs"];
        console.log("Model inputs:", signature.inputs);
        for (let i = 0; i < signature.inputs.length; i++) {
          console.log(signature.inputs[i].name);
          signature.inputs[i].label = signature.inputs[i].name || "Input";
          signature.inputs[i].data_type = signature.inputs[i].type;
          signature.inputs[i].encoding = "np";
        }
      }
      if (modelInfo.properties["dlm:outputs"]) {
        signature.outputs = modelInfo.properties["dlm:outputs"];
      }
      console.log("Signature", signature);
      return signature;
    },
    async encode_inputs() {
      this.executing = true;
      console.log("Executing with input form ...");
      let encoded_inputs = {
        parameters: {
          content_type: this.selectedContentType
        },
        inputs: []
      };
      for (let i = 0; i < this.signature.inputs.length; i++) {
        let input = this.signature.inputs[i];
        // TODO Move the datatypes mapping to a configuration file/variable
        let datatype = "FP32"; // If input.data_type == "double"
        let value_data = [input.inputValue];
        let input_shape = [0];
        let input_file_type = undefined;
        let input_file_name = undefined;
        if (typeof input.inputValue === "string" || input.inputValue instanceof String) {
          value_data = Array.from(input.inputValue.split(","), Number);
          input_shape = [value_data.length];
        } else if (input.inputValue instanceof File) {
          value_data = await toBase64(input.inputValue);
          input_shape = input["tensor-spec"]["shape"];
          input_file_name = input.inputValue.name;
          input_file_type = input.inputValue.name.split(".").pop();
        }

        let encoded_input = {
          name: input.name,
          shape: input_shape,
          datatype: datatype,
          data: value_data,
          file_type: input_file_type,
          file_name: input_file_name,
          parameters: {
            content_type: input.encoding
          }
        };
        encoded_inputs.inputs.push(encoded_input);
      }
      this.executionInputsJson = JSON.stringify(encoded_inputs, null, 2);
      // this.executionInputsJson = encoded_inputs;
      console.log("Encoded inputs:", this.executionInputsJson);
      // {
      //     "parameters": {
      //         "content_type": "pd"
      //     },
      //     "inputs": [
      //         {
      //           "name": "fixed acidity",
      //           "shape": [1],
      //           "datatype": "FP32",
      //           "data": [7.4],
      //           "parameters": {
      //               "content_type": "np"
      //           }
      //         },
    },
    async encode_and_execute() {
      await this.encode_inputs();
      this.execute();
    },
    execute() {
      this.executing = true;
      console.log("Executing with JSON: " + this.executionInputsJson);
      backendService
        .startModelExecution(
          this.collection_id,
          this.model_id,
          this.executionInputsJson,
          this.modelInfo.collection
        )
        .then(() => {
          console.log("execution started!");
          this.$router.push("/monitoring/models");
        });
    },
    inputIsImage(input) {
      if (input.data_type != "tensor") {
        return false;
      }
      const shape = input["tensor-spec"]["shape"];
      if (shape.length == 3) {
        return true;
      }
      if (shape.length == 4 && shape[0] == -1) {
        return true;
      }
      return false;
    },
    getLinkWithRel(rel) {
      if (this.modelInfo === undefined || this.modelInfo.links === undefined) {
        return null;
      }
      for (let link of this.modelInfo.links) {
        if (link.rel == rel) {
          return link;
        }
      }
      return null;
    },
    updateTCS() {
      this.tcs_updating = true;
      if (this.tcs_accepted) {
        userService
          .revokeConsentForTermsAndConditions(this.tc_url)
          .then(() => {
            this.$store.dispatch("FETCH_ACCEPTED_TCS");
            this.tcs_updating = false;
          })
          .catch(error => backendService.handleError(error));
      } else {
        userService
          .acceptTermsAndConditions(this.tc_url)
          .then(() => {
            this.$store.dispatch("FETCH_ACCEPTED_TCS");
            this.tcs_updating = false;
          })
          .catch(error => backendService.handleError(error));
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
      theme_features: state => state.instance.theme_features,
      accepted_tcs: state => state.accepted_tcs
    }),
    model_name() {
      let modelName = "model";
      if (this.modelInfo.properties) {
        modelName = this.modelInfo.properties.name
          ? this.modelInfo.properties.name
          : this.modelInfo.properties.title
          ? this.modelInfo.properties.title
          : this.modelInfo.id
          ? this.modelInfo.id
          : "model";
      }
      return modelName;
    },
    model_description() {
      let modelDescription = "model";
      if (this.modelInfo.properties) {
        modelDescription = this.modelInfo.properties.abstract
          ? this.modelInfo.properties.abstract
          : this.modelInfo.description
          ? this.modelInfo.description
          : "model";
      }
      return modelDescription;
    },
    tcs_accepted() {
      if (this.tc_url == null) {
        // No license specified so TC accepted by default
        return true;
      }
      console.log("License URL:", this.tc_url);
      console.log("Accepted TCs:", this.accepted_tcs);
      return this.accepted_tcs.includes(this.tc_url) || this.accepted_tcs.includes("*");
    },
    execution_disabled() {
      return this.theme_features.includes("aiopen-tcs")
        ? !this.tcs_accepted || this.executing
        : false | this.executing;
    },
    tc_name() {
      // Two possibilities:
      // 1. Use the syntax "SPDX-License-Identifier: <license_id>" in properties.license
      // 2. Use a link with relation type "license"
      // Doc: https://github.com/radiantearth/stac-spec/blob/master/item-spec/common-metadata.md#licensing
      if (
        this.modelInfo.properties &&
        this.modelInfo.properties.license &&
        this.modelInfo.properties.license.startsWith("SPDX-License-Identifier:")
      ) {
        return this.modelInfo.properties.license.split(":")[1].trim();
      }
      let tc_info = this.getLinkWithRel("license");
      if (tc_info) {
        return tc_info.title ? tc_info.title : tc_info.href;
      }
      return null;
    },
    tc_url() {
      // Check the license property
      if (
        this.modelInfo.properties &&
        this.modelInfo.properties.license &&
        this.modelInfo.properties.license.startsWith("SPDX-License-Identifier:")
      ) {
        let tc_id = this.modelInfo.properties.license.split(":")[1].trim();
        return "https://spdx.org/licenses/" + tc_id + ".html";
      }
      // Otherwise, check the links with relation type "license"
      let tc_info = this.getLinkWithRel("license");
      return tc_info ? tc_info.href : null;
    }
  },
  filters: {
    pretty: function(value) {
      return JSON.stringify(value, null, 2);
    },
    makeTitle: function(value) {
      const title = value.replace("_", " ");
      let title2 = title.charAt(0).toUpperCase() + title.slice(1);
      if (title2 == "Doi") {
        title2 = "DOI";
      }
      return title2;
    }
  }
};
</script>

<style></style>
