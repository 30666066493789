<template>
  <b-container class="mt-4" :fluid="fluid">
    <b-modal ref="new" size="lg" hide-footer id="new" :title="`Create a new ${verboseName}`">
      <slot name="create-resource-form">
        <create-resource-form
          :service="service"
          :name="name"
          :create-fields="createFields"
          :defaults="createDefaults"
        ></create-resource-form>
      </slot>
    </b-modal>
    <b-modal ref="archive" hide-footer id="archive" :title="`Archive ${verboseName}`" size="lg">
      <b-row align-h="center" no-gutters>
        <b-col class="my-3" cols="12">
          <span class="lead">
            <span>You are about to archive the {{ verboseName }} &nbsp;</span>
            <b>{{ identifierOf(selectedArchiveResource) }}</b>
          </span>
          <p class="mt-4">
            {{ archiveText }}
          </p>
        </b-col>
        <b-col cols="12">
          <b-button
            :variant="error ? 'failed' : 'warning'"
            @click="toggleArchive"
            :disabled="updating"
            block
          >
            <span v-if="!updating">
              <fa-icon class="mr-3" icon="archive" />
              <span>Archive it</span>
            </span>
            <b-spinner v-else small></b-spinner>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="shareable" ref="share" hide-footer id="share" :title="`Share ${verboseName}`">
      <b-row align-h="end" no-gutters>
        <b-col cols="12">
          <multiselect
            v-model="workspaces"
            :options="workspaceOptions"
            track-by="identifier"
            label="label"
            placeholder="Select workspaces"
            :multiple="true"
            :taggable="true"
          ></multiselect>
          <h4 class="mt-3"></h4>
        </b-col>
        <b-col cols="4">
          <b-button
            class="btn-block"
            :variant="updateFailed ? 'failed' : 'success'"
            @click="shareResource()"
            :disabled="updating"
          >
            <span v-if="!updating">Share {{ verboseName }}</span>
            <b-spinner v-else small></b-spinner>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal ref="delete" hide-footer id="delete" :title="`Delete ${verboseName}`" size="lg">
      <b-row align-h="center" no-gutters>
        <b-col class="my-3" cols="12">
          <span class="lead">
            <span>You are about to delete the {{ verboseName }} &nbsp;</span>
            <b>{{ identifierOf(selectedDeleteResource) }}</b>
          </span>

          <p class="mt-2">
            {{ deleteText }} <br />
            Please enter the identifier of the {{ verboseName }} to confirm you want to delete it.
          </p>
          <slot name="extra-delete-info" v-bind:resource="selectedDeleteResource"> </slot>
          <b-form>
            <b-form-row>
              <b-col cols="6">
                <b-form-group
                  description="Enter the identifier to confirm deletion"
                  :label="`${capitalize(verboseName)} identifier`"
                  label-for="verification-identifier"
                >
                  <b-form-input
                    id="verification-identifier"
                    v-model="verificationIdentifier"
                    trim
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-form>

          <b-button
            :variant="error ? 'failed' : 'failed'"
            @click="deleteResource"
            :disabled="updating || !verificationMatches"
            block
          >
            <span v-if="!updating">
              <fa-icon class="mr-3" icon="trash" />
              <span>Delete it</span>
            </span>
            <b-spinner v-else small></b-spinner>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <page-title :title="title ? title : capitalize(pluralVerboseName)">
      <template v-slot:toolbar>
        <b-btn-toolbar class="float-right" justify>
          <slot name="title-toolbar" v-bind:loading="loading"></slot>
          <b-btn-group v-if="enableCreate" class="ml-2">
            <b-btn variant="success" size="md" v-b-modal.new :disabled="loading">
              New {{ verboseName }}
            </b-btn>
          </b-btn-group>
        </b-btn-toolbar>
      </template>
    </page-title>
    <b-row>
      <b-col>
        <b-tabs>
          <b-tab title="All" active>
            <b-row>
              <b-col class="my-2" cols="12">
                <resource-filters
                  ref="resourceFilters"
                  :disabled="hideFilters"
                  :loading="loading"
                  :filters="filters"
                  :placeholder="`Filter ${pluralVerboseName}`"
                  @filter="searchResources()"
                  @ready="init()"
                ></resource-filters>
              </b-col>
              <b-col cols="12">
                <b-overlay class="mt-3" :show="loading">
                  <b-table
                    ref="table"
                    :items="Object.values(resources)"
                    thead-class="d-none"
                    :fields="fields"
                    :sort-by="sortBy"
                    :sort-desc="sortDesc"
                    :tbody-tr-class="rowClass"
                  >
                    <template v-slot:cell(detail)="data">
                      <div>
                        <slot name="resource-label" v-bind:resource="data.item">
                          <router-link
                            class="bold-link"
                            :to="{
                              name: name,
                              params: { id: data.item._jv.id }
                            }"
                            v-b-tooltip.hover
                            :title="data.item.identifier"
                          >
                            <span>{{ data.item.ownerId }} / {{ data.item.label }}</span>
                          </router-link>
                        </slot>
                        <span v-b-tooltip.right title="Archived">
                          <fa-icon
                            v-show="data.item.isArchived"
                            icon="archive"
                            class="ml-2"
                          ></fa-icon>
                        </span>

                        <workspace-badges
                          :workspaces="
                            proxyWorkspaces
                              ? data.item[proxyWorkspacesThrough].workspaces
                              : data.item.workspaces
                          "
                          :badge-variant="proxyWorkspaces ? 'info' : 'primary'"
                          :note="
                            proxyWorkspaces ? `shared through ${proxyWorkspacesThrough}` : undefined
                          "
                          :max-badges="3"
                        >
                        </workspace-badges>
                      </div>
                    </template>
                    <template v-slot:cell(toolbar)="data">
                      <div class="float-right">
                        <b-button-group class="float-right ml-2" v-if="showMenu(data.item)">
                          <b-dropdown class="no-caret" variant="transparent" size="sm" right>
                            <template slot="button-content">
                              <fa-icon icon="bars"></fa-icon>
                            </template>
                            <b-dropdown-item v-if="archivable" @click="confirmArchive(data.item)">
                              <fa-icon class="mr-2" icon="archive"></fa-icon>
                              <span>{{ data.item.isArchived ? "Unarchive" : "Archive" }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item v-if="shareable" @click="confirmShare(data.item)">
                              <fa-icon class="mr-2" icon="share"></fa-icon>
                              <span>Share</span>
                            </b-dropdown-item>
                            <b-dropdown-item v-if="deleteable" @click="confirmDelete(data.item)">
                              <fa-icon class="mr-2" icon="trash"></fa-icon>
                              <span>Delete</span>
                            </b-dropdown-item>
                          </b-dropdown>
                        </b-button-group>
                        <slot name="resource-toolbar" v-bind:resource="data.item"></slot>
                      </div>
                    </template>
                  </b-table>
                </b-overlay>
              </b-col>
            </b-row>
            <empty
              v-if="!loading && $_.isEmpty(resources)"
              :text="
                error
                  ? `Something went wrong while fetching ${pluralVerboseName}`
                  : this.$refs.resourceFilters && $refs.resourceFilters.search
                  ? `No ${pluralVerboseName} matching your search`
                  : `No ${pluralVerboseName} found`
              "
              :icon="error ? 'exclamation' : 'folder-open'"
              class="mt-2"
            ></empty>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <b-pagination
          v-show="paginate && !loading && !$_.isEmpty(resources)"
          align="center"
          :total-rows="pagination.count"
          :per-page="pageSize"
          v-model="pagination.page"
          @input="changePage"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from "vuex";
import PageTitle from "@/components/share/PageTitle";
import Empty from "@/components/share/Empty";
import CreateResourceForm from "@/components/generic/CreateResourceForm";
import Multiselect from "vue-multiselect";
import { capitalize, pluralize, removeJsonApiMeta } from "@/utils";
import { handleServiceError } from "@/api/utils";
import { utils } from "jsonapi-vuex";
import ResourceFilters from "@/components/generic/ResourceFilters";
import WorkspaceBadges from "@/components/share/WorkspaceBadges";

export default {
  name: "GenericList",
  components: {
    WorkspaceBadges,
    ResourceFilters,
    PageTitle,
    Empty,
    CreateResourceForm,
    Multiselect
  },
  props: {
    name: {
      type: String,
      required: true
    },
    service: {
      type: String,
      required: true
    },
    fluid: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Array,
      required: false,
      default: () => []
    },
    hideFilters: {
      type: Boolean,
      required: false,
      default: false
    },
    sortBy: {
      type: String,
      required: false,
      default: undefined
    },
    sortDesc: {
      type: Boolean,
      required: false,
      default: false
    },
    sortByMultipleFields: {
      type: Array,
      required: false,
      default: () => []
    },
    createFields: {
      type: Array,
      required: false,
      default: () => {
        return [
          {
            name: "example",
            label: "Example",
            type: "text",
            onChange: () => console.debug("Slug changing")
          }
        ];
      }
    },
    createDefaults: {
      type: Object,
      required: false,
      default: () => {}
    },
    enableCreate: {
      type: Boolean,
      required: false,
      default: false
    },
    // Archiving
    archiveText: {
      type: String,
      required: false,
      default: ""
    },
    // Pagination
    paginate: {
      type: Boolean,
      required: false,
      default: false
    },
    // Workspaces
    // Field
    proxyWorkspacesThrough: {
      // Field to proxy workspaces.
      // For example:
      // a processVersion get its workspaces through process: version.process.workspaces
      type: String,
      required: false,
      default: undefined
    },
    // Additional configuration
    identifierOf: {
      type: Function,
      required: false,
      default: resource => resource.identifier
    },
    title: {
      type: String,
      required: false,
      default: undefined
    },
    //Deleting a resource
    archivable: {
      type: Boolean,
      required: false,
      default: true
    },
    deleteable: {
      type: Boolean,
      required: false,
      default: false
    },
    deleteText: {
      type: String,
      required: false,
      default: ""
    },
    shareable: {
      type: Boolean,
      default: false
    },
    ownerOrAdminMenuOnly: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      loading: true,
      updating: false,
      updateFailed: false,
      error: false,
      // used for verification when deleting a resource.
      verificationIdentifier: "",
      fields: [
        {
          key: "detail",
          label: "",
          sortable: true
        },
        {
          key: "toolbar",
          label: "",
          sortable: false
        }
      ],
      // Archiving
      selectedArchiveResource: {},
      //Deleting
      selectedDeleteResource: {},
      //Sharing
      selectedShareResource: {},
      workspaces: [],

      allWorkspaces: [],
      workspaceOptions: [],
      // Pagination
      pagination: {
        page: 1,
        pages: 1,
        count: 1
      },
      pageSize: 20,
      // Workspaces
      maxWorkspaceTags: 3
    };
  },
  beforeUpdate() {
    console.debug("GenericList beforeUpdate");
  },
  methods: {
    capitalize: capitalize,
    pluralize: pluralize,
    init() {
      // We usually always need workspaces, so we fetch them prior to any other resource fetching
      this.$store
        .dispatch(`${this.workspaceService}/get`, "workspaces")
        .then(workspaces => {
          this.allWorkspaces = removeJsonApiMeta(workspaces);
          this.workspaceOptions = Object.values(this.allWorkspaces);
          this.fetchResources(1, this.userFilters);
        })
        .catch(error => {
          this.loading = false;
          this.error = true;
          handleServiceError(this.service, error);
        });

      // console.warn('workspaces');
      // console.log(workspaces);
      // workspaces = removeJsonApiMeta(workspaces);

      // this.workspaceOptions = Object.values(workspaces);
    },
    fetchResources(page = 1, filters = {}) {
      this.loading = true;
      this.error = false;
      let params = { "page[number]": page, "page[size]": this.pageSize };
      if (this.sortByMultipleFields.length > 0) {
        params["sort"] = this.sortByMultipleFields.toString();
      } else {
        if (this.sortBy) {
          let sort = this.sortBy;
          if (this.sortDesc) {
            sort = "-" + this.sortBy;
          }
          params["sort"] = sort;
        }
      }

      if (filters.search) {
        params["filter[search]"] = filters.search;
      }
      for (let filter in filters) {
        let value = filters[filter];
        console.log("Adding filter", value, "type", typeof value);
        if (typeof value === "boolean" || !this.$_.isEmpty(value)) {
          params[`filter[${filter}]`] = value;
        }
      }
      // Since we paginate, we need to keep in the store only the data from the requested page,
      // therefore we clear the data already present, probably from the previous pages.
      this.$store.commit(`${this.service}/clearRecords`, {
        _jv: { type: this.pluralName }
      });
      this.$store
        .dispatch(`${this.service}/get`, [this.pluralName, { params: params }])
        .then(response => {
          if (this.paginate) {
            response = response._jv.json;
            this.pagination = response.meta.pagination;
            console.debug("Pagination update", response.meta.pagination);
          }
        })
        .catch(error => {
          this.error = true;
          handleServiceError(this.service, error);
        })
        .finally(() => (this.loading = false));
    },
    changePage(page) {
      console.debug("Changing page to", page);
      this.fetchResources(page, this.userFilters);
    },
    searchResources() {
      console.debug("Searching for", this.userFilters);
      this.fetchResources(1, this.userFilters);
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.isArchived) return "table-archived";
    },
    async updateResource(resource) {
      // We assume that the resource is already deep copied (required for the patch)
      console.debug("Updating resource with", resource);
      this.updating = true;
      await this.$store.dispatch(`${this.service}/patch`, resource);
      this.updating = false;
    },
    toggleArchive() {
      let archivedResource = utils.deepCopy(this.selectedArchiveResource);
      let archiving = !archivedResource.isArchived;
      let isArchived = this.userFilters.isArchived === "true";
      let id = this.selectedArchiveResource._jv.id;
      archivedResource.isArchived = !archivedResource.isArchived;
      this.error = false;
      this.updateResource(archivedResource)
        .then(() => {
          this.$refs.archive.hide();
          this.$notify({
            group: "global",
            type: archiving ? "warn" : "success",
            title: "Archive",
            text: `${this.verboseName} #${id} ${archiving ? "" : "un"}archived`,
            duration: 5000
          });
          // We only remove the entry from the table if the user does not want to see it:
          // - If archiving and not showing archived
          // - If unarchiving and showing archived
          // Note: we use the non-boolean isArchived variable on purpose:
          // to check if the user has set the filter.
          // Then we use the boolean value, otherwise the string the"false" will be eval as true.
          console.log("Archiving flags (archiving, isArchived)", archiving, isArchived);
          if (
            this.userFilters.isArchived !== undefined &&
            ((archiving && !isArchived) || (!archiving && isArchived))
          ) {
            console.debug("Removing from resources object: ", id);
            this.$delete(this.resources, id);
          } else {
            // If we don't remove/hide it from the table, update it
            this.$store
              .dispatch(`${this.service}/get`, `${this.pluralName}/${id}`)
              .then(resource => (this.resources[id] = resource));
          }
        })
        .catch(error => {
          this.error = true;
          this.updating = false;
          handleServiceError(this.service, error);
        });
    },
    confirmArchive(resource) {
      this.selectedArchiveResource = resource;
      // We don't ask for confirmation for unarchiving
      if (this.selectedArchiveResource.isArchived) this.toggleArchive();
      else this.$refs.archive.show();
    },
    confirmDelete(resource) {
      this.selectedDeleteResource = resource;
      this.verificationIdentifier = "";
      this.$refs.delete.show();
    },
    deleteResource() {
      this.updating = true;
      this.$store
        .dispatch(`${this.service}/delete`, this.selectedDeleteResource)
        .then(() => {
          this.$refs.delete.hide();
          this.$notify({
            group: "global",
            type: "success",
            title: "Delete",
            text: `${this.verboseName} ${this.selectedDeleteResource.identifier} Deleted`,
            duration: 5000
          });
          this.updating = false;
        })
        .catch(error => {
          handleServiceError(this.service, error);
          this.updating = false;
        });
    },
    confirmShare(resource) {
      this.selectedShareResource = resource;
      this.workspaces = [];
      if (this.selectedShareResource.workspaces) {
        for (let workspaceId in this.allWorkspaces) {
          if (workspaceId in this.selectedShareResource.workspaces) {
            this.workspaces.push(this.allWorkspaces[workspaceId]);
          }
        }
      }
      this.$refs.share.show();
    },
    shareResource() {
      // updating status is handled manually event though jsonapi-vuex provide
      // a status getter for each action. There is an inconstistent behavious with this getter
      // see issue https://github.com/mrichar1/jsonapi-vuex/issues/75
      this.updating = true;
      let patchedResource = utils.deepCopy(this.selectedShareResource);
      patchedResource["_jv"]["relationships"].workspaces = {
        data: this.workspaces.map(workspace => ({
          id: workspace["_jv"].id,
          type: workspace["_jv"].type
        }))
      };
      let action = this.$store.dispatch(`${this.workspaceService}/patch`, patchedResource);
      action
        .then(() => {
          this.updateFailed = false;
          this.$refs.share.hide();
        })
        .catch(error => {
          this.updateFailed = true;
          handleServiceError(this.service, error);
        })
        .finally(() => (this.updating = false));
    },
    showMenu(item) {
      return this.ownerOrAdminMenuOnly
        ? this.user.is_superuser
          ? true
          : item.ownerId == this.user.username
        : true;
    }
  },
  computed: {
    ...mapState({
      user: state => state.user
    }),
    // FIXME verbose and plural should not be computed values but values created at init, they are not computed props!
    verboseName() {
      return this.name.replace("-", " ");
    },
    pluralName() {
      return this.pluralize(this.name);
    },
    pluralVerboseName() {
      return this.pluralize(this.verboseName);
    },
    userFilters() {
      return this.$refs.resourceFilters && this.$refs.resourceFilters.userFilters;
    },
    proxyWorkspaces() {
      return this.proxyWorkspacesThrough !== undefined;
    },
    resources() {
      let resources = Object.values(this.$store.getters[`${this.service}/get`](this.pluralName));
      if (this.sortByMultipleFields.length > 0) {
        resources = resources.sort((a, b) => {
          return (
            a[this.sortByMultipleFields[0]].localeCompare(b[this.sortByMultipleFields[0]]) ||
            a[this.sortByMultipleFields[1]].localeCompare(b[this.sortByMultipleFields[1]])
          );
        });
      }
      console.log(resources);
      return resources;
    },
    verificationMatches() {
      if (this.selectedDeleteResource) {
        return this.selectedDeleteResource.identifier === this.verificationIdentifier;
      }
      return false;
    },
    workspaceService() {
      // Backend has its own workspaces for sharing, all others use knowledge
      return this.service == "backend" ? "backend" : "knowledge";
    }
  },
  watch: {
    selectedDeleteResource() {
      this.$emit("delete-dialog-opened", this.selectedDeleteResource);
    }
  }
};
</script>

<style lang="scss" scoped></style>
