<template>
  <b-container v-show="bucket" class="mt-4">
    <page-title :title="`Bucket #${bucketId}`">
      <template v-slot:toolbar>
        <b-btn-toolbar class="float-right">
          <b-btn-group>
            <!--<b-btn variant="danger" :disabled="process === undefined">-->
            <!--<span class="mr-2">Reset</span>-->
            <!--<fa-icon icon="trash"></fa-icon>-->
            <!--</b-btn>-->
            <!--<b-btn v-if="dockerfile" variant="outline-dark">-->
            <!--<span class="mr-2">Dockerfile</span>-->
            <!--<fa-icon icon="download"></fa-icon>-->
            <!--</b-btn>-->
          </b-btn-group>
        </b-btn-toolbar>
      </template>
    </page-title>
    <process-bucket class="mt-3" :bucket-id="bucketId"></process-bucket>
  </b-container>
</template>

<script>
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { importService } from "@/api/asb";
import PageTitle from "@/components/share/PageTitle";
import knowledgeService from "@/api/asb/services/knowledge";
import ProcessBucket from "@/components/buckets/ProcessBucket";

const IMPORT_FAILED = "Import failed";
const IMPORT_SUCCESS = "Import succeeded";
const IMPORT_PENDING = "Importing";

export default {
  name: "ProcessBucketPage",
  components: {
    ProcessBucket,
    PageTitle
  },
  props: {},
  watch: {},
  data: function() {
    const bucketId = this.$route.params["id"];
    return {
      bucketId: bucketId,
      PROCESS_WRAPPER_FILENAME: "process_wrapper.py",
      loading: true,
      fetchError: true,
      dropzoneOptions: {
        url: importService.processBucketUploadFilesURL(bucketId),
        thumbnailWidth: 100,
        maxFilesize: 20
        // addRemoveLinks: true
      },
      process: undefined,
      dockerfile: undefined,
      properties: undefined,
      // Tree view files data
      files: undefined,
      importStatus: {
        statusCode: undefined,
        statusText: undefined,
        statusMessage: undefined,
        logs: []
      }
    };
  },
  methods: {
    // Dropzone events
    dropzoneMounted() {
      console.log("dropzone mounted");
      this.loading = true;
      this.$store
        .dispatch("knowledge/get", `process-buckets/${this.bucketId}`)
        .then(() => {
          this.updateBucketData(false)
            .catch(e => {
              this.$refs.dropzone.disable();
              importService.handleError(e);
            })
            .finally(() => (this.loading = false));
        })
        .catch(e => knowledgeService.handleError(e));
    },
    removedFile(file) {
      console.log("Removing file", file);
      // FIXME this function has been disabled (see below)
      // because the event is called not only when the button remove file is pressed,
      // but also when the page is reloaded or when the route change.

      // importService.removeProcessBucketFile(this.bucketId, file.id).then(
      //     // this.$notify({
      //     //     group: 'global',
      //     //     type: 'success',
      //     //     title: "Import Tool",
      //     //     duration: 3000,
      //     //     text: `File ${file.name} removed`,
      //     // })
      // )
      // this.updateBucketData()
    },
    addedFile(file) {
      console.log(file);
    },
    uploadSuccess(file, response) {
      console.debug("Upload Success: ", file, response);
      // Might not be necessary to update EVERYTHING at each upload..
      this.updateBucketData(false);
    },
    uploadError(file, response) {
      console.error("Upload Error: ", file, response);
    },
    // Other methods
    async updateBucketData(addFilesManually) {
      this.dockerfile = await importService.getProcessBucketDockerfile(this.bucketId);
      const properties = await importService.getProcessBucketProperties(this.bucketId);
      if (properties) {
        this.process = {
          workspace: properties.identification.mission,
          label: properties.identification.name,
          version: properties.identification.version
        };
      } else {
        this.process = undefined;
        this.files = undefined;
        this.dockerfile = undefined;
      }
      this.properties = properties;
      await this.getProcessFiles(addFilesManually);
    },
    async getProcessFiles(addFilesManually) {
      const rawFiles = await importService.getProcessBucketFiles(this.bucketId);
      this.files = this.buildTreeFiles(rawFiles);
      if (this.files.length > 0 && addFilesManually) {
        // files[0] -> Show only files under root
        // console.log(this.files[0].children[0])
        this.files[0].children.map(file => {
          this.$refs.dropzone.manuallyAddFile(
            {
              name: file.name
            },
            importService.processBucketUploadFilesURL(this.bucketId)
          );
        });
      }
    },
    /**
     * Convert raw files data from Import Service into tree by adding children
     * filed to all file element. Also adding a name property into elements to be
     * interpreted by TreeItem component.
     * @param rawFiles
     * @returns Array of files, with children attr. list.
     */
    buildTreeFiles(rawFiles) {
      let map = {},
        files = [];
      for (let index in rawFiles) {
        const rawFile = rawFiles[index];
        map[rawFile.id] = index;
        files.push({
          id: rawFile.id,
          parent: rawFile.parent,
          name: rawFile.text,
          children: [],
          type: "image/png"
        });
      }
      for (let file of files) {
        if (file.parent !== "#") {
          files[map[file.parent]].children.push(file);
        }
      }
      // return root only
      return files;
    },
    dryRunImport() {
      this.startImport(importService.dryRunImportProcessBucket);
    },
    commitImport() {
      this.startImport(importService.importProcessBucket);
    },
    startImport(importCall) {
      // NOTE: very ugly way of using websocket, need to be refactored when backend is.
      // This flow is taken from the old process import tool page, where the HTTP request to start
      // the import is made before connecting to the websocket,
      // and each import requires a new websocket connection. See ASBFW-174 for more details.
      this.$disconnect();
      importCall(this.bucketId)
        .then(() => {
          this.connectWebSocket();
        })
        .catch(e => importService.handleError(e));
    },
    connectWebSocket() {
      this.$connect(importService.processBucketStatusWebSocketURL(this.bucketId), {
        format: "json"
      });
      this.$options.sockets.onmessage = message => {
        let data = JSON.parse(message.data);
        console.log("Import data", data);
        this.importStatus.logs = data["content"];
        this.importStatus.statusText = data["status_text"];
        this.importStatus.statusMessage = data["status_message"];
        this.importStatus.statusCode = data["status_code"];
      };
      this.$options.sockets.onerror = event => {
        importService.handleError(event);
      };
      console.debug("Dryn run successfully sent");
    }
  },
  computed: {
    bucket() {
      return this.$store.getters["knowledge/get"](`process-buckets/${this.bucketId}`);
    },
    importSuccess() {
      return this.importStatus.statusText === IMPORT_SUCCESS;
    },
    importFailed() {
      return this.importStatus.statusText === IMPORT_FAILED;
    },
    importPending() {
      return this.importStatus.statusText === IMPORT_PENDING;
    },
    importVariant() {
      if (this.importStatus.statusCode === undefined) {
        return "default";
      } else if (this.importPending) {
        return "pending";
      } else if (this.importSuccess) {
        return "success";
      } else if (this.importFailed) {
        return "failed";
      } else {
        console.warn(`Unknown import status ${this.importStatus.statusText}`);
        return "default";
      }
    },
    dryRunPassed() {
      return this.importSuccess;
    }
  }
};
</script>

<style scoped>
.logs {
  font-family: "Courier New", serif;
  color: black;
}
</style>
