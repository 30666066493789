<template>
  <b-row>
    <b-col cols="12">
      <empty
        class="mt-2"
        v-show="loading || importerError"
        :loading="loading"
        :text="importerError ? 'Something went wrong while fetching files' : 'Fetching files...'"
        show-loading-content
        icon="exclamation"
      />
    </b-col>
    <b-col cols="12" v-if="bucketId === undefined">
      <empty text="No files found"></empty>
    </b-col>
    <b-col v-else cols="12">
      <span v-if="bucket && developerMode" class="text-secondary dev-mode">
        Current bucket: {{ bucket["label"] }}, ID: {{ bucket._jv.id }}
      </span>
      <div v-if="bucket && developerMode" class="text-secondary dev-mode">
        Read-only: {{ readOnly }}
      </div>
      <b-tabs v-show="!loading && !importerError">
        <b-tab title="Files" active>
          <b-row v-if="process" class="mt-4">
            <b-col>
              <h5 class="text-generated">
                <div v-if="developerMode" class="dev-mode mb-3">
                  <span class="text-dark mr-2">Found process:</span>
                  From importer: {{ process.identifier }} From bucket:
                  {{ bucket.process.identifier }}
                </div>
                <div>
                  <fa-icon class="mr-2" icon="check-circle"></fa-icon>
                  <span>Wrapper detected</span>
                </div>
              </h5>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="12">
              <vue-dropzone
                v-show="!readOnly"
                ref="dropzone"
                id="dropzone"
                v-on:vdropzone-mounted="dropzoneMounted"
                v-on:vdropzone-removed-file="removedFile"
                v-on:vdropzone-file-added="addedFile"
                v-on:vdropzone-success="uploadSuccess"
                v-on:vdropzone-error="uploadError"
                :options="dropzoneOptions"
                useCustomSlot
              >
                <div>
                  <h3>Upload your process files</h3>
                  <span
                    >The wrapper file <code>proccess_wrapper.py</code> will automatically be
                    detected
                  </span>
                  <div>
                    You can import folders that are bundled in tar archives, decompressed during the
                    build
                  </div>
                </div>
              </vue-dropzone>
            </b-col>
            <b-col class="mt-2" v-if="files">
              <b-card header="Files">
                <ul>
                  <!-- Show only root element (containing children refs) -->
                  <tree-item :item="files[0]" open></tree-item>
                </ul>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab v-if="properties" class="my-3" title="Properties">
          <b-card>
            <highlight-code lang="json">
              {{ properties }}
            </highlight-code>
          </b-card>
        </b-tab>
        <b-tab v-if="developerMode && dockerfile" class="dev-mode my-4" title="Dockerfile">
          <b-card>
            <highlight-code lang="dockerfile">
              {{ dockerfile }}
            </highlight-code>
          </b-card>
        </b-tab>
        <b-tab v-if="process !== undefined && !readOnly" title="Build">
          <b-row class="mt-3">
            <b-col cols="12" align="right">
              <b-btn-group v-if="dryRunPassed">
                <b-btn variant="outline-info" @click="dryRunImport">
                  <fa-icon class="mr-2" icon="redo"></fa-icon>
                  <span>Rebuild</span>
                </b-btn>
                <b-btn variant="success" @click="commitImport">
                  <fa-icon class="mr-2" icon="cloud-upload-alt"></fa-icon>
                  <span>Build & Release</span>
                </b-btn>
              </b-btn-group>
              <b-dropdown
                v-else
                variant="outline-info"
                split-variant="info"
                right
                split
                :disabled="importPending"
                @click="dryRunImport"
              >
                <template v-slot:button-content>
                  <fa-icon class="mr-2" icon="play-circle"></fa-icon>
                  <span>Build</span>
                </template>
                <b-dropdown-item @click="commitImport">
                  <fa-icon class="mr-2" icon="cloud-upload-alt"></fa-icon>
                  <span>Build & Release</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
            <b-col v-if="importStatus.statusText === undefined" cols="12">
              <empty icon="pause-circle" text="Build not started"> </empty>
            </b-col>
            <b-col v-else cols="12" class="mt-2">
              <b-card
                header-tag="header"
                :header-bg-variant="importVariant"
                header-text-variant="white"
                :border-variant="importVariant"
              >
                <template #header>
                  <p class="mb-0 d-inline my-auto">
                    {{ importStatus.statusText }} /
                    {{ importStatus.statusMessage }}
                  </p>
                  <fa-icon v-if="streamUnconnected" icon="unlink" variant="white" class="ml-2">
                  </fa-icon>
                </template>
                <b-card-text>
                  <span></span>
                  <code class="logs">
                    <ul class="list-unstyled mb-0">
                      <li v-for="(log, index) in importStatus.logs" :key="index">- {{ log }}</li>
                    </ul>
                  </code>
                  <b-icon
                    v-if="ESClientConnected"
                    color="grey"
                    class="h1 ml-2"
                    icon="three-dots"
                    animation="cylon"
                  ></b-icon>
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-col>
  </b-row>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { importService } from "@/api/asb";
import TreeItem from "@/components/share/TreeItem";
import knowledgeService from "@/api/asb/services/knowledge";
import Empty from "@/components/share/Empty";
import SSEClient from "@/api/sse.js";

const IMPORT_FAILED = "Import failed";
const IMPORT_SUCCESS = "Import succeeded";
const IMPORT_PENDING = "Importing";
const IMPORT_COMPLETE = "Import complete";

export default {
  name: "ProcessBucket",
  components: {
    vueDropzone: vue2Dropzone,
    TreeItem,
    Empty
  },
  props: {
    bucketId: {
      type: String,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    bucketId: function() {
      this.loadBucket();
    }
  },
  data: function() {
    return {
      PROCESS_WRAPPER_FILENAME: "process_wrapper.py",
      loading: true,
      importerError: false,
      buildPassed: false,
      releasing: false,
      dropzoneOptions: {
        url: importService.processBucketUploadFilesURL(this.bucketId),
        thumbnailWidth: 100,
        maxFilesize: 20
        // addRemoveLinks: true
      },
      process: undefined,
      dockerfile: undefined,
      properties: undefined,
      // Tree view files data
      files: undefined,
      importStatus: {
        statusCode: undefined,
        statusText: undefined,
        statusMessage: undefined,
        logs: []
      },
      ESClientConnected: false,
      ESClient: undefined
    };
  },
  methods: {
    // Dropzone events
    dropzoneMounted() {
      console.log("dropzone mounted");
      this.loadBucket();
    },
    loadBucket() {
      console.debug("Loading bucket ", this.bucketId);
      const uploadURL = importService.processBucketUploadFilesURL(this.bucketId);
      console.debug("Changing upload url", uploadURL);
      this.$refs.dropzone.setOption("url", uploadURL);
      this.resetBucket();
      this.$store
        .dispatch("knowledge/get", `process-buckets/${this.bucketId}`)
        .then(() => {
          this.updateBucketData(false)
            .catch(e => {
              this.$refs.dropzone.disable();
              this.importerError = true;
              importService.handleError(e);
            })
            .finally(() => (this.loading = false));
        })
        .catch(e => knowledgeService.handleError(e));
    },
    resetBucket() {
      this.importStatus = {
        statusCode: undefined,
        statusText: undefined,
        statusMessage: undefined,
        logs: []
      };
      this.loading = true;
      this.importerError = false;
      this.buildPassed = false;
      this.$refs.dropzone.removeAllFiles();
    },
    removedFile(file) {
      console.log("Removing file", file);
      // FIXME this function has been disabled (see below)
      //  because the event is called not only when the button remove file is pressed,
      //  but also when the page is reloaded or when the route change.

      // importService.removeProcessBucketFile(this.bucketId, file.id).then(
      //     // this.$notify({
      //     //     group: 'global',
      //     //     type: 'success',
      //     //     title: "Import Tool",
      //     //     duration: 3000,
      //     //     text: `File ${file.name} removed`,
      //     // })
      // )
      // this.updateBucketData()
    },
    addedFile(file) {
      console.log(file);
    },
    uploadSuccess(file, response) {
      console.debug("Upload Success: ", file, response);
      // Might not be necessary to update EVERYTHING at each upload..
      this.updateBucketData(false);
    },
    uploadError(file, response) {
      console.error("Upload Error: ", file, response);
    },
    // Other methods
    async updateBucketData(addFilesManually) {
      this.dockerfile = await importService.getProcessBucketDockerfile(this.bucketId);
      const properties = await importService.getProcessBucketProperties(this.bucketId);
      if (properties) {
        this.process = {
          identifier: properties.identification.identifier
        };
      } else {
        this.process = undefined;
        this.files = undefined;
        this.dockerfile = undefined;
      }
      this.properties = properties;
      await this.getProcessFiles(addFilesManually);
    },
    async getProcessFiles(addFilesManually) {
      const rawFiles = await importService.getProcessBucketFiles(this.bucketId);
      this.files = this.buildTreeFiles(rawFiles);
      if (this.files.length > 0 && addFilesManually) {
        // files[0] -> Show only files under root
        // console.log(this.files[0].children[0])
        this.files[0].children.map(file => {
          this.$refs.dropzone.manuallyAddFile(
            {
              name: file.name
            },
            importService.processBucketUploadFilesURL(this.bucketId)
          );
        });
      }
    },
    /**
     * Convert raw files data from Import Service into tree by adding children
     * filed to all file element. Also adding a name property into elements to be
     * interpreted by TreeItem component.
     * @param rawFiles
     * @returns Array of files, with children attr. list.
     */
    buildTreeFiles(rawFiles) {
      let map = {},
        files = [];
      for (let index in rawFiles) {
        const rawFile = rawFiles[index];
        map[rawFile.id] = index;
        files.push({
          id: rawFile.id,
          parent: rawFile.parent,
          name: rawFile.text,
          children: [],
          type: "image/png"
        });
      }
      for (let file of files) {
        if (file.parent !== "#") {
          files[map[file.parent]].children.push(file);
        }
      }
      // return root only
      return files;
    },
    dryRunImport() {
      this.buildPassed = false;
      this.releasing = false;
      this.startImport(importService.dryRunImportProcessBucket);
    },
    commitImport() {
      // FIXME when commit import is successful, new version should be fetched and
      //  bucket remove from the list
      this.buildPassed = false;
      this.releasing = true;
      this.startImport(importService.importProcessBucket);
    },
    startImport(importCall) {
      importCall(this.bucketId)
        .then(() => {
          this.connectEventSource();
        })
        .catch(e => importService.handleError(e));
    },
    async connectEventSource() {
      this.ESClient = new SSEClient(
        `/procimp/events/process-buckets/status?stream=${this.bucketId}`
      );
      this.ESClientConnected = true;
      this.ESClient.addMessageHandler(this.handleSSEMessage);
      this.ESClient.addErrorHandler(this.handleSSEError);
    },
    handleSSEMessage(message) {
      // let data = JSON.parse(message.data);
      this.importStatus.logs = message["content"];

      // We check if the import is complete and cleaned up.
      // if so, we keep the status of the previous message (failed or succeeded)
      // and just disconnect the client instead
      if (message["status_text"] === IMPORT_COMPLETE) {
        this.ESClient.disconnect();
        this.ESClientConnected = false;
      } else {
        this.importStatus.statusText = message["status_text"];
      }

      this.importStatus.statusMessage = message["status_message"];
      this.importStatus.statusCode = message["status_code"];
      if (this.importSuccess && !this.buildPassed) {
        this.buildPassed = true;
        let event = this.releasing ? "release" : "build";
        this.$emit(`${event}-passed`);
        console.debug(`${event.toUpperCase()} PASSED`);
      }
    },
    handleSSEError(error) {
      console.log(error);
      this.ESClientConnected = false;
      this.connectionLost = true;
      this.$notify({
        group: "global",
        type: "error",
        title: `Connection was lost during build`,
        duration: 3000,
        text: ""
      });
      // If the connection crashed midway through, change the appearance and error
      // Otherwise keep the original color (succeeded or failed) (e.g. connection failure during cleanup)
      if (this.importPending) {
        this.importStatus.statusCode = undefined;
        this.importStatus.statusMessage = "Connection lost";
        this.importStatus.statusText = "Unable to retrieve status";
      }
    }
  },
  computed: {
    developerMode() {
      return this.$store.state.settings.developerMode;
    },
    bucket() {
      return this.$store.getters["knowledge/get"](`process-buckets/${this.bucketId}`);
    },
    importSuccess() {
      return this.importStatus.statusText === IMPORT_SUCCESS;
    },
    importFailed() {
      return this.importStatus.statusText === IMPORT_FAILED;
    },
    importPending() {
      return this.importStatus.statusText === IMPORT_PENDING;
    },
    importVariant() {
      if (this.importStatus.statusCode === undefined) {
        return "disabled";
      } else if (this.importPending) {
        return "pending";
      } else if (this.importSuccess) {
        return "success";
      } else if (this.importFailed) {
        return "failed";
      } else {
        console.warn(`Unknown import status ${this.importStatus.statusText}`);
        return "default";
      }
    },
    dryRunPassed() {
      return this.importSuccess;
    },
    streamUnconnected() {
      return this.importStatus.statusCode === undefined;
    }
  }
};
</script>

<style scoped>
.logs {
  font-family: "Courier New", serif;
  color: black;
}
</style>
