<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-form-row class="title">
          <b-col v-if="!editor.editing">
            <h3>{{ dashboard.verboseName }}</h3>
          </b-col>
          <b-col sm="5" v-else>
            <b-form-input
              id="verbose-name"
              size="lg"
              v-model="dashboard.verboseName"
              placeholder="Dashboard name"
            ></b-form-input>
          </b-col>
        </b-form-row>
      </b-col>

      <b-col>
        <b-navbar class="my-3">
          <b-navbar-nav class="ml-auto">
            <b-nav-form v-show="editorEnabled && is_editable">
              <b-col>
                <b-button
                  v-show="editing"
                  variant="success"
                  size="sm"
                  class="no-border-radius mr-2"
                  @click="saveChanges"
                  >Save changes
                </b-button>

                <!--  SWITCH TO VIEW MODE                  -->
                <b-button
                  v-show="editing"
                  variant="outline-dark"
                  size="sm"
                  class="no-border-radius mr-2"
                  @click="confirmDiscard"
                >
                  <fa-icon :icon="'eye'" class="mr-2" />
                  <span>Switch to view mode</span>
                </b-button>

                <!--  EDIT DASHBOARD                 -->
                <b-button
                  v-show="!editing"
                  variant="outline-dark"
                  size="sm"
                  class="no-border-radius mr-2"
                  @click="editing = !editing"
                >
                  <fa-icon :icon="'pen'" class="mr-2" />
                  <span>Edit dashboard</span>
                </b-button>

                <!--  SWITCH TO VIEW MODE BAK                 -->

                <!--                    <b-button variant="outline-dark" size="sm"-->
                <!--                              class="no-border-radius mr-2"-->
                <!--                              @click="revertChanges">-->
                <!--                        <fa-icon :icon="editing ? 'eye' : 'pen'" class="mr-2"/>-->
                <!--                        <span v-if="editing">Switch to view mode</span>-->
                <!--                        <span v-else>Edit dashboard</span>-->
                <!--                    </b-button>-->

                <b-button
                  v-show="editing"
                  variant="outline-dark"
                  size="sm"
                  class="no-border-radius mr-2"
                  @click="showComponents = !showComponents"
                >
                  <fa-icon :icon="showComponents ? 'arrow-right' : 'arrow-left'" class="mr-2" />
                  <span v-if="showComponents">Hide components</span>
                  <span v-else>Show components</span>
                </b-button>
              </b-col>
            </b-nav-form>
          </b-navbar-nav>
        </b-navbar>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <grid-layout
          :layout.sync="dashboard.layout"
          :col-num="12"
          :row-height="30"
          :is-draggable="editor.editing"
          :is-resizable="editor.editing"
          :is-mirrored="false"
          :vertical-compact="true"
          :margin="[20, 20]"
          :auto-size="true"
          :use-css-transforms="true"
        >
          <grid-item
            v-for="(item, index) in dashboard.layout"
            :key="index"
            :x="item.x"
            :y="item.y"
            :w="item.w"
            :h="item.h"
            :i="item.i"
            :class="{ 'pb-2': editor.editing }"
            @resized="resizedEvent"
          >
            <b-button
              variant="danger"
              class="delete"
              size="sm"
              @click="removeFromDashboard(item)"
              v-show="editor.editing"
              >X
            </b-button>
            <component
              class="inner-item"
              :class="{ 'no-click': editor.editing }"
              v-bind:is="item['component']"
            />
          </grid-item>
        </grid-layout>
      </b-col>
      <b-col v-if="showComponentBar" cols="2" class="pl-0">
        <component-bar />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ComponentBar from "@/plugins/dashboards/components/ComponentBar";
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import VueGridLayout from "vue-grid-layout";
import { vueComponents } from "@/plugins/dashboards/components/components";
import {
  CHANGE_DASHBOARD,
  UPDATE_DASHBOARD,
  RELOAD_DASHBOARD
} from "@/plugins/dashboards/store/action-types";
import {
  DISABLE_EDITOR,
  ENABLE_EDITOR,
  UPDATE_EDIT_MODE,
  REMOVE_FROM_DASHBOARD,
  UPDATE_SHOW_COMPONENTS
} from "@/plugins/dashboards/store/mutation-types";

export default {
  name: "Dashboard",
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    ...vueComponents,
    ComponentBar
  },
  data: function() {
    return {
      dashboardId: undefined
    };
  },
  created() {
    this.changeDashboardTo(this.$route.params.id);
  },
  mounted() {
    // this.$root.$on('dashboard-insert', this.dashboardInsert)
    this.enableEditor();
    this.$root.$on("scrollToBottom", this.scrollToBottom);
  },
  beforeDestroy() {
    this.disableEditor();
  },

  beforeRouteLeave(to, from, next) {
    this.disableEditMode();
    next();
  },
  watch: {
    $route(/*to, from*/) {
      this.changeDashboardTo(this.$route.params.id);
      this.disableEditMode();
    }
  },
  methods: {
    resizedEvent(/*i, newH, newW, newHPx, newWPx*/) {
      // FIXME what to do with this event ?
      // console.debug("Received resized event for:", i)
    },
    disableEditMode() {
      console.debug("disabling editing");
      this.updateEditMode(false);
    },
    removeFromDashboard(item) {
      this.$store.commit(`dashboards/${REMOVE_FROM_DASHBOARD}`, item);
    },
    changeDashboardTo(dashboardId) {
      this.$store.dispatch(`dashboards/${CHANGE_DASHBOARD}`, dashboardId);
      // this.dashboardId = dashboardId;
      // this.changeDashboard(this.dashboardId);
    },
    scrollToBottom() {
      window.scrollTo({
        left: 0,
        top: document.body.scrollHeight,
        behavior: "smooth"
      });

      // let H = 0
      // let checkHeight = setInterval(() => {
      //     if (H === document.body.scrollHeight){
      //         window.scrollTo({left: 0, top: H, behavior: 'smooth'})
      //         clearInterval(checkHeight)
      //     } else {
      //         H = document.body.scrollHeight
      //     }
      // }, 25)
    },
    // Methods from navbar
    saveChanges() {
      this.$store.dispatch(`dashboards/${UPDATE_DASHBOARD}`);
    },
    revertChanges() {
      this.$store.dispatch(`dashboards/${RELOAD_DASHBOARD}`);
      this.editing = !this.editing;
    },
    confirmDiscard() {
      this.$bvModal
        .msgBoxConfirm("Are you sure ? This will discard any changes you made to this dashboard")
        .then(value => {
          value ? this.revertChanges() : {};
        });
    },
    ...mapActions({
      changeDashboard: `dashboards/${CHANGE_DASHBOARD}`
    }),
    ...mapMutations({
      enableEditor: `dashboards/${ENABLE_EDITOR}`,
      disableEditor: `dashboards/${DISABLE_EDITOR}`,
      updateEditMode: `dashboards/${UPDATE_EDIT_MODE}`
    })
  },
  computed: {
    editing: {
      get() {
        return this.$store.state.dashboards.editor.editing;
      },
      set(value) {
        this.$store.commit(`dashboards/${UPDATE_EDIT_MODE}`, value);
      }
    },
    showComponents: {
      get() {
        return this.$store.state.dashboards.editor.showComponents;
      },
      set(value) {
        this.$store.commit(`dashboards/${UPDATE_SHOW_COMPONENTS}`, value);
      }
    },
    ...mapState({
      editor: state => state.dashboards.editor,
      dashboard: state => state.dashboards.dashboard,
      defaultDashboardName: state => state.dashboards.defaultDashboardName,
      editorEnabled: state => state.dashboards.editor.enabled,
      is_editable: state => state.dashboards.dashboard.is_editable
    }),
    ...mapGetters({
      showComponentBar: "dashboards/showComponentBar"
    })
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/default/style.scss";
.title {
  margin: 20px 0 0 15px;
  padding: 0;
}

.delete {
  opacity: 1;
  position: absolute;
  top: 0px;
  right: 0px;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root-div {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#root-component {
  padding-right: 350px;
  &.collapsed {
    padding-right: 0;
  }
}

.v-sidebar-menu {
  .vsm-icon {
    padding: 7px;
  }
}

.flex-remaining-height {
  flex: 1;
  display: flex;
  flex-direction: column;
}
</style>
