<template>
  <b-badge :variant="variant" class="role-badge">{{ role }}</b-badge>
</template>

<script>
export default {
  name: "RoleBadge",
  props: {
    role: String
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
  computed: {
    variant() {
      return "dark";
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/default/asb.scss";

.role-badge {
  text-transform: capitalize;
}
</style>
