<template>
  <generic-list
    name="job"
    service="builder"
    :filters="filters"
    :sortBy="sortBy"
    :sortDesc="sortDesc"
    paginate
  >
    <template v-slot:resource-label="{ resource }">
      <b-row>
        <b-col cols="3" align-self="center" class="px-1">
          <status-badge :progress="resource.progress" :status="resource.status" />
        </b-col>
        <b-col align-self="center">
          <span>
            <b>{{ resource.identifier }}</b>
            &nbsp;
            <span>executed at</span>
            &nbsp;
            <span>{{ resource.createdAt | moment("YYYY-MM-DD HH:mm") }}</span>
            &nbsp;
            <span>by</span>
            &nbsp;
            <span>{{ resource.ownerId }}</span>
          </span>
        </b-col>
      </b-row>
    </template>
  </generic-list>
</template>

<script>
import GenericList from "@/components/generic/GenericList";
import StatusBadge from "@/components/share/StatusBadge";

export default {
  name: "JobList",
  components: { StatusBadge, GenericList },
  data() {
    return {
      // Filters
      filters: [
        { name: "ownerId", value: vm => vm.user.username, label: "Owned by me" },
        { name: "workspaces.identifier", value: vm => vm.user.username, label: "In my workspace" }
      ],
      sortBy: "createdAt",
      sortDesc: true
    };
  }
};
</script>

<style scoped></style>
