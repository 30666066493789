<template>
  <b-container class="mt-4" fluid>
    <b-row>
      <b-col cols="12">
        <b-form>
          <b-form-group
            id="input-group-1"
            label="Collection:"
            label-for="input-1"
            description="Collection/Product type"
          >
            <b-form-input
              id="input-1"
              v-model="collection"
              type="text"
              required
              placeholder="Collection or Product type"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-2"
            label="Bounding box:"
            label-for="input-2"
            description="Bounding box"
          >
            <b-form-input
              id="input-2"
              v-model="boundingBox"
              type="text"
              required
              placeholder=""
            ></b-form-input>
          </b-form-group>
          <b-form-group id="input-group-3" label="Date range:" label-for="input-3" description="">
            <b-form-input
              id="input-3"
              v-model="dateRange"
              type="text"
              required
              placeholder=""
            ></b-form-input>
          </b-form-group>

          <b-button class="float-right" variant="primary" @click="search" :disabled="searching">
            <span v-if="searching">Searching...</span>
            <span v-else>Search for products</span>
          </b-button>
        </b-form>
      </b-col>
    </b-row>
    <b-row v-show="productCount > 0">
      <b-col cols="12">
        <h5>Found {{ productCount }} products</h5>
      </b-col>
      <b-col cols="12">
        <b-table striped hover :items="productList"></b-table>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { knowledgeService } from "@/api/asb";

export default {
  name: "SearchProducts",
  data() {
    return {
      collection: "",
      boundingBox: "",
      dateRange: "",
      searching: false,
      productList: [],
      productCount: 0
    };
  },
  methods: {
    search() {
      this.searching = true;
      const params = {
        collection: this.collection,
        boundingBox: this.boundingBox,
        dateRange: this.dateRange
      };
      knowledgeService
        .searchMEPProducts(params)
        .then(data => {
          console.debug("recv products", data);
          this.productList = data["product_list"];
          this.productCount = data["product_count"];
        })
        .catch(e => knowledgeService.handleError(e))
        .finally(() => (this.searching = false));
    }
  }
};
</script>

<style scoped></style>
