<template>
  <b-row>
    <b-col cols="12">
      <empty
        class="my-3"
        v-if="!hasItems"
        :text="`No${label ? ' ' + pluralize(label) : 'thing'}`"
        no-icon
      ></empty>
      <b-row class="mb-2" v-for="(itemRoles, item) in items" :key="item">
        <b-col cols="2">
          {{ item }}
        </b-col>
        <b-col>
          <span v-show="readonly" v-for="role in roleOptions" :key="role" class="capitalize">
            <boolean-icon :value="itemRoles.includes(role)"></boolean-icon>
            <span class="mx-2">{{ role }}</span>
          </span>
          <b-form-checkbox-group
            v-show="!readonly"
            switches
            class="capitalize"
            v-model="items[item]"
            :options="roleOptions"
          ></b-form-checkbox-group>
        </b-col>
      </b-row>
    </b-col>
    <b-col v-if="hasItems" cols="12">
      <b-form-text v-for="(actions, role) in roles" :key="role">
        <b class="capitalize">{{ role }}: </b>
        <span
          >Can {{ actions.join(", ").replace(/, ([^,]*)$/, " and $1") }} any resources in the
          workspace</span
        >
      </b-form-text>
    </b-col>
    <b-col v-show="!readonly" cols="12">
      <b-form class="float-right" inline>
        <b-form-select v-model="newItem" class="mr-2">
          <option :value="undefined" disabled>-- Select {{ label }} --</option>
          <option v-for="item in remainingItems" :key="item.identifier" :value="item.identifier"
            >{{ item[itemLabelField] }}
          </option>
        </b-form-select>
        <b-btn variant="outline-success" size="md" @click="addItem">Add {{ label }}</b-btn>
      </b-form>
    </b-col>
  </b-row>
</template>

<script>
import Empty from "@/components/share/Empty";
import BooleanIcon from "@/components/share/BooleanIcon";

export default {
  name: "RoleTable",
  components: { Empty, BooleanIcon },
  props: {
    items: {
      type: Object
    },
    remainingItems: {
      type: Array
    },
    label: {
      type: String
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    itemLabelField: {
      type: String,
      default: "label"
    }
  },
  created() {},
  data() {
    return {
      newItem: undefined,
      // FIXME: unhardcode roles
      roles: {
        user: ["view"],
        operator: ["view", "change"],
        developer: ["view", "add", "change", "delete"]
      }
    };
  },
  methods: {
    addItem() {
      if (this.newItem) {
        this.$emit("add-item", this.newItem);
        this.$forceUpdate();
      }
    },
    pluralize(word) {
      // dirty pluralize..
      return word instanceof String && word.endsWith("s") ? word : `${word}s`;
    }
  },
  computed: {
    hasItems() {
      return Object.keys(this.items).length !== 0;
    },
    roleOptions() {
      return Object.keys(this.roles);
    }
  }
};
</script>

<style scoped>
.capitalize {
  text-transform: capitalize;
}
</style>
