<template>
  <div class="padding no-v-padding" style="height: 100%">
    <b-overlay :show="loading" style="height: 100%">
      <div class="map" id="map" style="height: 100%"></div>
    </b-overlay>
  </div>
</template>

<script>
import maplibregl from "maplibre-gl";
export default {
  name: "COGMap",
  props: {
    tiff_url: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      map: undefined,
      cog_tile_server: process.env.VUE_APP_COG_TILESERVER_URL,
      loading: false
    };
  },
  mounted() {
    this.initMap();
    // this.addTiffToMap();
  },
  methods: {
    initMap() {
      this.map = new maplibregl.Map({
        container: "map",
        style: {
          version: 8,
          sources: {
            "toner-lite": {
              type: "raster",
              tiles: [
                // "https://stamen-tiles-a.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}.png",
                "https://tile.openstreetmap.org/{z}/{x}/{y}.png"
              ],
              tileSize: 256,
              attribution:
                'Map tiles by <a href="http://stamen.com">Stamen Design</a>, under <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a href="http://openstreetmap.org">OpenStreetMap</a>, under <a href="http://www.openstreetmap.org/copyright">ODbL</a>.'
            }
          },
          layers: [
            {
              id: "basemap",
              type: "raster",
              source: "toner-lite",
              minzoom: 0,
              maxzoom: 20
            }
          ]
        },
        center: [0, 0],
        zoom: 1
      });
      this.map.on("load", () => {
        if (this.tiff_url !== "") {
          this.addTiffToMap();
        }
        this.map.resize();
      });
    },
    addTiffToMap() {
      this.loading = true;
      this.$emit("geotiff-loading");
      var tiff_info_endpoint = `${this.cog_tile_server}/cog/statistics?url=${this.tiff_url}`;
      fetch(tiff_info_endpoint)
        .then(res => {
          if (res.ok) return res.json();
          this.$emit("geotiff-loaded");
          this.loading = false;
          throw new Error("Network response was not ok.");
        })
        .then(data => {
          let rescale_factor = `${data.b1.min},${data.b1.max}`;
          var tilejson_endpoint = `${this.cog_tile_server}/cog/tilejson.json?url=${this.tiff_url}${
            rescale_factor ? "&rescale=" + rescale_factor : ""
          }`;
          fetch(tilejson_endpoint)
            .then(res => {
              if (res.ok) return res.json();
              this.$emit("geotiff-loaded");
              throw new Error("Network response was not ok.");
            })
            .then(data => {
              data.tiles = [
                `${this.cog_tile_server}/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?url=${this.tiff_url}&rescale=${rescale_factor}`
              ];

              let bounds = [...data.bounds];

              // Create 20% padding around the actual image
              let diffx = (bounds[2] - bounds[0]) / 10;
              let diffy = (bounds[3] - bounds[1]) / 10;
              // Limit to the max map size if the bounds exceed 90/180 degrees
              this.map.fitBounds([
                [
                  bounds[0] - diffx < -180 ? -180 : bounds[0] - diffx,
                  bounds[1] - diffy < -90 ? -90 : bounds[1] - diffy
                ],
                [
                  bounds[2] + diffx > 180 ? 180 : bounds[2] + diffx,
                  bounds[3] + diffy > 90 ? 90 : bounds[3] + diffy
                ]
              ]);

              if (!this.map.getLayer("raster")) {
                this.map.addSource("raster", {
                  type: "raster",
                  bounds: bounds,
                  tiles: data.tiles,
                  tileSize: 256 // This might be false but it's not in TileJSON
                });
                this.map.addLayer({
                  id: "raster",
                  type: "raster",
                  source: "raster"
                });
              }
              this.loading = false;
              this.$emit("geotiff-loaded");
            })
            .catch(err => {
              console.warn(err);
            });
        });
    }
  },

  watch: {
    tiff_url(value) {
      if (this.map.getLayer("raster")) {
        this.map.removeLayer("raster");
        this.map.removeSource("raster");
      }
      if (value !== "") {
        this.addTiffToMap();
      }
    }
  }
};
</script>

<style></style>
